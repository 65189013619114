import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgAppointmentCancel = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <rect width={48} height={48} rx={4} fill="#FFFCFC" />
    <rect
      x={0.5}
      y={0.5}
      width={47}
      height={47}
      rx={3.5}
      stroke="#E23C51"
      strokeOpacity={0.05}
    />
    <path
      d="M30 18 18 30M18 18l12 12"
      stroke="#E23C51"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgAppointmentCancel;
