import { COLORS } from 'constants/colors'
import { SalonStaff } from 'core/generated'
import React from 'react'
import { Button, Heading, Paragraph, SvgRefresh, SvgUser } from 'ui'

const SelectedStaffView = ({
  selectedStaff,
  openSelectStaffPage
}: {
  selectedStaff: SalonStaff,
  openSelectStaffPage: () => void
}) => {
  return (
    <div className='flex items-center justify-between p-4 rounded-md text-green-300 bg-green-300/10 cursor-pointer'>
      <div className="flex space-x-4 items-center">
        <SvgUser width="20px" height="20px" />
        <div className="flex flex-col">
          <Heading variant='h1' size='b5' weight='bold' color={COLORS.GREEN[300]} className="capitalize">{selectedStaff?.user?.firstName}</Heading>
          <Paragraph size='b6' color={COLORS.GREEN[300]}>Staff Selected</Paragraph>
        </div>
      </div>

      <Button
        variant="text"
        size="none"
        onClick={openSelectStaffPage}
      >
        <SvgRefresh width="20px" height="20px" />
      </Button>
    </div>
  )
}

export default SelectedStaffView
