import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgMember = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <rect width={32} height={32} rx={4} fill="#F3F6F6" />
    <g clipPath="url(#member_svg__a)">
      <path
        d="M14.5 14.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6ZM20.5 20.125c0 1.864 0 3.375-6 3.375s-6-1.511-6-3.375 2.687-3.375 6-3.375c3.314 0 6 1.511 6 3.375Z"
        stroke="#667368"
        strokeWidth={1.5}
      />
      <path
        d="m21.25 13.483-.399.396a.563.563 0 0 0 .798 0l-.399-.396Zm-.342 2.473c-.365-.278-.694-.5-.959-.79-.245-.267-.387-.543-.387-.892h-1.125c0 .713.311 1.248.683 1.653.353.385.808.698 1.105.924l.683-.894Zm-1.346-1.682c0-.309.18-.558.41-.66.201-.088.518-.097.879.265l.798-.793c-.652-.656-1.459-.799-2.132-.5a1.845 1.845 0 0 0-1.08 1.688h1.125Zm.663 2.577c.111.085.257.195.41.282.151.087.364.18.615.18v-1.125c.026 0 .013.008-.061-.034a2.592 2.592 0 0 1-.281-.197l-.683.894Zm2.05 0c.297-.227.752-.539 1.104-.924.373-.405.683-.941.683-1.652h-1.125c0 .348-.141.624-.387.893-.264.288-.593.51-.958.789l.683.894Zm1.788-2.576c0-.75-.44-1.406-1.08-1.69-.672-.298-1.48-.155-2.132.501l.798.793c.36-.362.678-.353.877-.265a.72.72 0 0 1 .412.661h1.125Zm-2.471 1.681c-.09.072-.183.138-.28.198-.075.043-.088.034-.062.034v1.125c.25 0 .463-.093.615-.18.153-.086.299-.197.41-.282l-.683-.894Z"
        fill="#667368"
      />
    </g>
    <defs>
      <clipPath id="member_svg__a">
        <path fill="#fff" transform="translate(7 7)" d="M0 0h18v18H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgMember;
