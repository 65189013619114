/* eslint-disable @typescript-eslint/no-explicit-any */
import { AppointmentService, HeaderImageUrl, PackageService, SalonStaff, Service, VisitSummaryGraphData, VoucherService } from "core/generated";
import { BookingPackages, BookingVoucherService } from "../../modals/types";
import { formatInToPrice } from "../../utils/misc";
import { getServiceTimeFromBookingSite } from "../../utils/utils";
import { SummaryData } from "../homeComponents/types";
import { BookingService, CheckoutFormDataInput, GroupedServices, ISalonAppointmentAvailabilityProps, PurchasePreviewSalonPackageRecipient, PurchaseSalonPackageRecipient } from "./types";
import { IOpeningHours } from "../types";

export const formatOpenHoursForPublic = (salonHours?: IOpeningHours) => {
  if (!salonHours) return;
  const dataArray = Object.entries(salonHours).map(([day, time]) => ({
    day,
    openTime: time?.openTime || null,
    closeTime: time?.closeTime || null,
  }));
  const filteredDataArray = dataArray.filter(({ openTime, closeTime }) => openTime !== null && closeTime !== null);

  if (filteredDataArray.length === 0) {
    return;
  }

  const start = filteredDataArray[0];
  const end = filteredDataArray[filteredDataArray.length - 1];
  const openTimeStr = start?.openTime > 12 ? `${start?.openTime - 12}pm` : `${start?.openTime}am`;
  const closeTimeStr = end?.closeTime > 12 ? `${end?.closeTime - 12}pm` : `${end?.closeTime}am`;

  return `${start?.day} - ${end?.day}: ${openTimeStr} - ${closeTimeStr}`;
}

export const calculateTotal = (services?: Service[]) => {
  return services?.reduce((acc, { price }) => acc + price, 0);
}

export const convertServicesNamesToString = (services?: Service[]) => {
  const title = services?.map(({ name }) => name).join(', ') || '';
  return title;
}

export const convertAppointmentServicesNamesToString = (services?: AppointmentService[]) => {
  const title = services?.map(({ name }) => name).join(', ') || '';
  return title;
}

export const convertSalonServicesNamesToString = (services?: Service[]) => {
  const title = services?.map(({ name }) => name).join(', ') || '';
  return title;
}

export const convertPackageVoucherSalonServicesNamesToString = (services?: PackageService[]) => {
  const title = services?.map(({ name }) => name).join(', ') || '';
  return title;
}

export const convertSalonPackageServicesNamesToString = (services?: PackageService[]) => {
  const title = services?.map(({ name, quantity }) => `${name} x${quantity}`).join(', ') || '';
  return title;
}

export const convertVoucherServicesNamesToString = (services?: VoucherService[]) => {
  const title = services?.map(({ name, quantity }) => `${name} x${quantity}`).join(', ') || '';
  return title;
}

export const convertSelectedVouchersOrAllVouchersServiceesToString = (services: BookingVoucherService[]) => {
  const checkIfAnyServiceIsSelected = services?.filter(({ selected }) => selected);

  if (checkIfAnyServiceIsSelected?.length) {
    return checkIfAnyServiceIsSelected?.map(({ name, cartQuantity }) => `${name} x${cartQuantity}`).join(', ') || '';
  }

  return convertVoucherServicesNamesToString(services);
}

export const convertRemainingVoucherServicesNamesToString = (services?: VoucherService[]) => {
  const title = services?.map(({ name, quantity, isRedeemed, redeemedCount }) => !isRedeemed && `${name} x ${quantity - redeemedCount}`).filter(Boolean).join(', ') || '-';
  return title;
}

// export const convertVoucherServicesNamesToStringToShowNonRedeemed = (services?: IServiceProps[]) => {
//   const title = services?.filter(({ isRedeemed }) => !isRedeemed).map(({ name }) => name).join(', ') || '';
//   return title;
// }

export const getVouchersNamesString = (vouchers: any) => {
  const namesString = vouchers
    .map((voucher) => `${voucher.name} x${voucher.quantity}`)
    .join(', ');

  return namesString;
}

export const getVouchersServicesNamesString = (vouchers: BookingPackages) => {
  const servicesNamesArray = Array?.isArray(vouchers) && vouchers.flatMap((voucher) =>
    voucher.packageServices.map((service) => `${service?.name} x${service?.quantity}`)
  );

  const namesString = servicesNamesArray.join(', ');
  return namesString;
}

export const getVoucherRecipientsNamesString = (recipients: PurchaseSalonPackageRecipient[]) => {
  const nameArray = recipients?.map((recipient) => recipient?.firstName).join(', ') || '';
  return nameArray;
}

export const convertServicesToStaffString = (services?: any[]) => {
  const title = services
    ?.map(({ staff }) => {
      if (Array.isArray(staff)) {
        return staff.map((staffMember) => staffMember.firstName).join(', ');
      }
      return '';
    })
    .join(', ') || '';

  return title;
}

export const convertAppointmentServicesToStaffString = (services?: AppointmentService[]) => {
  const title = services
    ?.map(({ appointmentServiceStaff }) => {
      if (Array.isArray(appointmentServiceStaff)) {
        return appointmentServiceStaff.map((staffMember) => staffMember?.salonStaff?.user?.firstName).join(', ');
      }
      return '';
    })
    .join(', ') || '';

  return title;
}

export const convertStaffToNameString = (staff?: SalonStaff[]) => {
  const title = staff?.map(({ user }) => user?.firstName).join(', ') || '';
  return title;
}

export const convertServicesNamesToStringForBooking = (services?: Service[]) => {
  const title = services?.map(({ name }) => name).join(', ') || '';
  return title;
}

export const convertServicesIdsToString = (services?: Service[]): string[] => {
  return Array.isArray(services) && services.length ? services?.map(({ id }) => id) : [];
}

export const convertServicesToTimeAvailabilityPayload = (services?: BookingService[]): {
  serviceId: string;
  staffId: string[] | null
}[] => {
  return Array.isArray(services) && services.length ? services?.map(({ id, staff, staffIds }) => ({
    serviceId: id,
    staffId: staff ? [staff?.id] : staffIds?.length ? staffIds : null,
  })) : [];
}

export const convertServicesToAppointmentStaffServiceForPreviewPrice = (services?: BookingService[], startAt?: string, bookingSite?: boolean) => {
  const newServiceIds: {
    staffIds: string[] | null;
    serviceId: string;
    quantity: number;
    startAt: string;
    price?: number;
    membershipDiscountAmount?: number
  }[] = Array.isArray(services) && services.length ? services.map((service, index) => ({
    serviceId: service?.id,
    staffIds: service?.staff ? [service?.staff?.id] : null,
    quantity: service?.quantity,
    startAt: getServiceTimeFromBookingSite(index, startAt, services[index - 1]?.duration, bookingSite),
    price: service?.staffPrice,
    membershipDiscountAmount: service?.membershipDiscountAmount
  })) : [];

  return newServiceIds;
}

export const convertServicesToAppointmentStaffService = (services?: BookingService[]) => {
  const newServiceIds: {
    staffIds: string[] | null;
    serviceId: string;
    quantity: number;
    price?: number
  }[] = Array.isArray(services) && services.length ? services.map((service) => ({
    serviceId: service?.id,
    staffIds: service?.staff ? [service?.staff?.id] : null,
    quantity: service?.quantity || 1,
    price: service?.staffPrice
  })) : [];

  return newServiceIds;
}

export const convertVoucherServicesToAppointmentStaffService = (services?: BookingVoucherService[]) => {
  const newServiceIds: {
    staffIds: string[] | null;
    serviceId: string;
    quantity: number;
  }[] = Array.isArray(services) && services.length ? services
    .filter(service => service?.selected)
    .map(service => ({
      serviceId: service?.serviceId,
      staffIds: service?.staff ? [service?.staff?.id] : null,
      quantity: service?.cartQuantity || 1,
    })) : [];

  return newServiceIds;
}

export const convertServiceOptionsToIdsString = (services?: []): string[] => {
  return Array.isArray(services) && services.length ? services?.map(({ value }) => value) : [];
}

export const convertAvailabilitiesToCalendarFormat = (newDates: ISalonAppointmentAvailabilityProps) => {
  const dates = Array.isArray(newDates) && newDates?.map((date) => {
    const timeSlots = date.results.map((result) => {
      return {
        time: result.startTime,
        isAvailable: true,
      }
    })
    return {
      date: date.date,
      isAvailable: timeSlots.length > 0,
      timeSlots,
    }
  })
  return dates;
};

export const calculatePercentageOff = (value: number, percentage: number): number => {
  if (percentage < 0 || percentage > 100) {
    throw new Error('Percentage must be between 0 and 100');
  }

  const discountAmount = (percentage / 100) * value;
  const discountedValue = value - discountAmount;

  return discountedValue;
};

export const calculatePriceWithPercentage = (amount: number, percentage: number): number => {
  if (percentage < 0 || percentage > 100) {
    throw new Error('Percentage must be between 0 and 100');
  }

  const price = (percentage / 100) * amount;
  return price;
};

export const hasHomeAsLocation = (location?: string) => {
  if (!location) return false;
  return location.toLowerCase() === 'salon' ? false : location.toLowerCase() === 'flexible' ? true : true;
}

export const hasHomeAsOnlyLocation = (location?: string) => {
  if (!location) return false;
  return location.toLowerCase() === 'home' ? true : false;
}

export const showPackageDetails = (salonPackage: any, currency: string) => {
  const { description, validityMonths, packageServices, usesCustomPrice, customPrice, totalPrice } = salonPackage;
  const servicesString = convertSalonPackageServicesNamesToString(packageServices);
  return [
    {
      label: 'Package Services',
      value: servicesString,
    }, {
      label: 'Valid For',
      value: validityMonths + ` Month${validityMonths > 1 ? 's' : ''}`,
    }, {
      label: 'Description',
      value: description,
    }, {
      label: 'Total Price',
      value: usesCustomPrice ? formatInToPrice(customPrice, currency) : formatInToPrice(totalPrice, currency),
    }
  ]
}

export const calculatePackagesTotalPrice = (bookingPackages: any): number => {
  let totalPrice = 0;

  bookingPackages.forEach(voucher => {
    voucher.totalPrice = calculatePackageTotalPrice(voucher);
    totalPrice += voucher.totalPrice; // Accumulate the total price
  });

  return totalPrice;
};

const calculatePackageTotalPrice = (bookingPackage: any): number => {
  // Multiply totalPrice by quantity
  const totalPrice = bookingPackage?.usesCustomPrice ? bookingPackage?.customPrice : bookingPackage?.totalPrice;
  return totalPrice * bookingPackage.quantity;
};


export const convertToPurchaseSalonPackageInput = (input: Record<string, any>, selectedPackages: BookingPackages): PurchaseSalonPackageRecipient[] => {
  const recipients: PurchaseSalonPackageRecipient[] = [];

  for (const [key, value] of Object.entries(input)) {
    const packageItem = selectedPackages.find((packageItem) => packageItem.id === key);
    if (!packageItem) continue;
    const { phone, name, email, countryCode, callingCode, otherRecipients } = value;

    recipients.push({
      firstName: name.split(' ')[0],
      lastName: name.split(' ')[1] || ' ', // Assuming there might be a last name
      email,
      countryCode,
      callingCode,
      phone,
      packages: [{
        packageId: key,
        quantity: Array.isArray(otherRecipients) && otherRecipients?.length > 0 ? packageItem?.quantity - otherRecipients?.length : packageItem?.quantity, // Assuming a default quantity of 1, modify as needed
      }],
    });

    if (otherRecipients && Array.isArray(otherRecipients) && otherRecipients?.length > 0) {
      // map otherRecipients array
      otherRecipients.map((otherRecipient) => {
        const { name, email, countryCode, callingCode, phone } = otherRecipient;
        recipients.push({
          firstName: name.split(' ')[0],
          lastName: name.split(' ')[1] || ' ', // Assuming there might be a last name
          email,
          countryCode,
          callingCode,
          phone,
          packages: [{
            packageId: key,
            quantity: 1, // Assuming a default quantity of 1, modify as needed
          }]
        })
      });
    }
  }

  const mergedData: PurchaseSalonPackageRecipient[] = [];

  recipients.forEach((item) => {
    const duplicateIndex = mergedData.findIndex(
      (existingItem) =>
        existingItem.email === item.email && existingItem.phone === item.phone
    );

    if (duplicateIndex !== -1) {
      // Merge packages
      mergedData[duplicateIndex].packages.push(...item.packages);
    } else {
      // Add new item to mergedData
      mergedData.push({ ...item });
    }
  });

  return mergedData;
}

export const convertToPreviewPurchaseSalonPackageInput = (input: Record<string, any>, selectedPackages: BookingPackages): PurchasePreviewSalonPackageRecipient[] => {
  const recipients: PurchasePreviewSalonPackageRecipient[] = [];

  for (const [key, value] of Object.entries(input)) {
    const packageItem = selectedPackages.find((packageItem) => packageItem.id === key);
    if (!packageItem) continue;
    const { phone, countryCode, otherRecipients } = value;

    recipients.push({
      countryCode,
      phone,
      packages: [{
        packageId: key,
        quantity: Array.isArray(otherRecipients) && otherRecipients?.length > 0 ? packageItem?.quantity - otherRecipients?.length : packageItem?.quantity, // Assuming a default quantity of 1, modify as needed
      }],
    });

    if (otherRecipients && Array.isArray(otherRecipients) && otherRecipients?.length > 0) {
      // map otherRecipients array
      otherRecipients.map((otherRecipient) => {
        const { countryCode, phone } = otherRecipient;
        recipients.push({
          countryCode,
          phone,
          packages: [{
            packageId: key,
            quantity: 1, // Assuming a default quantity of 1, modify as needed
          }]
        })
      });
    }
  }

  const mergedData: PurchasePreviewSalonPackageRecipient[] = [];

  recipients.forEach((item) => {
    const duplicateIndex = mergedData.findIndex(
      (existingItem) =>
        existingItem.phone === item.phone && existingItem.phone === item.phone
    );

    if (duplicateIndex !== -1) {
      // Merge packages
      mergedData[duplicateIndex].packages.push(...item.packages);
    } else {
      // Add new item to mergedData
      mergedData.push({ ...item });
    }
  });

  return mergedData;
}

export const breakSalonPackagesIntoGranular = (packages: any) => {
  const duplicatedPackages: any = [];

  packages.forEach((packageItem) => {
    for (let i = 0; i < packageItem.quantity; i++) {
      duplicatedPackages.push({ ...packageItem, quantity: 1 });
    }
  });

  return duplicatedPackages;
}

export const isPackagesFieldNotEmpty = (input: CheckoutFormDataInput): boolean => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  for (const [_key, value] of Object.entries(input)) {
    const { phone, name, email, countryCode, callingCode, otherRecipients } = value;

    // check if any of the fields are empty
    if (!(phone && name && email && countryCode && callingCode)) {
      return false;
    }

    // if otherRecipients has data, check if any of the fields are empty
    if (otherRecipients && Array.isArray(otherRecipients) && otherRecipients?.length > 0) {
      for (const otherRecipient of otherRecipients) {
        const { name, email, countryCode, callingCode, phone } = otherRecipient;
        if (!(phone && name && email && countryCode && callingCode)) {
          return false;
        }
      }
    }
  }

  return true;
};

export const formatServicesCategory = (services: Service[], leaveCategoryOpen?: boolean) => {
  const groupedServices: GroupedServices[] = Object.entries(
    services.reduce((acc: Record<string, Service[]>, service: Service) => {
      const category = service.category?.name?.toLowerCase() ?? 'Uncategorized';

      if (!acc[category]) {
        acc[category] = [];
      }

      acc[category].push(service);

      return acc;
    }, {})
  ).map(([name, services]) => ({
    orderIndex: services[0].category?.orderIndex ?? null,
    name, services: services.map(service => ({ ...service, selected: false, quantity: 1 })),
    show: leaveCategoryOpen || false,
    category: services[0].category?.name ?? ''
  }));

  // Sort grouped services by name in A-Z order
  groupedServices.sort((a, b) => a.name.localeCompare(b.name));
  groupedServices.sort((a, b) => {
    if (a.orderIndex === null && b.orderIndex === null) {
      return 0;
    }
    if (a.orderIndex === null) {
      return 1;
    }
    if (b.orderIndex === null) {
      return -1;
    }
    return a.orderIndex - b.orderIndex;
  });
  
  return groupedServices;
}

export const formatBookingPackages = (salonPackages: any) => {
  const packages = salonPackages?.map(packageItem => ({
    ...packageItem,
    selected: false,
    quantity: 1,
  }));

  return packages;
}

export const groupWorkingHoursByDay = (hours): {
  day: string,
  times: {
    openTime: string,
    closeTime: string
  }[]
}[] => {
  const daysOrder = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ];
  const sortedData = [...hours]?.sort((a, b) => {
    // Compare days based on order
    const dayDiff = daysOrder.indexOf(a.day) - daysOrder.indexOf(b.day);
    if (dayDiff !== 0) return dayDiff;

    // Compare openTime within the same day, nulls last
    if (!a.openTime && b.openTime) return 1;
    if (a.openTime && !b.openTime) return -1;
    if (!a.openTime && !b.openTime) return 0;

    return a.openTime.localeCompare(b.openTime);
  });

  // Step 2: Group by day
  const groupedData = daysOrder.map((day) => {
    const times = sortedData
      .filter((hour) => hour.day === day && hour?.openTime && hour?.closeTime)
      .map(({ openTime, closeTime }) => ({ openTime, closeTime }));

    return {
      day,
      times,
    };
  });

  return groupedData
}

export const getLastFiveImages = (headerImageUrls: HeaderImageUrl[]) => {
  const length = headerImageUrls?.length || 0;

  if (length === 0) {
    return ['', '', '', '', ''];
  } else if (length === 6) {
    const lastFive = headerImageUrls.slice(length - 5);
    return lastFive.map(url => url.imageUrl);
  } else if (length === 5) {
    const lastFive = headerImageUrls.slice(length - 4);
    const lastFiveStrings = lastFive.map(url => url.imageUrl);
    return [...lastFiveStrings, ''];
  } else if (length === 4) {
    const lastFour = headerImageUrls.slice(length - 3);
    const lastFourStrings = lastFour.map(url => url.imageUrl);
    return [...lastFourStrings, '', ''];
  } else if (length === 3) {
    const lastThree = headerImageUrls.slice(length - 2);
    const lastThreeStrings = lastThree.map(url => url.imageUrl);
    return [...lastThreeStrings, '', '', ''];
  } else if (length === 2) {
    const lastTwo = headerImageUrls.slice(length - 1);
    const lastTwoStrings = lastTwo.map(url => url.imageUrl);
    return [...lastTwoStrings, '', '', '', ''];
  } else { // length === 1
    return ['', '', '', '', ''];
  }
}

export const convertLoyaltyOverviewDataToChart = (data: VisitSummaryGraphData[]): SummaryData => {
  const barChartData: SummaryData = [
    ["Number Of Clients", "Number Of Visits"],
  ];

  // loop through data
  data.forEach((entry) => {
    barChartData.push([entry?.numberOfClients.toString(), entry?.numberOfVisits]);
  });

  return barChartData;
}

export const findCommonStaff = (servicesData) => {
  // Extract staff IDs from each service's serviceStaffs
  const staffIdsPerService = servicesData.map(serviceData =>
    serviceData.serviceStaffs.map(staff => staff.salonStaff.id)
  );

  // Flatten the list of staff IDs and count occurrences
  const allStaffIds = staffIdsPerService.flat();
  const staffIdCounts = allStaffIds.reduce((counts, id) => {
    counts[id] = (counts[id] || 0) + 1;
    return counts;
  }, {});

  // Find staff IDs that appear in all services
  const commonStaffIds = Object.keys(staffIdCounts).filter(
    id => staffIdCounts[id] === staffIdsPerService.length
  );

  // Collect common staff details, ensuring uniqueness
  const commonStaffMap = new Map();

  servicesData.forEach(serviceData => {
    serviceData.serviceStaffs.forEach(staff => {
      if (commonStaffIds.includes(staff.salonStaff.id)) {
        if (!commonStaffMap.has(staff.salonStaff.id)) {
          commonStaffMap.set(staff.salonStaff.id, staff);
        }
      }
    });
  });

  // Convert the map values to an array
  const commonStaffs = Array.from(commonStaffMap.values());

  // If there are common staff, return them; otherwise, return empty data
  return commonStaffs.length ? commonStaffs : [];
}

export const convertToSlug = (str: string): string => {
  return str.toLowerCase().replace(/ /g, '-').replace(/&/g, 'and');
}