import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgAppointmentNoShow = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <rect
      x={0.5}
      y={0.5}
      width={47}
      height={47}
      rx={3.5}
      stroke="#E9E9E9"
      strokeOpacity={0.6}
    />
    <path
      d="M24 33.375a9.375 9.375 0 1 0 0-18.75 9.375 9.375 0 1 0 0 18.75ZM18.375 29.625l12-12"
      stroke="#000"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgAppointmentNoShow;
