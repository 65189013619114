import React from 'react'
import { Paragraph } from '../paragraph';
import { COLORS } from '../../../constants/colors';

const ToggleWithOutLabel = ({ label, isChecked, toggleHandler }) => {
  return (
    <label className="flex items-center cursor-pointer">
      <div className="relative">
        <input
          type="checkbox"
          className="sr-only"
          checked={isChecked}
          onChange={toggleHandler}
        />
        <div
          className={`block w-10 h-6 rounded-full ${
            isChecked ? 'bg-green-300' : 'bg-grey-200'
          }`}
        ></div>
        <div
          className={`dot absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition ${
            isChecked ? 'transform translate-x-4' : ''
          }`}
        ></div>
      </div>
      <div className="ml-4 text-grey-300 font-medium">
        <Paragraph size="b6" color={COLORS.GREY[300]}>  
          {label}
        </Paragraph>
      </div>
    </label>
  );
};

export default ToggleWithOutLabel