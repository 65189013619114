import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgYellowsignal = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g filter="url(#yellowsignal_svg__a)">
      <rect
        x={4}
        width={16}
        height={16}
        rx={8}
        fill="#CFCEAB"
        fillOpacity={0.1}
        shapeRendering="crispEdges"
      />
      <rect
        x={4.25}
        y={0.25}
        width={15.5}
        height={15.5}
        rx={7.75}
        stroke="#CCA41B"
        strokeOpacity={0.1}
        strokeWidth={0.5}
        shapeRendering="crispEdges"
      />
      <rect
        x={6}
        y={2}
        width={12}
        height={12}
        rx={6}
        fill="#CCA41B"
        fillOpacity={0.2}
      />
      <rect x={8} y={4} width={8} height={8} rx={4} fill="#CCA41B" />
    </g>
    <defs>
      <filter
        id="yellowsignal_svg__a"
        x={0}
        y={0}
        width={24}
        height={24}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={4} />
        <feGaussianBlur stdDeviation={2} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_25749_96887"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_25749_96887"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
export default SvgYellowsignal;
