import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgPause = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M2.667 4.667c0-.943 0-1.414.293-1.707.292-.293.764-.293 1.707-.293.943 0 1.414 0 1.707.293.293.292.293.763.293 1.707v6.666c0 .943 0 1.414-.293 1.707-.292.293-.764.293-1.707.293-.943 0-1.414 0-1.707-.293-.293-.292-.293-.763-.293-1.707V4.667Zm6.667 0c0-.943 0-1.414.293-1.707.292-.293.763-.293 1.707-.293.943 0 1.414 0 1.706.293.294.292.294.763.294 1.707v6.666c0 .943 0 1.414-.294 1.707-.292.293-.764.293-1.706.293-.943 0-1.414 0-1.707-.293-.293-.292-.293-.763-.293-1.707V4.667Z"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgPause;
