import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgCircleGreenAddition = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M9.375 0c.866 0 1.696.11 2.49.332a9.748 9.748 0 0 1 2.246.938 8.67 8.67 0 0 1 1.895 1.464A10.22 10.22 0 0 1 17.47 4.64c.416.696.732 1.442.947 2.236.215.794.326 1.628.332 2.5 0 .866-.11 1.696-.332 2.49a9.752 9.752 0 0 1-.938 2.246 8.672 8.672 0 0 1-1.464 1.895 10.22 10.22 0 0 1-1.905 1.465 8.822 8.822 0 0 1-2.236.947 9.819 9.819 0 0 1-2.5.332c-.866 0-1.696-.11-2.49-.332a9.753 9.753 0 0 1-2.246-.938 8.674 8.674 0 0 1-1.895-1.464A10.218 10.218 0 0 1 1.28 14.11a8.821 8.821 0 0 1-.947-2.236A9.817 9.817 0 0 1 0 9.375c0-.866.11-1.696.332-2.49a9.75 9.75 0 0 1 .938-2.246 8.671 8.671 0 0 1 1.464-1.895A10.218 10.218 0 0 1 4.64 1.28 8.822 8.822 0 0 1 6.875.332 9.817 9.817 0 0 1 9.375 0Zm0 17.5c.749 0 1.468-.098 2.158-.293a8.227 8.227 0 0 0 3.584-2.09 7.692 7.692 0 0 0 1.27-1.64 8.757 8.757 0 0 0 .82-1.944c.202-.696.3-1.416.293-2.158 0-.749-.098-1.468-.293-2.158a8.226 8.226 0 0 0-2.09-3.584 7.694 7.694 0 0 0-1.64-1.27 8.76 8.76 0 0 0-1.944-.82 7.478 7.478 0 0 0-2.158-.293c-.749 0-1.468.098-2.158.293a8.228 8.228 0 0 0-3.584 2.09 7.693 7.693 0 0 0-1.27 1.64 8.759 8.759 0 0 0-.82 1.944 7.478 7.478 0 0 0-.293 2.158c0 .749.098 1.468.293 2.158a8.23 8.23 0 0 0 2.09 3.584 7.69 7.69 0 0 0 1.64 1.27 8.755 8.755 0 0 0 1.944.82c.696.202 1.416.3 2.158.293ZM10 8.75h5V10h-5v5H8.75v-5h-5V8.75h5v-5H10v5Z"
      fill="#16796F"
    />
  </svg>
);
export default SvgCircleGreenAddition;
