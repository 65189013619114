import { useMutation, useQuery, gql } from '@apollo/client';
import { Category, Package, PackagesSalesHistory, Product, Service } from 'core/generated';

const SERVICES = gql`
  query Services($salonId: ID!, $q: String) {
    services(salonId: $salonId, q: $q) {
      addedById
      businessId
      createdAt
      customerCanSchedule
      deletedAt
      depositType
      depositValue
      description
      duration
      extraTime
      id
      isDepositRequired
      isPublic
      featured
      featuredIndex
      name
      price
      pricingType
      salonId
      updatedAt
      aftercareMessage
      rebookReminder
      rebookReminderPeriod
      category {
        createdAt
        id
        name
        orderIndex
        updatedAt
      }
      serviceStaffs {
        id
        serviceId
        price
        salonStaff {
          id
          role
          specialty
          user {
            fullName
            firstName
            lastName
          }
        }
      }
    }
  }
`;

const ARCHIVED_SERVICES = gql`
  query ArchivedServices($salonId: ID!, $q: String) {
    archivedServices(salonId: $salonId, q: $q) {
      addedById
      businessId
      createdAt
      customerCanSchedule
      deletedAt
      depositType
      depositValue
      description
      duration
      extraTime
      id
      isDepositRequired
      isPublic
      featured
      name
      price
      pricingType
      salonId
      updatedAt
      category {
        id
        name
        orderIndex
      }
    }
  }
`;

export const useGetServices = (salonId: string, q?: string) => {
  return useQuery<{
    services: Service[]
  }>(SERVICES, {
    variables: { salonId, q },
  });
};

export const useGetArchivedServices = (salonId: string, q?: string) => {
  return useQuery<{
    archivedServices: Service[]
  }>(ARCHIVED_SERVICES, {
    variables: { salonId, q },
  });
};

const CREATE_SERVICE = gql`
  mutation CreateService($input: CreateServiceInput!) {
    createService(input: $input) {
      clientMutationId
      status
      errors {
        message
        property
      }
    }
  }
`;

export const useCreateService = () => {
  const [createService, options] = useMutation<{
    createService: {
      status: number;
      errors: {
        message: string;
        property: string;
      }[];
    };
  }>(CREATE_SERVICE);

  return {
    createService,
    ...options,
  };
};

const DELETE_SERVICE = gql`
  mutation DeleteService($input: DeleteServiceInput!) {
    deleteService(input: $input) {
      status
      errors {
        message
        property
      }
    }
  }
`;

export const useDeleteService = () => {
  const [deleteService, options] = useMutation<{
    deleteService: {
      status: number;
      errors: {
        message: string;
        property: string;
      }[];
    };
  }>(DELETE_SERVICE);

  return {
    deleteService,
    ...options,
  };
};

const DELETE_CATEGORY = gql`
  mutation DeleteCategory($input: DeleteCategoryInput!) {
    deleteCategory(input: $input) {
        clientMutationId
        message
        refreshToken
        status
        errors {
          message
          property
        }
    }
  }
`;

export const useDeleteCategory = () => {
  const [deleteCategory, options] = useMutation<{
    deleteCategory: {
      clientMutationId: string;
      message: string;
      refreshToken: string;
      status: number;
      errors: {
        message: string;
        property: string;
      }[];
    };
  }>(DELETE_CATEGORY);

  return {
    deleteCategory,
    ...options,
  };
};

const ARCHIVE_SERVICE = gql`
  mutation ArchiveService($input: ArchiveServiceInput!) {
    archiveService(input: $input) {
      status
      errors {
        message
        property
      }
    }
  }
`;

export const useArchiveService = () => {
  const [archiveService, options] = useMutation<{
    archiveService: {
      status: number;
      errors: {
        message: string;
        property: string;
      }[];
    };
  }>(ARCHIVE_SERVICE);

  return {
    archiveService,
    ...options,
  };
};

const RESTORE_SERVICE = gql`
  mutation RestoreService($input: RestoreServiceInput!) {
    restoreService(input: $input) {
      status
      errors {
        message
        property
      }
    }
  }
`;

export const useRestoreService = () => {
  const [restoreService, options] = useMutation<{
    restoreService: {
      status: number;
      errors: {
        message: string;
        property: string;
      }[];
    };
  }>(RESTORE_SERVICE);

  return {
    restoreService,
    ...options,
  };
};

const UPLOAD_SERVICE = gql`
  mutation UploadService($input: UploadServiceInput!) {
    uploadService(input: $input) {
      clientMutationId
      status
      errors {
        message
        property
      }
    }
  }
`;

export const useUploadService = () => {
  const [uploadService, options] = useMutation<{
    uploadService: {
      status: number;
      errors: {
        message: string;
        property: string;
      }[];
    };
  }>(UPLOAD_SERVICE);

  return {
    uploadService,
    ...options,
  };
}; 

export const CATEGORIES = gql`
  query Categories {
    categories {
      createdAt
      id
      name
      updatedAt
      orderIndex
      services {
        duration
        name
        price
        id
        salonId
      }
      salonStaffs {
        id
        specialty
        canServeCustomers
        user {
          firstName
          lastName
        }
      }
    }
  }

`;

export const useGetCategories = () => {
  return useQuery<{
    categories: Category[];
  }>(CATEGORIES);
};

const PRODUCTS = gql`
  query Products($salonId: ID!, $isPurchasable: Boolean, $q: String) {
    products(salonId: $salonId, isPurchasable: $isPurchasable, q: $q) {
      id
      name
      category {
        id
        name
      }
      isPurchasable
      description
      stockCount
      retailPrice
      status
      costPrice
      expirationDate
      lowStockThreshold
      imageUrl
      salonId
      totalValue
    }
  }
`;

export const useGetProducts = ({
  salonId,
  isPurchasable,
  q,
}: {
  salonId: string,
  isPurchasable?: boolean,
  q?: string
}) => {
  return useQuery<{
    products: Product[];
  }>(PRODUCTS, {
    variables: {
      salonId,
      isPurchasable,
      q,
    },
    skip: !salonId,
  });
};

const PRODUCT = gql`
  query Product($id: ID!) {
    product(id: $id) {
      id
      name
      category {
        id
        name
      }
      isPurchasable
      description
      stockCount
      retailPrice
      status
      costPrice
      expirationDate
      lowStockThreshold
      imageUrl
      salonId
      totalValue
    }
  }
`;

export const useGetProduct = (id: string) => {
  return useQuery<{
    product: Product;
  }>(PRODUCT, {
    variables: {
      id,
    },
    skip: !id,
  });
};

const UPDATE_PRODUCT_STOCK = gql`
  mutation UpdateProductStock($input: UpdateProductStockInput!) {
    updateProductStock(input: $input) {
      status
      errors {
        message
        property
      }
      productStock {
        id
      }
    }
  }
`;

export const useUpdateProductStock = () => {
  const [updateProductStock, options] = useMutation<{
    updateProductStock: {
      status: number;
      errors: {
        message: string;
        property: string;
      }[];
      productStock: {
        id: string;
      };
    };
  }>(UPDATE_PRODUCT_STOCK);
  return {
    updateProductStock,
    ...options,
  };
};

const CREATE_PRODUCT = gql`
  mutation CreateProduct($input: CreateProductInput!) {
    createProduct(input: $input) {
      status
      errors {
        message
        property
      }
    }
  }
`;

export const useCreateProduct = () => {
  const [createProduct, options] = useMutation<{
    createProduct: {
      status: number;
      errors: {
        message: string;
        property: string;
      }[];
    };
  }>(CREATE_PRODUCT);
  return {
    createProduct,
    ...options,
  };
};

const SALON_PACKAGES = gql`
  query Packages($salonId: ID!, $q: String) {
    packages(salonId: $salonId, q: $q) {
      addedById
      businessId
      createdAt
      customPrice
      deletedAt
      description
      packageServices {
        id
        name
        quantity
        serviceId
      }
      id
      isDiscountApplicable
      isPurchasableAsVoucher
      name
      salonId
      status
      allowSaleOn
      totalPrice
      updatedAt
      usesCustomPrice
      validityMonths
    }
  }
`;

export const useSalonPackages = (salonId: string, q?: string) => {
  return useQuery<{
    packages: Package[];
  }>(SALON_PACKAGES, {
    variables: { salonId, q },
    skip: !salonId,
  });
};

const UPLOAD_PRODUCT = gql`
  mutation UploadProduct($input: UploadProductInput!) {
    uploadProduct(input: $input) {
      status
      errors {
        message
        property
      }
    }
  }
`;

export const useUploadProduct = () => {
  const [uploadProduct, options] = useMutation<{
    uploadProduct: {
      status: number;
      errors: {
        message: string;
        property: string;
      }[];
    };
  }>(UPLOAD_PRODUCT);
  return {
    uploadProduct,
    ...options,
  };
};

const DELETE_PRODUCT = gql`
  mutation DeleteProduct($input: DeleteProductInput!) {
    deleteProduct(input: $input) {
      status
      product {
        id
        name
        category {
          id
          name
        }
        description
        stockCount
        retailPrice
        status
      }
      errors {
        message
        property
      }
    }
  }
`;

export const useDeleteProduct = () => {
  const [deleteProduct, options] = useMutation<{
    deleteProduct: {
      status: number;
      product: Product;
      errors: {
        message: string;
        property: string;
      }[];
    };
  }>(DELETE_PRODUCT);
  return {
    deleteProduct,
    ...options,
  };
};

const CREATE_PACKAGE_VOUCHER = gql`
  mutation CreatePackage($input: CreatePackageInput!) {
    createPackage(input: $input) {
      status
      errors {
        message
        property
      }
      package {
        id
        name
        status
      }
    }
  }
`;

export const useCreatePackage = () => {
  const [createPackage, options] = useMutation<{
    createPackage: {
      status: number;
      errors: {
        message: string;
        property: string;
      }[];
      package: {
        id: string;
        name: string;
        status: string
      };
    };
  }>(CREATE_PACKAGE_VOUCHER);
  return {
    createPackage,
    ...options,
  };
};

const DELETE_PACKAGE = gql`
  mutation DeletePackage($input: DeletePackageInput!) {
    deletePackage(input: $input) {
      status
      errors {
        message
        property
      }
    }
  }
`;

export const useDeletePackage = () => {
  const [deletePackage, options] = useMutation<{
    deletePackage: {
      status: number;
      errors: {
        message: string;
        property: string;
      }[];
    };
  }>(DELETE_PACKAGE);
  return {
    deletePackage,
    ...options,
  };
};

const CREATE_CATEGORY = gql`
  mutation CreateCategory($input: CreateCategoryInput!) {
    createCategory(input: $input) {
      clientMutationId
      status
      category {
        createdAt
        id
        name
        updatedAt
      }
      errors {
        message
        property
      }
    }
  }
`;

export const useCreateCategory = () => {
  const [createCategory, options] = useMutation<{
    createCategory: {
      clientMutationId: string;
      status: number;
      category: Category;
      errors: {
        message: string;
        property: string;
      }[];
    };
  }>(CREATE_CATEGORY);
  return {
    createCategory,
    ...options,
  };
};

const CLIENT_VOUCHER_REDEEMED_HISTORY = gql`
query ClientVoucherRedeemedHistory(
  $voucherId: ID!
) {
	clientVoucherRedeemedHistory(voucherId: $voucherId) {
		dateOfUse
		location
		serviceName
		staffsAssigned
	}
}
`;

export const useClientVoucherRedeemedHistory = ({
  voucherId,
}: {
  voucherId: string;
}) => {
  return useQuery<{
    clientVoucherRedeemedHistory: PackagesSalesHistory[];
  }>(CLIENT_VOUCHER_REDEEMED_HISTORY, {
    variables: {
      voucherId,
    },
    skip: !voucherId,
  });
};

export const UPDATE_CATEGORY_ORDER = gql`
  mutation UpdateCategoryOrder($input: UpdateCategoryOrderInput!) {
    updateCategoryOrder(input: $input) {
        clientMutationId
        message
        refreshToken
        status
        errors {
            message
            property
        }
    }
  }
`;

export const useUpdateCategoryOrder = () => {
  const [updateCategoryOrder, options] = useMutation<{
    updateCategoryOrder: {
      clientMutationId: string;
      message: string;
      refreshToken: string;
      status: number;
      errors: {
        message: string;
        property: string;
      }[];
    };
  }>(UPDATE_CATEGORY_ORDER);

  return {
    updateCategoryOrder,
    ...options,
  };
}

const UPDATE_FEATURED_SERVICES_ORDER = gql`
mutation UpdateFeaturedServicesOrder($input: UpdateFeaturedServicesOrderInput!) {
    updateFeaturedServicesOrder(input: $input) {
        clientMutationId
        message
        refreshToken
        status
        errors {
            message
            property
        }
    }
}
`;

export const useUpdateFeaturedServicesOrder = () => {
  const [updateFeaturedServicesOrder, options] = useMutation<{
    updateFeaturedServicesOrder: {
      clientMutationId: string;
      message: string;
      refreshToken: string;
      status: number;
      errors: {
        message: string;
        property: string;
      }[];
    };
  }>(UPDATE_FEATURED_SERVICES_ORDER);

  return {
    updateFeaturedServicesOrder,
    ...options,
  };
}