
import React, { useEffect, useMemo, useState } from 'react'
import axios from 'axios';
import AccountSetupTemplate from '../AccountSetupTemplate';
import Button from 'ui/atoms/button/Button';
import ToastWrapper from 'ui/molecules/toastWrapper/ToastWrapper';
import { ToastProps } from 'ui/atoms/toast/types';
import { COLORS } from 'constants/colors';
import Paragraph from 'ui/atoms/paragraph/Paragraph';
import Heading from 'ui/atoms/heading/Heading';
import { limitString } from '../../../utils/misc';
import { DEFAULT_CURRENCY } from 'constants/currency';
import { FormatNumber } from 'ui/atoms/formatNumber/FormatNumber';
import AccountSetupArrayShimmer from 'ui/organism/accountSetupArrayShimmer/accountSetupArrayShimmer';
import { SvgEdit, SvgTrash, SvgPlus } from 'ui';
import { API_ERRORS } from 'constants/errors';
import { useToast } from 'hooks/useToast';
import { useSalonCache } from 'hooks/useSalonCache';
import { useModal } from 'hooks/useModal';
import { PageProps } from '../types';
import { AddServiceModal } from '../../../modals/AddServiceModal';
import ImportServices from '../../ImportServices';
import ImportFileLink from '../../ImportFileLink';
import { DeleteServiceDocument, Service, ServicesDocument } from 'core/generated';
import { useDeleteService, useGetServices } from 'api/useCatalogue';

const ServicesComponent = ({ onNextPage, onPrevPage, business }: PageProps) => {
  const {
    isVisible,
    openModal,
    closeModal,
  } = useModal();
  const [selectedService, setSelectedService] = useState<Service | null>(null);
  const { getSalonFieldValue } = useSalonCache();
  const salonId = getSalonFieldValue('id');
  const { addToast, toast } = useToast();
  const businessId = business?.businessId;


  const {
    loading: servicesLoading,
    data: servicesData,
    refetch: getServices,
  } = useGetServices(salonId)
  const services = useMemo(() => servicesData?.services, [servicesData])

  const {
    deleteService,
    loading: deleteServiceLoading,
  } = useDeleteService()

  const showInitialLoadingShimmer = servicesLoading && !services && salonId;

  const deleteServiceAsync = (serviceId: string) => async () => {
    deleteService({
      variables: { input: { id: serviceId } },
    }).then(({ data }) => {
      if (data?.deleteService?.status === 200) {
        addToast({
          variant: "success",
          message: "Service deleted successfully",
        });
        // refresh page
        getServices();
      }

      if (data?.deleteService?.errors?.length) {
        const message = data?.deleteService?.errors[0]?.message || API_ERRORS.BUSINESS_SERVICES_DELETE_FAILED;
        addToast({
          variant: 'error',
          message,
        })
      }
    }).catch(deleteServiceError => {
      const message = deleteServiceError?.response?.data?.message || API_ERRORS.BUSINESS_SERVICES_DELETE_FAILED;
      addToast({
        variant: "error",
        message,
      });
    })
  }

  const editService = (serviceId: string) => () => {
    const service = services?.find((service) => service?.id === serviceId);
    if (!service) return;
    setSelectedService(service);
    openModal();
  }

  const getServicesContent = () => {
    if (showInitialLoadingShimmer) {
      return <AccountSetupArrayShimmer />
    }
    if (Array.isArray(services) && services.length) {
      return (
        <>
          {services.map((service) => (
            <div className='flex items-center justify-between p-2 border-b border-grey-20'>
              <div className='flex flex-col space-y-1'>
                <Heading variant='h2' size='h10' weight='normal' className='text-black'>{service?.name ? limitString(service?.name, 20) : ""}</Heading>
                <Paragraph size='b5' weight='normal' color={COLORS.GREY[400]} className=''>{DEFAULT_CURRENCY}<FormatNumber value={service?.price} /> | Description: {service?.description}</Paragraph>
              </div>
              <div className='flex space-x-6'>
                <SvgEdit width="24px" height="24px" className='cursor-pointer' onClick={editService(service?.id)} />
                <SvgTrash width="24px" height="24px" className='cursor-pointer' onClick={deleteServiceAsync(service?.id)} />
              </div>
            </div>
          ))}
        </>
      )
    }

    return (
      <div className='flex w-full justify-center items-center py-4'>
        <Paragraph size='b5' weight='normal' color={COLORS.GREY[300]} className=''>You have no services yet.</Paragraph>
      </div>
    )
  }

  return (
    <>
      <AccountSetupTemplate
        title='Services'
        subtitle='List out all the services your business provides'
        skip={{
          text: 'Skip Business Setup',
        }}
      >
        <ToastWrapper toast={toast as ToastProps} />
        <div className='flex space-x-4'>
          <ImportServices size='md' addToast={addToast} refetch={getServices} />
          <Button
            variant='primary'
            className=''
            size='md'
            rounded='md'
            type='button'
            onClick={openModal}
          >
            <SvgPlus width="24px" height="24px" color='text-white' />
            Add Service
          </Button>
        </div>
        <ImportFileLink modules='service' />
        <div className='w-full flex flex-col space-y-2 max-h-[400px] overflow-y-scroll'>
          {getServicesContent()}
        </div>
        <div className='w-full flex space-x-2'>
          <Button
            variant='secondary'
            className=''
            disabled={false}
            onClick={onPrevPage}
            size='lg'
            rounded='lg'
            type='button'
          >
            Prev
          </Button>
          <Button
            variant='primary'
            className=''
            disabled={false}
            onClick={onNextPage}
            size='lg'
            rounded='lg'
          >
            Proceed
          </Button>
        </div>
      </AccountSetupTemplate >
      {/* Add Service Modal */}
      <AddServiceModal
        isVisible={isVisible}
        closeModal={closeModal}
        businessId={businessId}
        salonId={salonId}
        service={selectedService}
        refetch={getServices}
        addToast={addToast}
        servicesCount={services?.length}
        removeSelectedServiceFromState={() => setSelectedService(null)}
      />
    </>
  )
}

export default ServicesComponent