import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgAppointmentReceipt = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <rect
      x={0.5}
      y={0.5}
      width={47}
      height={47}
      rx={3.5}
      stroke="#E9E9E9"
      strokeOpacity={0.6}
    />
    <path
      d="M14.572 16.928a2.357 2.357 0 0 1 2.357-2.357h9.857a2.358 2.358 0 0 1 2.357 2.357v9.643h4.286v3.643a3.214 3.214 0 0 1-3.215 3.214H17.786a3.214 3.214 0 0 1-3.215-3.214V16.928Zm14.571 15.215h1.071a1.929 1.929 0 0 0 1.929-1.929v-2.357h-3v4.286ZM16.929 15.857c-.592 0-1.072.48-1.072 1.071v13.286a1.929 1.929 0 0 0 1.929 1.929h10.071V16.928c0-.591-.48-1.071-1.071-1.071h-9.857Zm1.714 3a.643.643 0 1 0 0 1.286h6.428a.643.643 0 1 0 0-1.286h-6.428ZM18 23.786a.643.643 0 0 1 .643-.643h6.428a.643.643 0 1 1 0 1.285h-6.428a.643.643 0 0 1-.643-.642Zm.643 3.642a.643.643 0 0 0 0 1.286h3a.643.643 0 1 0 0-1.286h-3Z"
      fill="#000"
    />
  </svg>
);
export default SvgAppointmentReceipt;
