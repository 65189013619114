import { useMemo, useState } from 'react';
import {
  SvgBrandLoyalty,
  SvgBrandMessaging,
  SvgBrandSms,
  SvgBrandVoucher,
  SvgMasterCard,
  SvgVerve,
  SvgVisa,
} from 'ui';
import { Button, Heading, Paragraph } from '../../ui';
import {
  Card,
  PaystackPaymentAuthorization,
  User,
} from 'core/generated';
import { AddonSubscriptionProps } from './types';
import { formatInToPrice, getShortDate } from '../utils/misc';
import PaymentModal from '../modals/PaymentModal';
import { useModal } from 'hooks';
import { COLORS } from 'constants/colors';
import { getAddonContent } from './settingsUtils';
import PromptCardSelection from './PromptCardSelection';
import { useSalonCache } from 'hooks/useSalonCache';
import { canPerformAction } from '../utils/permission';
import { useLazyQuery } from '@apollo/client';
import { useCards, USER, useSubscribeToAddon, useUnsubscribeAddon } from 'api/useAccount';

const AddonSubscription = (props: AddonSubscriptionProps) => {
  const [paymentAuthorization, setPaymentAuthorization] =
    useState<PaystackPaymentAuthorization>(null);
  const [card, setCard] = useState<Card>(null);
  const { getSalonFieldValue } = useSalonCache();
  const salonId = getSalonFieldValue('id');
  const [showCards, setShowCards] = useState(false);
  const user: User = JSON.parse(localStorage.getItem('userData'));
  const addons = user?.business?.addons;
  const addon = addons?.find((addon) =>
    addon?.addon?.name?.toLowerCase()?.includes(props?.module)
  );
  const {
    isVisible: paymentModalIsvisible,
    openModal: openPaymentModal,
    closeModal: closePaymentModal,
  } = useModal();
  const {
    isVisible: promptPaymentModalIsvisible,
    openModal: openPromptPaymentModal,
    closeModal: closePromptPaymentModal,
  } = useModal();

  const {
    loading: unsubscribeIsLoading,
    unsubscribeAddon
  } = useUnsubscribeAddon()

  const {
    loading: isPaymentPageLoading,
    subscribeToAddon
  } = useSubscribeToAddon()

  const [getUser, { error, data, called }] = useLazyQuery(USER);
  useMemo(() => {
    const currentUser: User = data?.user;

    if (currentUser) {
      // Store user data in localStorage
      localStorage.setItem('userData', JSON.stringify(currentUser));
      localStorage.setItem(
        'role',
        JSON.stringify(currentUser?.salonStaff?.staffRole)
      );
    }

    return currentUser;
  }, [data]);

  const subscribeToAddonAsync = async (selectedCard?: Card) => {
    subscribeToAddon({
      variables: {
        input: {
          addonId: addon.addon?.id,
          ...(selectedCard
            ? {
              cardId: selectedCard?.id,
            }
            : {}),
        },
      },
    }).then(({ data }) => {
      const { subscribeToAddon 
      } = data;
      if (
        subscribeToAddon?.status === 200 &&
        subscribeToAddon?.paymentAuthorization
      ) {
        setPaymentAuthorization(subscribeToAddon?.paymentAuthorization);
        openPaymentModal();
      }

      if (
        subscribeToAddon?.status == 200 &&
        !subscribeToAddon?.paymentAuthorization
      ) {
        props.addToast({
          message: subscribeToAddon?.message,
          variant: 'success',
        });
        closePromptPaymentModal();

        // reload page
        window.location.reload();
      }

      if (subscribeToAddon?.status === 422) {
        props.addToast({
          message: subscribeToAddon?.errors[0]?.message,
          variant: 'error',
        });
      }

      if (subscribeToAddon?.status === 404) {
        props.addToast({
          message: 'Error using card, kindly try again',
          variant: 'error',
        });
      }
    })
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .catch((err) => {
        // addToast({ message: err.message, variant: 'error' })
      });
  };

  const unsubscribeFromAddon = async () => {
    unsubscribeAddon({
      variables: {
        input: {
          addonId: addon.addon?.id,
        },
      },
    }).then(({ data }) => {
      const { unsubscribeFromAddon } = data;

      if (unsubscribeFromAddon?.status === 200) {
        getUser();
      }

      if (unsubscribeFromAddon?.status === 422) {
        props.addToast({
          message: unsubscribeFromAddon?.errors[0]?.message,
          variant: 'error',
        });
      }
    })
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .catch((err) => {
        // addToast({ message: err.message, variant: 'error' })
      });
  };

  const {
    loading,
    data: cardsData,
    refetch: refetchCards
  } = useCards()
  const cards = useMemo(() => cardsData?.cards, [cardsData])

  const confirmPayment = () => {
    getUser();
    closePaymentModal();
    props.addToast({
      message: 'Addon subscribed successfully',
      variant: 'success',
    });
    // reload
    window.location.reload();
  };

  const details = getAddonContent(addon?.addon?.name);

  const selectCardOption = (card?: Card | null) => {
    if (card) {
      setCard(card);
      openPromptPaymentModal();
      return;
    }
    subscribeToAddon();
  };

  const getBranchLogo = (cardType: string) => {
    if (cardType?.toLowerCase() === 'visa') {
      return <SvgVisa width="30px" height="30px" />;
    }

    if (cardType?.toLowerCase() === 'verve') {
      return <SvgVerve width="30px" height="30px" />;
    }

    return <SvgMasterCard width="30px" height="30px" />;
  };

  const getCardsOptions = () => {
    return (
      <div className="w-full flex flex-col space-y-4">
        <Heading variant="h2" size="h10" weight="semiBold">
          Select a payment option
        </Heading>
        <div className="w-full flex flex-wrap gap-4">
          {Array?.isArray(cards) && cards?.length
            ? cards?.map((card) => {
              return (
                <div
                  className="w-full flex items-center space-x-4 max-w-[300px] border border-grey-100 rounded-md px-4 py-2 cursor-pointer"
                  onClick={() => selectCardOption(card)}
                >
                  <span className="rounded-md px-3 bg-green-300 w-fit">
                    {getBranchLogo(card?.cardType?.trim())}
                  </span>
                  <div className="flex flex-col space-y-1">
                    <Paragraph size="b4" weight="medium">
                      Use card **** **** {card?.last4}
                    </Paragraph>
                  </div>
                </div>
              );
            })
            : null}
          <div
            className="w-full flex items-center space-x-4 max-w-[300px] border border-grey-100 rounded-md px-4 py-2 cursor-pointer"
            onClick={() => selectCardOption()}
          >
            <span className="rounded-md px-3 bg-green-300 h-[30px] w-[55px] text-white flex justify-center items-center">
              ****
            </span>
            <div className="flex flex-col space-y-1">
              <Paragraph size="b4" weight="medium">
                Use new card
              </Paragraph>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const selectCard = () => {
    setShowCards(true);
  };

  const getFeatureImage = (addonName: string) => {
    if (addonName?.toLowerCase() === 'loyalty') {
      return <SvgBrandLoyalty width="100%" height="100%" />;
    }

    if (addonName?.toLowerCase() === 'sms reminders') {
      return <SvgBrandSms width="100%" height="100%" />;
    }

    if (addonName?.toLowerCase() === 'messaging') {
      return <SvgBrandMessaging width="100%" height="100%" />;
    }

    if (addonName?.toLowerCase() === 'vouchers') {
      return <SvgBrandVoucher width="100%" height="100%" />;
    }
  };

  const initiateClosePromptCard = (action?: string) => {
    if (card && action === 'proceed') {
      subscribeToAddonAsync(card);
    } else {
      closePromptPaymentModal();
    }
  };
  return (
    <>
      <div className="w-full xl:border-none bg-white py-12 px-9">
        <div className="w-full flex flex-col-reverse lg:flex-row lg:justify-between items-center gap-6">
          <div className="w-full flex flex-col space-y-4">
            <span className="text-green-300 font-semibold rounded-full py-2 px-4 border border-green-150 w-fit shadow-md">
              Addon
            </span>
            <div className="w-full flex flex-col space-y-2">
              <Heading
                variant="h1"
                size="h9"
                weight="semiBold"
                color={COLORS.GREY[900]}
              >
                {details?.title}
              </Heading>
              <Paragraph size="b3" color={COLORS.GREY[300]}>
                {details?.subTitle}
              </Paragraph>
            </div>

            <div className="w-full flex flex-col space-y-4">
              {details?.featuredList && details?.featuredList?.length
                ? details?.featuredList?.map((list) => {
                  return (
                    <div className="w-full flex items-start space-x-4">
                      <span className="text-b4">✨️</span>
                      <div className="flex flex-col space-y-2">
                        {list?.name && list?.name !== '' ? (
                          <Paragraph size="b4">{list?.name}</Paragraph>
                        ) : null}
                        <Paragraph size="b4" color={COLORS.GREY[300]}>
                          {list?.description}
                        </Paragraph>
                      </div>
                    </div>
                  );
                })
                : null}
            </div>
            {canPerformAction('Settings::ManageAddons') ? (
              <div className="w-full flex flex-col space-y-6">
                {addon?.subscribed ? (
                  <Paragraph size="b4">
                    Expires on {getShortDate(addon?.expiryDate)}
                  </Paragraph>
                ) : null}
                {showCards && cards && cards?.length ? (
                  getCardsOptions()
                ) : (
                  <Button
                    variant="primary"
                    size="lg"
                    type="button"
                    disabled={isPaymentPageLoading}
                    loading={isPaymentPageLoading}
                    rounded="lg"
                    onClick={
                      addon?.subscribed
                        ? () => unsubscribeFromAddon()
                        : cards && cards?.length
                          ? () => selectCard()
                          : () => subscribeToAddonAsync()
                    }
                  >
                    {addon?.subscribed ? 'Unsubscribe' : 'Subscribe'} -{' '}
                    {formatInToPrice(addon?.unitPrice).replace('.00', '')}/Location - <span className='font-semibold'>MONTHLY</span>
                  </Button>
                )}
              </div>
            ) : null}
          </div>
          <div className="w-full max-w-[350px] flex justify-center items-center">
            {getFeatureImage(addon?.addon?.name)}
          </div>
        </div>
      </div>
      <PaymentModal
        isVisible={paymentModalIsvisible}
        closeModal={() => {
          getUser();
          closePaymentModal();
        }}
        confirmPayment={confirmPayment}
        salonId={salonId}
        paymentAuthorization={paymentAuthorization || null}
      ></PaymentModal>
      <PromptCardSelection
        isVisible={promptPaymentModalIsvisible}
        closeModal={initiateClosePromptCard}
        card={card}
        isLoading={isPaymentPageLoading}
      ></PromptCardSelection>
    </>
  );
};

export default AddonSubscription;
