import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgPaymentSalesSvg = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <rect width={32} height={32} rx={4} fill="#F3F6F6" />
    <path
      d="M20.89 22.994 15.4 17.503V9.929l-3.104 3.104-.714-.713L15.899 8l4.314 4.313-.715.714L16.4 9.93v7.158l5.199 5.198-.708.709ZM10.909 23l-.708-.709 3.584-3.59.714.715L10.908 23Z"
      fill="#667368"
    />
  </svg>
);
export default SvgPaymentSalesSvg;
