import { PAGE_ROUTES } from "constants/routes";
import Dashboard from "./Dashboard";
import AccountSetup from "./AccountSetup";
import NotFound from "./NotFound";
import AppointmentPage from "./AppointmentPage";
import Booking from "./Booking";
import CompletePayment from "./CompletePayment";
import Clients from "../modules/clients/pages/Clients";
import Messaging from "./Messaging";
import Money from "./Money";
import Settings from "./Settings";
import BusinessClosedPeriod from "./BusinessClosedPeriod";
import Inventory from "./Inventory";
import LoyaltyAndPromos from "./LoyaltyAndPromos";
import AppointmentReceipt from "./AppointmentReceipt";
import AppointmentSummary from "./AppointmentSummary";
import Reports from "./Reports";
import AppointmentReports from "./AppointmentReports";
import ClientsReports from "./ClientsReports";
import InventoryReports from "./InventoryReports";
import LoyaltyReports from "./LoyaltyReports";
import PackagesReports from "./PackagesReports";
import SalesReports from "./SalesReports";
import StaffReports from "./StaffReports";
import Staff from "./Staff";
import Sales from "./Sales";
import Receipt from "./SaleReceipt";
import CustomerDashboard from "./CustomerDashboard";
import Review from "./Review";
import Support from "./Support";
import Otp from "modules/auth/pages/Otp";
import TipsReports from "./TipsReports";
import PayCancellationFee from "./PayCancellationFee";
import MembershipsReports from "./MembershipsReports";

export const routes = [
  {
    path: PAGE_ROUTES.HOME,
    Component: Dashboard,
    name: 'Home',
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: PAGE_ROUTES.ACCOUNT_SETUP,
    Component: AccountSetup,
    name: 'Account setup',
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: PAGE_ROUTES.NOT_FOUND,
    Component: NotFound,
    name: 'Not found',
    meta: {
      requiresAuth: false,
    },
  }, {
    path: PAGE_ROUTES.CALENDAR,
    Component: AppointmentPage,
    name: 'Calendar',
    meta: {
      requiresAuth: true,
    },
  }, {
    path: PAGE_ROUTES.APPOINTMENT,
    name: "Appointment",
    Component: AppointmentPage,
    meta: {
      requiresAuth: true,
    },
  }, {
    path: PAGE_ROUTES.BOOKING,
    Component: Booking,
    name: 'Booking',
    meta: {
      requiresAuth: false,
    },
  }, {
    path: PAGE_ROUTES.COMPLETE_PAYMENT,
    Component: CompletePayment,
    name: 'Complete payment',
    meta: {
      requiresAuth: false,
    },
  }, {
    path: PAGE_ROUTES.PAY_OUTSTANDING,
    Component: PayCancellationFee,
    name: 'Pay Cancellation Fee',
    meta: {
      requiresAuth: false,
    },
  }, {
    path: PAGE_ROUTES.CLIENTS,
    Component: Clients,
    name: 'Clients',
    meta: {
      requiresAuth: true,
    },
  }, {
    path: PAGE_ROUTES.MESSAGE,
    Component: Messaging,
    name: 'Messaging',
    meta: {
      requiresAuth: true,
    },
  }, {
    path: PAGE_ROUTES.MONEY,
    Component: Money,
    name: 'Money',
    meta: {
      requiresAuth: true,
    },
  }, {
    path: PAGE_ROUTES.SETTINGS,
    Component: Settings,
    name: 'Settings',
    meta: {
      requiresAuth: true,
    },
  }, {
    path: PAGE_ROUTES.CLOSED_PERIOD,
    Component: BusinessClosedPeriod,
    name: 'Closed Period',
    meta: {
      requiresAuth: true,
    },
  }, {
    path: PAGE_ROUTES.INVENTORY,
    Component: Inventory,
    name: 'Catalogue',
    meta: {
      requiresAuth: true,
    },
  }, {
    path: PAGE_ROUTES.LOYALTY_AND_PROMOS,
    Component: LoyaltyAndPromos,
    name: 'Rewards',
    meta: {
      requiresAuth: true,
    },
  }, {
    path: PAGE_ROUTES.RECEIPT,
    Component: AppointmentReceipt,
    name: 'Appointment Public Receipt',
    meta: {
      requiresAuth: false,
    },
  }, {
    path: PAGE_ROUTES.APPOINTMENT_SUMMARY,
    Component: AppointmentSummary,
    name: 'Appointment Public Summary',
    meta: {
      requiresAuth: false,
    },
  }, {
    path: PAGE_ROUTES.BUSINESS_PERFORMANCE,
    Component: Reports,
    name: 'Business Reports',
    meta: {
      requiresAuth: true,
    },
  }, {
    path: PAGE_ROUTES.APPOINTMENT_REPORTS,
    Component: AppointmentReports,
    name: 'Appointment Reports',
    meta: {
      requiresAuth: true,
    },
  }, {
    path: PAGE_ROUTES.CLIENTS_REPORTS,
    Component: ClientsReports,
    name: 'Client Reports',
    meta: {
      requiresAuth: true,
    },
  }, {
    path: PAGE_ROUTES.INVENTORY_REPORTS,
    Component: InventoryReports,
    name: 'Inventory Reports',
    meta: {
      requiresAuth: true,
    },
  }, {
    path: PAGE_ROUTES.LOYALTY_REPORTS,
    Component: LoyaltyReports,
    name: 'Loyalty Reports',
    meta: {
      requiresAuth: true,
    },
  }, {
    path: PAGE_ROUTES.PACKAGES_REPORTS,
    Component: PackagesReports,
    name: 'Vouchers Reports',
    meta: {
      requiresAuth: true,
    },
  }, {
    path: PAGE_ROUTES.TIPS_REPORTS,
    Component: TipsReports,
    name: 'Tips Reports',
    meta: {
      requiresAuth: true,
    },
  }, {
    path: PAGE_ROUTES.MEMBERSHIPS_REPORTS,
    Component: MembershipsReports,
    name: 'Memberships Reports',
    meta: {
      requiresAuth: true,
    },
  },{
    path: PAGE_ROUTES.SALES_REPORTS,
    Component: SalesReports,
    name: 'Sales Reports',
    meta: {
      requiresAuth: true,
    },
  }, {
    path: PAGE_ROUTES.STAFF_REPORTS,
    Component: StaffReports,
    name: 'Staff Reports',
    meta: {
      requiresAuth: true,
    },
  }, {
    path: PAGE_ROUTES.STAFF,
    Component: Staff,
    name: 'Staff',
    meta: {
      requiresAuth: true,
    },
  }, {
    path: PAGE_ROUTES.SALES,
    Component: Sales,
    name: 'Sales',
    meta: {
      requiresAuth: true,
    },
  }, {
    path: PAGE_ROUTES.SALE_RECEIPT,
    Component: Receipt,
    name: 'Sale Receipt',
    meta: {
      requiresAuth: false,
    },
  }, {
    path: PAGE_ROUTES.REDEEM_VOUCHER,
    Component: CustomerDashboard,
    name: 'Customer Dashboard',
    meta: {
      requiresAuth: false,
    },
  }, {
    path: PAGE_ROUTES.REVIEW,
    Component: Review,
    name: 'Review Appointment',
    meta: {
      requiresAuth: false,
    },
  }, {
    path: PAGE_ROUTES.SUPPORT,
    Component: Support,
    name: 'Support',
    meta: {
      requiresAuth: true,
    },
  }
]