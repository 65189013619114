import React, { Fragment, useEffect, useState } from 'react'
import { ViewCustomerMembershipModalProps } from '../types'
import { Button, Checkbox, Heading, Modal, Paragraph, SearchTerm, SvgArrowBack, SvgChevronLeft, SvgCreditCardWhite, SvgFilter, Table } from 'ui'
import { COLORS } from 'constants/colors'
import { formatInToPrice, getNumberMonthAndYearFromDate } from 'components/utils/misc'
import { CUSTOMER_MOBILE_WALLET_HISTORY_HEADERS, CUSTOMER_WALLET_HISTORY_HEADERS } from 'modules/clients/constants'
import { Menu, Transition } from '@headlessui/react'
import Label from 'ui/atoms/formLabel/Label'
import { ClientWalletTransaction } from 'core/generated'
import { useModal } from 'hooks'
import ViewTransactionModal from 'modules/clients/modals/ViewTransactionModal'

const WalletDetailsModal = ({ client, currency, ...props }: ViewCustomerMembershipModalProps) => {
  const [currentWalletTransaction, setCurrentWalletTransaction] = useState<ClientWalletTransaction | null>(null)
  const [searchWalletTransactionPreference, setSearchWalletTransactionPreference] = useState('')
  const {
    openModal: openViewWalletTransactionModal,
    closeModal: closeViewWalletTransactionModal,
    isVisible: isViewWalletTransactionModalVisible
  } = useModal()
  const closeModal = () => {
    props.closeModal()
  }
  const [filteredWalletTransactions, setFilteredWalletTransactions] = useState<ClientWalletTransaction[] | null>(
    [...(client?.clientWalletTransactions ?? [])]
  );

  const [walletTransactionsFilter, setWalletTransactionsFilter] = useState([
    {
      label: 'Credit',
      value: 'credit',
      show: true
    },
    {
      label: 'Debit',
      value: 'debit',
      show: true
    }
  ])

  useEffect(() => {
    if (client && props?.isVisible) {
      setFilteredWalletTransactions([...(client?.clientWalletTransactions ?? [])])
    }
  }, [client?.id, props?.isVisible])

  const handleKeyWalletTransactionCheckboxChange = (value: string) => {
    // modify keyPreferencesFilter show
    const index = walletTransactionsFilter?.findIndex?.((filter) => filter?.value === value)
    if (index === -1) return
    const newWalletTransactionsFilter = [...walletTransactionsFilter];
    newWalletTransactionsFilter[index].show = !newWalletTransactionsFilter[index].show;
    setWalletTransactionsFilter(newWalletTransactionsFilter)
  }

  const generateWalletTransactions = (walletTransactions: ClientWalletTransaction[]) => {
    // sort by date, latest to oldest
    walletTransactions?.sort?.((a, b) => {
      return new Date(b?.createdAt).getTime() - new Date(a?.createdAt).getTime()
    })
    return walletTransactions?.map?.((walletTransaction) => {
      return {
        date: getNumberMonthAndYearFromDate(walletTransaction?.createdAt),
        transactionType: walletTransaction?.transactionType,
        amount: () => {
          if (walletTransaction?.transactionType === 'debit') {
            return (
              <span className='text-red-500 text-b4'>
                -{formatInToPrice(walletTransaction?.amount)}
              </span>
            )
          }
          return (
            <span className='text-green-500 text-b4'>
              +{formatInToPrice(walletTransaction?.amount)}
            </span>
          )
        },
        trailingBalance: () => {
          if (walletTransaction?.trailingBalance?.toString()?.includes('-')) {
            return (
              <span className='text-red-500 text-b4'>
                {formatInToPrice(walletTransaction?.trailingBalance)}
              </span>
            )
          }
          return (
            <span className='text-green-500 text-b4'>
              {formatInToPrice(walletTransaction?.trailingBalance)}
            </span>
          )
        }
      }
    })
  }

  const openMembershipWalletTransaction = (id?: number) => {
    if (!filteredWalletTransactions?.length) return;
    const walletTransaction = filteredWalletTransactions[id];
    if (!walletTransaction) return;

    setCurrentWalletTransaction(walletTransaction)
    openViewWalletTransactionModal()
  }

  const closeWalletTransactionModal = () => {
    setCurrentWalletTransaction(null)
    closeViewWalletTransactionModal()
  }

  useEffect(() => {
    if (searchWalletTransactionPreference && searchWalletTransactionPreference !== '') {
      // check if searchWalletTransactionPreference is like transactionType or amount
      const filtered = client?.clientWalletTransactions?.filter?.((transaction) => {
        return transaction?.transactionType?.toLowerCase?.()?.includes?.(searchWalletTransactionPreference?.toLowerCase?.()) || transaction?.amount?.toString?.()?.includes?.(searchWalletTransactionPreference?.toLowerCase?.())
      })

      setFilteredWalletTransactions(filtered)
    } else {
      setFilteredWalletTransactions([...(client?.clientWalletTransactions ?? [])])
    }
  }, [searchWalletTransactionPreference])

  const walletTransactions = generateWalletTransactions(filteredWalletTransactions)
  return (
    <>
      <Modal
        show={props.isVisible}
        closeModal={closeModal}
        variant='right'
      >
        <div className='w-full relative my-[80px]'>
          <div className="fixed top-0 w-full bg-white flex border-b border-grey-100 cursor-pointer z-10">
            <div className='w-full hidden xl:flex space-x-2 px-6 py-5 items-start'>
              <Button
                variant='text'
                size='none'
                type='button'
                className='w-fit'
                fontSize='b4'
                onClick={closeModal}
              ><SvgArrowBack width="24px" height="24px" /> <span>Back</span></Button>
            </div>
            <div className='w-full flex xl:hidden space-x-[69px] p-4 items-center'>
              <Button
                variant='icon'
                size='square'
                type='button'
                rounded='md'
                className='w-fit'
                fontSize='b4'
                onClick={closeModal}
              ><SvgChevronLeft width="24px" height="24px" /></Button>
              <Paragraph size='b3' weight='bold'>View Details</Paragraph>
            </div>
          </div>
          <div className='w-full space-y-12 py-4'>
            <div className='w-full px-6 grid grid-cols-1 xl:grid-cols-2 gap-2 md:gap-4 border border-grey-50 md:border-0 rounded-md'>
              <div className="w-full h-[132px] col-span-2 flex flex-col justify-center rounded-md py-3 px-4 bg-green-300 bg-[url('https://res.cloudinary.com/dqqrgidob/image/upload/v1731940977/t7whvomghevc6wsndki4.png')] bg-cover bg-no-repeat">
                <div className='w-full flex justify-between items-start space-x-3'>
                  <SvgCreditCardWhite width="40px" height="40px" />
                  <div className='w-full flex flex-col'>
                    <Paragraph size='b4' color={COLORS.GREY[100]} weight='semiBold'>
                      Total balance
                    </Paragraph>
                    <Paragraph size='b2' color={COLORS.WHITE} weight='semiBold'>
                      {formatInToPrice(client?.walletBalance, currency)}
                    </Paragraph>
                  </div>
                </div>
              </div>
            </div>
            <div className='w-full flex flex-col space-y-4'>
              <div className='w-full py-4 px-6 bg-grey-50 border-grey-150/45 border-t border-b'>
                <Heading size='b4' weight='semiBold' color={COLORS.GREY[900]} variant='h2'>
                  Transaction history
                </Heading>
              </div>
              <div className='flex items-center px-4 space-x-3 w-1/2'>
                <SearchTerm
                  setDebouncedSearchQuery={setSearchWalletTransactionPreference}
                  placeholder="Search..."
                  size="md"
                />
                <Menu as="div" className="relative inline-block text-left">
                  <div>
                    <Menu.Button className="flex space-x-2 w-full items-center justify-center rounded-md bg-grey-50 border border-grey-20 px-4 py-2 text-b5 font-medium text-grey-300 hover:bg-opacity-90 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-85">
                      <SvgFilter width="20px" height="20px" />
                      <span className='hidden xl:inline'>Filter</span>
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-[9999]">
                      <div className="flex flex-col p-4 space-y-4">
                        {Array.isArray(walletTransactionsFilter) && walletTransactionsFilter.length && walletTransactionsFilter.map((heading, index) => (
                          <Label className='flex space-x-2 items-center cursor-pointer' key={index} htmlFor={heading.value} onClick={() => handleKeyWalletTransactionCheckboxChange(heading.value)}>
                            <Checkbox
                              isChecked={heading.show}
                              borderType='Black'
                              size='Sm'
                            />
                            <span>{heading.label}</span>
                          </Label>
                        ))}
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
              <Table
                headers={CUSTOMER_WALLET_HISTORY_HEADERS}
                mobileHeaders={CUSTOMER_MOBILE_WALLET_HISTORY_HEADERS}
                rows={walletTransactions}
                onClick={openMembershipWalletTransaction}
                tableOptions={{
                  view: true,
                  edit: false,
                  delete: false,
                  duplicate: false
                }}
              />
            </div>
          </div>
        </div>
      </Modal>
      <ViewTransactionModal
        isVisible={isViewWalletTransactionModalVisible}
        closeModal={closeWalletTransactionModal}
        walletTransaction={currentWalletTransaction}
      />
    </>
  )
}

export default WalletDetailsModal