/* eslint-disable @typescript-eslint/no-explicit-any */
import { cleanUpText } from "components/utils/misc";
import { Appointment, TopSeller } from "core/generated";

export const createAppointmentSummaryGroup = (appointments: Appointment[]) => {
  const colors = ["#F0CF60", "#663399", "#DDF3E3", "#000000", "#AC321D", "#198DB1", "#AC321D"];
  const labels = ['Pending', 'Checked In', 'Completed', 'No Show', 'Cancelled', 'Confirmed', 'Deposit Pending'];
  // loop through labels
  const counts = [];
  for (let i = 0; i < labels.length; i++) {
    counts.push(Array?.isArray(appointments) && appointments.filter((appointment) => appointment?.appointmentStatus === labels[i].toLowerCase()?.replaceAll(' ', "_")).length);
  }
  return {
    labels,
    datasets: [
      {
        label: 'Total Appointmennts',
        data: counts,
        backgroundColor: colors,
      },
    ]
  };
};

export const topSellersColors = ["#A6CECA", "#C1D5D3", "#DEE0E0", "#16796F", "#DEE0E0"];

export const createTopSellersGroup = (sellers: TopSeller[]) => {
  const labels = sellers.map((seller) => seller?.name);
  const counts = sellers.map((seller) => seller?.count);
  
  return {
    labels,
    datasets: [
      {
        label: 'Top Sellers',
        data: counts,
        backgroundColor: topSellersColors,
      },
    ]
  };
};

export const paymentColors = ["#16796F", "#E2E8E9", "#E4B91F", "#69A7A0", "#F2F0D7", "#F1C8A2"];
export const paymentModeLabels = [{
  label: 'Cash',
  value: 'cash',
  color: paymentColors[0]
}, {
  label: 'Pos',
  value: 'pos',
  color: paymentColors[1]
}, {
  label: 'Gift card',
  value: 'gift_card',
  color: paymentColors[2]
}, {
  label: 'Voucher',
  value: 'voucher',
  color: paymentColors[3]
}, {
  label: 'Bank Transfer',
  value: 'bank_transfer',
  color: paymentColors[4]
}, {
  label: 'Client wallet',
  value: 'client_wallet',
  color: paymentColors[5]
}]

// export const createPaymentsChart = (sellers: {
//   value: string;
//   amount: number;
// }[]) => {
//   const labels = sellers?.map((seller) => cleanUpText(seller?.value));
//   const counts = sellers?.map((seller) => seller?.amount);
  
//   return {
//     labels,
//     datasets: [
//       {
//         label: 'Payment methods',
//         data: counts,
//         backgroundColor: paymentColors,
//       },
//     ]
//   };
// };

export const createPaymentsChart = (sellers: {
    value: string;
    amount: number;
  }[]) => {
    const labels = sellers?.map((seller) => cleanUpText(seller?.value));
    const counts = sellers?.map((seller) => seller?.amount);
  
  return {
    labels,
    datasets: [
      {
        label: 'Payment methods',
        data: counts,
        backgroundColor: paymentColors,
      },
    ]
  };
};
