import React, { Fragment, useState } from 'react'
import { Menu, Transition } from '@headlessui/react';
import { useSalonCache } from 'hooks/useSalonCache';
import { SALES_TAB_NAME } from '../components/uicomponents/salesComponents/constants';
import { useToast } from 'hooks/useToast';
import { useModal } from 'hooks/useModal';
import ToastWrapper from 'ui/molecules/toastWrapper/ToastWrapper';
import { ToastProps } from 'ui/atoms/toast/types';
import Tabs from 'ui/molecules/tabs/Tabs';
import Layout from 'ui/layout/Layout';
import Heading from 'ui/atoms/heading/Heading';
import { SvgSelectDropDown } from 'ui';
import AddSaleModal from '../components/modals/AddSale';
import { Sale } from 'core/generated';
import AddAppointmentModal from '../components/modals/AddAppointmentModal';
import SuccessModal from '../components/modals/SuccessModal';
import ViewSaleReceiptModal from '../components/modals/ViewSaleReceiptModal';
import AddVoucherSaleModal from '../components/modals/AddVoucherSale';
import AppointmentSales from '../components/uicomponents/salesComponents/AppointmentSales';
import DailySales from '../components/uicomponents/salesComponents/DailySales';
import PaymentTransactions from '../components/uicomponents/salesComponents/PaymentTransactions';
import SalesReport from '../components/uicomponents/salesComponents/SalesReport';
import { canPerformAction, canShowPage } from '../components/utils/permission';
import { PERMISSION_CONSTANTS } from 'constants/permission';
import AddMembershipSale from 'components/modals/AddMembershipSale';

const Sales = () => {
  const SALES_TABS = [
    {
      key: SALES_TAB_NAME.DAILY_SALES,
      title: SALES_TAB_NAME.DAILY_SALES,
      show: canShowPage('Reports::')
    }, {
      key: SALES_TAB_NAME.APPOINTMENTS,
      title: SALES_TAB_NAME.APPOINTMENTS,
      show: canPerformAction(PERMISSION_CONSTANTS.sales.appointmentList)
    }, {
      key: SALES_TAB_NAME.SALES_REPORT,
      title: SALES_TAB_NAME.SALES_REPORT,
      show: canPerformAction(PERMISSION_CONSTANTS.sales.salesReport)
    }, {
      key: SALES_TAB_NAME.PAYMENT_TRANSACTIONS,
      title: SALES_TAB_NAME.PAYMENT_TRANSACTIONS,
      show: canPerformAction(PERMISSION_CONSTANTS.sales.paymentTransactions)
    }
  ];

  const [activeTab, setActiveTab] = useState<string>(SALES_TABS.filter((tab) => tab?.show)[0]?.key);
  const { toast, addToast } = useToast();
  const { getSalonFieldValue } = useSalonCache();
  const salonId = getSalonFieldValue('id');
  const branchName = getSalonFieldValue('branchName');
  const [salonBranchName, setSalonBranchName] = useState(branchName);
  const [selectedSale, setSelectedSale] = useState<Sale | null>(null)
  const [refreshData, setRefreshData] = useState<boolean>(false)
  const actions = {
    addAppointment: canPerformAction(PERMISSION_CONSTANTS.sales.addAppointment),
    addSale: canPerformAction(PERMISSION_CONSTANTS.sales.addSale),
    addVoucherSale: canPerformAction(PERMISSION_CONSTANTS.sales.addVoucherSale)
  }
  const props = {
    salonId,
    addToast,
    salonBranchName,
    refreshData,
    setRefreshData
  };

  const getTabView = () => {
    switch (activeTab) {
      case SALES_TAB_NAME.DAILY_SALES:
        return <DailySales {...props} />
      case SALES_TAB_NAME.APPOINTMENTS:
        return <AppointmentSales {...props} />
      case SALES_TAB_NAME.SALES_REPORT:
        return <SalesReport {...props} />
      default:
        return <PaymentTransactions {...props} />
    }
  }

  const {
    isVisible: isAddAppointmentModalVisible,
    openModal: openAddAppointmentModal,
    closeModal: closeAddAppointmentModal,
  } = useModal();

  const {
    isVisible: isSellMembershipModalVisible,
    openModal: openSellMembership,
    closeModal: closeSellMembership,
  } = useModal();

  const {
    isVisible: isAddSaleModalVisible,
    openModal: openAddSaleModal,
    closeModal: closeAddSaleModal,
  } = useModal();

  const {
    isVisible: isAddVoucherModalVisible,
    openModal: openAddVoucherModal,
    closeModal: closeAddVoucherModal,
  } = useModal();

  const {
    isVisible: successModalIsVisible,
    openModal: openSuccessModal,
    closeModal: closeSuccessModal,
  } = useModal();

  const {
    isVisible: isSaleReceiptModalVisible,
    openModal: openSaleReceiptModal,
    closeModal: closeSaleReceiptModal,
  } = useModal();

  const initiateCloseAddAppointmentModal = (action: string) => {
    closeAddAppointmentModal();
    if (action === 'addedAppointment') {
      openSuccessModal();
    }
  }

  const initiateCloseAddSaleModal = (action: string, sale?: Sale | null) => {
    closeAddSaleModal();
    setRefreshData(true)
    if (action === 'addedSale' && sale) {
      setSelectedSale(sale);
      openSaleReceiptModal();
    }
  }

  const initiateCloseAddVoucherModal = (action: string) => {
    closeAddVoucherModal();
    if (action === 'addedVoucherSale') {
      setRefreshData(true)
    }
  }

  const closeSuccessModalAndReloadCalendar = () => {
    closeSuccessModal();
    setRefreshData(true)
  }

  const initiateCloseSellMembershipModal = () => {
    closeSellMembership();
    setRefreshData(true)
  }

  return (
    <>
      <Layout
        pageTitle={
          activeTab
        }
        setSalonBranchName={setSalonBranchName}
        pageLevel={2}
      >
        <ToastWrapper toast={toast as ToastProps} />
        <Tabs
          tabs={SALES_TABS}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <div className='w-full flex justify-between items-center px-6 py-5'>
          <Heading variant={'h1'} size='h11' weight='bold'>{activeTab}</Heading>
          {actions?.addAppointment || actions?.addSale || actions?.addVoucherSale ?
            <div className='relative'>
              <Menu as="div" className="relative inline-block text-left">
                <div>
                  <Menu.Button className="flex space-x-2 w-full items-center justify-center rounded-md bg-green-300 px-4 py-2 text-b5 font-medium text-white hover:bg-opacity-90 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-85">
                    <span>Add</span>
                    <SvgSelectDropDown width="10px" height="10px" />
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
                    <div className="flex flex-col p-4 space-y-4">
                      {actions?.addAppointment ?
                        <Menu.Item>
                          <span className="text-b4 cursor-pointer" onClick={openAddAppointmentModal}>New Appointment</span>
                        </Menu.Item> : null}
                      {actions?.addSale ?
                        <Menu.Item>
                          <span className="text-b4 cursor-pointer" onClick={openAddSaleModal}>New Sale</span>
                        </Menu.Item> : null}
                      {actions?.addVoucherSale ?
                        <Menu.Item>
                          <span className="text-b4 cursor-pointer" onClick={openAddVoucherModal}>New Voucher Sale</span>
                        </Menu.Item> : null}
                      {actions?.addSale ?
                        <Menu.Item>
                          <span className="text-b4 cursor-pointer" onClick={openSellMembership}>Sell Membership</span>
                        </Menu.Item> : null}
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div> : null}
        </div>
        <div className='w-full border-t border-grey-100'>
          {getTabView()}
        </div>
      </Layout>

      <AddAppointmentModal
        isVisible={isAddAppointmentModalVisible}
        closeModal={initiateCloseAddAppointmentModal}
        addToast={addToast}
        salonId={salonId}
      />
      <AddSaleModal
        isVisible={isAddSaleModalVisible}
        closeModal={initiateCloseAddSaleModal}
        addToast={addToast}
        salonId={salonId}
      />
      <AddVoucherSaleModal
        isVisible={isAddVoucherModalVisible}
        closeModal={initiateCloseAddVoucherModal}
        addToast={addToast}
        salonId={salonId}
      />
      <AddMembershipSale
        isVisible={isSellMembershipModalVisible}
        closeModal={initiateCloseSellMembershipModal}
        addToast={addToast}
        salonId={salonId}
      />
      <SuccessModal
        isVisible={successModalIsVisible}
        closeModal={closeSuccessModalAndReloadCalendar}
      />
      <ViewSaleReceiptModal sale={selectedSale} salonId={salonId} closeModal={() => {
        closeSaleReceiptModal(), setSelectedSale(null)
      }} isVisible={isSaleReceiptModalVisible} addToast={addToast} />
    </>
  )
}

export default Sales