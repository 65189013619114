import React, { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { MoneyModalProps, PosInput } from './types';
import { useSalonCache } from 'hooks/useSalonCache';
import { BanksDocument, CreatePosDeviceDocument, DeletePosDeviceDocument, UpdatePosDeviceDocument } from 'core/generated';
import { API_ERRORS, ERRORS } from 'constants/errors';
import { Modal } from 'ui/templates/modal/Modal';
import Input from 'ui/molecules/input/Input';
import { REGEX_PATTERNS } from 'constants/pattern';
import SelectInput from 'ui/molecules/input/SelectInput';
import Button from 'ui/atoms/button/Button';
import { useAddPosDevice, useDeletePosDevice, useGetBanks, useUpdatePosDevice } from 'api/useMoney';

const AddOrEditPosDevice = (props: MoneyModalProps) => {
  const { getSalonFieldValue } = useSalonCache();
  const salonId = getSalonFieldValue('id');

  const {
    data: banksData,
  } = useGetBanks()
  const banks = banksData?.banks || []
  const bankListOptions = useMemo(() => {
    return banks.map((bank) => ({
      value: bank.code,
      label: bank.name,
    }))
  }, [banks])

  const {
    loading: isLoading,
    addPosDevice
  } = useAddPosDevice()

  const {
    loading: isUpdating,
    updatePosDevice
  } = useUpdatePosDevice()

  const {
    loading: deletePosIsLoading,
    deletePosDevice,
  } = useDeletePosDevice()

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<PosInput>({});

  const onSubmitData = async (input: PosInput) => {
    try {
      // update or create pos
      if (!props.pos) {
        addPosDevice({
          variables: {
            input: {
              name: input?.name,
              bankCode: input?.bankCode,
              salonId,
              ...(props?.posCount === 0 ? { onboardingStep: 'created_pos_devices' } : {})
            }
          }
        }).then(({ data }) => {
          if (data?.createPosDevice?.status === 201) {
            closePosModal()
            props?.addToast && props.addToast({
              message: props.pos ? 'POS updated successfully' : 'POS created successfully',
              variant: 'success',
            })
          }


          if (data?.createPosDevice?.errors?.length) {
            const message = data?.createPosDevice?.errors[0]?.message || API_ERRORS.BANK_POS_FAILED;
            props?.addToast && props?.addToast({
              variant: 'error',
              message,
            })
          }

        })
      } else {
        updatePosDevice({
          variables: {
            input: {
              id: props?.pos?.id,
              name: input?.name,
              bankCode: input?.bankCode,
              salonId
            }
          }
        }).then(({ data }) => {
          if (data?.updatePosDevice?.status === 200) {
            closePosModal()
            props?.addToast && props.addToast({
              message: props.pos ? 'POS updated successfully' : 'POS created successfully',
              variant: 'success',
            })
          }


          if (data?.updatePosDevice?.errors?.length) {
            const message = data?.updatePosDevice?.errors[0]?.message || API_ERRORS.BANK_POS_FAILED;
            props?.addToast && props?.addToast({
              variant: 'error',
              message,
            })
          }

        })
      }
    } catch (createBusinessPosError) {
      if (axios.isAxiosError(createBusinessPosError)) {
        const message = createBusinessPosError?.response?.data?.message || API_ERRORS.BANK_POS_FAILED;
        props?.addToast && props.addToast({
          variant: "error",
          message,
        });
      }
    }
  };

  const closePosModal = () => {
    props.refetch && props.refetch();
    props.closeModal();
    resetFormInput();
  }

  const resetFormInput = () => {
    setValue('bankCode', '');
    setValue('name', '');
  }

  useEffect(() => {
    if (props.pos) {
      setValue('bankCode', props.pos.bankCode || '')
      setValue('name', props.pos.name || '')
    }
  }, [props.pos, setValue])

  const deletePos = async () => {
    try {
      deletePosDevice({
        variables: {
          input: {
            id: props?.pos?.id,
            salonId
          }
        }
      }).then(({ data }) => {
        if (data?.deletePosDevice?.status === 200) {
          closePosModal();
          props?.addToast && props.addToast({
            message: 'POS Device deleted successfully',
            variant: 'success',
          })
        }

        if (data?.deletePosDevice?.errors?.length) {
          const message = data?.deletePosDevice?.errors[0]?.message || API_ERRORS.SALON_POS_DELETION_FAILED;
          props?.addToast && props?.addToast({
            variant: 'error',
            message,
          })
        }
      })
    } catch (deletePosError) {
      if (axios.isAxiosError(deletePosError)) {
        const message = deletePosError?.response?.data?.message || API_ERRORS.SALON_POS_DELETION_FAILED;
        props?.addToast && props.addToast({
          message,
          variant: 'error',
        })
      }
    }
  }

  return (
    <Modal
      title={props?.expense ? 'Point of Sale Terminal Details' : 'Add Point of Sale Terminal'}
      show={props.isVisible}
      closeModal={!props?.expense ? props.closeModal : () => closePosModal()}
    >
      <form onSubmit={handleSubmit(onSubmitData)} className="w-full space-y-6 pt-6">
        <Input name="name" id="pos-name" label="Point of Sale Terminal Name" type="text" placeholder='Enter Point of Sale Terminal Name' control={control} rules={{
          required: ERRORS.POS_NAME_REQUIRED,
          pattern: REGEX_PATTERNS.ALPHANUMERIC
        }} error={errors.name} />
        <SelectInput name="bankCode" id="bank-name" label="Bank Name " control={control} rules={{
          required: ERRORS.BANK_NAME_REQUIRED,
        }} error={errors.bankCode} options={bankListOptions} placeholder="Select Bank" />

        <Button
          variant='primary'
          className=''
          disabled={isLoading || isUpdating || deletePosIsLoading}
          loading={isLoading || isUpdating}
          size='lg'
          rounded='lg'
        >
          Save
        </Button>
        <Button
          variant='text'
          className={`mx-auto ${props?.pos && 'text-red-500'}`}
          disabled={deletePosIsLoading || isUpdating}
          loading={deletePosIsLoading}
          size='none'
          type='button'
          fontWeight="semiBold"
          onClick={props?.pos ? deletePos : props.closeModal}
        >
          {props?.pos ? 'Delete' : 'Cancel'}
        </Button>
      </form>
    </Modal>
  )
}

export default AddOrEditPosDevice