import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgArchive = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="m8 11.461 2.205-2.204-.472-.473-1.4 1.4V7.051h-.666v3.133l-1.4-1.4-.472.473L8 11.46ZM3.333 5.205v7.052a.4.4 0 0 0 .116.294.4.4 0 0 0 .295.115h8.513a.4.4 0 0 0 .294-.115.4.4 0 0 0 .116-.294V5.205H3.333Zm.514 8.128c-.299 0-.57-.122-.814-.366s-.366-.515-.366-.813V4.99a1.004 1.004 0 0 1 .249-.683L3.953 3.06a.92.92 0 0 1 .362-.295c.145-.066.3-.1.466-.1h6.41c.166 0 .323.034.473.1.149.066.272.164.368.294l1.052 1.273a.988.988 0 0 1 .186.324c.042.118.062.243.062.373v7.123c0 .298-.122.57-.366.813-.244.245-.515.367-.813.367H3.847Zm-.26-8.795H12.4l-.887-1.065a.51.51 0 0 0-.148-.102.414.414 0 0 0-.173-.038H4.795a.41.41 0 0 0-.174.039.49.49 0 0 0-.146.103l-.888 1.063Z"
      fill="#000"
    />
  </svg>
);
export default SvgArchive;
