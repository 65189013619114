import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import axios from 'axios';
import { AddClientModalProps } from './types';
import { ClientInput } from '../uicomponents/types';
import { convertFullDateStringToDate, formatDateToOriginalDate, sanitizePhoneNumber, validatePhoneNumber } from '../utils/misc';
import { API_ERRORS, ERRORS } from 'constants/errors';
// import { PAGE_ROUTES } from 'constants/routes';
import { Modal } from 'ui/templates/modal/Modal';
import Input from 'ui/molecules/input/Input';
import { ACCOUNT_SETUP_ERRORS } from '../uicomponents/accountSetupComponents/constants';
import FormPhone from 'ui/molecules/input/FormPhone';
import { REGEX_PATTERNS } from 'constants/pattern';
import { getHelperTextForReactHookFormErrors } from '../utils/form';
import Button from 'ui/atoms/button/Button';
import { useAddClient, useDeleteClient, useUpdateClient } from 'api/useClient';
import { FormLabel, ToggleOnly } from 'ui';

const AddClientModal = ({
  isVisible,
  closeModal,
  salonId,
  client,
  // navigateToPage,
  refetch,
  addToast,
  clientsCount,
  removeClientFromState
}: AddClientModalProps) => {
  const {
    control,
    handleSubmit,
    setValue,
    register,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<ClientInput>();
  const country = JSON.parse(localStorage.getItem('country'));
  const [countryCode, setCountryCode] = useState(country?.code);
  const [callingCode, setCallingCode] = useState(country?.countryCode);
  const [altCountryCode, setAltCountryCode] = useState(country?.code);
  const [altCallingCode, setAltCallingCode] = useState(country?.countryCode);

  const closeClientModal = () => {
    resetFormInput();
    refetch && refetch();
    closeModal();
    removeClientFromState && removeClientFromState();
  }

  const {
    loading: creatingClient,
    createClient
  } = useAddClient()

  const {
    loading: updatingClient,
    updateClient
  } = useUpdateClient()

  const {
    loading: deleteClientIsLoading,
    deleteClient
  } = useDeleteClient()

  const resetFormInput = () => {
    setValue('firstName', '');
    setValue('lastName', '');
    setValue('email', '');
    setValue('phone', '');
    setValue('altPhone', '');
    setValue('dob', '');
    setValue('optOutEmail', false);
    setValue('optOutSms', false);
  };

  useEffect(() => {
    if (client && isVisible) {
      setValue('firstName', client?.firstName || '');
      setValue('lastName', client?.lastName || '');
      setValue('email', client?.email || '');
      setValue('phone', client?.phone);
      setValue('altPhone', client?.altPhone);
      setValue('dob', convertFullDateStringToDate(client?.dob));
      setCountryCode(client?.countryCode);
      setCallingCode(client?.callingCode);
      setCountryCode(client?.altCountryCode);
      setCallingCode(client?.altCallingCode);
      setValue('optOutEmail', client?.optOutEmail === true ? false : true);
      setValue('optOutSms', client?.optOutSms === true ? false : true);
    }
  }, [client, setValue])

  const addClientSubmit = async (input: ClientInput) => {
    const clientData = {
      firstName: input.firstName,
      lastName: input.lastName,
      email: input?.email || null,
      phone: input.phone,
      altPhone: input?.altPhone ? input.altPhone : null,
      ...(input?.dob && input?.dob !== '-' && { dob: formatDateToOriginalDate(input.dob as unknown as Date, 'start') }),
      countryCode,
      callingCode,
      altCountryCode,
      altCallingCode,
      optOutEmail: input?.optOutEmail === true ? false : true,
      optOutSms: input?.optOutSms === true ? false : true,
    };
    try {
      if (!client) {
        createClient({
          variables: { input: { ...clientData, salonId, ...(clientsCount === 0 ? { onboardingStep: 'created_client' } : {}) } }
        }).then(({ data }) => {
          if (data?.createClient?.status === 201 || data?.createClient?.status === 200) {
            closeClientModal();
            addToast({
              message: 'Client added successfully',
              variant: 'success',
            })
          }
          if (data?.createClient?.errors?.length) {
            const message = data?.createClient?.errors[0]?.message || API_ERRORS.BUSINESS_STAFF_CREATE_FAILED;
            addToast({
              variant: 'error',
              message,
            })
          }
        })
      } else {
        updateClient({
          variables: { input: { ...clientData, id: client?.id, salonId } },
        }).then(({ data }) => {
          if (data?.updateClient?.status === 200) {
            closeClientModal();
            addToast({
              message: 'Client updated successfully',
              variant: 'success',
            })
          }
          if (data?.updateClient?.errors?.length) {
            const message = data?.updateClient?.errors[0]?.message || API_ERRORS.BUSINESS_STAFF_CREATE_FAILED;
            addToast({
              variant: 'error',
              message,
            })
          }
        })
      }
    } catch (createClientError) {
      if (axios.isAxiosError(createClientError)) {
        const message = createClientError?.response?.data?.message || API_ERRORS.BUSINESS_CLIENTS_CREATE_FAILED;
        addToast && addToast({
          message,
          variant: 'error',
        })
      }
    }
  }

  const deleteClientAsync = () => {
    deleteClient({
      variables: { input: { id: client?.id, salonId } }
    }).then(({ data }) => {
      if (data?.deleteClient?.status === 200) {
        closeClientModal()
        addToast({
          variant: "success",
          message: "Client deleted successfully",
        });
      }

      if (data?.deleteClient?.errors?.length) {
        const message = data?.deleteClient?.errors[0]?.message || API_ERRORS.BUSINESS_CLIENTS_DELETE_FAILED;
        addToast({
          variant: 'error',
          message,
        })
      }
    }).catch(deleteClientError => {
      const message = deleteClientError?.response?.data?.message || API_ERRORS.BUSINESS_CLIENTS_DELETE_FAILED;
      addToast({
        variant: "error",
        message,
      });
    })
  }

  const _sanitizePhoneNumber = (phoneNumber: string) => {
    const _phoneNumber = sanitizePhoneNumber(phoneNumber, callingCode);
    setValue('phone', _phoneNumber)

    const pn = validatePhoneNumber(_phoneNumber, countryCode);

    if (!pn.valid) {
      // If invalid, trigger an error
      setError('phone', {
        type: 'manual',
        message: 'Invalid phone number',
      });
    } else {
      // Clear the error if the phone number is valid
      clearErrors('phone');
    }
  }

  const _sanitizeAltPhoneNumber = (phoneNumber: string) => {
    const _phoneNumber = sanitizePhoneNumber(phoneNumber, altCallingCode);
    setValue('altPhone', _phoneNumber)

    const pn = validatePhoneNumber(_phoneNumber, countryCode);

    if (!pn.valid) {
      // If invalid, trigger an error
      setError('altPhone', {
        type: 'manual',
        message: 'Invalid phone number',
      });
    } else {
      // Clear the error if the phone number is valid
      clearErrors('altPhone');
    }
  }

  return (
    <Modal
      title={
        client ? 'Edit client' : 'Add client'
      }
      subTitle={
        client ? 'Edit client details' : 'Enter client information below'
      }
      show={isVisible}
      closeModal={closeClientModal}
    >
      <form onSubmit={handleSubmit(addClientSubmit)} className="w-full mt-6 space-y-6" autoComplete='off'>
        <div className='w-full flex gap-x-4'>
          <Input name="firstName" label="First name" id="first-name" type="text" placeholder='Enter first name here' control={control} rules={{
            required: ACCOUNT_SETUP_ERRORS.FIRST_NAME_REQUIRED,
          }} error={errors.firstName} />
          <Input name="lastName" label="Last name" id="last-name" type="text" placeholder='Enter last name here' control={control} rules={{
            required: ACCOUNT_SETUP_ERRORS.LAST_NAME_REQUIRED,
          }} error={errors.lastName} />
        </div>
        <FormPhone
          country={{
            disabled: false,
            onSelect: (code, country: string) => {
              setCallingCode(code);
              setCountryCode(country);
            },
            value: countryCode,
          }}
          phone={{
            name: "phone",
            type: "text",
            placeholder: "9151930463",
            rules: {
              required: ERRORS.PHONE_REQUIRED,
              pattern: REGEX_PATTERNS.NUMBER,
              onChange: (e) => {
                _sanitizePhoneNumber(e.target.value);
              },
              disabled: false,
            },
            register,
            id: "phone-number",
            label: "Phone Number",
          }}
          helperText={getHelperTextForReactHookFormErrors(
            errors?.phone?.message as string
          )}
        />
        <FormPhone
          country={{
            disabled: false,
            onSelect: (code, country: string) => {
              setAltCallingCode(code);
              setAltCountryCode(country);
            },
            value: altCountryCode,
          }}
          phone={{
            name: "altPhone",
            type: "text",
            placeholder: "9151930463",
            rules: {
              pattern: REGEX_PATTERNS.NUMBER,
              onChange: (e) => {
                _sanitizeAltPhoneNumber(e.target.value);
              },
              disabled: false,
            },
            register,
            id: "alt-phone-number",
            label: "Secondary Phone Number",
          }}
          helperText={getHelperTextForReactHookFormErrors(
            errors?.altPhone?.message as string
          )}
        />
        <Input name="email" label="Email" id="email" type="email" placeholder='email@acme.com' control={control} rules={{
          pattern: REGEX_PATTERNS.EMAIL,
        }} error={errors.email} />
        <Input name="dob" label="Client Birthday" id="dob" type="date" placeholder='DD/MM/YY' control={control} />
        <Controller
          control={control}
          name='optOutSms'
          render={({ field: { onChange, value } }) => {
            return (
              <div
                className='w-full flex flex-wrap gap-4 xl:gap-0 xl:space-x-4'
                onClick={() => onChange(!value)}
              >
                <FormLabel htmlFor='optOutSms'>
                  Does this client want to receive SMS reminders?
                </FormLabel>
                <div className='flex items-center cursor-pointer text-b5 text-grey-900 space-x-2'>
                  <ToggleOnly isChecked={value} />
                </div>
              </div>
            )
          }}
        />
        <Controller
          control={control}
          name='optOutEmail'
          render={({ field: { onChange, value } }) => {
            return (
              <div
                className='w-full flex flex-wrap gap-4 xl:gap-0 xl:space-x-4'
                onClick={() => onChange(!value)}
              >
                <FormLabel htmlFor='optOutEmail'>
                  Does this client want to receive Email reminders?
                </FormLabel>
                <div className='flex items-center cursor-pointer text-b5 text-grey-900 space-x-2'>
                  <ToggleOnly isChecked={value} />
                </div>
              </div>
            )
          }}
        />
        <div className='space-y-6'>
          <Button
            variant='primary'
            className=''
            size='lg'
            rounded='lg'
            disabled={creatingClient || updatingClient || deleteClientIsLoading}
            loading={creatingClient || updatingClient}
          >
            Save
          </Button>
          <Button
            variant='text'
            className={`mx-auto ${client && 'text-red-500'}`}
            size='none'
            type='button'
            fontWeight="semiBold"
            onClick={client ? deleteClientAsync : closeClientModal}
            disabled={creatingClient || updatingClient || deleteClientIsLoading}
            loading={deleteClientIsLoading}
          >
            {client ? 'Delete' : 'Cancel'}
          </Button>
        </div>
      </form>
    </Modal>
  )
}

export default AddClientModal
