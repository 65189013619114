import React, { useContext } from 'react'
import BookContext from './context';
import { SalonStaff } from 'core/generated';
import { GroupedServices } from './types';
import { Button, FormatNumber, Heading, Paragraph, SvgArrowDown, SvgArrowLeft, SvgArrowRight, SvgArrowUp, SvgWhiteShoppingBag } from 'ui';
import { COLORS } from 'constants/colors';
import { convertToSlug } from './utils';
import { getHoursAndMinutesString } from 'components/utils/misc';
import { INITIAL_STEP, TIME_AND_DATE } from './constants';
import BookingBreadcrumb from './BookingBreadcrumb';
import BookingDetailsCard from './BookingDetailsCard';
import BookingSteps from './BookingSteps';
import SelectedStaffView from './components/SelectedStaff';
import { useModal } from 'hooks';
import SelectStaff from './modals/SelectStaff';

const SelectAdditionalServices = () => {
  const { formattedServices: services, loadingServices, selectService, setVoucherServices, activeTab, setActiveTab, showOrHideCategory, handleNextStep, selectedServices, businessInfo, salons, selectedSalon, setSelectedSalon, formattedPackages, modifyPackageInCart, selectedPackages, setVoucherToBeRedeemed, addToast, setServiceClient, isMobile, openVoucherRedemptionModal, setOpenVoucherRedemptionModal, debouncedSearchQuery, searchCount, closeSearch, openSearchWrapper, setDebouncedSearchQuery, searchWrapperIsOpen, faqs, setSelectedStaff, selectedStaff } = useContext(BookContext);
  const {
    isVisible: isSelectStaffModalVisible,
    openModal: openSelectStaffPage,
    closeModal: closeSelectStaffPage,
  } = useModal();
  const getStaffServices = (staff: SalonStaff): GroupedServices[] => {
    if (!staff || !Array.isArray(services)) {
      return [];
    }

    // Map and filter services for the specific staff
    const staffServices = services
      .map((categoryService) => {
        // Filter services in the category
        const filteredServices = categoryService.services.filter((service) =>
          service.serviceStaffs?.some((staffMember) => staffMember?.salonStaff?.id === staff?.id)
        );

        // Return only categories with valid services
        if (filteredServices.length > 0) {
          return {
            ...categoryService,
            services: filteredServices,
          };
        }

        return null; // Skip empty categories
      })
      .filter(Boolean) as GroupedServices[]; // Remove nulls and cast to GroupedServices[]

    return staffServices;
  };
  return (
    <>
      <div className='w-full flex flex-col px-[10px] xl:px-[80px] pb-[150px] overflow-auto'>
        <BookingBreadcrumb />
        <div className='w-full flex flex-col xl:flex-row gap-4'>
          {/* Card */}
          <div className='w-full h-fit flex flex-col xl:max-w-[40%] border border-grey-50 rounded-xl py-8 px-6 gap-8'>
            <div className='w-full flex flex-col space-y-2'>
              <div className='w-full flex space-x-2 items-center' onClick={() => handleNextStep(INITIAL_STEP)}>
                <SvgArrowLeft />
                <Paragraph size='b4' weight='normal' color={COLORS.GREY[300]} className=''>Back</Paragraph>
              </div>
              <Heading variant='h1' size='h9' weight='bold'>Select additional services</Heading>
              <Paragraph size='b5' color={COLORS.GREY['300']}>Choose additional services based on selection.</Paragraph>
            </div>
            {selectedStaff ?
              <SelectedStaffView selectedStaff={selectedStaff} openSelectStaffPage={openSelectStaffPage} /> : null}
            <div className="w-full space-y-6">
              {selectedStaff && getStaffServices(selectedStaff) && getStaffServices(selectedStaff)?.length ? (
                <>
                  {getStaffServices(selectedStaff).map((category) => (
                    <div className='flex flex-col space-y-4' key={category.category} id={convertToSlug(category?.name)}>
                      <div className='flex justify-between cursor-pointer items-center' onClick={() => showOrHideCategory(category?.name)}>
                        <Heading variant='h3' size='h11' weight='semiBold' className='capitalize'>{category?.name}</Heading>
                        {category?.show ? (
                          <SvgArrowUp />
                        ) : (
                          <SvgArrowDown />
                        )}
                      </div>
                      {category?.show && Array.isArray(category?.services) && category?.services.map((service) => {
                        return (
                          <div className='flex flex-row justify-between items-center gap-2' key={service.id}>
                            <div className='w-full xl:max-w-[70%] flex flex-col justify-start'>
                              <Paragraph size='b4' weight='semiBold' color={COLORS.GREY[300]}>{service?.name}</Paragraph>
                              <Paragraph size='b5' weight='normal' color={COLORS.GREY[400]}>{service?.description}</Paragraph>
                              <Paragraph size='b5' weight='normal' color={COLORS.BLACK}> {service?.pricingType === 'from' ? (<span className='text-grey-400 bg-grey-100 text-b7 px-1 py-0.5 rounded-full mr-2'>from</span>) : null} {businessInfo?.country?.currency?.symbol}<FormatNumber value={service?.price} /> · <span className='text-grey-300'>{getHoursAndMinutesString(service?.duration)}</span></Paragraph>
                            </div>
                            {businessInfo?.acceptsOnlineBookings && (
                              <Button
                                variant={service?.selected ? 'primary' : 'light'}
                                className={`px-4 ${service?.selected ? 'text-white' : 'text-green-300'}`}
                                size='sm'
                                rounded='lg'
                                type='button'
                                fontWeight='semiBold'
                                onClick={() => selectService(service?.id)}
                              >
                                {service?.selected ? 'Added' : 'Add'}
                              </Button>
                            )}
                          </div>
                        )
                      })}
                    </div>
                  ))}
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>

      <div className="w-full flex lg:hidden fixed bottom-[20px] h-[60px] px-[20px]">
        <Button
          variant='primary'
          className=''
          size='lg'
          rounded='lg'
          onClick={() => handleNextStep(TIME_AND_DATE)}
        >
          <div className='w-full flex justify-between items-center px-4 py-2'>
            <div className="flex gap-2 items-center text-white">
              <SvgWhiteShoppingBag width="24px" height="24px" />
              <Paragraph size='b4' weight='semiBold' color={COLORS.WHITE}>Proceed</Paragraph>
            </div>
            <SvgArrowRight width="16px" height="16px" />
          </div>
        </Button>
      </div>
      <SelectStaff
        isVisible={isSelectStaffModalVisible}
        closeModal={closeSelectStaffPage}
        staffList={selectedSalon?.salonStaffs}
        selectedStaff={selectedStaff}
        service={selectedServices?.length ? selectedServices[0] : null}
      />
    </>
  )
}

export default SelectAdditionalServices