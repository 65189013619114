import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useToast } from "hooks/useToast";
import { LocationInput, LocationSetupEnum, PageProps } from "../types";
import { API_ERRORS, ERRORS } from "constants/errors";
import { formatNumber, sanitizePhoneNumber } from "../../../utils/misc";
import { DEFAULT_CURRENCY } from "constants/currency";
import AccountSetupTemplate from "../AccountSetupTemplate";
import ToastWrapper from "ui/molecules/toastWrapper/ToastWrapper";
import { ToastProps } from "ui/atoms/toast/types";
import { IMAGE_UPLOAD_PATTERN, REGEX_PATTERNS } from "constants/pattern";
import { getImageUploadContent } from "../../../utils/upload";
import { FormHelperText } from "ui/atoms/helperText/FormHelperText";
import Paragraph from "ui/atoms/paragraph/Paragraph";
import Input from "ui/molecules/input/Input";
import SelectInput from "ui/molecules/input/SelectInput";
import { FormLabel } from "ui/atoms/formLabel";
import Checkbox from "ui/atoms/checkbox/Checkbox";
import {
  NIGERIA_STATES,
  PROCESSING_FEE,
  YES_OR_NO_SETTINGS,
} from "constants/information";
import { DEPOSIT_DURATION_INTERVAL } from "constants/form";
import FormPhone from "ui/molecules/input/FormPhone";
import { getHelperTextForReactHookFormErrors } from "../../../utils/form";
import Button from "ui/atoms/button/Button";
import { useSalonCache } from "hooks/useSalonCache";
import { COLORS } from "constants/colors";
import { useImageUploader } from "hooks/useUploader";
import {
  Business,
  UpdateSalonInput,
  User,
} from "core/generated";
import { useUserCache } from "hooks/useUserCache";
import { PRICE_TYPE_REQUIREMENTS } from "../constants";
import { GOOGLE_PLACES_SEARCH, useGooglePlaceSearch, USER, useUploadFile, useUser } from "api/useAccount";
import { useCreateSalon, useUpdateSalon } from "api/useSalon";
import { useLazyQuery } from "@apollo/client";
import { MultiSelectOption } from "ui";
import SingleSelect from "ui/molecules/singleSelect/SingleSelect";

const SalonLocation = ({ onNextPage, business }: PageProps) => {
  const {
    loading: isUpdatingSalon,
    updateSalon
  } = useUpdateSalon()
  const {
    loading: isCreatingSalon,
    createSalon
  } = useCreateSalon()
  const {
    control,
    handleSubmit,
    setValue,
    clearErrors,
    register,
    watch,
    formState: { errors },
  } = useForm<LocationInput>({
    defaultValues: {
      locationSetupSalon: true,
      taxInclusivePricing: true,
      isTaxVisible: true,
      bearsProcessingFee: false,
      isDepositRequired: false,
    },
  });
  const { addToast, toast } = useToast();
  const { setSalonData, getSalonData } = useSalonCache();
  const { getBusinessData, setBusinessData } = useUserCache();
  const salon = getSalonData();
  const businessData: Business = getBusinessData();
  const [countryCode, setCountryCode] = useState(null);
  const [callingCode, setCallingCode] = useState(null);
  const [typeSign, setTypeSign] = useState<string | null>("-");
  const [showState, setShowState] = useState(false);
  const [locationSearchTerm, setLocationSearchTerm] = useState('')
  const [logoUrl, setLogoUrl] = useState(business?.logoUrl)

  const [getUser, { loading, error, data, called }] = useLazyQuery(USER);
  const user = useMemo(() => {
    const currentUser: User = data?.user;

    if (currentUser) {
      // Store user data in localStorage
      localStorage.setItem('userData', JSON.stringify(currentUser));
      localStorage.setItem(
        'role',
        JSON.stringify(currentUser?.salonStaff?.staffRole)
      );

      // Set additional state based on user data
      setBusinessData(currentUser?.business);
      const salons = currentUser?.business?.salons
      const getSalon = salons.find((salon) => salon.id === salon?.id);
      setSalonData(getSalon || salons[0])

      // Navigate to the next page
      onNextPage();
    }

    return currentUser;
  }, [data?.user, onNextPage]);


  const {
    loading: logoIsLoading,
    uploadFile: uploadLogo
  } = useUploadFile();

  const handleImageUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files[0]
    const reader = new FileReader()
    reader.onload = async (event) => {
      const base64DataUri = event.target.result;
      uploadLogo({
        variables: { input: { data: base64DataUri } }
      }).then(({ data }) => {
        const { uploadFile } = data;
        if (uploadFile?.status === 200) {
          setLogoUrl(uploadFile?.url);
        }

        if (uploadFile?.errors?.length) {
          addToast({
            message: uploadFile?.errors[0].message,
            variant: 'error'
          })
        }
      }).catch((error) => {
        addToast({
          message: error?.message,
          variant: 'error'
        })
      })
    }

    reader.readAsDataURL(file)
  }

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    setShowState(userData?.business?.countryCode === "NG");
    setCountryCode(userData?.business?.countryCode);
    setCallingCode(userData?.business?.callingCode);
  }, []);

  useEffect(() => {
    if (logoUrl) {
      setValue("logoUrl", logoUrl);
      clearErrors("logoUrl");
    }
  }, [clearErrors, logoUrl, setValue]);

  // useEffect(() => {
  //   if (user) {
  //     localStorage.setItem('userData', JSON.stringify(user));
  //     localStorage.setItem(
  //       'role',
  //       JSON.stringify(user?.salonStaff?.staffRole)
  //     );
  //     setBusinessData(user?.business);
  //     setIsLoading(false);
  //     onNextPage();
  //   }
  // }, [user])

  useEffect(() => {
    if (salon) {
      // setCountryCode(salon?.countryCode);
      // setCallingCode(salon?.callingCode);
      setValue("branchName", salon.branchName);
      // @ts-expect-error error expected
      setValue('address', { label: salon?.address, value: salon.address })
      setValue("city", salon.city);
      setValue("state", salon.state);
      setValue("logoUrl", businessData?.logoUrl);
      setValue("email", salon.email);
      setValue("phone", salon.phone);
      if (salon.locationType === "salon") {
        setValue("locationSetupSalon", true);
      }
      if (salon.locationType === "home") {
        setValue("locationSetupHome", true);
      }

      if (salon.locationType === "flexible") {
        setValue("locationSetupSalon", true);
        setValue("locationSetupHome", true);
      }
      setValue("depositValue", salon?.depositValue || "");
      setValue("depositType", salon?.depositType || "");
      setValue("depositLinkValidityHours", salon?.depositLinkValidityHours);
      setValue("isDepositRequired", salon?.isDepositRequired || false);
      setValue("isTaxVisible", salon?.isTaxVisible);
    }
  }, []);

  useEffect(() => {
    if (businessData) {
      setValue("logoUrl", businessData?.logoUrl);
    }
  }, [businessData?.logoUrl]);

  const onSubmitData = async (input: LocationInput) => {
    const locationTypes: LocationSetupEnum[] = [];
    if (input.locationSetupSalon) {
      locationTypes.push(LocationSetupEnum.SALON);
    }
    if (input.locationSetupHome) {
      locationTypes.push(LocationSetupEnum.HOME);
    }
    // @ts-expect-error error expected
    if (!input?.address?.value && input.locationSetupSalon) {
      addToast({
        message: API_ERRORS.ADDRESS_REQUIRED,
        variant: 'error',
      });
      return;
    }
    try {
      const salonData = {
        branchName: input.branchName,
        locationType:
          locationTypes.length === 2 ? "flexible" : locationTypes.join(","),
        // @ts-expect-error error expected
        address: input?.address?.value,
        city: '',
        state: '',
        taxInclusivePricing: input.taxInclusivePricing,
        isTaxVisible: input?.isTaxVisible || false,
        bearsProcessingFee: input?.bearsProcessingFee,
        callingCode,
        phone: input?.phone,
        email: input?.email,
        depositType: input.depositType ? input.depositType : null,
        depositValue: input.depositValue
          ? Number(input?.depositValue?.toString()?.replace(/,/g, ""))
          : 0,
        isDepositRequired: input.isDepositRequired,
        logoUrl: input.logoUrl,
        status: "open",
        intervalMinutes: salon?.intervalMinutes || 30,
      } as unknown as UpdateSalonInput;

      if (input.depositLinkValidityHours !== undefined) {
        salonData.depositLinkValidityHours = Number(
          input.depositLinkValidityHours
        );
      }

      if (!salon) {
        createSalon({
          variables: { input: { ...salonData, onboardingStep: 'created_salon' } },
        }).then(({ data }) => {
          const createSalon = data?.createSalon;
          if (createSalon?.status === 201) {
            getUser()
          } else {
            const message =
              createSalon?.errors[0]?.message ||
              API_ERRORS.CREATE_SALON_FAILED;
            addToast({
              message,
              variant: "error",
            });
          }
        })
      } else {
        updateSalon({
          variables: { input: { ...salonData, id: salon?.id } },
        }).then(({ data }) => {
          const updateSalon = data?.updateSalon;
          if (updateSalon?.status === 200) {
            getUser()
          } else {
            const message =
              updateSalon?.errors[0]?.message ||
              API_ERRORS.CREATE_SALON_FAILED;
            addToast({
              message,
              variant: "error",
            });
          }
        })
      }
    } catch (error) {
      console.error(error);
      const message =
        error?.response?.data?.message || API_ERRORS.CREATE_SALON_FAILED;
      addToast({
        message,
        variant: "error",
      });
    }
  };

  const _sanitizePhoneNumber = (phoneNumber: string) => {
    const _phoneNumber = sanitizePhoneNumber(phoneNumber, callingCode);
    setValue("phone", _phoneNumber);
  };

  const searchLocation = (e) => {
    setLocationSearchTerm(e)
  }

  const {
    data: googlePlacesSearchData,
    loading: googlePlacesSearchLoading,
    error: googlePlacesSearchError,
    refetch: googlePlacesSearchRefetch
  } = useGooglePlaceSearch({
    q: locationSearchTerm
  })

  const locationOptions = useMemo(() => {
    if (googlePlacesSearchData?.googlePlacesSearch) {
      return googlePlacesSearchData?.googlePlacesSearch.map((prediction) => {
        return {
          label: prediction.label,
          value: prediction?.value
        }
      })
    }
    return []
  }, [googlePlacesSearchData])

  useEffect(() => {
    const delaySearch = setTimeout(() => {
      if (locationSearchTerm) {
        googlePlacesSearchRefetch()
      }
    }, 500)

    return () => clearTimeout(delaySearch)
  }, [locationSearchTerm])

  useEffect(() => {
    if (watch("depositType")) {
      if (watch("depositType") === "fixed_value") {
        setTypeSign(DEFAULT_CURRENCY);
      } else if (watch("depositType") === "percentage") {
        setTypeSign("%");
      }
    } else {
      setTypeSign("-");
    }
  }, [watch("depositType")]);
  return (
    <AccountSetupTemplate
      title={`Welcome, ${businessData?.name || ""}`}
      subtitle="Enter your address and set where you want to serve your clients"
    >
      <ToastWrapper toast={toast as ToastProps} />
      <form
        onSubmit={handleSubmit(onSubmitData)}
        className="w-full space-y-6 pt-6"
      >
        <div className="w-full flex border rounded-sm border-grey-20 border-dashed h-[130px] justify-center items-center">
          <Controller
            control={control}
            name="logoUrl"
            render={({ field: { value }, formState: { errors } }) => {
              const logoImageUploadUrl = logoUrl || value;
              return (
                <div className="flex flex-col">
                  <div className="flex items-center space-x-4">
                    <div className="relative cursor-pointer">
                      {getImageUploadContent({
                        imageUrl: logoImageUploadUrl,
                        uploadIsLoading: logoIsLoading,
                        placeHolder: "Upload Business Logo",
                      })}
                      <input
                        className="cursor-pointer absolute block opacity-0 top-0 w-full h-[96px]"
                        type="file"
                        accept={IMAGE_UPLOAD_PATTERN}
                        onChange={handleImageUpload}
                      />
                    </div>
                  </div>
                  {errors?.logoUrl?.message && (
                    <FormHelperText variant="error">
                      {errors.logoUrl.message}
                    </FormHelperText>
                  )}
                </div>
              );
            }}
          />
        </div>
        <Paragraph size="b7" color={COLORS.GREY[300]} className="text-center">
          Kindly upload a logo with dimensions not exceeding 250px by 150px
        </Paragraph>
        <Input
          name="branchName"
          id="branch-name"
          label="Branch name"
          type="text"
          placeholder="Enter branch name here"
          control={control}
          rules={{
            pattern: REGEX_PATTERNS.ALPHANUMERIC_WITH_SPACE_OR_DASH,
            required: ERRORS.BUSINESS_BRANCH_NAME_REQUIRED,
          }}
          error={errors.branchName}
        />
        <div className='w-full flex flex-col space-y-2'>
          <Controller
            control={control}
            name='address'
            render={({ field: { onChange, value }, formState: { errors } }) => {
              const errorMessage: string = errors?.address?.message

              return (
                <>
                  <FormLabel htmlFor='client'>Business Address</FormLabel>
                  <SingleSelect
                    // @ts-expect-error exprect error
                    selected={value || []}
                    options={locationOptions as []}
                    setSelected={onChange}
                    isLoading={googlePlacesSearchLoading}
                    onInputChange={searchLocation}
                    placeholder='Enter address'
                  />
                  {errorMessage && (
                    <FormHelperText variant='error'>
                      {errorMessage}
                    </FormHelperText>
                  )}
                </>
              )
            }}
          />
        </div>
        {/* <div className="w-full flex gap-x-4">
          <Input
            name="city"
            label="City"
            id="city"
            type="text"
            placeholder="E.g Lekki"
            control={control}
            rules={{
              required: watch("locationSetupSalon")
                ? ERRORS.CITY_REQUIRED
                : false,
              pattern: REGEX_PATTERNS.ALPHANUMERIC_WITH_SPACE_OR_DASH,
            }}
            error={errors.city}
          />
          {showState && (
            <SelectInput
              name="state"
              id="state"
              label="State"
              control={control}
              rules={{
                required: watch("locationSetupSalon")
                  ? ERRORS.STATE_REQUIRED
                  : false,
              }}
              error={errors.state}
              options={NIGERIA_STATES}
              placeholder="Select State"
            />
          )}
        </div> */}
        <div className="flex flex-col space-y-4">
          <FormLabel htmlFor="locationSetupSalon">
            Where do you serve clients?
          </FormLabel>
          <div className="flex space-x-4">
            <Controller
              control={control}
              name="locationSetupSalon"
              render={({ field: { onChange, value } }) => {
                return (
                  <div className="flex" onClick={() => onChange(!value)}>
                    <div className="flex items-center cursor-pointer text-b5 text-grey-900 space-x-2">
                      <Checkbox isChecked={value} />
                      <span className="text-grey-300 whitespace-nowrap">
                        Business Address
                      </span>
                    </div>
                  </div>
                );
              }}
            />
            <Controller
              control={control}
              name="locationSetupHome"
              render={({ field: { onChange, value } }) => {
                return (
                  <div className="flex" onClick={() => onChange(!value)}>
                    <div className="flex items-center cursor-pointer text-b5 text-grey-300 space-x-2">
                      <Checkbox isChecked={value} />
                      <span className="text-grey-300 whitespace-nowrap">
                        Home Service
                      </span>
                    </div>
                  </div>
                );
              }}
            />
          </div>
        </div>
        <Controller
          control={control}
          name="taxInclusivePricing"
          render={({ field: { onChange, value } }) => {
            return (
              <div className="w-full flex flex-col space-y-4">
                <FormLabel htmlFor="taxInclusivePricing">
                  Are your display prices inclusive of VAT?
                </FormLabel>
                <div className="w-full flex flex-wrap gap-4 xl:gap-0 xl:space-x-4">
                  {Array.isArray(YES_OR_NO_SETTINGS) &&
                    YES_OR_NO_SETTINGS.length
                    ? YES_OR_NO_SETTINGS.map(
                      (taxSetting: { label: string; value: boolean }) => {
                        return (
                          <div
                            className="flex"
                            onClick={() => onChange(taxSetting?.value)}
                            key={taxSetting?.label}
                          >
                            <div className="flex items-center cursor-pointer text-b5 text-grey-900 space-x-2">
                              <Checkbox
                                isChecked={value === taxSetting?.value}
                              />
                              <span className="text-grey-900 whitespace-nowrap">
                                {taxSetting?.label}
                              </span>
                            </div>
                          </div>
                        );
                      }
                    )
                    : null}
                </div>
              </div>
            );
          }}
        />
        <Controller
          control={control}
          name="isTaxVisible"
          render={({ field: { onChange, value } }) => {
            return (
              <div className="w-full flex flex-col space-y-4">
                <FormLabel htmlFor="isTaxVisible">
                  Do you want to display tax in receipt?
                </FormLabel>
                <div className="w-full flex flex-wrap gap-4 xl:gap-0 xl:space-x-4">
                  {Array.isArray(YES_OR_NO_SETTINGS) &&
                    YES_OR_NO_SETTINGS.length
                    ? YES_OR_NO_SETTINGS.map(
                      (taxSetting: { label: string; value: boolean }) => {
                        return (
                          <div
                            className="flex"
                            onClick={() => onChange(taxSetting?.value)}
                            key={taxSetting?.label}
                          >
                            <div className="flex items-center cursor-pointer text-b5 text-grey-900 space-x-2">
                              <Checkbox
                                isChecked={value === taxSetting?.value}
                              />
                              <span className="text-grey-900 whitespace-nowrap">
                                {taxSetting?.label}
                              </span>
                            </div>
                          </div>
                        );
                      }
                    )
                    : null}
                </div>
              </div>
            );
          }}
        />
        <Controller
          control={control}
          name="bearsProcessingFee"
          render={({ field: { onChange, value } }) => {
            return (
              <div className="w-full flex flex-col space-y-4">
                <FormLabel htmlFor="locationSetupSalon">
                  Do you want to bear the {PROCESSING_FEE}% online payment
                  processing fee?
                </FormLabel>
                <div className="w-full flex flex-wrap gap-4 xl:gap-0 xl:space-x-4">
                  {Array.isArray(YES_OR_NO_SETTINGS) &&
                    YES_OR_NO_SETTINGS.length
                    ? YES_OR_NO_SETTINGS.map(
                      (feeSetting: { label: string; value: boolean }) => {
                        return (
                          <div
                            className="flex"
                            onClick={() => onChange(feeSetting?.value)}
                            key={feeSetting?.label}
                          >
                            <div className="flex items-center cursor-pointer text-b5 text-grey-900 space-x-2">
                              <Checkbox
                                isChecked={value === feeSetting?.value}
                              />
                              <span className="text-grey-900 whitespace-nowrap">
                                {feeSetting?.label}
                              </span>
                            </div>
                          </div>
                        );
                      }
                    )
                    : null}
                </div>
              </div>
            );
          }}
        />
        <Controller
          control={control}
          name="isDepositRequired"
          render={({ field: { onChange, value } }) => {
            return (
              <div className="w-full flex flex-col space-y-4">
                <FormLabel htmlFor="isDepositRequired">
                  Is payment required for booking?
                </FormLabel>
                <div className="w-full flex flex-wrap gap-4 xl:gap-0 xl:space-x-4">
                  {Array.isArray(YES_OR_NO_SETTINGS) &&
                    YES_OR_NO_SETTINGS.length
                    ? YES_OR_NO_SETTINGS.map(
                      (isDepositRequired: {
                        label: string;
                        value: boolean;
                      }) => {
                        return (
                          <div
                            className="flex"
                            onClick={() => onChange(isDepositRequired?.value)}
                            key={isDepositRequired?.label}
                          >
                            <div className="flex items-center cursor-pointer text-b5 text-grey-900 space-x-2">
                              <Checkbox
                                isChecked={value === isDepositRequired?.value}
                              />
                              <span className="text-grey-900 whitespace-nowrap">
                                {isDepositRequired?.label}
                              </span>
                            </div>
                          </div>
                        );
                      }
                    )
                    : null}
                </div>
              </div>
            );
          }}
        />
        {watch("isDepositRequired") && (
          <>
            <div className="w-full flex items-center gap-x-4">
              <SelectInput
                name="depositType"
                id="depositValue-type"
                label="How much deposit is required?"
                control={control}
                rules={{
                  required: ERRORS.DEPOSIT_TYPE_REQUIRED,
                }}
                error={errors.depositType}
                options={PRICE_TYPE_REQUIREMENTS}
                placeholder="Select Requirement"
              />
              <Controller
                control={control}
                name="depositValue"
                rules={{ required: "How much deposit is required?" }}
                render={({ field, formState: { errors } }) => (
                  <div className="w-full flex flex-col mt-7">
                    <div className="flex w-full">
                      <input
                        className="w-full flex items-center appearance-none border border-grey-20 px-4 py-3 font-normal text-b6 xl:text-b4 text-grey-900 rounded-tl-lg rounded-bl-lg focus:outline-none focus:border-grey-20 focus:shadow-grey-100 placeholder:text-grey-400"
                        type="text"
                        {...field}
                        value={formatNumber(field.value)}
                        onChange={(e) =>
                          field.onChange(formatNumber(e.target.value))
                        }
                        placeholder="Enter percentage"
                      />
                      <div className="px-5 py-3 bg-grey-20 rounded-tr-lg rounded-br-lg text-grey-900">
                        {typeSign}
                      </div>
                    </div>
                    {errors?.depositValue?.message && (
                      <FormHelperText variant="error">
                        {errors.depositValue.message}
                      </FormHelperText>
                    )}
                  </div>
                )}
              />
            </div>

            <SelectInput
              name="depositLinkValidityHours"
              id="depositLinkValidityHours"
              label="Select Payment Link Duration"
              control={control}
              rules={{
                required: ERRORS.DEPOSIT_INTERVAL_REQUIRED,
              }}
              error={errors.depositLinkValidityHours}
              options={DEPOSIT_DURATION_INTERVAL}
              placeholder="Select Duration"
            />
          </>
        )}
        <div>
          <Paragraph
            size="b4"
            color={COLORS.GREY[300]}
            weight="bold"
            className="pb-3"
          >
            Contact information
          </Paragraph>
          <Input
            name="email"
            label="Contact Email"
            id="email"
            type="email"
            placeholder="email@acme.com"
            control={control}
            rules={{
              pattern: REGEX_PATTERNS.EMAIL,
              required: ERRORS.EMAIL_REQUIRED,
            }}
            error={errors.email}
          />
        </div>
        <FormPhone
          country={{
            disabled: true,
            onSelect: (code, country: string) => {
              setCallingCode(code);
              setCountryCode(country);
            },
            value: countryCode,
          }}
          phone={{
            name: "phone",
            type: "text",
            placeholder: "9151930463",
            rules: {
              required: ERRORS.PHONE_REQUIRED,
              pattern: REGEX_PATTERNS.NUMBER,
              onChange: (e) => {
                _sanitizePhoneNumber(e.target.value);
              },
              disabled: false,
            },
            register,
            id: "phone-number",
            label: "Contact Phone Number",
          }}
          helperText={getHelperTextForReactHookFormErrors(
            errors?.phone?.message as string
          )}
        />
        <Button
          variant="primary"
          className=""
          size="lg"
          rounded="lg"
          disabled={isCreatingSalon || isUpdatingSalon}
          loading={isCreatingSalon || isUpdatingSalon}
        >
          Proceed
        </Button>
      </form>
    </AccountSetupTemplate>
  );
};

export default SalonLocation;
