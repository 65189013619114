import React, { useState, useEffect, useMemo } from 'react'
import {
  CalendarPicker,
  Heading,
  Layout,
  Paginator,
  Paragraph,
  SearchTerm,
  Tabs
} from 'ui'
import { SvgCalendar, SvgCartBag, SvgLocationPin, SvgSale } from 'ui'
import { COLORS } from 'constants/colors'
import {
  createFileNameFromDates,
  formatDateToOriginalDate,
  formatInToPrice,
  formatTableHeadersFilterArray,
  getDefaultQueryDates,
  getNumberMonthAndYearFromDate,
  handleDownload,
  handleExcelDownload,
  stringOffCommaAndDotFromMoney
} from '../components/utils/misc'
import FullTable from 'ui/organism/table/Table'
import { Product, StockFlowReport, StockFlowSummaryReport, StockFlowSummaryReportDocument, StockMovementLogReport, StockMovementLogSummaryReport, StockMovementLogSummaryReportDocument, StockOnHandSummaryReport, StockOnHandSummaryReportDocument } from 'core/generated'
import { TableFilterProps } from './Inventory'
import { useSalonCache } from 'hooks/useSalonCache'
import ContentLoader from 'ui/atoms/contentLoader/ContentLoader'
import { checkReportsTokenFromRoute, checkSalonIdFromRoute } from '../components/utils/token'
import ExportDataToCsvOrXlsx from '../components/uicomponents/ExportDataToCsvOrXlsx'
import { DEFAULT_CURRENCY } from 'constants/currency'
import { useStockFlow, useStockFlowSummary, useStockMovementLogs, useStockMovementSummaryLog, useStockOnHand, useStockOnHandSummary } from 'api/useReports'

const INVENTORY_REPORTS_TAB_NAME = {
  STOCK_ON_HAND: 'Stock on hand',
  STOCK_FLOW: 'Stock flow',
  STOCK_MOVEMENT_LOG: 'Stock movement log'
}

const InventoryReports = () => {
  const INVENTORY_REPORTS_TABS = [
    {
      title: INVENTORY_REPORTS_TAB_NAME.STOCK_ON_HAND,
      key: INVENTORY_REPORTS_TAB_NAME.STOCK_ON_HAND,
      show: true
    },
    {
      title: INVENTORY_REPORTS_TAB_NAME.STOCK_FLOW,
      key: INVENTORY_REPORTS_TAB_NAME.STOCK_FLOW,
      show: true
    },
    {
      title: INVENTORY_REPORTS_TAB_NAME.STOCK_MOVEMENT_LOG,
      key: INVENTORY_REPORTS_TAB_NAME.STOCK_MOVEMENT_LOG,
      show: true
    }
  ]
  const [activeTab, setActiveTab] = useState<string>(
    INVENTORY_REPORTS_TABS.filter((tab) => tab.show)[0]?.key
  )

  const getTabView = () => {
    switch (activeTab) {
      case INVENTORY_REPORTS_TAB_NAME.STOCK_ON_HAND:
        return (
          <StockOnHand />
        )
      case INVENTORY_REPORTS_TAB_NAME.STOCK_FLOW:
        return (
          <StockFlow />
        )
      case INVENTORY_REPORTS_TAB_NAME.STOCK_MOVEMENT_LOG:
        return (
          <StockMovementLog />
        )
    }
  }

  return (
    <Layout pageTitle={activeTab} pageLevel={3}>
      <Tabs
        tabs={INVENTORY_REPORTS_TABS}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      <div className='w-full pb-20'>{getTabView()}</div>
    </Layout>
  )
}

const StockOnHand = () => {
  const { getSalonFieldValue } = useSalonCache()
  const salonId = getSalonFieldValue('id')
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState('')
  const [selectedDates, setSelectedDates] = useState<[Date, Date] | null>(
    getDefaultQueryDates()
  )
  const [before, setBefore] = useState<string | null>(null)
  const [after, setAfter] = useState<string | null>(null)
  const PRODUCTS_HEADINGS = [
    {
      label: 'Product name',
      value: 'name',
      show: true
    },
    {
      label: 'Current stock',
      value: 'stock',
      show: true
    },
    {
      label: 'Low stock level',
      value: 'low_stock_level',
      show: true
    },
    {
      label: 'Retail value',
      value: 'retail_value',
      show: true
    },
    {
      label: 'Total value',
      value: 'total_value',
      show: true
    }
  ]

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [productHeadings, setProductHeadings] = useState(PRODUCTS_HEADINGS)

  const generateProductsTableData = (
    products: Product[],
    tableHeadings: TableFilterProps
  ) => {
    const data = products?.map?.((product) => {
      const rowData = {}
      tableHeadings.forEach((heading) => {
        if (heading.show) {
          switch (heading.value) {
            case 'name':
              rowData[heading.value] = product?.name
              break
            case 'stock':
              rowData[heading.value] = product.stockCount
              break
            case 'low_stock_level':
              rowData[heading.value] = product.lowStockThreshold
              break
            case 'retail_value':
              rowData[heading.value] = formatInToPrice(product.retailPrice)
              break
            case 'total_value':
              rowData[heading.value] = product?.totalValue
              break
          }
        }
      })
      return rowData
    })

    // compose total
    data?.length && data.push({
      name: 'Total',
      stock: products?.reduce((acc, curr) => acc + curr.stockCount, 0),
      low_stock_level: products?.reduce((acc, curr) => acc + curr.lowStockThreshold, 0),
      retail_value: formatInToPrice(products?.reduce((acc, curr) => acc + curr.retailPrice, 0)),
      total_value: formatInToPrice(products?.reduce((acc, curr) => acc + stringOffCommaAndDotFromMoney(curr.totalValue), 0)),
    })
    return data
  }

  const {
    data: stockOnHandData,
    refetch: fetchStockOnHand,
    loading: isLoading
  } = useStockOnHand({
    salonId,
    q: debouncedSearchQuery, before, after
  })
  const stockOnHand = useMemo(() => stockOnHandData?.stockOnHandReport, [stockOnHandData])

  const {
    data: stockOnHandSummaryData,
    refetch: fetchStockOnHandSummary,
    loading: isLoadingSummary
  } = useStockOnHandSummary({
    salonId,
    startDate: formatDateToOriginalDate(selectedDates[0], "start"),
    endDate: formatDateToOriginalDate(selectedDates[1], "end")
  })
  const stockOnHandSummary = useMemo(() => stockOnHandSummaryData?.stockOnHandSummaryReport, [stockOnHandSummaryData])

  useEffect(() => {
    fetchStockOnHand()
  }, [debouncedSearchQuery])

  const formatStockOnHandData = (data: Product[]) => data.map(row => ({
    "Product name": row.name,
    "Current stock": row.stockCount,
    "Low stock level": row.lowStockThreshold,
    "Retail value": row.retailPrice,
    "Total value": row.totalValue
  }))

  const filename = `${createFileNameFromDates(selectedDates || getDefaultQueryDates())}_stock_on_hand_report`
  const _handleDownload = () => {
    if (stockOnHand) {
      handleDownload(formatStockOnHandData(stockOnHand?.nodes), filename)
    }
  }

  const downloadExcel = () => {
    if (stockOnHand) {
      handleExcelDownload(formatStockOnHandData(stockOnHand?.nodes), filename, 'Stock on hand')
    }
  }

  const goToNext = () => {
    if (stockOnHand?.pageInfo?.hasNextPage) {
      setAfter(stockOnHand?.pageInfo?.endCursor)
      setBefore(null)
      fetchStockOnHand()
    }
  }

  const goToPrev = () => {
    if (stockOnHand?.pageInfo?.hasPreviousPage) {
      setAfter(null)
      setBefore(stockOnHand?.pageInfo?.startCursor)
      fetchStockOnHand()
    }
  }

  const getStockOnHandContent = () => {
    const tableClientsData = generateProductsTableData(
      stockOnHand?.nodes,
      productHeadings
    )
    return (
      <>
        {isLoading ? <ContentLoader /> : null}
        <div className='w-full flex flex-col xl:flex-row justify-between lg:items-center p-2 md:p-3 lg:p-6 gap-2'>
          <div className='flex flex-col md:flex-row xl:flex-col gap-3'>
            <Heading size='h9' variant='h1' weight='bold'>
              Stock on hand
            </Heading>
            <div className='flex space-x-1 items-center'>
              <Paragraph size='b4' color={COLORS.GREY[300]} className='flex space-x-1 items-center'>
                <SvgCalendar width="16px" height="16px" />
                <span>{`${getNumberMonthAndYearFromDate(selectedDates[0])} - ${getNumberMonthAndYearFromDate(selectedDates[1])}`}</span>
              </Paragraph>
              <span>|</span>
              <Paragraph size='b4' color={COLORS.GREY[300]} className='flex space-x-1 items-center'>
                <SvgLocationPin width="16px" height="16px" />
                <span>{getSalonFieldValue('branchName')}</span>
              </Paragraph>
            </div>
          </div>
          <div className='flex flex-col md:flex-row md:items-center gap-4 md:gap-2'>
            <Paragraph size="b4" className='hidden md:flex'>Showing:</Paragraph>
            <CalendarPicker {...{ selectedDates, setSelectedDates }} />
            <ExportDataToCsvOrXlsx downloadCsv={_handleDownload} downloadXlsx={downloadExcel} />
          </div>
        </div>
        <div className='w-full grid grid-col-1 xl:grid-cols-3 gap-2 p-3 md:p-6 border-t border-grey-100'>
          <div className='w-full flex flex-col justify-between space-y-3 xl:max-w-[374px] border border-grey-100 bg-grey-50/20 p-4 rounded-md'>
            <span className='w-[32px] h-[32px] flex justify-center items-center rounded-sm bg-grey-50'>
              <SvgCartBag width="24px" height="24px" />
            </span>

            <div className='w-full flex flex-col'>
              <Paragraph size='b4' color={COLORS.GREY[400]} weight='bold' className=''>
                Stock available
              </Paragraph>
              <Paragraph size='b1' weight='semiBold' className=''>
                {stockOnHandSummary?.stockAvailable}
              </Paragraph>
            </div>
          </div>
          <div className='w-full flex flex-col justify-between space-y-3 xl:max-w-[374px] border border-grey-100 bg-grey-50/20 p-4 rounded-md'>
            <span className='w-[32px] h-[32px] flex justify-center items-center rounded-sm bg-grey-50'>
              <SvgSale width="18px" height="18px" />
            </span>

            <div className='w-full flex flex-col'>
              <Paragraph size='b4' color={COLORS.GREY[400]} weight='bold' className=''>
                Total retail value ({DEFAULT_CURRENCY})
              </Paragraph>
              <Paragraph size='b1' weight='semiBold' className=''>
                {stockOnHandSummary?.totalRetailPrice}
              </Paragraph>
            </div>
          </div>
          <div className='w-full flex flex-col justify-between space-y-3 xl:max-w-[374px] border border-grey-100 bg-grey-50/20 p-4 rounded-md'>
            <span className='w-[32px] h-[32px] flex justify-center items-center rounded-sm bg-grey-50'>
              <SvgSale width="18px" height="18px" />
            </span>

            <div className='w-full flex flex-col'>
              <Paragraph size='b4' color={COLORS.GREY[400]} weight='bold' className=''>
                Total value ({DEFAULT_CURRENCY})
              </Paragraph>
              <Paragraph size='b1' weight='semiBold' className=''>
                {stockOnHandSummary?.totalValue}
              </Paragraph>
            </div>
          </div>
        </div>
        <div className='w-full flex items-center justify-between border-t border-grey-100 p-3 md:p-6'>
          <Paragraph size='b4' weight='semiBold' className=''>
            Overview
          </Paragraph>
          <div className='w-full xl:w-6/12 flex items-center space-x-2'>
            <SearchTerm
              placeholder='Search by name, stock level'
              setDebouncedSearchQuery={setDebouncedSearchQuery} size='sm'
            />
            <Paginator pageInfo={stockOnHand?.pageInfo} {...{ goToNext, goToPrev }} />
          </div>
        </div>
        <FullTable
          rows={tableClientsData}
          headers={formatTableHeadersFilterArray(productHeadings)}
          loading={isLoading}
          hasBorder={true}
          hasFooterBg={true}
        />
      </>
    )
  }

  return <div className='w-full mb-20'>{getStockOnHandContent()}</div>
}

const StockFlow = () => {
  const { getSalonFieldValue } = useSalonCache()
  const salonId = getSalonFieldValue('id')
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState('')
  const [selectedDates, setSelectedDates] = useState<[Date, Date] | null>(
    getDefaultQueryDates()
  )
  const [before, setBefore] = useState<string | null>(null)
  const [after, setAfter] = useState<string | null>(null)
  const PRODUCTS_HEADINGS = [
    {
      label: 'Product name',
      value: 'name',
      show: true
    },
    {
      label: 'Initial stock',
      value: 'initial_stock',
      show: true
    },
    {
      label: 'Stock received',
      value: 'stock_received',
      show: true
    },
    {
      label: 'Stock sold',
      value: 'stock_sold',
      show: true
    },
    {
      label: 'Stock adjusted',
      value: 'stock_adjusted',
      show: true
    },
    {
      label: 'Current stock',
      value: 'current_stock',
      show: true
    }
  ]

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [productHeadings, setProductHeadings] = useState(PRODUCTS_HEADINGS)

  const {
    data: stockFlowData,
    refetch: getStockFlows,
    loading: isLoading
  } = useStockFlow({
    salonId,
    q: debouncedSearchQuery,
    startDate: formatDateToOriginalDate(selectedDates[0], "start"),
    endDate: formatDateToOriginalDate(selectedDates[1], "end"),
    before, after
  })
  const stockFlows = useMemo(() => stockFlowData?.stockFlowReport, [stockFlowData])

  const {
    data: stockFlowSummaryData,
    refetch: getStockFlowSummary,
    loading: isLoadingSummary
  } = useStockFlowSummary({
    salonId,
    startDate: formatDateToOriginalDate(selectedDates[0], "start"),
    endDate: formatDateToOriginalDate(selectedDates[1], "end")
  })
  const stockFlowSummary = useMemo(() => stockFlowSummaryData?.stockFlowSummaryReport, [stockFlowSummaryData])

  const generateProductsTableData = (
    products: StockFlowReport[],
    tableHeadings: TableFilterProps
  ) => {
    const p = products?.map?.((product) => {
      const rowData = {}
      tableHeadings.forEach((heading) => {
        if (heading.show) {
          switch (heading.value) {
            case 'name':
              rowData[heading.value] = product?.productName
              break
            case 'initial_stock':
              rowData[heading.value] = product.initialStock
              break
            case 'stock_received':
              rowData[heading.value] = product.stockReceived
              break
            case 'stock_sold':
              rowData[heading.value] = product.stockSold
              break
            case 'stock_adjusted':
              rowData[heading.value] = product.stockAdjusted
              break
            case 'current_stock':
              rowData[heading.value] = product.currentStock
              break
          }
        }
      })

      return rowData
    })

    p && p.push({
      name: 'Total',
      // @ts-expect-error error expected
      initial_stock: p?.reduce((acc, curr) => acc + curr.initial_stock, 0),
      // @ts-expect-error error expected
      stock_received: p?.reduce((acc, curr) => acc + curr.stock_received, 0),
      // @ts-expect-error error expected
      stock_sold: p?.reduce((acc, curr) => acc + curr.stock_sold, 0),
      // @ts-expect-error error expected
      stock_adjusted: p?.reduce((acc, curr) => acc + curr.stock_adjusted, 0),
      // @ts-expect-error error expected
      current_stock: p?.reduce((acc, curr) => acc + curr.current_stock, 0)
    })
    return p
  }

  useEffect(() => {
    getStockFlowSummary()
    getStockFlows()
  }, [selectedDates])

  useEffect(() => {
    getStockFlows()
  }, [debouncedSearchQuery])

  const formatStockFlowData = (data: StockFlowReport[]) => data.map(row => ({
    "Product name": row.productName,
    "Initial stock": row.initialStock,
    "Stock received": row.stockReceived,
    "Stock sold": row.stockSold,
    "Stock adjusted": row.stockAdjusted
  }))

  const filename = `${createFileNameFromDates(selectedDates || getDefaultQueryDates())}_stock_flow_report`
  const _handleDownload = () => {
    if (stockFlows) {
      handleDownload(formatStockFlowData(stockFlows?.nodes), filename)
    }
  }

  const downloadExcel = () => {
    if (stockFlows) {
      handleExcelDownload(formatStockFlowData(stockFlows?.nodes), filename, 'Stock flow')
    }
  }

  const getStockFlowsContent = () => {
    const tableClientsData = generateProductsTableData(
      stockFlows?.nodes,
      productHeadings
    )
    return (
      <>
        <div className='w-full flex items-center justify-between border-t border-grey-100 p-3 md:p-6'>
          <Paragraph size='b4' weight='semiBold' className=''>
            Overview
          </Paragraph>
          <div className='w-full xl:w-6/12 flex items-center space-x-4'>
            <SearchTerm
              placeholder='Search by name, category, description, price'
              setDebouncedSearchQuery={setDebouncedSearchQuery}
              size='sm'
            />
            <Paginator pageInfo={stockFlows?.pageInfo} {...{ goToNext, goToPrev }} />
          </div>
        </div>
        <FullTable
          rows={tableClientsData}
          headers={formatTableHeadersFilterArray(productHeadings)}
          loading={isLoading}
          hasBorder={true}
          hasFooterBg={true}
        />
      </>
    )
  }

  const goToNext = () => {
    if (stockFlows?.pageInfo?.hasNextPage) {
      setAfter(stockFlows?.pageInfo?.endCursor)
      setBefore(null)
      getStockFlows()
    }
  }

  const goToPrev = () => {
    if (stockFlows?.pageInfo?.hasPreviousPage) {
      setBefore(stockFlows?.pageInfo?.startCursor)
      setAfter(null)
      getStockFlows()
    }
  }

  return (
    <>
      {isLoading || isLoadingSummary ? <ContentLoader /> : null}
      <div className='w-full flex flex-col xl:flex-row justify-between lg:items-center p-2 md:p-3 lg:p-6 gap-2'>
        <div className='flex flex-col md:flex-row xl:flex-col gap-3'>
          <Heading size='h9' variant='h1' weight='bold'>
            Stock flow
          </Heading>
          <div className='flex space-x-1 items-center'>
            <Paragraph size='b4' color={COLORS.GREY[300]} className='flex space-x-1 items-center'>
              <SvgCalendar width="16px" height="16px" />
              <span>{`${getNumberMonthAndYearFromDate(selectedDates[0])} - ${getNumberMonthAndYearFromDate(selectedDates[1])}`}</span>
            </Paragraph>
            <span>|</span>
            <Paragraph size='b4' color={COLORS.GREY[300]} className='flex space-x-1 items-center'>
              <SvgLocationPin width="16px" height="16px" />
              <span>{getSalonFieldValue('branchName')}</span>
            </Paragraph>
          </div>
        </div>
        <div className='flex flex-col md:flex-row md:items-center gap-4 md:gap-2'>
          <Paragraph size="b4" className='hidden md:flex'>Showing:</Paragraph>
          <CalendarPicker {...{ selectedDates, setSelectedDates }} />
          <ExportDataToCsvOrXlsx downloadCsv={_handleDownload} downloadXlsx={downloadExcel} />
        </div>
      </div>
      <div className='w-full grid grid-col-1 xl:grid-cols-3 gap-2 p-3 md:p-6 border-t border-grey-100'>
        <div className='w-full flex flex-col justify-between space-y-3 xl:max-w-[374px] border border-grey-100 bg-grey-50/20 p-4 rounded-md'>
          <span className='w-[32px] h-[32px] flex justify-center items-center rounded-sm bg-grey-50'>
            <SvgCartBag width="24px" height="24px" />
          </span>

          <div className='w-full flex flex-col'>
            <Paragraph size='b4' color={COLORS.GREY[400]} weight='bold' className=''>
              Stock available
            </Paragraph>
            <Paragraph size='b1' weight='semiBold' className=''>
              {stockFlowSummary?.stockAvailable}
            </Paragraph>
          </div>
        </div>
        <div className='w-full flex flex-col justify-between space-y-3 xl:max-w-[374px] border border-grey-100 bg-grey-50/20 p-4 rounded-md'>
          <span className='w-[32px] h-[32px] flex justify-center items-center rounded-sm bg-grey-50'>
            <SvgCartBag width="24px" height="24px" />
          </span>

          <div className='w-full flex flex-col'>
            <Paragraph size='b4' color={COLORS.GREY[400]} weight='bold' className=''>
              Stock sold
            </Paragraph>
            <Paragraph size='b1' weight='semiBold' className=''>
              {stockFlowSummary?.stockSold}
            </Paragraph>
          </div>
        </div>
        <div className='w-full flex flex-col justify-between space-y-3 xl:max-w-[374px] border border-grey-100 bg-grey-50/20 p-4 rounded-md'>
          <span className='w-[32px] h-[32px] flex justify-center items-center rounded-sm bg-grey-50'>
            <SvgCartBag width="24px" height="24px" />
          </span>

          <div className='w-full flex flex-col'>
            <Paragraph size='b4' color={COLORS.GREY[400]} weight='bold' className=''>
              Stock received
            </Paragraph>
            <Paragraph size='b1' weight='semiBold' className=''>
              {stockFlowSummary?.stockReceived}
            </Paragraph>
          </div>
        </div>
      </div>
      {getStockFlowsContent()}
    </>
  )
}

const StockMovementLog = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState('')
  const { getSalonFieldValue } = useSalonCache()
  const salonId = getSalonFieldValue('id')
  const [selectedDates, setSelectedDates] = useState<[Date, Date] | null>(
    getDefaultQueryDates()
  )
  const [before, setBefore] = useState<string | null>(null)
  const [after, setAfter] = useState<string | null>(null)
  const PRODUCTS_HEADINGS = [
    {
      label: 'Date of movement',
      value: 'date_of_movement',
      show: true
    },
    {
      label: 'Product name',
      value: 'product_name',
      show: true
    },
    {
      label: 'Adjustment reason',
      value: 'adjustment_reason',
      show: true
    },
    {
      label: 'Quantity adjusted',
      value: 'quantity_adjusted',
      show: true
    },
    {
      label: 'Unit cost',
      value: 'unit_cost',
      show: true
    },
    {
      label: 'Location',
      value: 'location',
      show: true
    },
    {
      label: 'Quantity left',
      value: 'quantity_left',
      show: true
    }
  ]

  const {
    data: stockMovementLogsSummaryData,
    refetch: getStockMovementSummaryLogs,
    loading: isLoadingLogs
  } = useStockMovementSummaryLog({
    salonId,
    startDate: formatDateToOriginalDate(selectedDates[0], "start"),
    endDate: formatDateToOriginalDate(selectedDates[1], "end")
  })
  const stockMovementSummary = useMemo(() => stockMovementLogsSummaryData?.stockMovementLogSummaryReport, [stockMovementLogsSummaryData])

  const {
    data: stockMovementLogsData,
    refetch: getStockMovementLogs,
    loading: isLoadingMovement
  } = useStockMovementLogs({
    salonId,
    q: debouncedSearchQuery,
    startDate: formatDateToOriginalDate(selectedDates[0], "start"),
    endDate: formatDateToOriginalDate(selectedDates[1], "end"),
    before, after
  })
  const stockMovementLogs = useMemo(() => stockMovementLogsData?.stockMovementLogReport, [stockMovementLogsData])

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [productHeadings, setProductHeadings] = useState(PRODUCTS_HEADINGS)


  const generateProductsTableData = (
    products: StockMovementLogReport[],
    tableHeadings: TableFilterProps
  ) => {
    console.log(products)
    const p = products?.map?.((product) => {
      const rowData = {}
      tableHeadings.forEach((heading) => {
        if (heading.show) {
          switch (heading.value) {
            case 'date_of_movement':
              rowData[heading.value] = getNumberMonthAndYearFromDate(product?.dateOfMovement)
              break
            case 'product_name':
              rowData[heading.value] = product?.productName
              break
            case 'adjustment_reason':
              rowData[heading.value] = product?.adjustmentReason
              break
            case 'quantity_adjusted':
              // eslint-disable-next-line no-case-declarations
              const sign = product.actionType === 'add' ? '+' : '-'
              rowData[heading.value] = `${sign}${product?.quantityAdjusted}`
              break
            case 'unit_cost':
              rowData[heading.value] = formatInToPrice(product?.unitCost)
              break
            case 'location':
              rowData[heading.value] = product?.location
              break
            case 'quantity_left':
              rowData[heading.value] = product?.quantityLeft
              break
          }
        }
      })

      return rowData
    })
    return p
  }

  useEffect(() => {
    getStockMovementSummaryLogs()
  }, [debouncedSearchQuery, selectedDates])

  const formatStockFlowData = (data: StockMovementLogReport[]) => data.map(row => ({
    "Date of movement": row.dateOfMovement,
    "Product name": row.productName,
    "Adjustment reason": row.adjustmentReason,
    "Quantity adjusted": row.quantityAdjusted,
    "Unit cost": row.unitCost,
    "Location": row.location,
    "Quantity Left": row.quantityLeft
  }))

  const filename = `${createFileNameFromDates(selectedDates || getDefaultQueryDates())}_stock_movement_log_report`
  const _handleDownload = () => {
    if (stockMovementLogs) {
      handleDownload(formatStockFlowData(stockMovementLogs?.nodes), filename)
    }
  }

  const downloadExcel = () => {
    if (stockMovementLogs) {
      handleExcelDownload(formatStockFlowData(stockMovementLogs?.nodes), filename, 'Stock movement log')
    }
  }

  const goToNext = () => {
    if (stockMovementLogs?.pageInfo?.hasNextPage) {
      setAfter(stockMovementLogs?.pageInfo?.endCursor)
      setBefore(null)
      getStockMovementLogs()
    }
  }

  const goToPrev = () => {
    if (stockMovementLogs?.pageInfo?.hasPreviousPage) {
      setBefore(stockMovementLogs?.pageInfo?.startCursor)
      setAfter(null)
      getStockMovementLogs()
    }
  }

  const getStockFlowsContent = () => {
    const tableClientsData = generateProductsTableData(
      stockMovementLogs?.nodes,
      productHeadings
    )
    return (
      <>
        {isLoadingLogs || isLoadingMovement ? <ContentLoader /> : null}
        <div className='w-full flex items-center justify-between border-t border-grey-100 p-3 md:p-6'>
          <Paragraph size='b4' weight='semiBold' className=''>
            Overview
          </Paragraph>
          <div className='w-full xl:w-6/12 flex items-center space-x-4'>
            <SearchTerm
              placeholder='Search by name, category, description, price'
              setDebouncedSearchQuery={setDebouncedSearchQuery}
              size='sm'
            />
            <Paginator pageInfo={stockMovementLogs?.pageInfo} {...{ goToNext, goToPrev }} />
          </div>
        </div>
        <FullTable
          rows={tableClientsData}
          headers={formatTableHeadersFilterArray(productHeadings)}
          loading={isLoading}
          hasBorder={true}
          hasFooterBg={false}
        />
      </>
    )
  }

  return (
    <>
      <div className='w-full flex flex-col xl:flex-row justify-between lg:items-center p-2 md:p-3 lg:p-6 gap-2'>
        <div className='flex flex-col md:flex-row xl:flex-col gap-3'>
          <Heading size='h9' variant='h1' weight='bold'>
            Stock movement
          </Heading>
          <div className='flex space-x-1 items-center'>
            <Paragraph size='b4' color={COLORS.GREY[300]} className='flex space-x-1 items-center'>
              <SvgCalendar width="16px" height="16px" />
              <span>{`${getNumberMonthAndYearFromDate(selectedDates[0])} - ${getNumberMonthAndYearFromDate(selectedDates[1])}`}</span>
            </Paragraph>
            <span>|</span>
            <Paragraph size='b4' color={COLORS.GREY[300]} className='flex space-x-1 items-center'>
              <SvgLocationPin width="16px" height="16px" />
              <span>{getSalonFieldValue('branchName')}</span>
            </Paragraph>
          </div>
        </div>
        <div className='flex flex-col md:flex-row md:items-center gap-4 md:gap-2'>
          <Paragraph size="b4" className='hidden md:flex'>Showing:</Paragraph>
          <CalendarPicker {...{ selectedDates, setSelectedDates }} />
          <ExportDataToCsvOrXlsx downloadCsv={_handleDownload} downloadXlsx={downloadExcel} />
        </div>
      </div>
      <div className='w-full grid grid-col-1 lg:grid-cols-2 gap-2 p-3 md:p-6 border-t border-grey-100'>
        <div className='w-full flex flex-col justify-between space-y-3 border border-grey-100 bg-grey-50/20 p-4 rounded-md'>
          <span className='w-[32px] h-[32px] flex justify-center items-center rounded-sm bg-grey-50'>
            <SvgCartBag width="24px" height="24px" />
          </span>

          <div className='w-full flex flex-col'>
            <Paragraph size='b4' color={COLORS.GREY[400]} weight='bold' className=''>
              Stock available
            </Paragraph>
            <Paragraph size='b1' weight='semiBold' className=''>
              {stockMovementSummary?.stockAvailable}
            </Paragraph>
          </div>
        </div>
      </div>
      {getStockFlowsContent()}
    </>
  )
}

export default InventoryReports
