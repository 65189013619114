import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { HeaderImageInput, PageProps } from '../types';
import { useToast } from 'hooks/useToast';
import { useUserCache } from 'hooks/useUserCache';
import { useImageUploader } from 'hooks/useUploader';
import { API_ERRORS } from 'constants/errors';
import AccountSetupTemplate from '../AccountSetupTemplate';
import ToastWrapper from 'ui/molecules/toastWrapper/ToastWrapper';
import { ToastProps } from 'ui/atoms/toast/types';
import { getImageUploadContent } from '../../../utils/upload';
import { IMAGE_UPLOAD_PATTERN } from 'constants/pattern';
import { FormHelperText } from 'ui/atoms/helperText/FormHelperText';
import { getHelpTextForCharacterLeft, getHelperTextForReactHookFormErrors } from '../../../utils/form';
import FormTextarea from 'ui/molecules/input/FormTextarea';
import { YES_OR_NO_SETTINGS } from 'constants/information';
import Checkbox from 'ui/atoms/checkbox/Checkbox';
import Button from 'ui/atoms/button/Button';
import { FormLabel } from 'ui/atoms/formLabel';
import { UpdateBusinessDocument } from 'core/generated';
import { useUpdateBusiness } from 'api/useAccount';

const BusinessAboutAndHeaderImage = ({ onPrevPage, onNextPage }: PageProps) => {
  const {
    control,
    handleSubmit,
    setValue,
    clearErrors,
  } = useForm<HeaderImageInput>({
    defaultValues: {
      acceptsOnlineBookings: true,
    },
  });
  const { getBusinessData, setBusinessData } = useUserCache();
  const _business = getBusinessData();

  const {
    loading: isLoading,
    updateBusiness
  } = useUpdateBusiness()

  const { imageUrl, errorMessage, handleImageUpload, uploadIsLoading } = useImageUploader(
    {
      width: 3400,
      height: 3400,
    }
  );
  const { addToast, toast } = useToast();

  useEffect(() => {
    if (errorMessage) {
      addToast({
        message: errorMessage,
        variant: "error",
      });
    }
  }, [errorMessage, uploadIsLoading]);

  useEffect(() => {
    if (imageUrl) {
      setValue("headerImageUrl", imageUrl);
      clearErrors("headerImageUrl");
    }
  }, [imageUrl, setValue]);

  const onSubmitData = async (data: HeaderImageInput) => {
    try {
      updateBusiness({
        variables: {
          input: {
            headerImageUrl: imageUrl,
            headerImageUrls: [imageUrl],
            about: data.about,
            cancellationPolicy: data?.cancellationPolicy,
            acceptsOnlineBookings: data?.acceptsOnlineBookings || false,
            bookingUrlIdentifier: _business?.bookingUrlIdentifier,
            pointValue: 0,
            onboardingStep: 'business_about_added'
          }
        }
      }).then(({ data }) => {
        if (data?.updateBusiness) {
          setBusinessData(data?.updateBusiness?.business);
          onNextPage()
        }

        if (data?.updateBusiness?.errors) {
          const message = data?.updateBusiness?.errors[0]?.message || API_ERRORS.BUSINESS_HEADER_IMAGE_UPLOAD_FAILED;
          addToast({
            variant: "error",
            message,
          });
        }
      });
    } catch (updateBusinessHeaderImageError) {
      if (axios.isAxiosError(updateBusinessHeaderImageError)) {
        addToast({
          message: updateBusinessHeaderImageError?.response?.data?.message || API_ERRORS.BUSINESS_HEADER_IMAGE_UPLOAD_FAILED,
          variant: 'error',
        })
      }
    }
  }

  useEffect(() => {
    if (_business) {
      setValue('about', _business?.about)
      setValue('headerImageUrl', _business?.headerImageUrl)
      setValue('cancellationPolicy', _business?.cancellationPolicy)
      setValue('acceptsOnlineBookings', _business?.acceptsOnlineBookings)
    }
  }, [_business])
  return (
    <AccountSetupTemplate title="Set up your appointment bookings page" subtitle='This is the page where customers make appointments to book your services' skip={{
      text: 'Skip Business Setup',
    }}>
      <ToastWrapper toast={toast as ToastProps} />
      <form onSubmit={handleSubmit(onSubmitData)} className="w-full space-y-6 pt-6">
        <div className='w-full flex border rounded-sm border-grey-20 border-dashed h-[130px] justify-center items-center'>
          <Controller
            control={control}
            name="headerImageUrl"
            render={({ field: { value }, formState: { errors } }) => {
              const headerImageUploadUrl = imageUrl || value;
              return (
                <div className="flex flex-col">
                  <div className="flex items-center space-x-4">
                    <div className="relative cursor-pointer">
                      {getImageUploadContent({ imageUrl: headerImageUploadUrl, uploadIsLoading, placeHolder: 'Upload Business Cover Image' })}
                      <input
                        className="cursor-pointer absolute block opacity-0 top-0 w-[128px] h-[96px]"
                        type="file"
                        accept={IMAGE_UPLOAD_PATTERN}
                        onChange={handleImageUpload}
                      />
                    </div>
                  </div>
                  {errors?.headerImageUrl?.message && (
                    <FormHelperText variant="error">
                      {errors.headerImageUrl.message}
                    </FormHelperText>
                  )}
                </div>
              );
            }}
          />
        </div>
        <Controller
          control={control}
          name="about"
          render={({
            field: { onChange, onBlur, name, ref, value },
            formState: { errors },
          }) => {
            const maxLength = 1000;
            const formErrorsHelpTexts = getHelperTextForReactHookFormErrors(
              errors?.about?.message as string
            );
            const helperTexts = [];

            helperTexts.push(getHelpTextForCharacterLeft(maxLength, value));
            if (formErrorsHelpTexts) helperTexts.push(formErrorsHelpTexts);

            return (
              <FormTextarea
                type="text"
                id="about"
                label="About"
                placeholder="Add a short description about the business, this shows on the booking page."
                {...{
                  ref,
                  name,
                  value,
                  onChange,
                  onBlur,
                  maxLength,
                }}
                helperText={helperTexts}
              />
            );
          }}
        />
        <Controller
          control={control}
          name="cancellationPolicy"
          render={({
            field: { onChange, onBlur, name, ref, value },
            formState: { errors },
          }) => {
            const maxLength = 1000;
            const formErrorsHelpTexts = getHelperTextForReactHookFormErrors(
              errors?.cancellationPolicy?.message as string
            );
            const helperTexts = [];

            helperTexts.push(getHelpTextForCharacterLeft(maxLength, value));
            if (formErrorsHelpTexts) helperTexts.push(formErrorsHelpTexts);

            return (
              <FormTextarea
                type="text"
                id="cancellationPolicy"
                label="Cancellation Policy"
                placeholder="Add cancellation policy"
                {...{
                  ref,
                  name,
                  value,
                  onChange,
                  onBlur,
                  maxLength,
                }}
                helperText={helperTexts}
              />
            );
          }}
        />

        <Controller
          control={control}
          name="acceptsOnlineBookings"
          render={({ field: { onChange, value } }) => {
            return (
              <div className="w-full flex flex-col space-y-4">
                <FormLabel htmlFor='acceptsOnlineBookings'>Do you wish to make this business's online bookings public?</FormLabel>
                <div className="w-full flex flex-wrap gap-4 xl:gap-0 xl:space-x-4">
                  {Array.isArray(YES_OR_NO_SETTINGS) && YES_OR_NO_SETTINGS.length
                    ? YES_OR_NO_SETTINGS
                      .map((acceptsOnlineBookings: {
                        label: string;
                        value: boolean;
                      }) => {
                        return (
                          <div
                            className="flex"
                            onClick={() => onChange(acceptsOnlineBookings?.value)}
                            key={acceptsOnlineBookings?.label}
                          >
                            <div className="flex items-center cursor-pointer text-b5 text-grey-900 space-x-2">
                              <Checkbox
                                isChecked={value === acceptsOnlineBookings?.value}
                              />
                              <span className="text-grey-900 whitespace-nowrap">
                                {acceptsOnlineBookings?.label}
                              </span>
                            </div>
                          </div>
                        );
                      })
                    : null}
                </div>
              </div>
            );
          }}
        />
        <div className='w-full flex space-x-2'>
          <Button
            variant='secondary'
            className=''
            disabled={false}
            onClick={onPrevPage}
            size='lg'
            rounded='lg'
            type='button'
          >
            Prev
          </Button>
          <Button
            variant='primary'
            className=''
            disabled={isLoading}
            loading={isLoading}
            size='lg'
            rounded='lg'
          >
            Proceed
          </Button>
        </div>
      </form>
    </AccountSetupTemplate>
  )
}

export default BusinessAboutAndHeaderImage