import { SalonHour } from "core/generated";
import { HelperTextProps } from "../../ui";
import { checkStaffWorkingSchedule } from "../uicomponents/utils";
import { getStaffRoleName } from "./misc";

export const getHelperTextForReactHookFormErrors = (
  message: string
): HelperTextProps | undefined => {
  return typeof message === "string"
    ? {
        variant: "error",
        message,
      }
    : undefined;
};

export const getHelpTextForCharacterLeft = (
  maxLength: number,
  value = ""
): HelperTextProps => {
  const characterLength = `${value || ""}`.length;
  return {
    message: `${maxLength - characterLength} of ${maxLength} characters left`,
    variant: "info",
  };
};

export const formatStaffUpdateData = (data: any, salonHours: SalonHour[]) => {
  const getWorkingSchedule = () => {
    if (data && data?.workingSchedule && !data?.workingSchedule?.workScheduleType) {
      const workingType = checkStaffWorkingSchedule(salonHours, data?.workingSchedule?.workSchedule);
  
      return {
        workScheduleType: workingType,
        workSchedule: data?.workingSchedule?.workSchedule
          ?.filter(schedule => schedule?.openTime && schedule?.closeTime)
          ?.map(({ __typename, ...rest }) => rest) // Remove __typename from each schedule
      };
    }
  
    return {
      workScheduleType: data?.workingSchedule?.workScheduleType,
      workSchedule: data?.workingSchedule?.workSchedule
        ?.filter(schedule => schedule?.openTime && schedule?.closeTime)
        ?.map(({ __typename, ...rest }) => rest) // Remove __typename from each schedule
    };
  };

  const { __typename: _, ...salaryInformation } = data?.salaryInformation || {};
  const updatedServices = data?.employmentDetails?.services?.map(({ __typename, ...rest }) => rest);
  const inputData = {
    salaryInformation,
    reminder: {
      birthday: data?.reminder?.birthday || false,
      workAnniversary: data?.reminder?.workAnniversary || false,
    },
    workingSchedule: getWorkingSchedule(),
    employmentDetails: {
      canLogin: data?.employmentDetails?.canLogin || false,
      canServeCustomers: data?.employmentDetails?.canServeCustomers || false,
      endDate: data?.employmentDetails?.endDate || null,
      level: data?.employmentDetails?.level?.toString(),
      present: data?.employmentDetails?.present,
      salonId: data?.employmentDetails?.salonId,
      specialty: data?.employmentDetails?.specialty,
      startDate: data?.employmentDetails?.startDate || null,
      role: data?.employmentDetails?.level && getStaffRoleName(data?.employmentDetails?.level?.toString()),
      services: Array?.isArray(updatedServices) && updatedServices?.length ? updatedServices : []
    },
    salonId: data?.employmentDetails?.salonId,
    active: data?.active,
    id: data?.id,
    receiveBusinessReports: data?.receiveBusinessReports,
    receivePushNotifications: data?.receivePushNotifications,
    leave: { daysEntitled: data?.leave?.daysEntitled || "0" },
    user: { firstName: data?.user?.firstName, lastName: data?.user?.lastName, email: data?.user?.email || "", countryCode: data?.user?.countryCode, phone: data?.user?.phone, dateOfBirth: data?.user?.dateOfBirth, profileUrl: data?.user?.profileUrl, callingCode: data?.user?.callingCode }
  }
  // check if __typename exist is inputData?.salaryInformation, if yes delete
  if (inputData?.salaryInformation?.__typename) delete inputData?.salaryInformation?.__typename;
  return inputData;
};