import React, {
  Fragment,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import {
  Button,
  Checkbox,
  FormHelperText,
  Heading,
  Layout,
  Modal,
  NameAvatar,
  Paragraph,
  SearchTerm,
  SvgBlacklistedClients,
  SvgClientEmpty,
  TableFilterProps,
  TableSkeleton,
  Tabs,
  ToastWrapper
} from 'ui'
import { useModal, useToast } from 'hooks'
import {
  AppointmentMilestone,
  Client
} from 'core/generated'
import { Controller, useForm } from 'react-hook-form'
import { CSV_UPLOAD_PATTERN } from 'constants/pattern'
import {
  formatInToPrice,
  getNumberMonthAndYearFromDate,
  sortClientData
} from '../../../components/utils/misc'
import { COLORS } from 'constants/colors'
import { Menu, Transition } from '@headlessui/react'
import {
  SvgDiamond,
  SvgFilter,
  SvgMdiLightImage
} from 'ui'
import Label from 'ui/atoms/formLabel/Label'
import FullTable from 'ui/organism/table/Table'
import ImportFileLink from '../../../components/uicomponents/ImportFileLink'
import { useSalonCache } from 'hooks/useSalonCache'
import { canPerformAction } from '../../../components/utils/permission'
import { PERMISSION_CONSTANTS } from 'constants/permission'
import SvgUploadImage from 'assets/icons/UploadImage'
import { useBlacklistedGetClients, useGetClients, useUploadClient } from 'api/useClient';
import { FileUploadInput } from '../types';
import AddClientModal from '../modals/AddClientModal';
import DeleteClientDialog from '../modals/DeleteClientDialog';
import ViewClient from '../modals/Client';
import { CLIENTS_HEADINGS, MOBILE_CLIENTS_HEADINGS } from '../constants';
import { useUploadFile } from 'api/useAccount';
import ContentLoader from 'ui/atoms/contentLoader/ContentLoader';

const CLIENTS_TAB = {
  CLIENTS: "Clients",
  BLACK_LIST: "Blacklisted clients",
}

const CLIENTS_TABS = [
  {
    key: CLIENTS_TAB.CLIENTS,
    title: CLIENTS_TAB.CLIENTS
  },
  {
    key: CLIENTS_TAB.BLACK_LIST,
    title: CLIENTS_TAB.BLACK_LIST
  }
]

const ClientTabPage = () => {
  const [activeTab, setActiveTab] = useState<string>(CLIENTS_TAB.CLIENTS)

  const getTabView = () => {
    switch (activeTab) {
      case CLIENTS_TAB.CLIENTS:
        return <Clients />
      case CLIENTS_TAB.BLACK_LIST:
        return <Blacklisted />
    }
  }

  return (
    <Layout pageTitle='Clients'>
      <Tabs
        tabs={CLIENTS_TABS}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      {getTabView()}
    </Layout>
  )
}


const Clients = () => {
  const [openAddClientModal, setOpenAddClientModal] = useState(false)

  const openClientModal = () => {
    setOpenAddClientModal(true)
  }

  const closeClientModal = () => {
    setOpenAddClientModal(false)
  }
  return (
    <>
      <ClientsContent
        openPage={openAddClientModal}
        closePage={closeClientModal}
      />
    </>
  )
}

export const ClientAvatarAndNameView = (client: Client) => {
  let charater = '--'
  if (client?.fullName) {
    // split fullName
    const fullName = client?.fullName.split(' ')
    // get first and second letter of each name
    if (fullName.length > 1) {
      charater = `${fullName[0]?.charAt(0)}${fullName[1]?.length > 0
        ? fullName[1]?.charAt(0)
        : fullName[0]?.charAt(1)
        }`
    } else {
      charater = fullName[0]?.charAt(0) + fullName[0]?.charAt(1)
    }
  }

  return (
    <span className='flex items-center space-x-2'>
      <NameAvatar name={charater} />
      <Paragraph size='b4' color={COLORS.GREY[900]}>
        {client?.firstName} {client?.lastName}
      </Paragraph>
    </span>
  )
}

const ClientsContent = ({
  openPage,
  closePage
}: {
  openPage: boolean
  closePage: () => void
}) => {
  const { addToast, toast } = useToast()
  const [client, setClient] = useState<Client | null>(null)
  const [clients, setClients] = useState<Client[]>([])
  const [clientHeadings, setClientHeadings] = useState(CLIENTS_HEADINGS)
  const { openModal, closeModal, isVisible } = useModal()
  const { getSalonData } = useSalonCache()
  const salon = getSalonData();
  const salonId = salon?.id;
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState<string>('')
  const [isEndReached, setIsEndReached] = useState(false)
  const targetRef = useRef(null)
  const [before, setBefore] = useState(null)
  const [after, setAfter] = useState(null)
  const actions = {
    addClient: canPerformAction(`Clients::${PERMISSION_CONSTANTS.client.add}`),
    seeInfo: canPerformAction(
      `Clients::${PERMISSION_CONSTANTS.client.view}`
    ),
    viewClient: canPerformAction(
      `Clients::${PERMISSION_CONSTANTS.client.view}`
    ) || canPerformAction(
      `Clients::${PERMISSION_CONSTANTS.client.edit}`
    ) || canPerformAction(
      `Clients::${PERMISSION_CONSTANTS.client.view_note}`
    ) || canPerformAction(
      `Clients::${PERMISSION_CONSTANTS.client.add_note}`
    ) || canPerformAction(
      `Clients::${PERMISSION_CONSTANTS.client.refund}`
    ),
    deleteClient: canPerformAction(
      `Clients::${PERMISSION_CONSTANTS.client.delete}`
    ),
    recordRefund: canPerformAction(
      `Clients::${PERMISSION_CONSTANTS.client.refund}`
    ),
    editClient: canPerformAction(
      `Clients::${PERMISSION_CONSTANTS.client.edit}`
    ),
    addNotes: canPerformAction(
      `Clients::${PERMISSION_CONSTANTS.client.add_note}`
    ),
    viewNotes: canPerformAction(
      `Clients::${PERMISSION_CONSTANTS.client.view_note}`
    ),
    editNote: canPerformAction(
      `Clients::${PERMISSION_CONSTANTS.client.edit_note}`
    ),
    deleteNote: canPerformAction(
      `Clients::${PERMISSION_CONSTANTS.client.delete_note}`
    ),
    viewHistory: canPerformAction(
      `Clients::${PERMISSION_CONSTANTS.client.view_appointment_history}`
    )
  }
  const {
    openModal: openViewClientModal,
    closeModal: closeViewClientModal,
    isVisible: isViewClientModalVisible
  } = useModal()

  const {
    openModal: openDeleteClientModal,
    closeModal: closeDeleteClientModal,
    isVisible: isDeleteClientModalVisible
  } = useModal()

  const {
    openModal: openImportClientModal,
    closeModal: closeImportClientModal,
    isVisible: isImportClientModalVisible
  } = useModal()

  const {
    data: clientsData,
    loading,
    refetch,
    networkStatus
  } = useGetClients({
    salonId,
    q: debouncedSearchQuery,
    after,
    before,
    first: 50
  })
  const isRefetching = networkStatus === 4;
  const clientConnection = useMemo(() => clientsData?.salonClients, [clientsData])
  useMemo(() => {
    if (clientConnection?.nodes?.length) {
      const existingNodes = clients ?? [];
      const newNodes = clientConnection?.nodes ?? [];
      // Combine the arrays and remove duplicates by client id
      const combinedNodes = [...existingNodes, ...newNodes]
        .reduce((acc: Client[], client: Client) => {
          const existingIndex = acc.findIndex(existingClient => existingClient.id === client.id);
          if (existingIndex === -1) {
            acc.push(client);
          } else {
            acc[existingIndex] = client; // Replace existing with new
          }
          return acc;
        }, []);

      if (debouncedSearchQuery && debouncedSearchQuery !== '') {
        setClients(sortClientData(newNodes));
        return;
      }
      setClients(sortClientData(combinedNodes));
    }
  }, [clientConnection]);

  useEffect(() => {
    if (openPage) {
      openModal()
    }
  }, [openPage])

  useEffect(() => {
    if (debouncedSearchQuery && debouncedSearchQuery !== '') {
      refetch({
        search: debouncedSearchQuery,
        after: null,
        before: null
      })
    }
  }, [debouncedSearchQuery])

  const handleCloseModal = () => {
    closeModal()
    closePage()
    setClient(null)
    refetch()
  }

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsEndReached(true)
          } else {
            setIsEndReached(false)
          }
        })
      },
      { threshold: 0.5 } // Trigger when at least 50% of the target is visible
    )

    if (targetRef.current) {
      observer.observe(targetRef.current)
    }

    // Cleanup function
    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current)
      }
    }
  }, [])

  useEffect(() => {
    if (
      isEndReached &&
      clientConnection &&
      clientConnection?.pageInfo &&
      clientConnection?.pageInfo?.hasNextPage
    ) {
      setAfter(clientConnection?.pageInfo?.endCursor)
      refetch({
        after: clientConnection?.pageInfo?.endCursor
      })
    }
  }, [isEndReached, clients])

  // const loadClientsShimmer = loading && !clientConnection?.nodes?.length && !debouncedSearchQuery
  const loadClientsShimmer = loading && !clients?.length;

  useEffect(() => {
    if (salonId) {
      refetch();
    }
  }, [salonId, refetch]);

  const handleHeadingCheckboxChange = (value: string) => {
    // search for the heading with that value in clientHeadings then update the show
    const updatedHeadings = clientHeadings.map((heading) => {
      if (heading.value === value) {
        return {
          ...heading,
          show: !heading.show
        }
      }
      return heading
    })
    setClientHeadings(updatedHeadings)
  }

  const generateClientsTableData = (
    clients: Client[],
    tableHeadings: TableFilterProps,
    countryCode?: string
  ) => {
    return clients?.map?.((client) => {
      const rowData = {} // Using 'any' here, you can replace it with a more specific type
      tableHeadings.forEach((heading) => {
        if (heading.show) {
          switch (heading.value) {
            case 'name':
              rowData[heading.value] = ClientAvatarAndNameView(client)
              break
            case 'date':
              rowData[heading.value] = actions.seeInfo
                ? getNumberMonthAndYearFromDate(
                  client?.createdAt,
                )
                : null
              break
            case 'phone':
              rowData[heading.value] = actions?.seeInfo
                ? client?.callingCode + '' + client?.phone
                : '-'
              break
            case 'email':
              rowData[heading.value] = actions?.seeInfo ? client?.email : '-'
              break
            case 'total':
              rowData[heading.value] = actions?.seeInfo
                ? formatInToPrice(client?.totalTransactions)
                : '-'
              break
          }
        }
      })
      return rowData
    })
  }

  const formatTableHeadersFilterArray = (data: TableFilterProps) => {
    // return an array of string to be used in the table filter, select the labels only if show is true
    const results = data
      ?.filter((item) => item?.show)
      ?.map((item) => ({ label: item.label, value: item.value }))

    return results
  }

  const openClientPage = (id: number, action?: string) => {
    if (!clients?.length) return;
    const client_ = clients[id]
    if (!action && !actions.viewClient) return

    setClient(client_)
    switch (action) {
      case 'view':
        openViewClientModal()
        break
      case 'edit':
        openModal()
        break
      case 'delete':
        openDeleteClientModal()
        break
      default:
        openViewClientModal()
        break
    }
  }

  const getClientsContent = () => {
    if (loadClientsShimmer) {
      return (
        <div className='flex flex-col xl:flex-row px-5 py-4'>
          <TableSkeleton />
        </div>
      )
    }

    if (
      (Array.isArray(clients) && clients?.length) ||
      debouncedSearchQuery
    ) {
      const tableClientsData = generateClientsTableData(
        clients || [],
        clientHeadings,
        'NG'
      )
      return (
        <>
          <div className='flex flex-col md:flex-row py-4 px-8 justify-between md:items-center gap-4 border-b border-grey-20'>
            <Heading variant='h2' size='h11' weight='bold'>
              Clients
            </Heading>
            {actions?.addClient ? (
              <div className='flex md:items-center space-x-2'>
                <Button
                  variant='secondary'
                  size='sm'
                  disabled={false}
                  loading={false}
                  type='button'
                  rounded='md'
                  fontSize='b5'
                  onClick={openImportClientModal}
                >
                  Import Clients
                </Button>
                <Button
                  variant='primary'
                  size='sm'
                  disabled={false}
                  loading={false}
                  type='button'
                  rounded='md'
                  fontSize='b5'
                  onClick={openModal}
                >
                  Add Client
                </Button>
              </div>
            ) : null}
          </div>
          <div className='flex flex-col xl:flex-row px-12 py-4 space-x-4'>
            <div className='w-full xl:w-6/12 flex items-center space-x-4'>
              <SearchTerm setDebouncedSearchQuery={setDebouncedSearchQuery} />
              <Menu as='div' className='relative inline-block text-left'>
                <div>
                  <Menu.Button className='flex space-x-2 w-full items-center justify-center rounded-md bg-grey-50 border border-grey-20 px-4 py-2 text-b5 font-medium text-grey-300 hover:bg-opacity-90 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-85'>
                    <SvgFilter width='20px' height='20px' />
                    <span className='hidden xl:inline'>Filter</span>
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter='transition ease-out duration-100'
                  enterFrom='transform opacity-0 scale-95'
                  enterTo='transform opacity-100 scale-100'
                  leave='transition ease-in duration-75'
                  leaveFrom='transform opacity-100 scale-100'
                  leaveTo='transform opacity-0 scale-95'
                >
                  <Menu.Items className='absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-[9999]'>
                    <div className='flex flex-col p-4 space-y-4'>
                      {Array.isArray(clientHeadings) &&
                        clientHeadings.length &&
                        clientHeadings.map((heading, index) => (
                          <Label
                            className='flex space-x-2 items-center cursor-pointer'
                            key={index}
                            htmlFor={heading.value}
                            onClick={() =>
                              handleHeadingCheckboxChange(heading.value)
                            }
                          >
                            <Checkbox
                              isChecked={heading.show}
                              borderType='Black'
                              size='Sm'
                            />
                            <span>{heading.label}</span>
                          </Label>
                        ))}
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
          <FullTable
            headers={formatTableHeadersFilterArray(clientHeadings)}
            mobileHeaders={MOBILE_CLIENTS_HEADINGS}
            rows={tableClientsData}
            onClick={openClientPage}
            tableOptions={{
              view: actions?.viewClient,
              edit: actions?.editClient,
              delete: actions?.deleteClient,
              duplicate: false
            }}
          />
        </>
      )
    }

    return (
      <div className='w-full flex h-full justify-center items-center'>
        <div className='flex flex-col justify-center items-center space-y-6 w-full max-w-[450px] p-12'>
          <SvgClientEmpty width="100%" height="100%" />
          <Heading variant='h2' size='h7'>
            Clients
          </Heading>
          <p className='mt-4'>Manage your clients with ease.</p>
          <div className='w-full flex flex-col items-center xl:flex-row gap-2'>
            <Button
              variant='secondary'
              size='sm'
              disabled={false}
              loading={false}
              type='button'
              rounded='md'
              fontSize='b5'
              onClick={openImportClientModal}
            >
              Import Clients
            </Button>
            {actions?.addClient ? (
              <Button
                variant='primary'
                size='md'
                disabled={false}
                loading={false}
                type='button'
                rounded='md'
                onClick={openModal}
              >
                Add Client
              </Button>
            ) : null}
          </div>
          <ImportFileLink modules='client' />
        </div>
      </div>
    )
  }

  const closeDeleteClientModalAsync = (action?: string) => {
    closeDeleteClientModal()
    if (action === 'deleted') {
      // remove client from clients list
      const newClients = clients.filter((c) => c.id !== client.id)
      setClients(newClients)
    }
    setClient(null)
    refetch()
  }

  const closeViewClientModalAsync = () => {
    closeViewClientModal()
    setClient(null)
    refetch()
  }

  return (
    <>
      <div>
        <ToastWrapper toast={toast} />
        {loadClientsShimmer ? <ContentLoader /> : null}
        {getClientsContent()}
      </div>
      <div
        className='w-full flex py-4 justify-center items-center'
        ref={targetRef}
      ></div>
      <AddClientModal
        isVisible={isVisible}
        closeModal={handleCloseModal}
        client={client}
        setClient={setClient}
        salonId={salonId}
        actions={actions}
        addToast={addToast}
      />
      <ViewClient
        isVisible={isViewClientModalVisible}
        closeModal={closeViewClientModalAsync}
        client={client}
        setClient={setClient}
        salonId={salonId}
        actions={actions}
        addToast={addToast}
      />
      <DeleteClientDialog
        isVisible={isDeleteClientModalVisible}
        closeModal={closeDeleteClientModalAsync}
        client={client}
        setClient={setClient}
        addToast={addToast}
      />
      <ImportClientModal
        isVisible={isImportClientModalVisible}
        closeModal={closeImportClientModal}
        addToast={addToast}
      />
    </>
  )
}
const ImportClientModal = ({ isVisible, closeModal, addToast }) => {
  const baseUrl = process.env.REACT_APP_API_BASE_URL || ''
  const CLIENT_TEMPLATE_URL = `${baseUrl}/home/download_template/client_import_template.csv`
  const { getSalonFieldValue } = useSalonCache()
  const [uploadUrl, setUploadUrl] = useState(null)
  const { handleSubmit, control, setValue } = useForm<FileUploadInput>()
  const {
    loading,
    uploadClient
  } = useUploadClient()

  const {
    loading: uploadIsLoading,
    uploadFile
  } = useUploadFile();

  const importClientsSubmit = async () => {
    const payload = {
      salonId: getSalonFieldValue('id'),
      url: uploadUrl
    }
    uploadClient({
      variables: {
        input: payload
      }
    }).then(({ data }) => {
      const { uploadClient: uc } = data
      if (uc.status === 200) {
        setValue('fileUpload', null)
        setUploadUrl(null)
        closeModal()
        addToast({ message: 'Clients uploaded successfully. This may take a few minutes to process', variant: 'success' })
      } else {
        addToast({ message: uc.errors[0].message, variant: 'error' })
      }
    })
  }

  const handleCsvUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0]
    setValue('fileUpload', file)
    const reader = new FileReader()
    reader.onload = async (event) => {
      const base64DataUri = event.target.result;
      uploadFile({
        variables: { input: { data: base64DataUri } }
      }).then(({ data }) => {
        const { uploadFile } = data;
        if (uploadFile?.status === 200) {
          setUploadUrl(uploadFile?.url);
        }

        if (uploadFile?.errors?.length) {
          addToast({
            message: uploadFile?.errors[0].message,
            variant: 'error'
          })
        }
      }).catch((error) => {
        addToast({
          message: error?.message,
          variant: 'error'
        })
      })
    }

    reader.readAsDataURL(file)
  }

  return (
    <Modal title='Import Clients' show={isVisible} closeModal={closeModal}>
      <div className='w-full flex flex-col my-6 items-center space-y-6'>
        <a href={CLIENT_TEMPLATE_URL} target='_blank' download>
          <Paragraph size='b5' color={COLORS.GREY[400]}>
            First, download the <span className='underline'>template</span> here
          </Paragraph>
        </a>
        <form
          onSubmit={handleSubmit(importClientsSubmit)}
          className='w-full mt-6 space-y-6'
          autoComplete='off'
        >
          <Controller
            control={control}
            name='fileUpload'
            render={({ field: { value }, formState: { errors } }) => {
              return (
                <div className='w-full flex flex-col py-6'>
                  <div className='w-full flex items-center space-x-4 border rounded-sm border-grey-20 border-dashed h-[130px] justify-center'>
                    <div className='relative cursor-pointer'>
                      {value ? (
                        <SvgMdiLightImage width='2rem' height='2rem' />
                      ) : (
                        <SvgUploadImage width='2rem' height='2rem' />
                      )}
                      <input
                        className='cursor-pointer absolute block opacity-0 top-0 w-[128px] h-[96px]'
                        type='file'
                        accept={CSV_UPLOAD_PATTERN}
                        onChange={handleCsvUpload}
                      />
                    </div>
                    <div className='flex flex-col'>
                      <Paragraph size='b5'>
                        {value ? value?.name : 'Upload Clients csv file'}
                      </Paragraph>
                    </div>
                  </div>
                  {errors?.fileUpload?.message && (
                    <FormHelperText variant='error'>
                      {errors.fileUpload.message}
                    </FormHelperText>
                  )}
                </div>
              )
            }}
            rules={{
              required: 'Client CSV file is required'
            }}
          />
          <Button
            variant='primary'
            className=''
            disabled={loading || uploadIsLoading}
            loading={loading || uploadIsLoading}
            size='lg'
            rounded='lg'
          >
            Upload
          </Button>
          <Button
            variant='text'
            className='w-fit text-red-500 mx-auto'
            size='sm'
            fontWeight='semiBold'
            onClick={closeModal}
            type='button'
          >
            Cancel
          </Button>
        </form>
      </div>
    </Modal>
  )
}

export const ClientBookingRedDot = (
  services: string,
  paid: boolean,
  mileStone?: AppointmentMilestone
) => {
  return (
    <div className='flex items-center space-x-2'>
      <div
        className='ml-1'
        style={{
          width: '8px',
          height: '8px',
          borderRadius: '50%',
          backgroundColor: paid ? 'green' : 'red'
        }}
      ></div>
      {mileStone ? <SvgDiamond width='14px' height='14px' /> : null}
      <Paragraph size='b4' color={COLORS.GREY[900]}>
        {services}
      </Paragraph>
    </div>
  )
}

const Blacklisted = () => {
  const { addToast, toast } = useToast()
  const [client, setClient] = useState<Client | null>(null)
  const [clients, setClients] = useState<Client[]>([])
  const [clientHeadings, setClientHeadings] = useState(CLIENTS_HEADINGS)
  const { openModal, closeModal, isVisible } = useModal()
  const { getSalonData } = useSalonCache()
  const salon = getSalonData();
  const salonId = salon?.id;
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState<string>('')
  const [isEndReached, setIsEndReached] = useState(false)
  const targetRef = useRef(null)
  const [before, setBefore] = useState(null)
  const [after, setAfter] = useState(null)
  const actions = {
    addClient: canPerformAction(`Clients::${PERMISSION_CONSTANTS.client.add}`),
    viewClient: canPerformAction(
      `Clients::${PERMISSION_CONSTANTS.client.view}`
    ),
    seeInfo: canPerformAction(
      `Clients::${PERMISSION_CONSTANTS.client.view}`
    ),
    deleteClient: canPerformAction(
      `Clients::${PERMISSION_CONSTANTS.client.delete}`
    ),
    recordRefund: canPerformAction(
      `Clients::${PERMISSION_CONSTANTS.client.refund}`
    ),
    editClient: canPerformAction(
      `Clients::${PERMISSION_CONSTANTS.client.edit}`
    ),
    addNotes: canPerformAction(
      `Clients::${PERMISSION_CONSTANTS.client.add_note}`
    ),
    viewNotes: canPerformAction(
      `Clients::${PERMISSION_CONSTANTS.client.view_note}`
    ),
    editNote: canPerformAction(
      `Clients::${PERMISSION_CONSTANTS.client.edit_note}`
    ),
    deleteNote: canPerformAction(
      `Clients::${PERMISSION_CONSTANTS.client.delete_note}`
    ),
    viewHistory: canPerformAction(
      `Clients::${PERMISSION_CONSTANTS.client.view_appointment_history}`
    )
  }
  const {
    openModal: openViewClientModal,
    closeModal: closeViewClientModal,
    isVisible: isViewClientModalVisible
  } = useModal()

  const {
    openModal: openDeleteClientModal,
    closeModal: closeDeleteClientModal,
    isVisible: isDeleteClientModalVisible
  } = useModal()

  const {
    openModal: openImportClientModal,
    closeModal: closeImportClientModal,
    isVisible: isImportClientModalVisible
  } = useModal()

  const {
    data: clientsData,
    loading,
    refetch,
  } = useBlacklistedGetClients({
    salonId,
    q: debouncedSearchQuery,
    after,
    before,
    first: 50,
  })
  const clientConnection = useMemo(() => clientsData?.salonClients, [clientsData])
  useMemo(() => {
    if (clientConnection?.nodes?.length) {
      const existingNodes = clients ?? [];
      const newNodes = clientConnection?.nodes ?? [];

      // Combine the arrays and remove duplicates by client id
      const combinedNodes = [...existingNodes, ...newNodes]
        .reduce((acc: Client[], client: Client) => {
          if (!acc.some(existingClient => existingClient.id === client.id)) {
            acc.push(client);
          }
          return acc;
        }, []);

      setClients(sortClientData(combinedNodes));
    }
  }, [clientConnection]);

  useEffect(() => {
    if (debouncedSearchQuery) {
      setClients([])
      refetch({
        search: debouncedSearchQuery,
        after: null,
        before: null
      })
    }
  }, [debouncedSearchQuery])

  const handleCloseModal = () => {
    closeModal()
    setClient(null)
    refetch()
  }

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsEndReached(true)
          } else {
            setIsEndReached(false)
          }
        })
      },
      { threshold: 0.5 } // Trigger when at least 50% of the target is visible
    )

    if (targetRef.current) {
      observer.observe(targetRef.current)
    }

    // Cleanup function
    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current)
      }
    }
  }, [])

  useEffect(() => {
    if (
      isEndReached &&
      clientConnection &&
      clientConnection?.pageInfo &&
      clientConnection?.pageInfo?.hasNextPage
    ) {
      setAfter(clientConnection?.pageInfo?.endCursor)
      refetch({
        after: clientConnection?.pageInfo?.endCursor
      })
    }
  }, [isEndReached, clients])

  const loadClientsShimmer = false;

  useEffect(() => {
    if (salonId) {
      refetch();
    }
  }, [salonId, refetch]);
  const handleHeadingCheckboxChange = (value: string) => {
    // search for the heading with that value in clientHeadings then update the show
    const updatedHeadings = clientHeadings.map((heading) => {
      if (heading.value === value) {
        return {
          ...heading,
          show: !heading.show
        }
      }
      return heading
    })
    setClientHeadings(updatedHeadings)
  }

  const generateClientsTableData = (
    clients: Client[],
    tableHeadings: TableFilterProps,
    countryCode?: string
  ) => {
    return clients?.map?.((client) => {
      const rowData = {} // Using 'any' here, you can replace it with a more specific type
      tableHeadings.forEach((heading) => {
        if (heading.show) {
          switch (heading.value) {
            case 'name':
              rowData[heading.value] = ClientAvatarAndNameView(client)
              break
            case 'date':
              rowData[heading.value] = actions.seeInfo
                ? getNumberMonthAndYearFromDate(
                  client?.createdAt,
                )
                : null
              break
            case 'phone':
              rowData[heading.value] = actions?.seeInfo
                ? client?.callingCode + '' + client?.phone
                : '-'
              break
            case 'email':
              rowData[heading.value] = actions?.seeInfo ? client?.email : '-'
              break
            case 'total':
              rowData[heading.value] = actions?.seeInfo
                ? formatInToPrice(client?.totalTransactions)
                : '-'
              break
          }
        }
      })
      return rowData
    })
  }

  const formatTableHeadersFilterArray = (data: TableFilterProps) => {
    // return an array of string to be used in the table filter, select the labels only if show is true
    const results = data
      ?.filter((item) => item?.show)
      ?.map((item) => ({ label: item.label, value: item.value }))

    return results
  }

  const openClientPage = (id: number, action?: string) => {
    if (!clients?.length) return;
    const client_ = clients[id]
    if (!action && !actions.viewClient) return

    setClient(client_)

    switch (action) {
      case 'view':
        openViewClientModal()
        break
      case 'edit':
        openModal()
        break
      case 'delete':
        openDeleteClientModal()
        break
      default:
        openViewClientModal()
        break
    }
  }

  const getClientsContent = () => {
    if (loadClientsShimmer) {
      return (
        <div className='flex flex-col xl:flex-row px-5 py-4'>
          <TableSkeleton />
        </div>
      )
    }

    if (
      (Array.isArray(clients) && clients?.length) ||
      debouncedSearchQuery
    ) {
      const tableClientsData = generateClientsTableData(
        clients || [],
        clientHeadings,
        'NG'
      )
      return (
        <>
          <div className='flex flex-col md:flex-row py-4 px-8 justify-between md:items-center gap-4 border-b border-grey-20'>
            <Heading variant='h2' size='h11' weight='bold'>
              Blacklisted Clients
            </Heading>
          </div>
          <div className='flex flex-col xl:flex-row px-12 py-4 space-x-4'>
            <div className='w-full xl:w-6/12 flex items-center space-x-4'>
              <SearchTerm setDebouncedSearchQuery={setDebouncedSearchQuery} />
              <Menu as='div' className='relative inline-block text-left'>
                <div>
                  <Menu.Button className='flex space-x-2 w-full items-center justify-center rounded-md bg-grey-50 border border-grey-20 px-4 py-2 text-b5 font-medium text-grey-300 hover:bg-opacity-90 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-85'>
                    <SvgFilter width='20px' height='20px' />
                    <span className='hidden xl:inline'>Filter</span>
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter='transition ease-out duration-100'
                  enterFrom='transform opacity-0 scale-95'
                  enterTo='transform opacity-100 scale-100'
                  leave='transition ease-in duration-75'
                  leaveFrom='transform opacity-100 scale-100'
                  leaveTo='transform opacity-0 scale-95'
                >
                  <Menu.Items className='absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-[9999]'>
                    <div className='flex flex-col p-4 space-y-4'>
                      {Array.isArray(clientHeadings) &&
                        clientHeadings.length &&
                        clientHeadings.map((heading, index) => (
                          <Label
                            className='flex space-x-2 items-center cursor-pointer'
                            key={index}
                            htmlFor={heading.value}
                            onClick={() =>
                              handleHeadingCheckboxChange(heading.value)
                            }
                          >
                            <Checkbox
                              isChecked={heading.show}
                              borderType='Black'
                              size='Sm'
                            />
                            <span>{heading.label}</span>
                          </Label>
                        ))}
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
          <FullTable
            headers={formatTableHeadersFilterArray(clientHeadings)}
            mobileHeaders={MOBILE_CLIENTS_HEADINGS}
            rows={tableClientsData}
            onClick={openClientPage}
            tableOptions={{
              view: actions?.viewClient,
              edit: actions?.editClient,
              delete: actions?.deleteClient,
              duplicate: false
            }}
          />
        </>
      )
    }

    return (
      <div className='w-full flex h-full justify-center items-center'>
        <div className='flex flex-col justify-center items-center space-y-6 w-full max-w-[450px] p-12'>
          <SvgBlacklistedClients width="100%" height="100%" />
          <Heading variant='h2' size='h7'>
            Blacklisted Clients
          </Heading>
          <p className='mt-4'>You have no blacklisted clients</p>
        </div>
      </div>
    )
  }

  const closeDeleteClientModalAsync = () => {
    closeDeleteClientModal()
    setClient(null)
    refetch()
  }

  const closeViewClientModalAsync = () => {
    setClient(null)
    closeViewClientModal()
    refetch()
  }

  return (
    <>
      <div>
        <ToastWrapper toast={toast} />
        {getClientsContent()}
      </div>
      <div
        className='w-full flex py-4 justify-center items-center'
        ref={targetRef}
      ></div>
      <AddClientModal
        isVisible={isVisible}
        closeModal={handleCloseModal}
        client={client}
        setClient={setClient}
        actions={actions}
        addToast={addToast}
      />
      <ViewClient
        isVisible={isViewClientModalVisible}
        closeModal={closeViewClientModalAsync}
        client={client}
        setClient={setClient}
        actions={actions}
        addToast={addToast}
      />
      <DeleteClientDialog
        isVisible={isDeleteClientModalVisible}
        closeModal={closeDeleteClientModalAsync}
        client={client}
        setClient={setClient}
        addToast={addToast}
      />
    </>
  )
}

export default ClientTabPage
