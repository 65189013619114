import { COLORS } from 'constants/colors'
import React from 'react'
import { Button, Heading, Paragraph } from 'ui'
import { ClientMembershipProps } from '../types'
import { formatInToPrice } from 'components/utils/misc'

const UpgradeMembership = ({ isVisible, closeModal, addToast, client }: ClientMembershipProps) => {
  return isVisible ? (
    <div className='fixed top-0 left-0 flex items-center justify-center w-full h-screen bg-black/70 backdrop-blur-[2px] z-[999999]'>
      <div className='table-row'>
        <div className='table-cell align-middle'>
          <div className='w-full xl:border-none bg-white rounded-lg shadow-medium mx-auto py-12 px-9 max-w-[400px] xl:min-w-[500px]'>
            <div className='flex flex-col items-center space-y-6'>
              <Heading
                variant='h1'
                size='h8'
                weight='bold'
                color={COLORS.BLACK}
              >
                Upgrade Membership
              </Heading>
              <Paragraph
                size='b5'
                weight='medium'
                className='w-full text-center max-w-[400px]'
                color={COLORS.GREY[400]}
              >
                By proceeding with this upgrade, you will forfeit the client's current membership balance ({formatInToPrice(client?.membershipWalletBalance)}). 
                Any existing balance will not be transferred.
              </Paragraph>

              <Button
                variant='danger'
                size='lg'
                rounded='lg'
                fontSize='b5'
                className='capitalize'
                onClick={() => closeModal('upgrade')}
                disabled={false}
              >
                Yes, I'm sure
              </Button>
              <Button
                variant='text'
                size='none'
                rounded='none'
                fontSize='b5'
                className='mx-0'
                onClick={() => closeModal('back')}
                disabled={false}
              >
                No, go back
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null
}

export default UpgradeMembership