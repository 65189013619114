import React from 'react';
import { COLORS } from "constants/colors"
import { Paragraph } from "../../ui";
import { cleanUpText, formatInToPrice } from "../utils/misc";
import { Appointment } from 'core/generated';

export const AppointmentPriceCard = ({ appointment }: { appointment: Appointment | null }) => {
  if (!appointment || !appointment?.isActive) return null;

  if (appointment.paymentMethod === 'voucher') {
    return (
      <Paragraph size="b4" color={COLORS.GREEN[850]}>
        Paid by {appointment?.appointmentVoucher?.packageVoucher?.package?.name} voucher
      </Paragraph>
    );
  }

  if (appointment.depositAmount > 0 && appointment?.depositPaymentMethod !== 'unpaid' && appointment?.paymentMethod === 'unpaid') {
    return (
      <Paragraph size="b4" color={COLORS.GREEN[850]}>
      {appointment?.depositAmount === appointment?.totalAmountPaid ? formatInToPrice(appointment.depositAmount) : formatInToPrice(appointment?.totalAmountPaid)} paid by {cleanUpText(appointment.depositPaymentMethod)}
      </Paragraph>
    );
  }

  if (appointment.depositAmount === 0 && appointment?.totalAmountPaid > 0 && appointment?.totalAmountPaid < appointment?.totalPaid) {
    return (
      <>
        <Paragraph size="b4" color={COLORS.GREEN[850]}>
        {formatInToPrice(appointment.totalAmountPaid)} paid {appointment.depositPaymentMethod !== 'unpaid' ? ` by ${cleanUpText(appointment.depositPaymentMethod)}`: ''}
        </Paragraph>
      </>
    );
  }

  if (appointment.paymentMethod !== 'unpaid' && appointment.depositAmount > 0 && appointment?.depositPaymentMethod !== 'unpaid') {
    return (
      <>
        <Paragraph size="b4" color={COLORS.GREEN[850]}>
          Deposit: {formatInToPrice(appointment.depositAmount)} paid by {cleanUpText(appointment.depositPaymentMethod)}
        </Paragraph>
        <Paragraph size="b4" color={COLORS.GREEN[850]}>
          Balance: {formatInToPrice((appointment.totalPaid || 0) - appointment.depositAmount)} paid by {cleanUpText(appointment.paymentMethod)}
        </Paragraph>
      </>
    );
  }

  if (appointment.paymentMethod !== 'unpaid' && appointment.depositPaymentMethod === "unpaid") {
    return (
      <Paragraph size="b4" color={COLORS.GREEN[850]}>
        {formatInToPrice(appointment.actualTotalPaid + (appointment?.tipAmount || 0) || 0)} paid by {cleanUpText(appointment.paymentMethod)}
      </Paragraph>
    );
  }
};