import { getNumberMonthYearAndTimeFromDate } from 'components/utils/misc'
import { COLORS } from 'constants/colors'
import React from 'react'
import { Button, Heading, Paragraph, SvgRefresh, SvgTime, SvgTimeGreen } from 'ui'

const SelectedCheckinTime = ({
  appointmentDateTime,
  modifySelectionTime
}: {
  appointmentDateTime: string,
  modifySelectionTime: () => void
}) => {
  return (
    <div className='flex items-center justify-between p-4 rounded-md text-green-300 bg-green-300/10 cursor-pointer'>
      <div className="flex space-x-4 items-center text-green-300">
        <SvgTime width="20px" height="20px" />
        <div className="flex flex-col">
          <Heading variant='h1' size='b5' weight='bold' color={COLORS.GREEN[300]} className="capitalize">{getNumberMonthYearAndTimeFromDate(appointmentDateTime)}</Heading>
          <Paragraph size='b6' color={COLORS.GREEN[300]}>Time</Paragraph>
        </div>
      </div>

      <Button
        variant="text"
        size="none"
        onClick={modifySelectionTime}
      >
        <SvgRefresh width="20px" height="20px" />
      </Button>
    </div>
  )
}

export default SelectedCheckinTime