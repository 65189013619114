import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgAppointmentCheckin = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <rect
      x={0.5}
      y={0.5}
      width={47}
      height={47}
      rx={3.5}
      stroke="#E9E9E9"
      strokeOpacity={0.6}
    />
    <path
      d="M26 34h-4.378c-2.348 0-3.522 0-4.336-.706-.815-.705-1.014-1.895-1.413-4.275l-1.204-7.179c-.182-1.085-.273-1.627.019-1.983.291-.356.827-.356 1.898-.356h14.828c1.071 0 1.607 0 1.898.356.292.356.2.898.019 1.984l-.279 1.66M29.5 19.5a5.5 5.5 0 1 0-11 0m10 12c.491.506 1.8 2.5 2.5 2.5m0 0c.7 0 2.009-1.994 2.5-2.5M31 34v-8"
      stroke="#000"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgAppointmentCheckin;
