import React from 'react';

const FormToggle = ({ label, isChecked, toggleHandler }) => {
  return (
    <label className="flex items-center cursor-pointer">
      <div className="mr-4 text-grey-900 text-b5 font-medium">
        {label}
      </div>
      <div className="relative">
        <input
          type="checkbox"
          className="sr-only"
          checked={isChecked}
          onChange={toggleHandler}
        />
        <div
          className={`block w-14 h-8 rounded-full ${
            isChecked ? 'bg-green-300' : 'bg-grey-200'
          }`}
        ></div>
        <div
          className={`dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition ${
            isChecked ? 'transform translate-x-6' : ''
          }`}
        ></div>
      </div>
    </label>
  );
};

export default FormToggle;