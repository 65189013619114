import { useClientAppointmentSummary } from 'api/useClient';
import { cleanUpText, formatInToPrice, getDayMonthAndNumberAndTimeFromDate, getNumberMonthYearAndTimeFromDateTimezone } from 'components/utils/misc';
import { COLORS } from 'constants/colors';
import { AppointmentProduct, AppointmentService, ClientWalletTransaction } from 'core/generated'
import React, { useEffect, useMemo, useState } from 'react'
import { Button, Heading, Modal, Paragraph, Pill, SvgArrowBack, SvgChevronLeft } from 'ui';

const ViewTransactionModal = ({
  isVisible,
  walletTransaction,
  closeModal: _closeModal
}: {
  isVisible: boolean;
  walletTransaction: ClientWalletTransaction
  closeModal: () => void
}) => {
  const [bookingId, setBookingId] = useState(null);
  const {
    data,
    refetch
  } = useClientAppointmentSummary(bookingId)
  const appointmentSummary = useMemo(() => data?.appointmentSummary, [data]);
  useEffect(() => {
    if (walletTransaction) {
      if (walletTransaction?.description && walletTransaction?.description !== '' && walletTransaction?.description?.includes('appointment')) {
        const descriptionArray = walletTransaction?.description?.split(' ');
        const bookingIndentity = descriptionArray[descriptionArray?.length - 1];
        setBookingId(bookingIndentity)
        refetch()
      }
    }
  }, [walletTransaction])

  const closeModal = () => {
    setBookingId(null);
    _closeModal();
  }

  const getAppointmentPillsForServices = (appointmentServices: AppointmentService[]) => {
    if (Array.isArray(appointmentServices) && appointmentServices.length > 0) {
      return (<div className='flex flex-wrap gap-3'>
        {appointmentServices?.map(appointmentService => {
          return <Pill variant='light' rounded='lg' key={appointmentService?.id}>{appointmentService?.name} - {formatInToPrice(appointmentService?.price)}</Pill>
        })}
      </div>)
    }

    return "-"
  }

  const getAppointmentPillsForProducts = (appointmentProducts: AppointmentProduct[]) => {
    if (Array.isArray(appointmentProducts) && appointmentProducts.length > 0) {
      return (<div className='flex flex-wrap gap-3'>
        {appointmentProducts?.map(appointmentProduct => {
          return <Pill variant='light' rounded='lg' key={appointmentProduct?.id}>{appointmentProduct?.name} x{appointmentProduct?.quantity} - {formatInToPrice(appointmentProduct?.quantity * appointmentProduct?.price)}</Pill>
        })}
      </div>)
    }

    return "-"
  }

  const nameMap = {
    "packageName": "Voucher Name",
    "servicesIncluded": "Services",
    "voucherCode": "Voucher Code",
    "price": "Total Price",
    "totalPrice": "Total Price",
    "totalServices": "Total Services",
    "voucherBalance": "Voucher Balance",
    "servicesRemaining": "Services Remaining",
    "location": "Location",
    "dateOfPurchase": "Checkin Datetime",
    "status": "Status"
  };

  const voucherMap = {
    "servicesIncluded": getAppointmentPillsForServices(appointmentSummary?.appointment?.appointmentServices),
    "productsIncluded": getAppointmentPillsForProducts(appointmentSummary?.appointment?.appointmentProducts),
    "bookingId": appointmentSummary?.appointment?.bookingId,
    "price": formatInToPrice(appointmentSummary?.appointment?.totalPaid),
    "location": appointmentSummary?.appointment?.salon?.branchName,
    "dateOfPurchase": appointmentSummary?.appointment?.appointmentStatus === 'checked_in' || appointmentSummary?.appointment?.appointmentStatus === 'completed' ? getNumberMonthYearAndTimeFromDateTimezone(appointmentSummary?.appointment?.startAt as string, appointmentSummary?.appointment?.salon?.business?.country?.timezone) : '-',
    "status": cleanUpText(appointmentSummary?.appointment?.appointmentStatus)
  };
  return (
    <Modal
      show={isVisible}
      closeModal={closeModal}
      variant='right'
    >
      <div className='w-full relative my-[80px]'>
        <div className="fixed top-0 w-full bg-white flex border-b border-grey-100 cursor-pointer z-10">
          <div className='w-full hidden xl:flex space-x-2 px-6 py-5 items-start'>
            <Button
              variant='text'
              size='none'
              type='button'
              className='w-fit'
              fontSize='b4'
              onClick={closeModal}
            ><SvgArrowBack width="24px" height="24px" /> <span>Back</span></Button>
          </div>
          <div className='w-full flex xl:hidden space-x-[69px] p-4 items-center'>
            <Button
              variant='icon'
              size='square'
              type='button'
              rounded='md'
              className='w-fit'
              fontSize='b4'
              onClick={closeModal}
            ><SvgChevronLeft width="24px" height="24px" /></Button>
            <Paragraph size='b3' weight='bold'>View Voucher Details</Paragraph>
          </div>
        </div>
        <div className='w-full space-y-12 px-6 py-4'>
          <div className='w-full flex items-center justify-between'>
            <div className="flex flex-col space-y-6">
              <Heading variant='h1' size='h9' weight='semiBold'>Transaction details</Heading>
              <Paragraph size="b4">Find information about transaction here</Paragraph>
            </div>
          </div>
          <div className='flex flex-col gap-6 p-3 rounded-md border border-grey-125/45'>
            <div className='flex items-center justify-between bg-grey-50/40 p-2'>
              <Paragraph size='b5' color={COLORS.GREY[300]}>Amount</Paragraph>
              {walletTransaction?.transactionType === 'debit' ?
                <Paragraph size='b4' color={COLORS.RED[300]}>
                  -{formatInToPrice(walletTransaction?.amount)}
                </Paragraph> :
                <Paragraph size='b4' color={COLORS.GREEN[300]}>
                  -{formatInToPrice(walletTransaction?.amount)}
                </Paragraph>
              }
            </div>
            <div className='flex items-center justify-between bg-grey-50/40 p-2'>
              <Paragraph size='b5' color={COLORS.GREY[300]}>Transaction Type</Paragraph>
              <Paragraph size='b4' color={COLORS.BLACK}>{walletTransaction?.transactionType}</Paragraph>
            </div>
            <div className='flex items-center justify-between bg-grey-50/40 p-2'>
              <Paragraph size='b5' color={COLORS.GREY[300]}>Date</Paragraph>
              <Paragraph size='b4' color={COLORS.BLACK}>{getDayMonthAndNumberAndTimeFromDate(walletTransaction?.createdAt) || "-"}</Paragraph>
            </div>
            <div className='flex items-center justify-between bg-grey-50/40 p-2'>
              <Paragraph size='b5' color={COLORS.GREY[300]}>Description</Paragraph>
              <Paragraph size='b4' color={COLORS.BLACK}>{walletTransaction?.description}</Paragraph>
            </div>
          </div>


          {appointmentSummary ?
            <div className="w-full flex border border-grey-50 rounded-md p-4 flex-col space-y-3">
              {Object.entries(voucherMap).map(([key, value]) => (

                nameMap[key] && value !== null && (
                  <div key={key} className="px-2 flex justify-between items-center space-x-4 bg-grey-10">
                    <div className="flex flex-col space-y-1 p-2">
                      <Paragraph size="b4" color="grey-300" className="text-left">
                        {nameMap[key]}
                      </Paragraph>
                    </div>
                    <div className="flex flex-col space-y-1">
                      <Paragraph size="b4" color="grey-800" weight="semiBold" className="text-right">
                        {value}
                      </Paragraph>
                    </div>
                  </div>
                )
              ))}
            </div> : null}
        </div>
      </div>
    </Modal>
  )
}

export default ViewTransactionModal