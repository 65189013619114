import * as React from 'react';
import { CollapseRowProps } from './types';
import { Heading, Paragraph, SvgInfo } from 'ui';
import { COLORS } from 'constants/colors';

const CollapseRow: React.FC<CollapseRowProps> = ({ title, children, tooltip, leadingIcon }) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(true);

  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="mb-4">
      <div
        className="flex items-center justify-between cursor-pointer bg-grey-10 border border-grey-100 px-4 py-2 rounded-tl-md rounded-tr-md"
        onClick={toggleCollapse}
      >
        <div className='flex items-center space-x-3'>
          {leadingIcon}
          <Paragraph size='b4' weight='normal' color={COLORS.BLACK}>{title}</Paragraph>
          {tooltip ? (
            <div className='group flex relative text-grey-300'>
              <SvgInfo width='15px' height='15px' />
              <span className='w-[200px] group-hover:opacity-100 transition-opacity bg-grey-400 p-2 text-b6 text-grey-20 rounded-md absolute left-2 -translate-x-2 opacity-0 m-4 mx-auto z-30'>
                {tooltip}
              </span>
            </div>
          ) : null}
        </div>
        <svg
          className={`w-5 h-5 transition-transform ${isOpen ? 'transform rotate-180' : ''
            }`}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d={isOpen ? 'M5 15l7-7 7 7' : 'M19 9l-7 7-7-7'}
          />
        </svg>
      </div>
      {isOpen && (
        <div className="flex flex-col gap-4 px-3 py-6 border-b border-l border-r border-grey-100">
          {children}
        </div>
      )}
    </div>
  );
};

export default CollapseRow;