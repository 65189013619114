import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgForbidden = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M13.166 18.958H8.333c-.741 0-1.692-.391-2.208-.916l-3.417-3.417c-.525-.525-.917-1.475-.917-2.208V7.583c0-.741.392-1.691.917-2.208l3.417-3.417c.525-.525 1.475-.916 2.208-.916h4.833c.742 0 1.692.391 2.209.916l3.416 3.417c.526.525.917 1.475.917 2.208v4.834c0 .741-.391 1.691-.916 2.208l-3.417 3.417c-.525.525-1.467.916-2.209.916ZM8.333 2.292c-.408 0-1.042.258-1.325.55L3.592 6.258c-.284.292-.55.917-.55 1.325v4.834c0 .408.258 1.041.55 1.325l3.416 3.416c.292.284.917.55 1.325.55h4.833c.409 0 1.042-.258 1.325-.55l3.417-3.416c.284-.292.55-.917.55-1.325V7.583c0-.408-.258-1.041-.55-1.325l-3.417-3.416c-.291-.284-.916-.55-1.325-.55H8.333Z"
      fill="currentColor"
    />
    <path
      d="M4.867 16.525a.618.618 0 0 1-.442-.183.629.629 0 0 1 0-.884L16.208 3.675a.629.629 0 0 1 .884 0 .629.629 0 0 1 0 .883L5.308 16.342a.618.618 0 0 1-.441.183Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgForbidden;
