import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgAppointmentAddProducts = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <rect
      x={0.5}
      y={0.5}
      width={47}
      height={47}
      rx={3.5}
      stroke="#E9E9E9"
      strokeOpacity={0.6}
    />
    <path
      d="m18 14-3 4v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V18l-3-4H18ZM15 18h18"
      stroke="#000"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28 22a4 4 0 1 1-8 0"
      stroke="#000"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgAppointmentAddProducts;
