import * as React from "react";
import { TabsProps } from "./types";
import { TAB_VARIANT_NAMES } from "../../atoms/tab/constants";
import { COLORS } from "constants/colors";
import { Tab } from "../..";

const Tabs = ({ tabs, activeTab, setActiveTab, variant, bgColor, fontWeight }: TabsProps) => {
  const handleClick = (tab: string) => {
    setActiveTab(tab);
  };
  const tabVariantClass = variant === TAB_VARIANT_NAMES.underline ? 'bg-grey-10 px-3' : variant === TAB_VARIANT_NAMES.background ? 'px-3' : 'p-3'
  return (
    <div className={`w-full overflow-x-auto border-b border-grey-100/70 flex space-x-2 ${tabVariantClass}`} style={{ backgroundColor: bgColor || COLORS.WHITE }}>
      {tabs.map((tab, index) => {
        const showTab = tab?.show === undefined ? true : tab?.show;
        return showTab ? (
          <Tab
            onClick={() => {
              handleClick(tab.key);
            }}
            key={index}
            isActive={activeTab === tab.key}
            title={tab.title}
            variant={variant}
            fontWeight={fontWeight}
            disabled={tab.disabled || false}
          />
        ) : null
      })}
    </div>
  );
};

export default Tabs;
