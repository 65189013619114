import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgUpgrade = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M4.439 14A.43.43 0 0 1 4 13.56a.428.428 0 0 1 .439-.438h7.022a.43.43 0 0 1 .439.44.428.428 0 0 1-.439.438H4.44Zm3.511-2.97a.424.424 0 0 1-.313-.127.426.426 0 0 1-.126-.312V3.364l-2.444 2.44a.379.379 0 0 1-.291.116.442.442 0 0 1-.3-.134.4.4 0 0 1-.12-.299c0-.12.041-.222.123-.305l2.975-2.975a.68.68 0 0 1 .237-.16.721.721 0 0 1 .26-.047c.094 0 .18.016.26.047.08.03.16.083.236.16l2.99 2.992a.408.408 0 0 1 0 .59.4.4 0 0 1-.299.12.414.414 0 0 1-.305-.122L8.39 3.363v7.227a.424.424 0 0 1-.127.312.425.425 0 0 1-.313.127"
      fill="#000"
    />
  </svg>
);
export default SvgUpgrade;
