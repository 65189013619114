import React, { ChangeEvent, useEffect, useMemo, useState } from 'react'
import {
  Button,
  Checkbox,
  FormHelperText,
  FormLabel,
  FormTextarea,
  Input,
  Layout,
  Modal,
  MultiSelect,
  MultiSelectOption,
  Paragraph,
  Pill,
  SearchTerm,
  SelectInput,
  SvgCartBag,
  SvgMove,
  SvgArrowDown,
  ToggleOnly,
  SvgCategory,
  SvgCharmMenuKebab,
  SvgDeleteRegular,
  SvgArchive,
  SvgEditRegular,
  SvgCarbonView
} from '../ui'
import {
  DEPOSIT_TYPE_REQUIREMENTS,
  FORM_ERRORS,
  INVENTORY_TAB_NAME,
  PRODUCTS_HEADINGS,
  PRODUCT_TYPE_LIST_OPTIONS,
  SERVICES_HEADINGS,
  SERVICE_PRICE_TYPES
} from 'constants/information'
import ToastWrapper from 'ui/molecules/toastWrapper/ToastWrapper'
import { useModal, useToast } from 'hooks'
import { ToastProps } from 'ui/atoms/toast/types'
import Tabs from 'ui/molecules/tabs/Tabs'
import TableSkeleton from 'ui/organism/tableSkeleton/TableSkeleton'
import { Heading } from 'ui/atoms/heading'
import { Menu, Transition } from '@headlessui/react'
import { SvgSelectDropDown } from 'ui'
import { SvgFilter } from 'ui'
import Label from 'ui/atoms/formLabel/Label'
import FullTable from 'ui/organism/table/Table'
import {
  convertExistingStaffOptionsToCommaSeparatedStringForServices,
  convertFullDateStringToDate,
  convertStaffOptionsToCommaSeparatedStringForServices,
  filterServicesBySalonId,
  formatInToPrice,
  formatNumber,
  formatServiceCustomPriceInToSelectOptions,
  formatTableHeadersFilterArray,
  getFullTime,
  getHoursAndMinutes,
  getHoursAndMinutesString,
  limitString,
  sortCategories,
  sortFeaturedServices,
  sortProducts,
  sortServices
} from '../components/utils/misc'
import {
  Category,
  CreateProductInput,
  CreateServiceInput,
  DepositTypeEnum,
  Package,
  PackageService,
  Product,
  Service,
  ServicePricingTypeEnum,
  ServiceStaff,
  UpdateProductStockInput
} from 'core/generated'
import { COLORS } from 'constants/colors'
import { Controller, useForm } from 'react-hook-form'
import {
  CSV_UPLOAD_PATTERN,
  IMAGE_UPLOAD_PATTERN,
  REGEX_PATTERNS
} from 'constants/pattern'
import { ERRORS } from 'constants/errors'
import { DEFAULT_CURRENCY } from 'constants/currency'
import ImportFileLink from '../components/uicomponents/ImportFileLink'
import {
  SvgArrowBack,
  SvgBrandVoucher,
  SvgChevronLeft,
  SvgClose,
  SvgGreyMinus,
  SvgGreyPlus,
  SvgMdiLightImage,
  SvgPackageCheckGrey,
  SvgPlus,
  SvgProductEmpty
} from 'ui'
import { useSalonCache } from 'hooks/useSalonCache'
import { ProductInput } from '../components/uicomponents/types'
import { getImageUploadContent } from '../components/utils/upload'
import SingleSelect from 'ui/molecules/singleSelect/SingleSelect'
import { formatCategoriesToSelectFieldCreation, formatStaffToSelectField } from '../components/utils/utils'
import { canPerformAction, checkPageRightStatus } from '../components/utils/permission'
import { PERMISSION_CONSTANTS } from 'constants/permission'
import { PermissionActionProp } from '../components/utils/types'
import SvgUploadImage from 'assets/icons/UploadImage'
import { useArchiveService, useCreateCategory, useCreatePackage, useCreateProduct, useCreateService, useDeleteCategory, useDeletePackage, useDeleteProduct, useDeleteService, useGetArchivedServices, useGetCategories, useGetProduct, useGetProducts, useRestoreService, useSalonPackages, useUpdateCategoryOrder, useUpdateFeaturedServicesOrder, useUpdateProductStock, useUploadProduct } from 'api/useCatalogue'
import { useGetServices, useUploadService } from 'api/useCatalogue'
import { useGetStaffList } from 'api/useStaff'
import BlockPage from 'components/uicomponents/BlockPage'
import AddonSubscription from 'components/uicomponents/AddonSubscription'
import { useUploadFile } from 'api/useAccount'
import AddPackageModal from 'modules/inventory/modals/AddPackageModal'
import { getHelperTextForReactHookFormErrors, getHelpTextForCharacterLeft } from 'components/utils/form'
import { MAXIMUM_NOTE_LENGTH } from 'constants/form'
import { GroupedServicesIndex } from 'components/uicomponents/bookingSales/types'
import { TextEditor } from './Messaging'
import DOMPurify from 'dompurify'
import { MenuButton, MenuItem, Menu as SzhMenu } from '@szhsin/react-menu'
import { useLocation, useNavigate } from 'react-router-dom'
import { PAGE_ROUTES } from 'constants/routes'
import Disclaimer from 'components/uicomponents/bookingSales/Disclaimer'
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
export interface ITableFilterProp {
  label: string
  value: string
  show: boolean
}

export type TableFilterProps = ITableFilterProp[]

const Inventory = () => {
  const [activeTab, setActiveTab] = React.useState(
    INVENTORY_TAB_NAME.SERVICES as string
  )
  const { addToast, toast } = useToast();
  const [openAddProduct, setOpenAddProductModal] = useState(false);
  const [openAddService, setOpenAddServiceModal] = useState(false);
  const [openAddSalonPackage, setOpenAddSalonPackageModal] = useState(false);

  const location = useLocation();
  const appointmentParams = new URLSearchParams(location.search);
  const productId = appointmentParams.get('product');

  useEffect(() => {
    if (productId) {
      setActiveTab(INVENTORY_TAB_NAME.PRODUCTS)
    }
  }, [productId])

  const openAddProductModal = () => {
    setOpenAddProductModal(true);
  }

  const closeAddProductModal = () => {
    setOpenAddProductModal(false)
  }

  const openAddServiceModal = () => {
    setOpenAddServiceModal(true);
  }

  const closeAddServiceModal = () => {
    setOpenAddServiceModal(false)
  }

  const openAddSalonPackageModal = () => {
    setOpenAddSalonPackageModal(true);
  }

  const closeAddSalonPackageModal = () => {
    setOpenAddSalonPackageModal(false)
  }

  const INVENTORY_TABS = [
    {
      key: INVENTORY_TAB_NAME.SERVICES,
      title: INVENTORY_TAB_NAME.SERVICES,
      show: true,
    },
    {
      key: INVENTORY_TAB_NAME.PRODUCTS,
      title: INVENTORY_TAB_NAME.PRODUCTS,
      show: true
    },
    {
      key: INVENTORY_TAB_NAME.PACKAGES,
      title: INVENTORY_TAB_NAME.PACKAGES,
      show: canPerformAction('Catalogue::Packages::ViewPackage')
    }
  ]

  const showVoucherPage = checkPageRightStatus('vouchers', 'Catalogue::Packages::ViewPackage')
  const reloadPage = () => {
    window.location.reload()
  }
  return (
    <Layout pageTitle={activeTab}
      openModal={
        activeTab === INVENTORY_TAB_NAME.PRODUCTS ?
          openAddProductModal : activeTab === INVENTORY_TAB_NAME?.SERVICES ?
            openAddServiceModal : openAddSalonPackageModal
      } pageLevel={2}>
      <ToastWrapper toast={toast as ToastProps} />
      <Tabs
        tabs={INVENTORY_TABS}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      {activeTab === INVENTORY_TAB_NAME.SERVICES ? (
        <Services openPage={openAddService} closePage={closeAddServiceModal} />
      ) : activeTab === INVENTORY_TAB_NAME.PRODUCTS ? (
        <Products openPage={openAddProduct} closePage={closeAddProductModal} productId={productId} />
      ) : activeTab === INVENTORY_TAB_NAME.PACKAGES ? (
        <>
          {showVoucherPage === 'showPage' ? (
            <Packages openPage={openAddSalonPackage} closePage={closeAddSalonPackageModal} />
          ) : showVoucherPage === 'shouldSubscribe' ? (
            <AddonSubscription module='vouchers' addToast={addToast} reloadPage={reloadPage} />
          ) : showVoucherPage === 'hidePage' ? <BlockPage /> : null}
        </>
      ) : null}
    </Layout>
  )
}

const Products = ({ openPage, closePage, productId }: { openPage: boolean, closePage: () => void, productId?: string }) => {
  const { addToast, toast } = useToast();
  const navigate = useNavigate();
  const { getSalonData } = useSalonCache()
  const salon = getSalonData();
  const salonId = salon?.id;
  const [product, setProduct] = useState<Product | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState<string>('');
  const [productHeadings, setProductHeadings] = useState(PRODUCTS_HEADINGS)
  const MOBILE_PRODUCTS_HEADINGS = [
    {
      title: 'Product name',
      key: 'name'
    }, {
      title: 'Category',
      key: 'category'
    },
    {
      title: 'Description',
      key: 'description',
    },
    {
      title: 'Stock count',
      key: 'stock',
    },
    {
      title: 'Retail price',
      key: 'price'
    }
  ]

  const {
    data: productsData,
    loading,
    refetch,
  } = useGetProducts({
    salonId,
    q: debouncedSearchQuery
  });

  const products = useMemo(() => {
    return sortProducts(productsData?.products)
  }, [productsData])

  useEffect(() => {
    if (salonId) {
      refetch()
    }
  }, [salonId, refetch])

  const actions = {
    addProduct: canPerformAction(`Catalogue::Product::${PERMISSION_CONSTANTS.product.add}`),
    viewProduct: canPerformAction(`Catalogue::Product::${PERMISSION_CONSTANTS.product.view}`),
    editProduct: canPerformAction(`Catalogue::Product::${PERMISSION_CONSTANTS.product.edit}`),
    deleteProduct: canPerformAction(`Catalogue::Product::${PERMISSION_CONSTANTS.product.delete}`),
    increaseStock: canPerformAction(`Catalogue::Product::${PERMISSION_CONSTANTS.product.increase_stock}`),
    decreaseStock: canPerformAction(`Catalogue::Product::${PERMISSION_CONSTANTS.product.decrease_stock}`),
    importProduct: canPerformAction(`Catalogue::Product::${PERMISSION_CONSTANTS.product.import}`)
  }

  const {
    isVisible: isDeleteProductDialogModalVisible,
    openModal: openDeleteProductDialogModal,
    closeModal: closeDeleteProductDialogModal
  } = useModal()

  const {
    isVisible: isAddProductModalVisible,
    openModal: openAddProductModal,
    closeModal: closeAddProductModal
  } = useModal()

  const {
    isVisible: isViewProductModalVisible,
    openModal: openViewProductModal,
    closeModal: closeViewProductModal
  } = useModal()

  const {
    isVisible: isImportProductModalVisible,
    openModal: openImportProductModal,
    closeModal: closeImportProductModal
  } = useModal()

  useEffect(() => {
    if (openPage) {
      openAddProductModal()
    }
  }, [openPage])

  useEffect(() => {
    refetch()
  }, [debouncedSearchQuery])

  const initiateCloseViewProductModal = () => {
    closeViewProductModal()
    setProduct(null)
    refetch()
    if (productId) {
      navigate(PAGE_ROUTES.INVENTORY, {
        replace: true,
      });
    }
  }

  const initiateCloseAddProductModal = () => {
    closeAddProductModal()
    setProduct(null)
    closePage()
    refetch()
  }

  const initiateCloseImportProductModal = () => {
    closeImportProductModal()
    refetch()
  }

  const initiateCloseDeleteProductDialogModal = () => {
    closeDeleteProductDialogModal()
    setProduct(null)
    refetch()
  }

  const openProduct = (id: number, action?: string) => {
    const product = products[id]
    setProduct(product)
    switch (action) {
      case 'delete':
        openDeleteProductDialogModal()
        break
      case 'edit':
        openAddProductModal()
        break
      default:
        openViewProductModal()
        break
    }
  }

  useEffect(() => {
    if (productId) {
      const product = products?.find((product: Product) => product?.id === productId)
      if (product) {
        setProduct(product)
        openViewProductModal()
      }
    }
  }, [productId, products,])

  const handleHeadingCheckboxChange = (value: string) => {
    // search for the heading with that value in productHeadings then update the show
    const updatedHeadings = productHeadings.map((heading) => {
      if (heading.value === value) {
        return {
          ...heading,
          show: !heading.show
        }
      }
      return heading
    })
    setProductHeadings(updatedHeadings)
  }

  const generateProductsTableData = (
    products: Product[],
    tableHeadings: TableFilterProps
  ) => {
    return products?.map?.((product) => {
      const rowData = {} // Using 'any' here, you can replace it with a more specific type
      tableHeadings.forEach((heading) => {
        if (heading.show) {
          switch (heading.value) {
            case 'name':
              rowData[heading.value] = product?.name
              break
            case 'category':
              rowData[heading.value] = product?.category?.name
              break
            case 'description':
              rowData[heading.value] = limitString(product?.description, 25)
              break
            case 'stock':
              rowData[heading.value] = product?.stockCount
              break
            case 'price':
              rowData[heading.value] = formatInToPrice(product?.retailPrice)
              break
            case 'status':
              rowData[heading.value] = product.status ? (
                <Pill
                  variant={
                    product.status === 'In stock'
                      ? 'success'
                      : product.status === 'Sold out'
                        ? 'danger'
                        : product.status === 'Limited'
                          ? 'light'
                          : 'light'
                  }
                >
                  {product.status}
                </Pill>
              ) : (
                <Paragraph size='b5'> - </Paragraph>
              )
              break
          }
        }
      })
      return rowData
    })
  }
  const loadProductsShimmer = isLoading && !products?.length && !debouncedSearchQuery;

  const getProductsContent = () => {
    if (loadProductsShimmer)
      return (
        <div className='flex flex-col xl:flex-row px-5 py-4'>
          <TableSkeleton />
        </div>
      )

    if (Array.isArray(products) && products.length || debouncedSearchQuery) {
      const tableClientsData = generateProductsTableData(
        products,
        productHeadings
      )
      return (
        <>
          <div className='flex flex-col md:flex-row py-4 px-8 justify-between md:items-center gap-4 border-b border-grey-20'>
            <Heading variant='h2' size='h11' weight='bold'>
              Product list
            </Heading>
            {actions?.addProduct || actions?.importProduct ?
              <div className='flex md:items-center space-x-2'>
                <Menu as='div' className='relative inline-block text-left'>
                  <div>
                    <Menu.Button className='flex space-x-2 w-full items-center justify-center rounded-md bg-green-300 px-4 py-2 text-b5 font-medium text-white hover:bg-opacity-90 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-85'>
                      <span>Add</span>
                      <SvgSelectDropDown width='10px' height='10px' />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={React.Fragment}
                    enter='transition ease-out duration-100'
                    enterFrom='transform opacity-0 scale-95'
                    enterTo='transform opacity-100 scale-100'
                    leave='transition ease-in duration-75'
                    leaveFrom='transform opacity-100 scale-100'
                    leaveTo='transform opacity-0 scale-95'
                  >
                    <Menu.Items className='absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-[9999]'>
                      <div className='flex flex-col p-4 space-y-4'>
                        {actions?.addProduct ?
                          <Menu.Item>
                            <span
                              className='text-b4 cursor-pointer'
                              onClick={openAddProductModal}
                            >
                              Add Product
                            </span>
                          </Menu.Item> : null}
                        {actions?.importProduct ?
                          <Menu.Item>
                            <span
                              className='text-b4 cursor-pointer'
                              onClick={openImportProductModal}
                            >
                              Import Products
                            </span>
                          </Menu.Item> : null}
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div> : null}
          </div>
          <div className='flex flex-col xl:flex-row py-4 px-8 space-x-4'>
            <div
              className='w-full xl:w-6/12 flex items-center space-x-4'
            >
              <SearchTerm
                placeholder='Search by name, category, description, price'
                setDebouncedSearchQuery={setDebouncedSearchQuery}
              />
              <Menu as='div' className='relative inline-block text-left'>
                <div>
                  <Menu.Button className='flex space-x-2 w-full items-center justify-center rounded-md bg-grey-50 border border-grey-20 px-4 py-2 text-b5 font-medium text-grey-300 hover:bg-opacity-90 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-85'>
                    <SvgFilter width='20px' height='20px' />
                    <span className='hidden xl:inline'>Filter</span>
                  </Menu.Button>
                </div>
                <Transition
                  as={React.Fragment}
                  enter='transition ease-out duration-100'
                  enterFrom='transform opacity-0 scale-95'
                  enterTo='transform opacity-100 scale-100'
                  leave='transition ease-in duration-75'
                  leaveFrom='transform opacity-100 scale-100'
                  leaveTo='transform opacity-0 scale-95'
                >
                  <Menu.Items className='absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-[9999]'>
                    <div className='flex flex-col p-4 space-y-4'>
                      {Array.isArray(productHeadings) &&
                        productHeadings.length &&
                        productHeadings.map((heading, index) => (
                          <Label
                            className='flex space-x-2 items-center cursor-pointer'
                            key={index}
                            htmlFor={heading.value}
                            onClick={() =>
                              handleHeadingCheckboxChange(heading.value)
                            }
                          >
                            <Checkbox
                              isChecked={heading.show}
                              borderType='Black'
                              size='Sm'
                            />
                            <span>{heading.label}</span>
                          </Label>
                        ))}
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
          <FullTable
            headers={formatTableHeadersFilterArray(productHeadings)}
            mobileHeaders={MOBILE_PRODUCTS_HEADINGS}
            rows={tableClientsData}
            onClick={openProduct}
            tableOptions={{
              view: actions.viewProduct,
              edit: actions.editProduct,
              delete: actions.deleteProduct,
              duplicate: false
            }}
          />
        </>
      )
    }

    return (
      <div className='w-full flex h-full justify-center items-center'>
        <div
          style={{ maxWidth: '450px' }}
          className='flex flex-col justify-center items-center space-y-6 w-full p-12'
        >
          <SvgProductEmpty width="100%" height="100%" />
          <Heading variant='h2' size='h3'>
            Products
          </Heading>
          <Paragraph size='b5' className='mt-4'>
            You don't have any products yet.
          </Paragraph>
          <div className='w-full flex space-x-2'>
            {actions?.importProduct ?
              <Button
                variant='success'
                size='lg'
                type='button'
                rounded='lg'
                onClick={openImportProductModal}
              >
                Import Product
              </Button> : null}
            {actions?.addProduct ?
              <Button
                variant='primary'
                size='lg'
                type='button'
                rounded='lg'
                onClick={openAddProductModal}
              >
                Add Product
              </Button> : null}
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className='w-full mb-20'>
      <ToastWrapper toast={toast} />
      {getProductsContent()}
      <AddProductModal
        isVisible={isAddProductModalVisible}
        closeModal={initiateCloseAddProductModal}
        product={product}
        setProduct={setProduct}
        actions={actions}
        addToast={addToast}
      />
      <DeleteProductDialog
        product={product}
        isVisible={isDeleteProductDialogModalVisible}
        closeModal={initiateCloseDeleteProductDialogModal}
        setProduct={setProduct}
        addToast={addToast}
      />
      <ViewProductModal
        isVisible={isViewProductModalVisible}
        closeModal={initiateCloseViewProductModal}
        product={product}
        setProduct={setProduct}
        actions={actions}
        addToast={addToast}
      />
      <ImportProduct
        isVisible={isImportProductModalVisible}
        closeModal={initiateCloseImportProductModal}
        addToast={addToast}
      />
    </div>
  )
}

type ImportProductModalProps = {
  isVisible: boolean
  closeModal: () => void
  addToast: (toast: ToastProps) => void
}

const ImportProduct = ({ isVisible, closeModal, addToast }: ImportProductModalProps) => {
  const {
    loading,
    uploadProduct
  } = useUploadProduct()
  const baseURl = process.env.REACT_APP_API_BASE_URL as string
  const PRODUCT_TEMPLATE_URL = `${baseURl}/home/download_template/product_import_template.csv`
  const [uploadUrl, setUploadUrl] = useState(null)
  const { getSalonFieldValue } = useSalonCache()
  const { handleSubmit, control, setValue } = useForm<FileUploadInput>()

  const {
    loading: uploadIsLoading,
    uploadFile
  } = useUploadFile();

  const importProductSubmit = async () => {
    const payload = {
      url: uploadUrl,
      salonId: getSalonFieldValue('id')
    }
    uploadProduct({
      variables: {
        input: payload
      }
    }).then(({ data }) => {
      const { uploadProduct } = data;
      if (uploadProduct?.status === 200) {
        addToast({
          variant: 'success',
          message: 'Products uploaded successfully. This may take a few minutes to process'
        })
        setValue('fileUpload', null)
        setUploadUrl(null)
        closeModal()
      }

      if (uploadProduct?.errors?.length) {
        addToast({
          variant: 'error',
          message: uploadProduct?.errors[0]?.message
        })
      }
    }).catch((error) => {
      addToast({
        variant: 'error',
        message: error?.message
      })
    })
  }

  const handleCsvUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0]
    setValue('fileUpload', file)
    const reader = new FileReader()
    reader.onload = async (event) => {
      const base64DataUri = event.target.result;
      uploadFile({
        variables: { input: { data: base64DataUri } }
      }).then(({ data }) => {
        const { uploadFile } = data;
        if (uploadFile?.status === 200) {
          setUploadUrl(uploadFile?.url);
        }

        if (uploadFile?.errors?.length) {
          addToast({
            variant: 'error',
            message: uploadFile?.errors[0]?.message
          })
        }
      }).catch((error) => {
        addToast({
          variant: 'error',
          message: error?.message
        })
      })
    }

    reader.readAsDataURL(file)
  }

  return (
    <Modal title='Import Products' show={isVisible} closeModal={closeModal}>
      <div className='w-full flex flex-col my-6 items-center space-y-6'>
        <a href={PRODUCT_TEMPLATE_URL} target='_blank' download>
          <Paragraph size='b5' color={COLORS.GREY[400]}>
            First, download the <span className='underline'>template</span> here
          </Paragraph>
        </a>
        <form
          onSubmit={handleSubmit(importProductSubmit)}
          className='w-full mt-6 space-y-6'
          autoComplete='off'
        >
          <Controller
            control={control}
            name='fileUpload'
            render={({ field: { value } }) => {
              return (
                <div className='w-full flex flex-col py-6'>
                  <div className='w-full flex items-center space-x-4 border rounded-sm border-grey-20 border-dashed h-[130px] justify-center'>
                    <div className='relative cursor-pointer'>
                      {value ? (
                        <SvgMdiLightImage width='2rem' height='2rem' />
                      ) : (
                        <SvgUploadImage width='2rem' height='2rem' />
                      )}
                      <input
                        className='cursor-pointer absolute block opacity-0 top-0 w-[128px] h-[96px]'
                        type='file'
                        accept={CSV_UPLOAD_PATTERN}
                        onChange={handleCsvUpload}
                      />
                    </div>
                    <div className='flex flex-col'>
                      <Paragraph size='b5'>
                        {value ? value?.name : 'Upload product inventory'}
                      </Paragraph>
                    </div>
                  </div>
                  {/* {errors?.fileUpload?.message && (
                    <FormHelperText variant='error'>
                      {errors.fileUpload.message}
                    </FormHelperText>
                  )} */}
                </div>
              )
            }}
            rules={{
              required: ERRORS.CSV_FILE_REQUIRED
            }}
          />
          <Button
            variant='primary'
            className=''
            disabled={loading || uploadIsLoading}
            loading={loading || uploadIsLoading}
            size='lg'
            rounded='lg'
          >
            Upload
          </Button>
          <Button
            variant='text'
            className={`mx-auto text-red-500`}
            size='none'
            fontWeight="semiBold"
            onClick={closeModal}
            type='button'
          >
            Cancel
          </Button>
        </form>
      </div>
    </Modal>
  )
}

const ViewProductModal = ({
  isVisible,
  closeModal,
  product: p_,
  setProduct,
  actions,
  addToast
}: AddProductModalProps<'addProduct' | 'editProduct' | 'deleteProduct' | 'increaseStock' | 'decreaseStock'>) => {
  const [actionType, setActionType] = useState('')
  const { getSalonData } = useSalonCache()
  const salon = getSalonData();
  const salonId = salon?.id;
  const {
    isVisible: isAddProductModalVisible,
    openModal,
    closeModal: closeAddProductModal
  } = useModal()
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors }
  } = useForm<CreateProductInput>()

  const {
    loading,
    createProduct
  } = useCreateProduct()

  const {
    data: productData,
    loading: loadingProduct,
    refetch
  } = useGetProduct(p_?.id)

  const product = useMemo(() => productData?.product, [productData])

  const {
    isVisible: isAddStockModalVisible,
    openModal: openAddStockModal,
    closeModal: closeAddStockModal
  } = useModal()

  const {
    isVisible: isDeleteProductDialogModalVisible,
    openModal: openDeleteProductDialogModal,
    closeModal: closeDeleteProductDialogModal
  } = useModal()

  const addToStock = () => {
    setActionType('add')
    openAddStockModal()
  }

  const removeStock = () => {
    setActionType('remove')
    openAddStockModal()
  }

  const onSave = async (input: CreateProductInput) => {
    const payload = {
      id: product?.id,
      name: product?.name,
      description: product?.description,
      imageUrl: product?.imageUrl,
      categoryId: product?.category?.id,
      isPurchasable: product?.isPurchasable,
      costPrice: product?.costPrice,
      salonId,
      ...(product?.isPurchasable ? { retailPrice: product?.retailPrice || 0 } : {}),
      stockCount: Number(input?.stockCount),
      expirationDate: input?.expirationDate,
      lowStockThreshold: Number(input?.lowStockThreshold)
    }
    createProduct({
      variables: {
        input: payload
      }
    }).then(({ data }) => {
      const { createProduct } = data;
      if (createProduct.status === 200) {
        addToast({
          variant: 'success',
          message: 'Product saved successfully'
        })
        resetFormProduct()
        closeModal()
      } else {
        addToast({
          variant: 'error',
          message: createProduct?.errors[0].message || 'Error saving product'
        })
      }
    })
  }

  const initiateCloseDeleteProductDialogModal = (deleteProduct: boolean) => {
    if (deleteProduct) {
      closeDeleteProductDialogModal()
      _closeModal()
    } else {
      closeDeleteProductDialogModal()
    }
  }

  const _closeModal = () => {
    closeModal()
    resetFormProduct()
  }

  const resetFormProduct = () => {
    setValue('name', '');
    setValue('description', '');
    setValue('category', '');
    setValue('costPrice', 0);
    setValue('retailPrice', 0)
    setValue('isPurchasable', false)
    setValue('imageUrl', '')
    setValue('stockCount', 0)
    setValue('lowStockThreshold', 0)
    setValue('expirationDate', null)
    setProduct && setProduct(null)
  }


  useEffect(() => {
    if (product) {
      setValue('stockCount', product.stockCount)
      setValue('lowStockThreshold', product.lowStockThreshold || 0)
      setValue('isPurchasable', product.isPurchasable)
      setValue(
        'expirationDate',
        product?.expirationDate
          ? convertFullDateStringToDate(product?.expirationDate as string)
          : null
      )
    }
  }, [product, setValue])

  return (
    <>
      <Modal show={isVisible} closeModal={_closeModal} size='lg'>
        <div className='w-full flex flex-col space-y-4 items-start justify-start'>
          <Button
            variant='icon'
            size='square'
            rounded='md'
            type='button'
            className='hidden xl:flex max-w-[50px]'
            onClick={_closeModal}
          >
            <SvgClose width='24px' height='24px' />
          </Button>
          <div className='w-full flex space-x-20'>
            <div
              style={{ width: '30%' }}
              className='w-[30%] flex flex-col space-y-6'
            >
              <div className='w-full'>
                <Heading variant='h1' size='h8' weight='bold'>
                  Product Details
                </Heading>
                <Paragraph size='b5' color={COLORS.GREY[400]}>
                  Find details about your product below
                </Paragraph>
              </div>
              <div className='w-full flex flex-col space-y-4'>
                <div className='w-full flex justify-between items-center'>
                  <Paragraph size='b6' color={COLORS.GREY[300]} weight='semiBold'>
                    BASIC INFORMATION
                  </Paragraph>
                  {actions?.editProduct ?
                    <Button
                      variant='text'
                      fontWeight='bold'
                      className={`mx-auto text-green-600`}
                      size='none'
                      onClick={openModal}
                      type='button'
                    >
                      Edit
                    </Button> : null}
                </div>

                {product?.imageUrl && (
                  <img
                    src={product?.imageUrl}
                    alt='product image'
                    className='w-full max-w-[150px] p-2'
                    loading='lazy'
                  />
                )}
              </div>
              <div className='w-full'>
                <Heading variant='h2' size='h10'>
                  {product?.name}
                </Heading>
                <Paragraph size='b5' color={COLORS.GREY[400]}>
                  {product?.description} ・{product?.category?.name}
                </Paragraph>
              </div>
            </div>
            <div className='w-[70%] flex flex-col space-y-6'>
              <form
                onSubmit={handleSubmit(onSave)}
                className='w-full mt-6 space-y-6'
                autoComplete='off'
              >
                <Input
                  name='expirationDate'
                  label='Expiration Date'
                  id='expirationDate'
                  type='date'
                  placeholder='DD/MM/YY'
                  control={control}
                />
                <div className='w-full flex flex-col space-y-2'>
                  <div className='w-full flex gap-x-4'>
                    <Input
                      name='stockCount'
                      label='Stock count'
                      id='stock-count'
                      type='text'
                      placeholder='0'
                      control={control}
                      rules={{
                        required: FORM_ERRORS.STOCK_COUNT_REQUIRED,
                        pattern: REGEX_PATTERNS.NUMBER
                      }}
                      error={errors?.stockCount}
                      disabled={true}
                    />
                    <Input
                      name='lowStockThreshold'
                      label='Low stock alert at'
                      id='lowStockThreshold'
                      type='text'
                      placeholder='0'
                      control={control}
                      rules={{
                        required: FORM_ERRORS.THRESHOLD_REQUIRED,
                        pattern: REGEX_PATTERNS.NUMBER
                      }}
                      error={errors?.lowStockThreshold}
                      disabled={true}
                    />
                  </div>
                  <div className='w-full flex gap-x-4'>
                    {actions?.increaseStock ?
                      <Button
                        variant='success'
                        size='sm'
                        rounded='sm'
                        fontWeight='semiBold'
                        onClick={addToStock}
                        type='button'
                      >
                        Add item
                      </Button> : null}
                    {actions?.decreaseStock ?
                      <Button
                        variant='light'
                        size='sm'
                        rounded='sm'
                        fontWeight='semiBold'
                        onClick={removeStock}
                        type='button'
                      >
                        Remove item
                      </Button> : null}
                  </div>
                </div>
                <div className='space-y-6'>
                  {actions?.editProduct ?
                    <Button
                      variant='primary'
                      className=''
                      disabled={loading}
                      loading={loading}
                      size='lg'
                      rounded='lg'
                    >
                      Save Changes
                    </Button> : null}
                  {actions?.deleteProduct ?
                    <Button
                      variant='text'
                      className={`mx-auto text-red-600`}
                      disabled={false}
                      loading={false}
                      size='none'
                      fontWeight='semiBold'
                      onClick={openDeleteProductDialogModal}
                      type='button'
                    >
                      Delete Product
                    </Button> : null}
                </div>
              </form>
            </div>
          </div>
        </div>
        <AddProductModal
          isVisible={isAddProductModalVisible}
          product={product}
          closeModal={() => {
            closeAddProductModal()
            refetch()
          }}
          actions={actions}
          addToast={addToast}
        />
        <UpdateStockModal
          isVisible={isAddStockModalVisible}
          actionType={actionType}
          closeModal={() => {
            closeAddStockModal()
            refetch()
          }}
          product={product}
          actions={actions}
          addToast={addToast}
        />
      </Modal>
      <DeleteProductDialog
        product={product}
        isVisible={isDeleteProductDialogModalVisible}
        closeModal={initiateCloseDeleteProductDialogModal}
        setProduct={setProduct}
        addToast={addToast}
      />
    </>
  )
}

type UpdateStockModalProps<T extends string> = {
  isVisible: boolean;
  closeModal: () => void;
  product?: Product;
  setProduct?: (product: Product | null) => void;
  actions: PermissionActionProp<T>;
  actionType: string,
  addToast: (toast: ToastProps) => void;
}

const UpdateStockModal = ({
  actionType,
  isVisible,
  closeModal,
  product,
  actions,
  addToast
}: UpdateStockModalProps<'increaseStock' | 'decreaseStock'>) => {
  const [saving, setSaving] = useState(false)
  const {
    loading,
    updateProductStock
  } = useUpdateProductStock()
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors }
  } = useForm<UpdateProductStockInput>()

  const resetForm = () => {
    setValue('quantity', 0)
    setValue('reason', '')
  }

  const modifyProductStockSubmit = async (input: UpdateProductStockInput) => {
    setSaving(true)
    const payload: UpdateProductStockInput = {
      quantity: Number(input.quantity.toString().replace(/,/, '')),
      reason: input.reason,
      actionType,
      productId: product.id,
      salonId: product.salonId
    }
    updateProductStock({
      variables: {
        input: {
          ...payload
        }
      }
    }).then(({ data }) => {
      const { updateProductStock } = data;
      if (updateProductStock.status === 200) {
        addToast({
          variant: 'success',
          message: 'Product stock updated successfully'
        })
        resetForm()
        closeModal()
      } else {
        addToast({
          variant: 'error',
          message: updateProductStock?.errors[0].message || 'Error updating product stock'
        })
      }
    })
    setSaving(false)
  }

  const _closeModal = () => {
    resetForm()
    closeModal()
  }

  const increaseStockCount = () => {
    const currentStockCount = watch('quantity') // Get the current stock count value

    if (isNaN(currentStockCount)) {
      // Check if it's not a valid number
      setValue('quantity', 1) // Initialize stockCount to 1
    } else {
      setValue('quantity', Number(currentStockCount) + 1) // Increment stockCount by 1
    }
  }

  const decreaseStockCount = () => {
    if (watch('quantity') > 0) {
      setValue('quantity', Number(watch('quantity')) - 1)
    }
  }

  return (
    <Modal
      title={actionType === 'add' ? 'Add Item' : 'Remove Item'}
      show={isVisible}
      closeModal={_closeModal}
    >
      <div className='w-full flex flex-col my-6 items-center space-y-6'>
        <div className='w-full flex flex-col items-center space-y-4'>
          <div className='w-full flex flex-col items-center space-y-2'>
            <Paragraph size='b4' color={COLORS.GREY[900]}>
              {product?.name}
            </Paragraph>
            <Paragraph size='b6' color={COLORS.GREY[900]}>
              {formatInToPrice(product?.retailPrice)}
            </Paragraph>
          </div>

          <Pill variant='light' className='text-b4 text-grey-400 font-semibold'>
            {product?.stockCount} IN STOCK
          </Pill>
        </div>
        <form
          onSubmit={handleSubmit(modifyProductStockSubmit)}
          className='w-full mt-6 space-y-6'
          autoComplete='off'
        >
          <div className='w-full flex gap-x-4'>
            <Controller
              control={control}
              name='quantity'
              render={({ field, formState: { errors } }) => {
                return (
                  <div className='w-full flex flex-col'>
                    <FormLabel htmlFor='quantity'>Quantity</FormLabel>
                    <div className='w-full flex space-x-4 pt-2'>
                      {actions?.increaseStock ?
                        <Button
                          variant='icon'
                          size='none'
                          type='button'
                          className='border-0'
                          onClick={increaseStockCount}
                        >
                          <SvgGreyPlus width='36px' height='36px' />
                        </Button> : null}
                      <input
                        className='w-full flex items-center appearance-none border border-grey-20 px-4 py-3 font-normal text-b6 xl:text-b4 text-grey-900 rounded-lg focus:outline-none focus:border-grey-20 focus:shadow-grey-100 placeholder:text-grey-400'
                        type='text'
                        {...field}
                        value={formatNumber(field.value)}
                        onChange={(e) =>
                          field.onChange(formatNumber(e.target.value))
                        }
                        placeholder='1'
                      />
                      {actions?.decreaseStock ?
                        <Button
                          variant='icon'
                          size='none'
                          type='button'
                          className='border-0'
                          onClick={decreaseStockCount}
                        >
                          <SvgGreyMinus width='36px' height='36px' />
                        </Button> : null}
                    </div>
                    {errors?.quantity?.message && (
                      <FormHelperText variant='error'>
                        {errors.quantity.message}
                      </FormHelperText>
                    )}
                  </div>
                )
              }}
              rules={{
                required: 'Stock quantity is required'
              }}
            />
            <SelectInput
              name='reason'
              id='type'
              label='Reason'
              control={control}
              error={errors.reason}
              rules={{ required: 'Select a reason' }}
              options={PRODUCT_TYPE_LIST_OPTIONS}
              placeholder='Select Reason'
            />
          </div>
          {actions?.increaseStock || actions?.decreaseStock ?
            <Button
              variant='primary'
              className=''
              disabled={saving}
              loading={saving}
              size='lg'
              rounded='lg'
            >
              Save
            </Button> : null}
          <Button
            variant='text'
            className={`mx-auto text-red-500`}
            size='none'
            fontWeight="semiBold"
            onClick={_closeModal}
            type='button'
          >
            Cancel
          </Button>
        </form>
      </div>
    </Modal>
  )
}

type AddProductModalProps<T extends string> = {
  isVisible: boolean
  closeModal: () => void
  product?: Product
  setProduct?: (product: Product | null) => void
  actions: PermissionActionProp<T>
  addToast: (toast: ToastProps) => void
}
const AddProductModal = ({
  isVisible,
  closeModal,
  product,
  setProduct,
  actions,
  addToast
}: AddProductModalProps<'editProduct' | 'addProduct' | 'deleteProduct'>) => {
  const {
    data: categoriesData
  } = useGetCategories()
  const categories = useMemo(() => categoriesData?.categories, [categoriesData])
  const categoriesOptions = useMemo(() => formatCategoriesToSelectFieldCreation(categories), [categories])
  const [newCategory, setNewCategory] = useState(false)
  const { getSalonData } = useSalonCache()
  const salon = getSalonData()
  const salonId = salon?.id
  const {
    createCategory,
  } = useCreateCategory()
  const {
    loading: saving,
    createProduct
  } = useCreateProduct()
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors }
  } = useForm<ProductInput>()
  const [imageUrl, setImageUrl] = useState('')

  useEffect(() => {
    if (watch('categoryId')) {
      const categoryIdField = watch('categoryId') as unknown as {
        label: string;
        value: string;
      };
      if (categoryIdField?.value === 'new-cate') {
        setNewCategory(true);
      } else {
        setNewCategory(false);
      }
    }
  }, [watch('categoryId')])

  const addProductSubmit = async (input: ProductInput) => {
    try {
      const categoryIdField = input.categoryId as unknown as {
        label: string;
        value: string;
      };
      let categoryId = categoryIdField?.value;

      if (newCategory) {
        try {
          const { data } = await createCategory({
            variables: {
              input: {
                name: input?.category
              }
            }
          });

          if (data?.createCategory?.status === 200 && data?.createCategory?.category) {
            categoryId = data?.createCategory?.category?.id;
          } else {
            throw new Error('Failed to create category');
          }
        } catch (error) {
          addToast({
            message: error?.message,
            variant: 'error'
          });
          return; // Exit the function if category creation fails
        }
      }

      const payload = {
        name: input?.name,
        description: input?.description,
        imageUrl: imageUrl,
        categoryId,
        salonId,
        isPurchasable: input.isPurchasable ? true : false,
        id: product ? product.id : null,
        lowStockThreshold: Number(input.lowStockThreshold),
        stockCount: Number(input.stockCount),
        costPrice: Number(input.costPrice.toString().replace(/,/g, '')),
        retailPrice: input.isPurchasable
          ? Number(input.retailPrice.toString().replace(/,/g, ''))
          : null,
        expirationDate: input.expirationDate,
      };

      const { data } = await createProduct({
        variables: {
          input: payload
        }
      });

      if (data?.createProduct?.status === 200) {
        addToast({
          message: 'Product saved successfully',
          variant: 'success'
        });
        closeModal();
      } else if (data?.createProduct?.errors?.length) {
        addToast({
          message: data?.createProduct?.errors[0].message,
          variant: 'error'
        });
      } else {
        throw new Error('Failed to create product');
      }
    } catch (error) {
      addToast({
        message: error?.message,
        variant: 'error'
      });
    }
  };

  useEffect(() => {
    if (product) {
      setValue('name', product?.name)
      setValue('description', product?.description)
      setValue('categoryId', product?.category ? {
        label: product?.category?.name,
        value: product?.category?.id
      } as unknown as [] : [])
      setValue('costPrice', product?.costPrice)
      setValue('retailPrice', product?.retailPrice)
      setValue('isPurchasable', product?.isPurchasable)
      setValue('imageUrl', product?.imageUrl)
      setValue('stockCount', product?.stockCount)
      setValue('lowStockThreshold', product?.lowStockThreshold || 0)
      setValue(
        'expirationDate',
        product?.expirationDate
          ? convertFullDateStringToDate(product?.expirationDate as string)
          : null
      )
    } else {
      resetFormInput()
    }
  }, [product]);

  const resetFormInput = () => {
    setValue('name', '');
    setValue('description', '');
    setValue('categoryId', []);
    setValue('costPrice', 0);
    setValue('retailPrice', 0)
    setValue('isPurchasable', false)
    setValue('imageUrl', '')
    setValue('stockCount', 0)
    setValue('lowStockThreshold', 0)
    setValue('expirationDate', null)
    setProduct && setProduct(null)
  }

  const _closeModal = () => {
    closeModal()
    resetFormInput()
  }

  const {
    loading: isFileUploading,
    uploadFile
  } = useUploadFile();
  const handleFileUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files[0]
    const reader = new FileReader()
    reader.onload = async (event) => {
      const base64DataUri = event.target.result
      uploadFile({
        variables: { input: { data: base64DataUri } }
      }).then(({ data }) => {
        const { uploadFile } = data;
        if (uploadFile?.status === 200) {
          setImageUrl(uploadFile?.url);
        }

        if (uploadFile?.errors?.length) {
          addToast({
            message: uploadFile?.errors[0].message,
            variant: 'error'
          })
        }
      }).catch((error) => {
        addToast({
          message: error?.message,
          variant: 'error'
        })
      })
    }

    reader.readAsDataURL(file)
  }

  return (
    <Modal
      title={product ? 'Edit Product' : 'Add Product'}
      subTitle={product ? 'Edit Product' : 'Add a new product to your business'}
      show={isVisible}
      closeModal={_closeModal}
    >
      <form
        onSubmit={handleSubmit(addProductSubmit)}
        className='w-full mt-6 space-y-6'
        autoComplete='off'
      >
        <Input
          name='name'
          label='Product name'
          id='product-name'
          type='text'
          placeholder='E.g Manicure'
          control={control}
          rules={{
            required: 'Product name is required'
          }}
          error={errors.name}
        />
        <Input
          name='description'
          label='Description'
          id='description'
          type='text'
          placeholder='A brief description of this service'
          control={control}
          error={errors.description}
        />
        <div className='w-full flex gap-x-4'>
          <div className='w-full flex flex-col space-y-2'>
            <Controller
              control={control}
              name="categoryId"
              render={({
                field: { onChange, value },
                formState: { errors },
              }) => {
                const errorMessage: string = errors?.categoryId?.message;
                return (
                  <>
                    <FormLabel htmlFor="service">
                      Select a category
                    </FormLabel>
                    <SingleSelect
                      selected={value || []}
                      options={categoriesOptions}
                      setSelected={onChange}
                    />
                    {errorMessage && (
                      <FormHelperText variant="error">
                        {errorMessage}
                      </FormHelperText>
                    )}
                  </>
                );
              }}
              rules={{
                required: "Select a category",
              }}
            />
            {newCategory && (
              <Input
                name='category'
                label='Category'
                id='category'
                type='text'
                placeholder='Enter a category'
                control={control}
                rules={{
                  required: newCategory && FORM_ERRORS.CATEGORY_REQUIRED
                }}
                error={errors.category}
              />
            )}
          </div>
          <div className='w-full'>
            <Input
              name='expirationDate'
              label='Expiration Date'
              id='expirationDate'
              type='date'
              placeholder='DD/MM/YY'
              control={control}
            />
          </div>
        </div>
        <div className='w-full flex border rounded-sm border-grey-20 border-dashed h-[130px] justify-center items-center'>
          <Controller
            control={control}
            name='imageUrl'
            render={({ field: { value }, formState: { errors } }) => {
              const productImageUploadUrl = imageUrl || value
              return (
                <div className='flex flex-col'>
                  <div className='flex items-center space-x-4'>
                    <div className='relative cursor-pointer'>
                      {getImageUploadContent({
                        imageUrl: productImageUploadUrl,
                        uploadIsLoading: isFileUploading
                      })}
                      <input
                        className='cursor-pointer absolute block opacity-0 top-0 w-full h-[96px]'
                        type='file'
                        accept={IMAGE_UPLOAD_PATTERN}
                        onChange={handleFileUpload}
                      />
                    </div>
                  </div>
                  {errors?.imageUrl?.message && (
                    <FormHelperText variant='error'>
                      {errors.imageUrl.message}
                    </FormHelperText>
                  )}
                </div>
              )
            }}
          />
        </div>
        <div className='w-full flex gap-x-4'>
          <Input
            name='stockCount'
            label='Stock count'
            id='stock-count'
            type='text'
            placeholder='0'
            control={control}
            rules={{
              required: FORM_ERRORS.STOCK_COUNT_REQUIRED,
              pattern: REGEX_PATTERNS.NUMBER
            }}
            error={errors.stockCount}
          />
          <Input
            name='lowStockThreshold'
            label='Low stock alert at'
            id='lowStockThreshold'
            type='text'
            placeholder='0'
            control={control}
            rules={{
              required: FORM_ERRORS.THRESHOLD_REQUIRED,
              pattern: REGEX_PATTERNS.NUMBER
            }}
            error={errors.lowStockThreshold}
          />
        </div>
        <div className='w-full flex gap-x-4'>
          <div className='w-1/2 flex flex-col space-y-4'>
            <Controller
              control={control}
              name='costPrice'
              rules={{ required: 'Please enter a price' }}
              render={({ field, formState: { errors } }) => (
                <div className='w-full flex flex-col space-y-2'>
                  <FormLabel htmlFor='price'>Cost Price</FormLabel>
                  <input
                    className='w-full flex items-center appearance-none border border-grey-20 px-4 py-3 font-normal text-b6 xl:text-b4 text-grey-900 rounded-lg focus:outline-none focus:border-grey-20 focus:shadow-grey-100 placeholder:text-grey-400'
                    type='text'
                    {...field}
                    value={formatNumber(field.value)}
                    onChange={(e) =>
                      field.onChange(formatNumber(e.target.value))
                    }
                    placeholder='Enter price'
                  />
                  {errors?.costPrice?.message && (
                    <FormHelperText variant='error'>
                      {errors.costPrice.message}
                    </FormHelperText>
                  )}
                </div>
              )}
            />
            <Controller
              control={control}
              name='isPurchasable'
              render={({ field: { onChange, value } }) => {
                return (
                  <div className='w-full flex flex-col space-y-4'>
                    <div className='w-full flex flex-wrap gap-4 xl:gap-0 xl:space-x-4'>
                      <div className='flex' onClick={() => onChange(!value)}>
                        <div className='flex items-center cursor-pointer text-b5 text-grey-900 space-x-2'>
                          <Checkbox isChecked={value} />
                          <span className='text-grey-900 whitespace-nowrap'>
                            Allow sales of this product
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }}
            />
          </div>
          <div className='w-1/2'>
            {watch('isPurchasable') ? (
              <Controller
                control={control}
                name='retailPrice'
                rules={{ required: 'Please enter a price' }}
                render={({ field, formState: { errors } }) => (
                  <div className='w-full flex flex-col space-y-2'>
                    <FormLabel htmlFor='price'>Retail Price</FormLabel>
                    <input
                      className='w-full flex items-center appearance-none border border-grey-20 px-4 py-3 font-normal text-b6 xl:text-b4 text-grey-900 rounded-lg focus:outline-none focus:border-grey-20 focus:shadow-grey-100 placeholder:text-grey-400'
                      type='text'
                      {...field}
                      value={formatNumber(field.value as number)}
                      onChange={(e) =>
                        field.onChange(formatNumber(e.target.value))
                      }
                      placeholder='Enter price'
                    />
                    {errors?.retailPrice?.message && (
                      <FormHelperText variant='error'>
                        {errors.retailPrice.message}
                      </FormHelperText>
                    )}
                  </div>
                )}
              />
            ) : null}
          </div>
        </div>
        <div className='space-y-6'>
          {actions?.editProduct || actions?.addProduct ?
            <Button
              variant='primary'
              className=''
              disabled={saving}
              loading={saving}
              size='lg'
              rounded='lg'
            >
              Save
            </Button> : null}
          <Button
            variant='text'
            className={`mx-auto text-red-500`}
            size='none'
            fontWeight="semiBold"
            onClick={_closeModal}
            type='button'
          >
            Cancel
          </Button>
        </div>
      </form>
    </Modal>
  )
}

type DeleteProductDialogProps = {
  product: Product
  isVisible: boolean
  closeModal: (deleteProduct?: boolean) => void
  setProduct: (product: Product | null) => void
  addToast: (toast: ToastProps) => void
}
const DeleteProductDialog = ({
  product,
  isVisible,
  closeModal,
  setProduct,
  addToast
}: DeleteProductDialogProps) => {
  const {
    loading: isLoading,
    deleteProduct
  } = useDeleteProduct()

  const cancelProductDeletion = () => {
    closeModal()
  }

  const handleDelete = () => {
    deleteProduct({
      variables: {
        input: {
          id: product?.id
        }
      }
    }).then(({ data }) => {
      const { deleteProduct } = data;
      if (deleteProduct?.status === 200) {
        closeModal(true)
        setProduct(null)
        addToast({
          message: 'Product deleted',
          variant: 'success'
        })
      }
      if (deleteProduct?.errors?.length) {
        addToast({
          message: deleteProduct?.errors[0]?.message,
          variant: 'error'
        })
      }
    })
  }

  return isVisible ? (
    <div className='fixed top-0 left-0 flex items-center justify-center w-full h-screen bg-black/70 backdrop-blur-[2px] z-[999999]'>
      <div className='table-row'>
        <div className='table-cell align-middle'>
          <div className='w-full xl:border-none bg-white rounded-lg shadow-medium mx-auto py-12 px-9 max-w-[400px] xl:max-w-[500px]'>
            <div className='flex flex-col items-center space-y-6'>
              <Heading
                variant='h1'
                size='h8'
                weight='bold'
                color={COLORS.BLACK}
              >
                Delete Product
              </Heading>
              <Paragraph
                size='b5'
                weight='medium'
                className='w-full text-center max-w-[400px]'
                color={COLORS.GREY[400]}
              >
                Are you sure you want to delete {product?.name}? This means that
                this product will be permanently deleted.
              </Paragraph>

              <Button
                variant='danger'
                size='lg'
                rounded='lg'
                fontSize='b5'
                className='capitalize'
                onClick={() => handleDelete()}
                disabled={false}
                loading={isLoading}
              >
                Yes, I'm sure
              </Button>
              <Button
                variant='text'
                size='none'
                rounded='none'
                fontSize='b5'
                className='mx-0'
                onClick={() => cancelProductDeletion()}
                disabled={false}
              >
                No, go back
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null
}

const Packages = ({ openPage, closePage }: { openPage: boolean, closePage: () => void }) => {
  const { addToast, toast } = useToast()
  const { getSalonData } = useSalonCache()
  const salon = getSalonData()
  const PACKAGES_HEADINGS = [
    {
      label: 'Voucher name',
      value: 'name',
      show: true
    },
    {
      label: 'Description',
      value: 'description',
      show: true
    },
    {
      label: 'Services',
      value: 'services',
      show: true
    },
    {
      label: 'Valid for',
      value: 'validityMonths',
      show: true
    },
    {
      label: 'Sale price',
      value: 'price',
      show: true
    },
    {
      label: 'Status',
      value: 'status',
      show: true
    }
  ]
  const MOBILE_PACKAGES_HEADINGS = [
    {
      title: 'Package name',
      key: 'name'
    },
    {
      title: 'Description',
      key: 'description',
    },
    {
      title: 'Services',
      key: 'services',
    },
    {
      title: 'Valid for',
      key: 'validityMonths',
    },
    {
      title: 'Sale price',
      key: 'price'
    },
  ]
  const [loading, setLoading] = useState(false)
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState<string>('');
  const [selectedPackage, setSelectedPackage] = useState<Package | null>(null)
  const {
    data: packagesData,
    loading: packagesLoading,
    refetch: refetchPackages
  } = useSalonPackages(salon?.id, debouncedSearchQuery)
  const packages = useMemo(() => packagesData?.packages, [packagesData])
  const [packageHeadings, setPackageHeadings] =
    React.useState(PACKAGES_HEADINGS)

  const actions = {
    addPackage: canPerformAction(`Catalogue::Packages::${PERMISSION_CONSTANTS.voucher.add}`),
    editPackage: canPerformAction(`Catalogue::Packages::${PERMISSION_CONSTANTS.voucher.edit}`),
    viewPackages: canPerformAction(`Catalogue::Packages::${PERMISSION_CONSTANTS.voucher.view}`),
    deletePackage: canPerformAction(`Catalogue::Packages::${PERMISSION_CONSTANTS.voucher.delete}`)
  }
  const {
    isVisible: isAddPackageModalVisible,
    closeModal: closeAddPackageModal,
    openModal: openAddPackageModal
  } = useModal()

  const {
    isVisible: isViewPackageModalVisible,
    closeModal: closeViewPackageModal,
    openModal: openViewPackageModal
  } = useModal()

  const {
    isVisible: isDeletePackageModalVisible,
    closeModal: closeDeletePackageModal,
    openModal: openDeletePackageModal
  } = useModal()

  useEffect(() => {
    if (openPage) {
      openAddPackageModal()
    }
  }, [openPage])

  useEffect(() => {
    refetchPackages()
  }, [debouncedSearchQuery])

  const closePackageAddModal = () => {
    closeAddPackageModal()
    closePage();
    refetchPackages()
  }

  const initaiteCloseDeletePackageModal = () => {
    closeDeletePackageModal()
    setSelectedPackage(null)
    refetchPackages()
  }

  const handleHeadingCheckboxChange = (value: string) => {
    // search for the heading with that value in packageHeadings then update the show
    const updatedHeadings = packageHeadings.map((heading) => {
      if (heading.value === value) {
        return {
          ...heading,
          show: !heading.show
        }
      }
      return heading
    })
    setPackageHeadings(updatedHeadings)
  }

  const convertSalonServicesNamesToString = (services?: PackageService[]) => {
    const title = services?.map(({ name }) => name).join(', ') || ''
    return title
  }

  const generatePackagesTableData = (
    packages: Package[],
    tableHeadings: TableFilterProps
  ) => {
    return packages?.map?.((salonPackage) => {
      const rowData = {} // Using 'any' here, you can replace it with a more specific type
      tableHeadings.forEach((heading) => {
        if (heading.show) {
          switch (heading.value) {
            case 'name':
              rowData[heading.value] = salonPackage?.name
              break
            case 'services':
              rowData[heading.value] = limitString(
                convertSalonServicesNamesToString(salonPackage?.packageServices),
                20
              )
              break
            case 'description':
              rowData[heading.value] = limitString(salonPackage?.description, 12)
              break
            case 'validityMonths':
              rowData[heading.value] = `${salonPackage?.validityMonths} Month${salonPackage?.validityMonths > 1 ? 's' : ''
                }`
              break
            case 'price':
              rowData[heading.value] = salonPackage?.usesCustomPrice
                ? formatInToPrice(salonPackage?.customPrice)
                : formatInToPrice(salonPackage?.totalPrice)
              break
            case 'status':
              rowData[heading.value] = salonPackage?.status ? (
                <Pill
                  variant={
                    salonPackage.status === 'active'
                      ? 'success'
                      : salonPackage.status === 'expired'
                        ? 'danger'
                        : salonPackage.status === 'inactive'
                          ? 'light'
                          : 'light'
                  }
                >
                  {salonPackage?.status}
                </Pill>
              ) : (
                <Paragraph size='b5'> - </Paragraph>
              )
              break
          }
        }
      })
      return rowData
    })
  }

  const handleOpenViewPackageModal = (id: number, action?: string) => {
    const p = packages[id]
    setSelectedPackage(p)

    switch (action) {
      case 'view':
        openViewPackageModal()
        break
      case 'edit':
        openAddPackageModal()
        break
      case 'delete':
        openDeletePackageModal()
        break
      default:
        openViewPackageModal()
        break
    }
  }


  const loadPackagesShimmer = loading && !packages?.length && !debouncedSearchQuery;

  const getPackagesContent = () => {
    if (loadPackagesShimmer)
      return (
        <div className='flex flex-col xl:flex-row px-5 py-4'>
          <TableSkeleton />
        </div>
      )

    if (Array.isArray(packages) && packages.length || debouncedSearchQuery) {
      const rows = generatePackagesTableData(packages || [], packageHeadings)
      return (
        <>
          <div className='flex flex-col md:flex-row py-4 px-8 justify-between md:items-center gap-4 border-b border-grey-20'>
            <Heading variant='h2' size='h11' weight='bold'>
              Vouchers list
            </Heading>
            {actions?.addPackage ?
              <div className='flex md:items-center space-x-2'>
                <Menu as='div' className='relative inline-block text-left'>
                  <div>
                    <Menu.Button className='flex space-x-2 w-full items-center justify-center rounded-md bg-green-300 px-4 py-2 text-b5 font-medium text-white hover:bg-opacity-90 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-85'>
                      <span>Add</span>
                      <SvgSelectDropDown width='10px' height='10px' />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={React.Fragment}
                    enter='transition ease-out duration-100'
                    enterFrom='transform opacity-0 scale-95'
                    enterTo='transform opacity-100 scale-100'
                    leave='transition ease-in duration-75'
                    leaveFrom='transform opacity-100 scale-100'
                    leaveTo='transform opacity-0 scale-95'
                  >
                    <Menu.Items className='absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-[9999]'>
                      <div className='flex flex-col p-4 space-y-4'>
                        <Menu.Item>
                          <span
                            className='text-b4 cursor-pointer'
                            onClick={openAddPackageModal}
                          >
                            Create Voucher
                          </span>
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
              : null}
          </div>
          <div className='flex flex-col xl:flex-row py-4 px-8 space-x-4'>
            <div
              className='w-full xl:w-6/12 flex items-center space-x-4'
            >
              <SearchTerm
                placeholder='Search by name, category, description, price'
                setDebouncedSearchQuery={setDebouncedSearchQuery}
              />
              <Menu as='div' className='relative inline-block text-left'>
                <div>
                  <Menu.Button className='flex space-x-2 w-full items-center justify-center rounded-md bg-grey-50 border border-grey-20 px-4 py-2 text-b5 font-medium text-grey-300 hover:bg-opacity-90 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-85'>
                    <SvgFilter width='20px' height='20px' />
                    <span className='hidden xl:inline'>Filter</span>
                  </Menu.Button>
                </div>
                <Transition
                  as={React.Fragment}
                  enter='transition ease-out duration-100'
                  enterFrom='transform opacity-0 scale-95'
                  enterTo='transform opacity-100 scale-100'
                  leave='transition ease-in duration-75'
                  leaveFrom='transform opacity-100 scale-100'
                  leaveTo='transform opacity-0 scale-95'
                >
                  <Menu.Items className='absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-[9999]'>
                    <div className='flex flex-col p-4 space-y-4'>
                      {Array.isArray(packageHeadings) &&
                        packageHeadings.length &&
                        packageHeadings.map((heading, index) => (
                          <Label
                            className='flex space-x-2 items-center cursor-pointer'
                            key={index}
                            htmlFor={heading.value}
                            onClick={() =>
                              handleHeadingCheckboxChange(heading.value)
                            }
                          >
                            <Checkbox
                              isChecked={heading.show}
                              borderType='Black'
                              size='Sm'
                            />
                            <span>{heading.label}</span>
                          </Label>
                        ))}
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
          <FullTable
            headers={formatTableHeadersFilterArray(packageHeadings)}
            mobileHeaders={MOBILE_PACKAGES_HEADINGS}
            rows={rows}
            onClick={handleOpenViewPackageModal}
            tableOptions={{
              view: actions?.viewPackages,
              delete: actions?.deletePackage,
              edit: false,
              duplicate: false
            }}
          />
        </>
      )
    }

    return (
      <div className='w-full flex h-full justify-center items-center'>
        <div className='flex flex-col justify-center items-center space-y-6 w-full max-w-[450px] p-12'>
          <SvgBrandVoucher width="100%" height="100%" />
          <Heading variant='h2' size='h3'>
            Vouchers
          </Heading>
          <Paragraph size='b5' className='mt-4'>
            You don't have any vouchers yet.
          </Paragraph>
          <div className='w-full flex space-x-2'>
            <Button
              variant='primary'
              size='lg'
              disabled={false}
              loading={false}
              type='button'
              rounded='lg'
              onClick={openAddPackageModal}
            >
              Create Voucher
            </Button>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className='w-full mb-20'>
      <ToastWrapper toast={toast} />
      {getPackagesContent()}
      {isAddPackageModalVisible ? <AddPackageModal
        isVisible={isAddPackageModalVisible}
        closeModal={closePackageAddModal}
        package_={selectedPackage}
        setPackage={setSelectedPackage}
        actions={actions}
      /> : null}
      {isViewPackageModalVisible ? <ViewPackage
        isVisible={isViewPackageModalVisible}
        closeModal={closeViewPackageModal}
        package_={selectedPackage}
        setPackage={setSelectedPackage}
        actions={actions}
      /> : null}
      <DeleteSalonPackageModal
        isVisible={isDeletePackageModalVisible}
        closeModal={initaiteCloseDeletePackageModal}
        package_={selectedPackage}
        setPackage={setSelectedPackage}
      />
    </div>
  )
}

type AddPackageModalProps<T extends string> = {
  isVisible: boolean
  closeModal: () => void
  package_?: Package,
  setPackage?: (package_: Package | null) => void,
  actions: PermissionActionProp<T>
}

const ViewPackage = ({
  isVisible,
  closeModal,
  package_,
  setPackage,
  actions
}: AddPackageModalProps<'editPackage' | 'deletePackage' | 'addPackage'>) => {
  const { getSalonFieldValue } = useSalonCache()
  const { loading: saving, createPackage } = useCreatePackage()
  const [packageStatus, setPackageStatus] = useState<string | null>(package_?.status)
  const {
    openModal: openAddPackageModal,
    closeModal: closeAddPackageModal,
    isVisible: isAddPackageModalVisible
  } = useModal()
  const convertSalonServicesNamesToString = (services?: PackageService[]) => {
    const title = services?.map(({ name, quantity }) => `${name} x${quantity}`).join(', ') || ''
    return title
  }

  const {
    isVisible: isDeletePackageModalVisible,
    closeModal: closeDeletePackageModal,
    openModal: openDeletePackageModal
  } = useModal()

  const initiateCloseDeletePackageModal = (deleteSalonPackage?: boolean) => {
    if (deleteSalonPackage) {
      closeDeletePackageModal()
      _closeModal()
    } else {
      closeDeletePackageModal()
    }
  }

  const _closeModal = () => {
    closeModal()
    setPackage(null)
  }

  const convertSelectedServicesIntoServiceIdsAndQuantity = () => {
    return Array?.isArray(package_?.packageServices) && package_?.packageServices?.length && package_?.packageServices.map((service) => ({
      serviceId: service?.serviceId,
      quantity: service.quantity || 1
    }))
  }

  const updateSalonPackage = () => {
    const payload = {
      name: package_?.name,
      description: package_?.description,
      isPurchasableAsVoucher: package_?.isPurchasableAsVoucher,
      isDiscountApplicable: package_?.isDiscountApplicable ? package_?.isDiscountApplicable : false,
      id: package_ ? package_.id : null,
      validityMonths: Number(package_?.validityMonths),
      status: packageStatus === 'active' ? 'inactive' : 'active',
      serviceIdsAndQuantity: convertSelectedServicesIntoServiceIdsAndQuantity(),
      usesCustomPrice: package_?.customPrice ? true : false,
      customPrice: package_?.customPrice
        ? Number(package_?.customPrice.toString().replace(/,/g, ''))
        : null,
      salonId: getSalonFieldValue('id'),
      activateTax: false,
      allowSaleOn: package_?.allowSaleOn
    }

    createPackage({
      variables: {
        input: payload
      }
    }).then(({ data }) => {
      const { createPackage } = data;
      if (createPackage?.status === 200) {
        setPackageStatus(createPackage?.package?.status)
      }
    }).catch((error) => {
      // console.log('')
    })
  }

  return (
    <>
      <Modal show={isVisible} closeModal={_closeModal} variant='right'>
        <div className='w-full relative my-[80px]'>
          <div className='fixed top-0 w-full bg-white flex border-b border-grey-100 cursor-pointer z-10'>
            <div className='w-full hidden xl:flex space-x-2 px-6 py-5 items-start'>
              <Button
                variant='text'
                size='none'
                type='button'
                className='w-fit'
                fontSize='b4'
                onClick={_closeModal}
              >
                <SvgArrowBack width='24px' height='24px' /> <span>Back</span>
              </Button>
            </div>
            <div className='w-full flex xl:hidden space-x-[69px] p-4 items-center'>
              <Button
                variant='icon'
                size='square'
                type='button'
                rounded='md'
                className='w-fit'
                fontSize='b4'
                onClick={_closeModal}
              >
                <SvgChevronLeft width='24px' height='24px' />
              </Button>
              <Paragraph size='b3' weight='bold'>
                View voucher Details
              </Paragraph>
            </div>
          </div>
          <div className='w-full space-y-12 px-6 py-4'>
            <div className='w-full flex items-center justify-between'>
              <div className='flex flex-col space-y-6'>
                <Heading variant='h1' size='h9' weight='semiBold'>
                  Voucher details
                </Heading>
                <Paragraph size='b4'>
                  Find information about package here
                </Paragraph>
              </div>
              <div className="flex flex-col xl:flex-row gap-4">
                {actions?.deletePackage ?
                  <Button
                    variant='text'
                    type='button'
                    fontWeight='semiBold'
                    fontSize='b5'
                    className='text-red-500'
                    onClick={openDeletePackageModal}
                  >
                    Delete
                  </Button> : null}
                {actions?.editPackage ?
                  <Button
                    variant='secondary'
                    size='sm'
                    type='button'
                    rounded='md'
                    fontWeight='semiBold'
                    fontSize='b5'
                    loading={saving}
                    onClick={updateSalonPackage}
                  >
                    {packageStatus === 'active' ? 'Deactivate' : 'Activate'}
                  </Button> : null}
              </div>
            </div>
            <div className='w-full flex flex-col space-y-4'>
              <div className='w-full flex flex-col border border-grey-20 space-y-6 p-4 rounded-md'>
                <div className='grid grid-col-1 md:grid-cols-4 gap-4'>
                  <div className='flex justify-start items-start space-x-4'>
                    <div className='w-[20px] h-[20px] bg-grey-75'></div>
                    <div className='w-full flex flex-col'>
                      <Paragraph size='b4' color={COLORS.GREY[300]}>
                        Name
                      </Paragraph>
                      <Paragraph size='b4'>{package_?.name}</Paragraph>
                    </div>
                  </div>
                  <div className='col-span-2 flex justify-start items-start space-x-4'>
                    <div className='w-[20px] h-[20px] bg-grey-75'></div>
                    <div className='w-full flex flex-col'>
                      <Paragraph size='b4' color={COLORS.GREY[300]}>
                        Services
                      </Paragraph>
                      <Paragraph size='b4'>
                        {convertSalonServicesNamesToString(package_?.packageServices)}
                      </Paragraph>
                    </div>
                  </div>
                  <div className='flex justify-start items-start space-x-4'>
                    <div className='w-[20px] h-[20px] bg-grey-75'></div>
                    <div className='w-full flex flex-col'>
                      <Paragraph size='b4' color={COLORS.GREY[300]}>
                        Valid for
                      </Paragraph>
                      <Paragraph size='b4'>
                        {package_?.validityMonths} Month(s)
                      </Paragraph>
                    </div>
                  </div>
                </div>
                <div className='grid grid-col-1 md:grid-cols-4 gap-4 pt-4 border-t border-grey-50'>
                  <div className='flex justify-start items-start space-x-4'>
                    <div className='w-[20px] h-[20px] bg-grey-75'></div>
                    <div className='w-full flex flex-col'>
                      <Paragraph size='b4' color={COLORS.GREY[300]}>
                        Total price
                      </Paragraph>
                      <Paragraph size='b4'>
                        {package_?.usesCustomPrice ? formatInToPrice(package_?.customPrice) : formatInToPrice(package_?.totalPrice)}
                      </Paragraph>
                    </div>
                  </div>
                  <div className='flex justify-start items-start space-x-4'>
                    <div className='w-[20px] h-[20px] bg-grey-75'></div>
                    <div className='w-full flex flex-col'>
                      <Paragraph size='b4' color={COLORS.GREY[300]}>
                        Total quantity
                      </Paragraph>
                      <Paragraph size='b4'>
                        {package_?.services?.length}
                      </Paragraph>
                    </div>
                  </div>
                  <div className='flex justify-start items-start space-x-4'>
                    <div className='w-[20px] h-[20px] bg-grey-75'></div>
                    <div className='w-full flex flex-col'>
                      <Paragraph size='b4' color={COLORS.GREY[300]}>
                        Status
                      </Paragraph>
                      <Paragraph size='b4' className='capitalize'>
                        {packageStatus || package_?.status}
                      </Paragraph>
                    </div>
                  </div>
                </div>
              </div>

              <div className='w-full flex flex-col border border-grey-20 space-y-6 p-4 rounded-md'>
                <Paragraph size='b4' color={COLORS.GREY[300]}>
                  Permissions
                </Paragraph>
                <div className='flex gap-4'>
                  {package_?.isPurchasableAsVoucher ?
                    <Paragraph
                      size='b4'
                      color={COLORS.GREY[900]}
                      className='flex space-x-4 items-center'
                    >
                      {package_?.allowSaleOn === 'salon' ? <div className='w-[20px] h-[20px] bg-grey-75'></div> : <SvgPackageCheckGrey width='16px' height='16px' />}{' '}
                      <span>Online sale as vouchers</span>
                    </Paragraph> : null}
                  <Paragraph
                    size='b4'
                    color={COLORS.GREY[900]}
                    className='flex space-x-4 items-center'
                  >
                    {package_?.isDiscountApplicable ? <SvgPackageCheckGrey width='16px' height='16px' /> : <div className='w-[20px] h-[20px] bg-grey-75'></div>}{' '}
                    <span>Promos/discounts applicable</span>
                  </Paragraph>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <DeleteSalonPackageModal
        isVisible={isDeletePackageModalVisible}
        closeModal={initiateCloseDeletePackageModal}
        package_={package_}
        setPackage={setPackage}
      />
    </>
  )
}

type DeleteAddPackageModalProps = {
  isVisible: boolean
  closeModal: (deletePackage?: boolean) => void
  package_?: Package,
  setPackage?: (package_: Package | null) => void
}
const DeleteSalonPackageModal = ({
  package_,
  isVisible,
  closeModal,
  setPackage
}: DeleteAddPackageModalProps) => {
  const {
    loading,
    deletePackage
  } = useDeletePackage()
  const cancelPackage = () => {
    closeModal();
  }
  const { addToast, toast } = useToast()
  const handleDelete = async () => {
    deletePackage({
      variables: {
        input: { packageId: package_.id }
      }
    }).then(({ data }) => {
      const { deletePackage } = data
      if (deletePackage?.status === 200) {
        addToast({
          message: 'Package deleted successfully',
          variant: 'success'
        })
      }

      if (deletePackage?.errors?.length) {
        addToast({
          message: deletePackage?.errors[0]?.message,
          variant: 'error'
        })
      }
    })
    closeModal(true)
    setPackage(null);
  }

  return isVisible ? (
    <div className='fixed top-0 left-0 flex items-center justify-center w-full h-screen bg-black/70 backdrop-blur-[2px] z-[999999]'>
      <ToastWrapper toast={toast} />
      <div className='table-row'>
        <div className='table-cell align-middle'>
          <div className='w-full xl:border-none bg-white rounded-lg shadow-medium mx-auto py-12 px-9 max-w-[400px] xl:max-w-[500px]'>
            <div className='flex flex-col items-center space-y-6'>
              <Heading
                variant='h1'
                size='h8'
                weight='bold'
                color={COLORS.BLACK}
              >
                Delete Package
              </Heading>
              <Paragraph
                size='b5'
                weight='medium'
                className='w-full text-center max-w-[400px]'
                color={COLORS.GREY[400]}
              >
                Are you sure you want to delete {package_?.name}? This means
                that this package will be permanently deleted.
              </Paragraph>

              <Button
                variant='danger'
                size='lg'
                rounded='lg'
                fontSize='b5'
                className='capitalize'
                onClick={handleDelete}
                disabled={false}
                loading={false}
              >
                Yes, I'm sure
              </Button>
              <Button
                variant='text'
                size='none'
                rounded='none'
                fontSize='b5'
                className='mx-0'
                onClick={cancelPackage}
                disabled={false}
              >
                No, go back
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null
}

type FilteredCategory = Category & {
  show: boolean
}
const Services = ({ openPage, closePage }: { openPage: boolean, closePage: () => void }) => {
  const { addToast, toast } = useToast()
  const [isLoading, setIsLoading] = useState(false)
  const [service, setService] = useState<Service>()
  const { getSalonData } = useSalonCache()
  const salon = getSalonData()
  const salonId = salon?.id;
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState<string>('');
  const [serviceHeadings, setServiceHeadings] = useState(SERVICES_HEADINGS)
  const archiveServiceHeadings = [...SERVICES_HEADINGS, {
    label: 'cta',
    value: 'cta',
    show: true
  }]
  const [category, setCategory] = useState<Category | null>(null)
  const [filteredCategory, setFilteredCategory] = useState<FilteredCategory[]>([])
  const {
    data: servicesData,
    refetch,
  } = useGetServices(salonId, debouncedSearchQuery)
  const services = useMemo(() => sortServices(servicesData?.services), [servicesData])

  const {
    data: categoriesData,
    refetch: refetchCategories,
  } = useGetCategories()
  const categories = useMemo(() => {
    const categoryList = sortCategories(filterServicesBySalonId(categoriesData?.categories, salonId))
    const filteredList = categoryList?.length ? categoryList?.map((category) => {
      return {
        ...category,
        show: true,
      }
    }) : [];
    setFilteredCategory(filteredList)
    return categoryList;
  }, [categoriesData])

  const openCategoryDrawer = (id: string) => {
    const newFilteredCategory = filteredCategory?.map((category) => {
      if (category?.id === id) {
        return {
          ...category,
          show: !category?.show
        }
      }
      return category
    })
    setFilteredCategory(newFilteredCategory)
  }

  const {
    data: archivedServicesData,
    refetch: refetchArchivedServices,
  } = useGetArchivedServices(salonId, debouncedSearchQuery)
  const archivedServices = useMemo(() => sortServices(archivedServicesData?.archivedServices), [archivedServicesData])

  useEffect(() => {
    if (salonId) {
      refetch()
      refetchArchivedServices()
      refetchCategories()
    }
  }, [salonId, refetch])

  const actions = {
    addService: canPerformAction(`Catalogue::Service::${PERMISSION_CONSTANTS.service?.add}`),
    editService: canPerformAction(`Catalogue::Service::${PERMISSION_CONSTANTS.service?.edit}`),
    deleteService: canPerformAction(`Catalogue::Service::${PERMISSION_CONSTANTS.service.delete}`),
    viewService: canPerformAction(`Catalogue::Service::${PERMISSION_CONSTANTS.service.view}`),
  }
  const MOBILE_SERVICES_HEADINGS = [
    {
      title: 'Service Name',
      key: 'name'
    }, {
      title: 'Category',
      key: 'category'
    }, {
      title: 'Description',
      key: 'description'
    }, {
      title: 'Duration',
      key: 'duration'
    },
    {
      title: 'Price',
      key: 'price'
    },
    {
      title: 'Custom Price',
      key: 'customPrice'
    }
  ]

  const {
    isVisible: isDeleteServiceDialogModalVisible,
    openModal: openDeleteServiceDialogModal,
    closeModal: closeDeleteServiceDialogModal
  } = useModal()
  const {
    isVisible: isDeleteCategoryDialogModalVisible,
    openModal: openDeleteCategoryDialogModal,
    closeModal: closeDeleteCategoryDialogModal
  } = useModal()
  const {
    isVisible: isViewServiceCategoryModalVisible,
    openModal: openViewServiceCategoryModal,
    closeModal: closeViewServiceCategoryModal
  } = useModal()
  const {
    isVisible: isArchiveServiceDialogModalVisible,
    openModal: openArchiveServiceDialogModal,
    closeModal: closeArchiveServiceDialogModal
  } = useModal()
  const {
    isVisible: isRestoreServiceDialogModalVisible,
    openModal: openRestoreServiceDialogModal,
    closeModal: closeRestoreServiceDialogModal
  } = useModal()
  const {
    isVisible: isServiceModalVisible,
    openModal: openAddServiceModal,
    closeModal: closeAddServiceModal
  } = useModal()
  const {
    isVisible: isServiceCategoryModalVisible,
    openModal: openAddServiceCategoryModal,
    closeModal: closeAddServiceCategoryModal
  } = useModal()
  const {
    isVisible: isViewServiceModalVisible,
    openModal: openViewServiceModal,
    closeModal: closeViewServiceModal
  } = useModal()
  const {
    isVisible: isImportServiceModalVisible,
    openModal: openImportServiceModal,
    closeModal: closeImportServiceModal
  } = useModal()
  const {
    isVisible: isServicesSettingsModalVisible,
    openModal: openServiceSettingsModal,
    closeModal: closeServiceSettingsModal,
  } = useModal()

  const initiateCloseImportServiceModal = () => {
    closeImportServiceModal()
    refetch()
  }

  const handleCloseAddServiceModal = () => {
    setService(null)
    closeAddServiceModal()
    closePage()
    refetch()
    refetchCategories()
  }

  const handleCloseAddServiceCategoryModal = () => {
    setCategory(null)
    closeAddServiceCategoryModal()
    refetch()
    refetchCategories()
  }

  const handleCloseViewServiceModal = () => {
    setService(null)
    closeViewServiceModal()
    refetchCategories()
  }

  const handleCloseViewCategoryModal = () => {
    setCategory(null)
    closeViewServiceCategoryModal()
    refetch()
    refetchCategories()
  }

  const handleDeleteService = () => {
    setService(null)
    closeDeleteServiceDialogModal()
    refetch()
    refetchArchivedServices()
  }

  const handleDeleteCategory = () => {
    setCategory(null)
    closeDeleteCategoryDialogModal()
    refetch()
    refetchCategories()
  }

  const handleArchiveService = () => {
    refetch()
    setService(null)
    closeArchiveServiceDialogModal()
    refetchArchivedServices()
  }

  const handleRestoreService = () => {
    setService(null)
    closeRestoreServiceDialogModal()
    refetch()
    refetchArchivedServices()
  }

  useEffect(() => {
    if (openPage) {
      openAddServiceModal()
    }
  }, [openPage])

  const getHighestPriceStaff = (service: Service) => {
    return service?.serviceStaffs?.reduce((highest, current) => {
      // Skip if current staff has no price
      if (current.price === null) return highest;

      // If no highest yet, or current price is higher
      if (!highest || (highest.price || 0) < current.price && current?.price !== service?.price) {
        return current;
      }

      return highest;
    }, null as ServiceStaff | null);
  };

  const getHighestServiceCustomPrice = (service: Service) => {
    const staff = getHighestPriceStaff(service)

    if (staff && staff?.price !== service?.price) {
      return formatInToPrice(staff?.price)
    }

    return null;
  }

  const generateServicesTableData = (
    services: Service[],
    tableHeadings: TableFilterProps
  ) => {
    return services?.map?.((service) => {
      const rowData = {}
      tableHeadings.forEach((heading) => {
        if (heading.show) {
          switch (heading.value) {
            case 'name':
              rowData[heading.value] = service?.name
              break
            case 'category':
              rowData[heading.value] = service?.category?.name
              break
            case 'description':
              rowData[heading.value] = limitString(service?.description, 10)
              break
            case 'duration':
              rowData[heading.value] = `${service?.duration} mins`
              break
            case 'price':
              rowData[heading.value] = formatInToPrice(service?.price)
              break
            case 'customPrice':
              rowData[heading.value] = getHighestServiceCustomPrice(service)
              break
          }
        }
      })
      return rowData
    })
  }

  const generateArchiveServicesTableData = (
    services: Service[],
    tableHeadings: TableFilterProps
  ) => {
    return services?.map?.((service) => {
      const rowData = {}
      tableHeadings.forEach((heading) => {
        if (heading.show) {
          switch (heading.value) {
            case 'name':
              rowData[heading.value] = service?.name
              break
            case 'category':
              rowData[heading.value] = service?.category?.name
              break
            case 'description':
              rowData[heading.value] = limitString(service?.description, 10)
              break
            case 'duration':
              rowData[heading.value] = `${service?.duration} mins`
              break
            case 'price':
              rowData[heading.value] = formatInToPrice(service?.price)
              break
            case 'customPrice':
              rowData[heading.value] = getHighestServiceCustomPrice(service)
              break
            case 'cta':
              rowData[heading.value] = (
                <div className='flex space-x-4'>
                  <Button
                    variant='light'
                    size='xs'
                    rounded='lg'
                    fontSize='b5'
                    fontWeight='semiBold'
                    className='px-5 py-2'
                  >
                    Restore
                  </Button>
                </div>
              )
              break
          }
        }
      })
      return rowData
    })
  }

  const handleHeadingCheckboxChange = (value: string) => {
    const updatedHeadings = serviceHeadings.map((heading) => {
      if (heading.value === value) {
        return {
          ...heading,
          show: !heading.show
        }
      }
      return heading
    })
    setServiceHeadings(updatedHeadings)
  }

  const SERVICE_TAB_NAME = {
    CATEGORIES: "Services",
    ARCHIVES: "Archived Services",
  };

  const SERVICE_TABS = [
    {
      key: SERVICE_TAB_NAME.CATEGORIES,
      title: SERVICE_TAB_NAME.CATEGORIES,
      show: true
    },
    {
      key: SERVICE_TAB_NAME.ARCHIVES,
      title: SERVICE_TAB_NAME.ARCHIVES,
      show: true
    }
  ]

  const openService = (id: string, action?: string) => {
    if (!Array?.isArray(services)) return null;
    const service = services?.find((service) => service?.id === id);
    if (!action && !actions?.editService && !actions?.viewService) return;
    setService(service)
    switch (action) {
      case 'archive':
        openArchiveServiceDialogModal()
        break
      case 'delete':
        openDeleteServiceDialogModal()
        break
      case 'edit':
        openAddServiceModal()
        break
      default:
        openViewServiceModal()
    }
  }

  const openCategory = (id: string) => {
    // get id
    const category = categories?.find((category) => category.id === id)
    if (!category) return;
    setCategory(category)
    openViewServiceCategoryModal()
  }

  const editCategory = (id: string) => {
    // get id
    const category = categories?.find((category) => category.id === id)
    if (!category) return;
    setCategory(category)
    openAddServiceCategoryModal()
  }

  const deleteCategory = (id: string) => {
    // get id
    const category = categories?.find((category) => category.id === id)
    if (!category) return;
    setCategory(category)
    openDeleteCategoryDialogModal()
  }

  const restoreService = (id: number) => {
    const service = archivedServices[id]
    if (!service) return;
    if (!actions?.editService) return;
    setService(service)
    openRestoreServiceDialogModal()
  }
  const [serviceActiveTab, setServiceActiveTab] = useState<string>(SERVICE_TAB_NAME.CATEGORIES);

  const loadServicesShimmer = isLoading && !services?.length && !debouncedSearchQuery;

  useEffect(() => {
    if (serviceActiveTab === SERVICE_TAB_NAME.CATEGORIES) {
      if (debouncedSearchQuery === '' || !debouncedSearchQuery) {
        const categoryList = categories?.map((category) => {
          return {
            ...category,
            show: true,
          }
        })
        setFilteredCategory(categoryList)
        return;
      }
      if (debouncedSearchQuery && debouncedSearchQuery !== '') {
        // search for services in each categories that matches name, description or price
        const categoryListSearch = categories?.map((category) => {
          const services = category?.services?.filter((service: Service) => {
            return service?.name?.toLowerCase()?.includes(debouncedSearchQuery?.toLowerCase()) ||
              service?.description?.toLowerCase()?.includes(debouncedSearchQuery?.toLowerCase()) ||
              service?.price?.toString()?.includes(debouncedSearchQuery?.toLowerCase())
          })
          return {
            ...category,
            services,
            show: true,
          }
        }).filter((category) => category?.services?.length)
        setFilteredCategory(categoryListSearch)
        return;
      }
    } else {
      refetch()
      refetchArchivedServices()
    }
  }, [debouncedSearchQuery])

  useEffect(() => {
    refetch()
    refetchCategories()
    refetchArchivedServices()
  }, [serviceActiveTab])

  const getServicesContent = () => {
    if (loadServicesShimmer) {
      return (
        <div className='flex flex-col xl:flex-row px-5 py-4'>
          <TableSkeleton />
        </div>
      )
    }
    if (Array.isArray(services) && services.length || debouncedSearchQuery) {
      const tableServicesData = generateServicesTableData(
        services,
        serviceHeadings
      )

      const archiveServicesData = generateArchiveServicesTableData(
        archivedServices,
        archiveServiceHeadings
      )

      return (
        <>
          <div className="w-full flex p-4 border-y border-grey-100">
            <div className='w-full grid grid-cols-2 gap-3'>
              <div className='w-full flex justify-start gap-3 border border-grey-100 bg-grey-50/20 p-4 rounded-md'>
                <span className='w-[32px] h-[32px] flex justify-center items-center rounded-sm bg-grey-50'>
                  <SvgCartBag width="18px" height="18px" />
                </span>

                <div className='w-full flex flex-col'>
                  <Paragraph size='b5' color={COLORS.GREY[300]} weight='bold' className=''>
                    Total services
                  </Paragraph>
                  <Paragraph size='b5' color={COLORS.GREY[400]} className=''>
                    Total number of services
                  </Paragraph>
                  <Paragraph size='b1' weight='semiBold' className=''>
                    {services?.length || 0}
                  </Paragraph>
                </div>
              </div>
              {/* <div className='w-full flex justify-start gap-3 border border-grey-100 bg-grey-50/20 p-4 rounded-md'>
                <span className='w-[32px] h-[32px] flex justify-center items-center rounded-sm bg-grey-50'>
                  <SvgCartBag width="18px" height="18px" />
                </span>

                <div className='w-full flex flex-col'>
                  <Paragraph size='b5' color={COLORS.GREY[300]} weight='bold' className=''>
                    Total categories
                  </Paragraph>
                  <Paragraph size='b5' color={COLORS.GREY[400]} className=''>
                    Total number of categories
                  </Paragraph>
                  <Paragraph size='b1' weight='semiBold' className=''>
                    {categories?.length || 0}
                  </Paragraph>
                </div>
              </div> */}
              <div className='w-full flex justify-start gap-3 border border-grey-100 bg-grey-50/20 p-4 rounded-md'>
                <span className='w-[32px] h-[32px] flex justify-center items-center rounded-sm bg-grey-50'>
                  <SvgCartBag width="18px" height="18px" />
                </span>

                <div className='w-full flex flex-col'>
                  <Paragraph size='b5' color={COLORS.GREY[300]} weight='bold' className=''>
                    Archived services
                  </Paragraph>
                  <Paragraph size='b5' color={COLORS.GREY[400]} className=''>
                    Total archived services
                  </Paragraph>
                  <Paragraph size='b1' weight='semiBold' className=''>
                    {archivedServices?.length || 0}
                  </Paragraph>
                </div>
              </div>
            </div>
          </div>
          <div className='flex flex-col md:flex-row py-4 px-8 justify-between md:items-center gap-4 border-b border-grey-20'>
            <Heading variant='h2' size='h11' weight='bold'>
              Service list
            </Heading>
            {actions?.addService ?
              <div className='flex md:items-center space-x-2'>
                <Button
                  variant="light"
                  size='sm'
                  rounded='md'
                  fontSize='b5'
                  fontWeight='semiBold'
                  onClick={openServiceSettingsModal}
                >
                  Settings
                </Button>
                <Menu as='div' className='relative inline-block text-left'>
                  <div>
                    <Menu.Button className='flex space-x-2 w-full items-center justify-center rounded-md bg-green-300 px-4 py-2 text-b5 font-medium text-white hover:bg-opacity-90 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-85'>
                      <span>Add</span>
                      <SvgSelectDropDown width='10px' height='10px' />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={React.Fragment}
                    enter='transition ease-out duration-100'
                    enterFrom='transform opacity-0 scale-95'
                    enterTo='transform opacity-100 scale-100'
                    leave='transition ease-in duration-75'
                    leaveFrom='transform opacity-100 scale-100'
                    leaveTo='transform opacity-0 scale-95'
                  >
                    <Menu.Items className='absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-[9999]'>
                      <div className='flex flex-col p-4 space-y-4'>
                        {actions?.addService ?
                          <Menu.Item>
                            <span
                              className='text-b4 cursor-pointer'
                              onClick={openAddServiceModal}
                            >
                              Add Service
                            </span>
                          </Menu.Item>
                          : null}
                        {actions?.addService ?
                          <Menu.Item>
                            <span
                              className='text-b4 cursor-pointer'
                              onClick={openAddServiceCategoryModal}
                            >
                              New category
                            </span>
                          </Menu.Item>
                          : null}
                        <Menu.Item>
                          <span
                            className='text-b4 cursor-pointer'
                            onClick={openImportServiceModal}
                          >
                            Import Services
                          </span>
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div> : null}
          </div>
          <div className='flex flex-col xl:flex-row py-4 px-8 space-x-4 border-b border-grey-100'>
            <div
              className='w-full xl:w-1/2 flex items-center space-x-4'
            >
              <SearchTerm
                placeholder='Search by name, category, description, price'
                setDebouncedSearchQuery={setDebouncedSearchQuery}
              />
              <Menu as='div' className='relative inline-block text-left'>
                <div>
                  <Menu.Button className='flex space-x-2 w-full items-center justify-center rounded-md bg-grey-50 border border-grey-20 px-4 py-2 text-b5 font-medium text-grey-300 hover:bg-opacity-90 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-85'>
                    <SvgFilter width='20px' height='20px' />
                    <span className='hidden xl:inline'>Filter</span>
                  </Menu.Button>
                </div>
                <Transition
                  as={React.Fragment}
                  enter='transition ease-out duration-100'
                  enterFrom='transform opacity-0 scale-95'
                  enterTo='transform opacity-100 scale-100'
                  leave='transition ease-in duration-75'
                  leaveFrom='transform opacity-100 scale-100'
                  leaveTo='transform opacity-0 scale-95'
                >
                  <Menu.Items className='absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-[9999]'>
                    <div className='flex flex-col p-4 space-y-4'>
                      {Array.isArray(serviceHeadings) &&
                        serviceHeadings.length &&
                        serviceHeadings.map((heading, index) => (
                          <Label
                            className='flex space-x-2 items-center cursor-pointer'
                            key={index}
                            htmlFor={heading.value}
                            onClick={() =>
                              handleHeadingCheckboxChange(heading.value)
                            }
                          >
                            <Checkbox
                              isChecked={heading.show}
                              borderType='Black'
                              size='Sm'
                            />
                            <span>{heading.label}</span>
                          </Label>
                        ))}
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
          <div className="w-full border-b border-grey-100">
            <Tabs
              tabs={SERVICE_TABS}
              activeTab={serviceActiveTab}
              setActiveTab={setServiceActiveTab}
              variant='background'
            />
          </div>
          {serviceActiveTab === SERVICE_TAB_NAME.ARCHIVES ?
            <FullTable
              headers={formatTableHeadersFilterArray(serviceHeadings)}
              rows={archiveServicesData}
              onClick={restoreService}
              mobileHeaders={MOBILE_SERVICES_HEADINGS}
            /> :
            <div className='w-full flex p-6 gap-6'>
              <div className='w-full h-fit flex flex-col border max-w-[400px] px-4 border-green-300/10 bg-green-300/5'>
                <div className='w-full flex items-center space-x-3 py-4 border-b border-grey-100'>
                  <SvgCategory width="24px" height="24px" />
                  <Paragraph size='b4' color={COLORS.GREEN[300]} weight='bold' className=''>
                    Categories
                  </Paragraph>
                </div>
                <div className="w-full flex flex-col py-6 gap-3">
                  {Array?.isArray(categories) && categories?.length ? categories?.map((category) => {
                    return (
                      <div className='w-full flex items-center justify-between p-2 border border-green-300/5 hover:bg-green-300/5 hover:border-green-300/10 rounded-sm' key={category?.id}>
                        <Heading variant='h3' size='b5' weight='bold' className='capitalize' color={COLORS.GREEN[300]}>
                          {category?.name}
                        </Heading>
                        <Paragraph size='b5' color={COLORS.GREY[300]}>
                          {category?.services?.length} services
                        </Paragraph>
                      </div>
                    )
                  }) : null}
                </div>
              </div>

              <div className='flex w-full flex-col gap-6 max-w-[750px]'>
                {Array?.isArray(filteredCategory) && filteredCategory?.length ? filteredCategory?.map((category) => {
                  return (
                    <div className="w-full flex flex-col" key={category?.name}>
                      <div className="w-full py-2 px-4 flex justify-between items-center cursor-pointer">
                        <div className="flex space-y-2 flex-col">
                          <Heading variant='h3' size='b4' weight='bold' className='capitalize' color={COLORS.GREY[900]}>
                            {category?.name} ({category?.services?.length})
                          </Heading>
                          {category?.salonStaffs?.length ?
                            <Paragraph size='b5' color={COLORS.GREY[300]} weight='semiBold'>
                              Staff: {category?.salonStaffs?.map((staff) => staff?.user?.firstName).join(', ')}
                            </Paragraph> :
                            <Paragraph size='b5' color={COLORS.GREY[300]} weight='semiBold'>
                              Staff: None
                            </Paragraph>
                          }
                        </div>
                        <SzhMenu
                          menuButton={
                            <MenuButton className='bg-[#F7F8F780] border border-[#F6F6F6] flex items-center justify-center w-[28px] h-[28px] text-grey-300 rounded-md'>
                              <SvgCharmMenuKebab width='14px' height='14px' />
                            </MenuButton>
                          }
                          key='top'
                          direction='top'
                          transition
                        >
                          <MenuItem>
                            <span
                              className='text-b4 cursor-pointer'
                              onClick={() => {
                                setCategory(category)
                                openAddServiceModal()
                              }}
                            >
                              Add Service
                            </span>
                          </MenuItem>
                          <MenuItem>
                            <span
                              className='text-b4 cursor-pointer'
                              onClick={() => openCategory(category?.id)}
                            >
                              View Category
                            </span>
                          </MenuItem>
                          <MenuItem>
                            <span
                              className='text-b4 cursor-pointer'
                              onClick={() => editCategory(category?.id)}
                            >
                              Edit Category
                            </span>
                          </MenuItem>
                          <MenuItem>
                            <span
                              className='text-b4 cursor-pointer'
                              onClick={() => deleteCategory(category?.id)}
                            >
                              Delete Category
                            </span>
                          </MenuItem>
                        </SzhMenu>
                      </div>
                      {category?.show ?
                        <div className="w-full flex flex-col space-y-4 p-6 border border-[#F3F6F6]">
                          {category?.services?.length ? category?.services?.map((service) => {
                            return (
                              <div className="w-full bg-grey-20/40 border-grey-100/30 rounded-sm border border-l-4 py-3 px-4 flex justify-between items-center cursor-pointer">
                                <div className='flex flex-col space-y-1' role="button" onClick={() => openService(service?.id, 'view')}>
                                  <Paragraph size="b5" color={COLORS.GREY[300]}>
                                    {service?.name}
                                  </Paragraph>
                                  <Paragraph size="b6" color={COLORS.GREY[300]}>
                                    {getHoursAndMinutesString(service?.duration)}
                                  </Paragraph>
                                </div>

                                <div className='flex items-center space-x-3'>
                                  <Paragraph size="b5" color={COLORS.GREY[900]} weight='bold'>
                                    {formatInToPrice(service?.price)}
                                  </Paragraph>
                                  <SzhMenu
                                    menuButton={
                                      <MenuButton className='flex items-center justify-center w-[28px] h-[28px] rounded-full text-grey-300 bg-[#F7F8F7]'>
                                        <SvgCharmMenuKebab width='14px' height='14px' />
                                      </MenuButton>
                                    }
                                    key='top'
                                    direction='top'
                                    transition
                                  >
                                    <MenuItem>
                                      <span className="text-b4 cursor-pointer flex items-center space-x-2" onClick={() => openService(service?.id, 'view')}>
                                        {/* Your menu item content */}
                                        <SvgCarbonView width="24px" height="24px" />
                                        <span>View Service</span>
                                      </span>
                                    </MenuItem>
                                    <MenuItem>
                                      <span className="text-b4 cursor-pointer flex items-center space-x-2" onClick={() => openService(service?.id, 'edit')}>
                                        {/* Your menu item content */}
                                        <SvgEditRegular width="24px" height="24px" />
                                        <span>Edit Service</span>
                                      </span>
                                    </MenuItem>
                                    <MenuItem>
                                      <span className="text-b4 cursor-pointer flex items-center space-x-2" onClick={() => openService(service?.id, 'archive')}>
                                        {/* Your menu item content */}
                                        <SvgArchive width="24px" height="24px" />
                                        <span>Archive Service</span>
                                      </span>
                                    </MenuItem>
                                    <MenuItem>
                                      <span className="text-b4 cursor-pointer flex items-center space-x-2" onClick={() => openService(service?.id, 'delete')}>
                                        {/* Your menu item content */}
                                        <SvgDeleteRegular width="24px" height="24px" />
                                        <span>Delete Service</span>
                                      </span>
                                    </MenuItem>
                                  </SzhMenu>
                                </div>
                              </div>
                            )
                          }) :
                            <div className="w-full bg-grey-20/40 border-grey-100/30 rounded-sm border border-l-4 py-3 px-4 flex justify-between items-center cursor-pointer">
                              <div className='flex flex-col space-y-1'>
                                <Paragraph size="b5" color={COLORS.GREY[300]}>
                                  No services added
                                </Paragraph>
                              </div>

                              <Button
                                variant="text"
                                size="xs"
                                rounded="lg"
                                fontWeight='semiBold'
                                type="button"
                                className="text-grey-300"
                                onClick={() => {
                                  editCategory(category?.id);
                                }}
                              >
                                <SvgPlus width="14px" height="14px" />
                                Add services
                              </Button>
                            </div>}
                        </div> : null}
                    </div>
                  )
                }) : null}
              </div>
            </div>}
        </>
      )
    }

    return (
      <div className='w-full flex h-full justify-center items-center'>
        <div
          style={{ maxWidth: '450px' }}
          className='flex flex-col justify-center items-center space-y-6 w-full p-12'
        >
          <SvgProductEmpty width="100%" height="100%" />
          <Heading variant='h2' size='h3'>
            Services
          </Heading>
          <Paragraph size='b5' className='mt-4'>
            You don't have any services yet
          </Paragraph>
          <div className='w-full flex space-x-2'>
            <ImportServices addToast={addToast} />
            {actions?.addService ?
              <Button
                variant='primary'
                size='lg'
                disabled={false}
                loading={false}
                type='button'
                rounded='lg'
                onClick={openAddServiceModal}
              >
                Add Service
              </Button>
              : null}
          </div>
          <ImportFileLink modules='service' />
        </div>
      </div>
    )
  }

  return (
    <div className='w-full mb-20'>
      <ToastWrapper toast={toast} />
      {getServicesContent()}
      <DeleteServiceDialog
        isVisible={isDeleteServiceDialogModalVisible}
        closeModal={handleDeleteService}
        service={service}
        addToast={addToast}
      />
      <DeleteCategoryDialog
        isVisible={isDeleteCategoryDialogModalVisible}
        closeModal={handleDeleteCategory}
        category={category}
        addToast={addToast}
      />
      <ArchiveServiceDialog
        isVisible={isArchiveServiceDialogModalVisible}
        closeModal={handleArchiveService}
        service={service}
        addToast={addToast}
      />
      <RestoreServiceDialog
        isVisible={isRestoreServiceDialogModalVisible}
        closeModal={handleRestoreService}
        service={service}
        addToast={addToast}
      />
      <AddServiceModal
        service={service}
        isVisible={isServiceModalVisible}
        closeModal={handleCloseAddServiceModal}
        actions={actions}
        addToast={addToast}
        category={category}
      />
      <AddServiceCategoryModal
        isVisible={isServiceCategoryModalVisible}
        closeModal={handleCloseAddServiceCategoryModal}
        addToast={addToast}
        category={category}
      />
      <ViewServiceCategoryModal
        isVisible={isViewServiceCategoryModalVisible}
        closeModal={handleCloseViewCategoryModal}
        category={category}
        addToast={addToast}
      />
      <ViewServiceModal
        service={service}
        isVisible={isViewServiceModalVisible}
        closeModal={handleCloseViewServiceModal}
      />
      <ImportServiceModal
        isVisible={isImportServiceModalVisible}
        closeModal={initiateCloseImportServiceModal}
        addToast={addToast}
      />
      <ServicesSettings
        isVisible={isServicesSettingsModalVisible}
        closeModal={closeServiceSettingsModal}
        addToast={addToast}
        categoryServices={servicesData?.services}
      />
    </div>
  )
}

const SERVICE_SETTINGS_TAB_NAME = {
  ONLINE_SERVICE_DISPLAY: 'Online service display',
  FEATURED_SERVICES: 'Featured services'
};

const SERVICE_SETTINGS_TABS = [
  {
    title: SERVICE_SETTINGS_TAB_NAME.ONLINE_SERVICE_DISPLAY,
    key: SERVICE_SETTINGS_TAB_NAME.ONLINE_SERVICE_DISPLAY,
    show: true
  },
  {
    title: SERVICE_SETTINGS_TAB_NAME.FEATURED_SERVICES,
    key: SERVICE_SETTINGS_TAB_NAME.FEATURED_SERVICES,
    show: true
  }
];
const ServicesSettings = ({
  isVisible,
  closeModal,
  addToast,
  categoryServices,
}: {
  isVisible: boolean,
  closeModal: () => void,
  addToast: (toast: ToastProps) => void,
  categoryServices: Service[]
}) => {
  const [showServiceSelection, setShowServiceSelection] = useState(false)
  const [selectedServices, setSelectedServices] = useState<Service[]>()
  const [filteredServices, setFilteredServices] = useState<Service[]>([]);
  const { getSalonData } = useSalonCache()
  const salon = getSalonData()
  const salonId = salon?.id;
  const [settingsActiveTab, setSettingsActiveTab] = useState<string>(SERVICE_SETTINGS_TAB_NAME.ONLINE_SERVICE_DISPLAY)
  const {
    data: servicesData,
    refetch,
  } = useGetServices(salonId)
  const services = useMemo(() => {
    setFilteredServices(sortServices(servicesData?.services?.filter((service) => service?.isPublic)))
    // add services featured to selected services
    if (servicesData?.services) {
      const featuredServices = servicesData?.services?.filter((service) => service?.featured)
      setSelectedServices(sortFeaturedServices(featuredServices))
    }
    return sortServices(servicesData?.services?.filter((service) => service?.isPublic))
  }, [servicesData])
  useEffect(() => {
    if (salonId) {
      refetch()
    }
  }, [salonId, refetch])
  const {
    loading,
    createService
  } = useCreateService()
  const {
    updateCategoryOrder,
  } = useUpdateCategoryOrder();

  const {
    updateFeaturedServicesOrder
  } = useUpdateFeaturedServicesOrder()

  const searchItem = (search: string) => {
    // if showProducts is true
    if (search === '') {
      setFilteredServices(sortServices(services?.filter((service) => service?.isPublic)));
      return;
    }
    const filteredServices = services?.filter((service) => {
      return service.name.toLowerCase().includes(search.toLowerCase()) && service?.isPublic;
    });
    setFilteredServices(sortServices(filteredServices));
  }

  const addService = (id: string) => {
    // add service or remove service
    const service = services?.find((service) => service.id === id);

    // set selected services
    if (service) {
      const payload = {
        categoryId: service?.category?.id,
        duration: service?.duration,
        description: service?.description,
        depositType: service?.depositType,
        aftercareMessage: service?.aftercareMessage,
        name: service?.name,
        salonId,
        isDepositRequired: service?.isDepositRequired,
        isPublic: service?.isPublic,
        extraTime: service?.extraTime,
        id: service ? service?.id : null,
        customerCanSchedule: true,
        depositValue: service.depositValue && service?.isDepositRequired
          ? Number(service.depositValue.toString().replace(/,/g, ''))
          : 0,
        salonStaffIds: service?.serviceStaffs?.length ? convertExistingStaffOptionsToCommaSeparatedStringForServices(service?.serviceStaffs, service?.price) : [],
        price: Number(service.price.toString().replace(/,/g, '')),
        rebookReminderPeriod: service?.rebookReminderPeriod,
        rebookReminder: service?.rebookReminder,
        pricingType: service?.pricingType,
        featured: !service?.featured
      }
      createService({
        variables: {
          input: payload
        }
      }).then(({ data }) => {
        const { createService } = data;

        if (createService.status === 200) {
          addToast({
            message: 'Service saved successfully',
            variant: 'success'
          })
          refetch()
          if (selectedServices?.find((service) => service.id === id)) {
            setSelectedServices(selectedServices?.filter((service) => service.id !== id))
          } else {
            setSelectedServices([...(selectedServices || []), service])
          }
        } else {
          addToast({
            message: createService.errors[0].message,
            variant: 'error'
          })
        }
      })
    }
  }

  const processCloseModal = () => {
    if (showServiceSelection) {
      setShowServiceSelection(false)
      return;
    }
    closeModal()
  }

  const _closeModal = () => {
    setShowServiceSelection(false)
    closeModal()
  }

  const list = formatServicesInCategory(formatCategoryToOrderList(formatServicesCategory(categoryServices)));
  const [items, setItems] = useState(list);
  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedItems = Array.from(items);
    const [removed] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, removed);

    // Detect moved items
    const hasMoved = result.source.index !== result.destination.index;
    if (hasMoved) {
      const categoryOrder = reorderedItems?.map((category, index) => ({
        categoryId: category?.id,
        orderIndex: index
      }))
      updateCategoryOrder({
        variables: {
          input: {
            categoryOrder
          }
        }
      }).then(({ data }) => {
        if (data?.updateCategoryOrder?.status === 200) {
          addToast({
            message: 'Category order updated successfully',
            variant: 'success',
          })
          setItems(reorderedItems);
          return;
        }

        if (data?.updateCategoryOrder?.errors?.length) {
          addToast({
            message: data?.updateCategoryOrder?.errors[0]?.message,
            variant: 'error',
          })
        }
      })
    }
  };

  const handleFeaturedDragEnd = (result) => {
    if (!result.destination) return;
    // sortFeaturedList(result.source.index, result.destination.index)
    const reorderedItems = Array.from(selectedServices);
    const [removed] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, removed);
    // Detect moved items
    const hasMoved = result.source.index !== result.destination.index;
    if (hasMoved) {
      const serviceOrder = reorderedItems?.map((service, index) => ({
        serviceId: service?.id,
        orderIndex: index
      }))
      updateFeaturedServicesOrder({
        variables: {
          input: {
            serviceOrder
          }
        }
      }).then(({ data }) => {
        if (data?.updateFeaturedServicesOrder?.status === 200) {
          addToast({
            message: 'Featured service order updated successfully',
            variant: 'success',
          })
          setSelectedServices(reorderedItems)
          return;
        }

        if (data?.updateFeaturedServicesOrder?.errors?.length) {
          addToast({
            message: data?.updateFeaturedServicesOrder?.errors[0]?.message,
            variant: 'error',
          })
        }
      })
    }
  };
  return (
    <Modal
      show={isVisible}
      closeModal={_closeModal}
      variant='right'
    >
      <div className="w-full relative my-[80px]">
        <div className='fixed top-0 w-full bg-white flex border-b border-grey-100 cursor-pointer z-10'>
          <div className='w-full hidden xl:flex space-x-2 px-6 py-5 items-start'>
            <Button
              variant='text'
              size='none'
              type='button'
              className='w-fit'
              fontSize='b4'
              onClick={processCloseModal}
            >
              <SvgArrowBack width='24px' height='24px' /> <span>Back</span>
            </Button>
          </div>
          <div className='w-full flex xl:hidden space-x-[69px] p-4 items-center'>
            <Button
              variant='icon'
              size='square'
              type='button'
              rounded='md'
              className='w-fit'
              fontSize='b4'
              onClick={processCloseModal}
            >
              <SvgChevronLeft width='24px' height='24px' />
            </Button>
            <Paragraph size='b3' weight='bold'>
              Settings
            </Paragraph>
          </div>
        </div>
        {!showServiceSelection ?
          <>
            <div className='w-full flex flex-col px-6 py-4 space-y-6'>
              <Heading variant='h1' size='h9' weight='semiBold'>
                Service settings
              </Heading>
              <Paragraph size='b4' color={COLORS.GREY[400]}>
                Make changes to service here
              </Paragraph>
            </div>

            <div className="w-full border-y border-grey-100">
              <Tabs
                tabs={SERVICE_SETTINGS_TABS}
                activeTab={settingsActiveTab}
                setActiveTab={setSettingsActiveTab}
                variant='underline'
              />
            </div>
            {settingsActiveTab === SERVICE_SETTINGS_TAB_NAME.ONLINE_SERVICE_DISPLAY ?
              <div className="flex flex-col w-full space-y-4">
                <div className='w-full flex flex-col px-6 py-6 space-y-6'>
                  <Heading variant='h3' size='h11' weight='semiBold'>
                    Arrange your cetgories display order
                  </Heading>
                  <Paragraph size='b4' color={COLORS.GREY[400]}>
                    Choose how you’d like your categories to appear on your booking site. The default order is alphabetical.
                  </Paragraph>
                </div>

                <DragDropContext onDragEnd={handleDragEnd}>
                  <Droppable droppableId="droppable">
                    {(provided) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={{ padding: "16px", background: "#fff", borderRadius: "8px" }}
                      >
                        {items.map((item, index) => (
                          <Draggable key={item.id} draggableId={item.id} index={index}>
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={{
                                  padding: "1px",
                                  margin: "8px 0",
                                  background: "#fff",
                                  ...provided.draggableProps.style,
                                }}
                              >
                                <div className="w-full flex flex-col bg-white border border-grey-100/70 rounded-md">
                                  <div className="w-full py-2 px-4 flex justify-between items-center border-b border-grey-100/70">
                                    <Paragraph size='b3' weight='semiBold' className="capitalize" color={COLORS.GREY[900]}>
                                      {item.content}
                                    </Paragraph>
                                    <Button
                                      variant='icon'
                                      size='square'
                                      type='button'
                                    >
                                      <SvgMove width="32px" height="32px" />
                                    </Button>
                                  </div>
                                  <div className="w-full flex flex-col space-y-4 p-6">
                                    {item?.services?.map((service) => {
                                      return (
                                        <div className="w-full p-5 flex justify-between space-x-2 items-center border border-grey-100/70 rounded-md" key={service?.id}>
                                          <div className="flex items-center space-x-2">
                                            <Paragraph size='b4' weight='medium' className="capitalize" color={COLORS.GREY[900]}>
                                              {service?.name}
                                            </Paragraph>
                                          </div>
                                          <Paragraph size='b5' weight='medium' className="" color={COLORS.GREY[300]}>
                                            {getHoursAndMinutesString(service?.duration)} • {formatInToPrice(service?.price)}
                                          </Paragraph>
                                        </div>
                                      )
                                    })}</div>
                                </div>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
              : <div className="flex flex-col w-full space-y-4">
                <div className='w-full flex flex-col px-6 py-6 space-y-6'>
                  <Heading variant='h3' size='h11' weight='semiBold'>
                    Select featured services
                  </Heading>
                  <Paragraph size='b4' color={COLORS.GREY[400]}>
                    Highlight services to display first when clients book online
                  </Paragraph>
                </div>

                <div className="w-full flex flex-col px-6 gap-4">
                  <Button
                    variant="primary"
                    size="sm"
                    rounded="md"
                    type="button"
                    className='w-fit'
                    onClick={() => {
                      setShowServiceSelection(true);
                    }}
                  >
                    <SvgPlus width="14px" height="14px" />
                    Add/Remove Featured service
                  </Button>

                  {/* <Draggable onPosChange={sortFeaturedList}> */}

                  <DragDropContext onDragEnd={handleFeaturedDragEnd}>
                    <Droppable droppableId="droppable">
                      {(provided) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          style={{ padding: "16px", background: "#fff", borderRadius: "8px" }}
                        >
                          {selectedServices?.map((item, index) => (
                            <Draggable key={item.id} draggableId={item.id} index={index}>
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={{
                                    padding: "1px",
                                    margin: "8px 0",
                                    background: "#fff",
                                    ...provided.draggableProps.style,
                                  }}
                                >
                                  <div className="w-full p-5 flex justify-start space-x-4 items-center border border-grey-100/70 rounded-md">
                                    <SvgMove width="24px" height="24px" />
                                    <div className="flex flex-col space-y-2">
                                      <Paragraph size='b4' weight='medium' className="capitalize" color={COLORS.GREY[900]}>
                                        {item?.name}
                                      </Paragraph>
                                      <Paragraph size='b5' weight='medium' className="" color={COLORS.GREY[300]}>
                                        {getHoursAndMinutesString(item?.duration)} • {formatInToPrice(item?.price)}
                                      </Paragraph>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                  {/* </Draggable> */}
                </div>
              </div>}
          </> : null}
        {showServiceSelection ?
          <>
            <div className='w-full flex flex-col p-4 gap-y-3'>
              <div className='w-full flex justify-between items-center'>
                <Paragraph size="b3" color={COLORS.GREY[300]}>
                  Select service
                </Paragraph>

                <div className="w-2/5">
                  <SearchTerm
                    placeholder='Search.'
                    showIcon={false}
                    setDebouncedSearchQuery={searchItem}
                  />
                </div>
              </div>
              <div className='w-full flex flex-col space-y-3'>
                {filteredServices && Array?.isArray(filteredServices) && filteredServices?.length ? filteredServices?.map((service) => {
                  const isChecked = selectedServices?.some((selectedService) => selectedService?.id === service?.id);
                  return (
                    <div className="w-full bg-grey-20/40 border-grey-100 rounded-sm border-l-4 py-3 px-4 flex justify-between items-start cursor-pointer" onClick={() => addService(service?.id)}>
                      <div className='flex space-x-2 items-start'>
                        <Checkbox isChecked={isChecked} />
                        <div className='flex flex-col space-y-1'>
                          <Paragraph size="b5" color={COLORS.GREY[300]}>
                            {service?.name}
                          </Paragraph>
                          <Paragraph size="b6" color={COLORS.GREY[300]}>
                            {getHoursAndMinutesString(service?.duration)}
                          </Paragraph>
                        </div>
                      </div>

                      <Paragraph size="b5" color={COLORS.GREY[300]} weight='bold'>
                        {formatInToPrice(service?.price)}
                      </Paragraph>
                    </div>
                  )
                }) : null}
              </div>
            </div>
            <div className="w-full max-w-[850px] fixed bottom-0 bg-white flex justify-between border-t border-grey-100 cursor-pointer">
              <div className="w-full flex justify-end py-4 px-8">
                <div className="flex space-x-2">
                  <Button
                    variant="primary"
                    className=""
                    size="md"
                    rounded="lg"
                    type="button"
                    onClick={() => {
                      setShowServiceSelection(false)
                    }}
                  >
                    Done
                  </Button>
                </div>
              </div>
            </div>
          </> : null}
      </div>
    </Modal>
  )
}

const ImportServices = ({
  addToast
}: {
  addToast: (toast: ToastProps) => void
}) => {
  const { getSalonFieldValue } = useSalonCache()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setUploadUrl] = useState(null)
  const {
    loading: isLoading,
    uploadService
  } = useUploadService()

  const {
    loading: uploadIsLoading,
    uploadFile
  } = useUploadFile();

  const uploadServiceAsync = (url: string) => {

    const payload = {
      salonId: getSalonFieldValue('id'),
      url
    }
    uploadService({
      variables: { input: payload }
    }).then(({ data }) => {
      const { uploadService } = data;

      if (uploadService.status === 200) {
        addToast({ message: 'Services uploaded successfully. This may take a few minutes to process', variant: 'success' })
      } else {
        addToast({ message: uploadService.errors[0].message, variant: 'error' })
      }
    })
  }

  const handleCsvUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0]
    // setValue('fileUpload', file)
    const reader = new FileReader()
    reader.onload = async (event) => {
      const base64DataUri = event.target.result
      uploadFile({
        variables: { input: { data: base64DataUri } }
      }).then(({ data }) => {
        const { uploadFile } = data;
        if (uploadFile?.status === 200) {
          uploadServiceAsync(uploadFile?.url)
        }

        if (uploadFile?.errors?.length) {
          addToast({
            message: uploadFile?.errors[0].message,
            variant: 'error'
          })
        }
      }).catch((error) => {
        addToast({
          message: error?.message,
          variant: 'error'
        })
      })
    }

    reader.readAsDataURL(file)
  }

  return (
    <Button
      variant='success'
      className='relative'
      size='lg'
      rounded={'lg'}
      fontSize='b5'
      type='button'
    >
      {!isLoading ? (
        <>
          Import Services
          <input
            className='cursor-pointer absolute block opacity-0 top-0 w-[128px] h-full'
            type='file'
            accept={CSV_UPLOAD_PATTERN}
            onChange={handleCsvUpload}
          />
        </>
      ) : (
        <div className='flex justify-center gap-x-2 cursor-pointer'>
          {[1, 2, 3].map((item, index) => (
            <div
              key={index}
              className='h-1 w-1 rounded-full bg-gradient-to-r from-grey-200 via-grey-300 to-grey-200 animate-pulse'
            />
          ))}
        </div>
      )}
    </Button>
  )
}

type ImportServiceModalProps = {
  isVisible: boolean
  closeModal: () => void
  addToast: (toast: ToastProps) => void
}
type FileUploadInput = {
  fileUpload: File | null
}
const ImportServiceModal = ({
  isVisible,
  closeModal,
  addToast
}: ImportServiceModalProps) => {
  const SERVICE_TEMPLATE_URL = `${process.env.REACT_APP_API_BASE_URL}/home/download_template/service_import_template.csv`
  const { getSalonFieldValue } = useSalonCache()
  const [uploadUrl, setUploadUrl] = useState(null)
  const { handleSubmit, control, setValue } = useForm<FileUploadInput>()
  const {
    loading: isLoading,
    uploadService
  } = useUploadService()

  const {
    loading: uploadIsLoading,
    uploadFile
  } = useUploadFile();
  const importServiceSubmit = async () => {
    const payload = {
      salonId: getSalonFieldValue('id'),
      url: uploadUrl
    }
    uploadService({
      variables: { input: payload }
    }).then(({ data }) => {
      const { uploadService } = data;

      if (uploadService.status === 200) {
        addToast({ message: 'Services uploaded successfully. This may take a few minutes to process', variant: 'success' })
        setValue('fileUpload', null)
        setUploadUrl(null)
        closeModal()
      } else {
        addToast({ message: uploadService.errors[0].message, variant: 'error' })
      }
    })
  }
  const handleCsvUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0]
    setValue('fileUpload', file)
    const reader = new FileReader()
    reader.onload = async (event) => {
      const base64DataUri = event.target.result
      // eslint-disable-next-line react-hooks/rules-of-hooks
      uploadFile({
        variables: { input: { data: base64DataUri } }
      }).then(({ data }) => {
        const { uploadFile } = data;
        if (uploadFile?.status === 200) {
          setUploadUrl(uploadFile?.url);
        }
        if (uploadFile?.errors?.length) {
          addToast({ message: uploadFile.errors[0].message, variant: 'error' })
        }
      }).catch((error) => {
        addToast({
          message: error?.message,
          variant: 'error'
        })
      })
    }

    reader.readAsDataURL(file)
  }

  return (
    <Modal title='Import Services' show={isVisible} closeModal={closeModal}>
      <div className='w-full flex flex-col my-6 items-center space-y-6'>
        <a href={SERVICE_TEMPLATE_URL} target='_blank' download>
          <Paragraph size='b5' color={COLORS.GREY[400]}>
            First, download the <span className='underline'>template</span> here
          </Paragraph>
        </a>
        <form
          onSubmit={handleSubmit(importServiceSubmit)}
          className='w-full mt-6 space-y-6'
          autoComplete='off'
        >
          <Controller
            control={control}
            name='fileUpload'
            render={({ field: { value }, formState: { errors } }) => {
              return (
                <div className='w-full flex flex-col py-6'>
                  <div className='w-full flex items-center space-x-4 border rounded-sm border-grey-20 border-dashed h-[130px] justify-center'>
                    <div className='relative cursor-pointer'>
                      {value ? (
                        <SvgMdiLightImage width='2rem' height='2rem' />
                      ) : (
                        <SvgUploadImage width='2rem' height='2rem' />
                      )}
                      <input
                        className='cursor-pointer absolute block opacity-0 top-0 w-[128px] h-[96px]'
                        type='file'
                        accept={CSV_UPLOAD_PATTERN}
                        onChange={handleCsvUpload}
                      />
                    </div>
                    <div className='flex flex-col'>
                      <Paragraph size='b5'>
                        {value ? value?.name : 'Upload service inventory'}
                      </Paragraph>
                    </div>
                  </div>
                  {errors?.fileUpload?.message && (
                    <FormHelperText variant='error'>
                      {errors?.fileUpload.message}
                    </FormHelperText>
                  )}
                </div>
              )
            }}
            rules={{
              required: 'Service file is required'
            }}
          />
          <Button
            variant='primary'
            className=''
            disabled={isLoading || uploadIsLoading}
            loading={isLoading || uploadIsLoading}
            size='lg'
            rounded='lg'
          >
            Upload
          </Button>
          <Button
            variant='text'
            fontWeight='semiBold'
            className={`mx-auto text-red-500`}
            size='none'
            onClick={closeModal}
            type='button'
          >
            Cancel
          </Button>
        </form>
      </div>
    </Modal>
  )
}

const SERVICE_CREATION_TAB_NAME = {
  BASIC: 'Service details',
  REMINDERS: 'Instructions & Reminders'
}

const SERVICE_CREATION_TABS = [
  {
    key: SERVICE_CREATION_TAB_NAME.BASIC,
    title: SERVICE_CREATION_TAB_NAME.BASIC,
    show: true
  },
  {
    key: SERVICE_CREATION_TAB_NAME.REMINDERS,
    title: SERVICE_CREATION_TAB_NAME.REMINDERS,
    show: true
  }
]

interface ICreateServiceInput extends CreateServiceInput {
  durationMinutes: string
  durationHours: string
  extraTimeMinutes: string
  extraTimeHours: string
  category: string
  serviceCategoryId: []
  isPublic?: boolean
}
const AddServiceModal = ({
  service,
  closeModal: closeModal_,
  isVisible,
  actions,
  addToast,
  category
}: ServiceDialogProps<'editService' | 'viewService' | 'deleteService'>) => {
  const [activeTab, setActiveTab] = useState(SERVICE_CREATION_TAB_NAME.BASIC)
  const [typeSign, setTypeSign] = useState<string | null>('-')
  const [allowCustomPrice, setAllowCustomPrice] = useState(false);
  const [allowServiceExtraTime, setAllowServiceExtraTime] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isDeleting, setIsDeleting] = useState(false)
  const { getSalonFieldValue } = useSalonCache()
  const [serviceCustomPrices, setServiceCustomPrices] = useState<{ price: number, staff: MultiSelectOption[] }[]>([])
  const salonId = getSalonFieldValue('id')
  const [newCategory, setNewCategory] = useState(false)
  const [showCustomButton, setShowCustomButton] = useState(false)
  const [isAfterCareEnabled, setIsAfterCareEnabled] = useState(false)
  const [rebookPeriodType, setRebookPeriodType] = useState<string | null>(null)
  const {
    loading,
    createService
  } = useCreateService()

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors }
  } = useForm<ICreateServiceInput>({
    delayError: 100,
    mode: 'onChange',
    defaultValues: {
      isDepositRequired: false
    }
  })

  const {
    isVisible: isDeleteServiceDialogModalVisible,
    openModal: openDeleteServiceDialogModal,
    closeModal: closeDeleteServiceDialogModal
  } = useModal()

  const closeModal = () => {
    setActiveTab(SERVICE_CREATION_TAB_NAME.BASIC)
    closeModal_()
  }

  const handleClose = () => {
    resetServiceFormInput();
    closeModal()
  }

  const resetServiceFormInput = () => {
    setValue('name', '')
    setValue('description', '')
    setValue('category', '')
    setValue('serviceCategoryId', []);
    setValue('price', 0)
    setValue('pricingType', null)
    setValue('durationHours', null)
    setValue('durationMinutes', null)
    setValue('extraTimeMinutes', null)
    setValue('extraTimeHours', null)
    setValue('isDepositRequired', null)
    setValue('depositValue', null)
    setValue('depositType', null)
    setValue('salonStaffIds', [])
    setServiceCustomPrices([])
    setAllowCustomPrice(false)
    setAllowServiceExtraTime(false)
    setShowCustomButton(false)
    setValue('isPublic', false)
    setIsAfterCareEnabled(false)
    setValue('aftercareMessage', '')
    setValue('rebookReminder', false)
    setValue('rebookReminderPeriod', null)
    setRebookPeriodType(null)
  }

  const {
    data: staffListData,
  } = useGetStaffList({
    salonId,
    active: true
  })
  const staff = useMemo(() => staffListData?.staffManagement?.staffs, [staffListData])
  const staffMembersOptions = useMemo(() => formatStaffToSelectField(staff), [staff])

  const {
    data: categoriesData
  } = useGetCategories()
  const categories = useMemo(() => categoriesData?.categories, [categoriesData])
  const categoriesOptions = useMemo(() => formatCategoriesToSelectFieldCreation(categories), [categories])

  useEffect(() => {
    if (watch('serviceCategoryId') && !service) {
      const categoryIdField = watch('serviceCategoryId') as unknown as {
        label: string;
        value: string;
      };
      if (categoryIdField?.value === 'new-cate') {
        setNewCategory(true);
      } else {
        setNewCategory(false);
        // get category
        const category = categories?.find((x) => x.id === categoryIdField?.value)
        if (category) {
          const salonStaff = formatStaffToSelectField(category?.salonStaffs)
          console.log({
            salonStaff,
            category
          })
          setValue('salonStaffIds', salonStaff as [])
        }
      }
    }
  }, [watch('serviceCategoryId')])

  useEffect(() => {
    if (category && Array?.isArray(categories) && categories?.length) {
      if (category) {
        setValue('serviceCategoryId', {
          label: category?.name,
          value: category?.id
        } as unknown as [])
      }
    }
  }, [category, categories])

  useEffect(() => {
    const salonStaffIds = watch('salonStaffIds');
    if (Array?.isArray(salonStaffIds) && salonStaffIds?.length > 0) {
      setShowCustomButton(true);
    } else {
      setShowCustomButton(false);
    }
  }, [watch('salonStaffIds')]);

  useEffect(() => {
    if (service) {
      const { hours, minutes } = getHoursAndMinutes(service?.duration)
      const { hours: extraTimeHours, minutes: extraTimeMinutes } = getHoursAndMinutes(service?.extraTime)
      if (service?.extraTime > 0) {
        setAllowServiceExtraTime(true)
      }
      const serviceType =
        service?.pricingType === 'fixed'
          ? ServicePricingTypeEnum.Fixed
          : ServicePricingTypeEnum.StartingAt
      setValue('name', service?.name)
      setValue('description', service?.description)
      setValue('price', service?.price)
      setValue('pricingType', serviceType)
      setValue('durationHours', hours.toString())
      setValue('durationMinutes', minutes.toString())
      setValue('extraTimeHours', extraTimeHours.toString())
      setValue('extraTimeMinutes', extraTimeMinutes.toString())
      setValue('isDepositRequired', service?.isDepositRequired)
      setValue('depositValue', service?.depositValue)
      setValue('aftercareMessage', service?.aftercareMessage)
      if (service?.aftercareMessage) {
        setIsAfterCareEnabled(true)
      }
      setValue('isPublic', service?.isPublic)
      setValue('rebookReminder', service?.rebookReminder)
      setValue('isPublic', service?.isPublic)
      if (service?.rebookReminderPeriod) {
        const days = service?.rebookReminderPeriod % 7;

        if (days > 0) {
          setValue('rebookReminderPeriod', service?.rebookReminderPeriod)
          setRebookPeriodType('days')
        } else {
          setValue('rebookReminderPeriod', service?.rebookReminderPeriod / 7)
          setRebookPeriodType('weeks')
        }
      }
      setValue(
        'depositType',
        service.depositType === 'percentage'
          ? DepositTypeEnum.Percentage : service.depositType === 'fixed_value' ? DepositTypeEnum.FixedValue : null
      )
    } else {
      resetServiceFormInput()
    }
  }, [service])

  useEffect(() => {
    if (service && Array?.isArray(categories) && categories?.length) {
      const serviceCategory = service?.category?.id;
      const checkCategory = categories.find((cate) => cate?.id === serviceCategory);
      if (checkCategory) {
        setValue('serviceCategoryId', {
          label: checkCategory?.name,
          value: checkCategory?.id
        } as unknown as [])
      }
    }
  }, [service, categories])

  useEffect(() => {
    if (service && Array?.isArray(staff) && staff?.length) {
      // get staff ids in staff that matches service?.serviceStaffs
      const staffIds = staff.filter(staff => {
        const staffService = service?.serviceStaffs?.find(
          serviceStaff => serviceStaff?.salonStaff?.id === staff.id
        )
        return staffService
      })
      if (staffIds?.length) {
        setShowCustomButton(true)
      }
      setValue('salonStaffIds', formatStaffToSelectField(staffIds) as []);

      // checkif service?.serviceStaffs price are different
      const serviceStaffs = service?.serviceStaffs;
      let differentPriceStaffs = [];
      if (serviceStaffs?.length) {
        differentPriceStaffs = serviceStaffs.filter((serviceStaff) => serviceStaff.price !== service?.price && serviceStaff?.price);
      }
      if (Array?.isArray(differentPriceStaffs) && differentPriceStaffs?.length) {

        setAllowCustomPrice(true);
        setServiceCustomPrices(formatServiceCustomPriceInToSelectOptions(differentPriceStaffs));
      }
    } else {
      setValue('salonStaffIds', [])
    }
  }, [service, staff])

  const {
    createCategory,
  } = useCreateCategory()

  useEffect(() => {
    if (watch('depositType')) {
      if (watch('depositType') === 'fixed_value') {
        setTypeSign(DEFAULT_CURRENCY)
      } else if (watch('depositType') === 'percentage') {
        setTypeSign('%')
      }
    } else {
      setTypeSign('-')
    }
  }, [watch('depositType')])

  const handleCreateService = async (input: ICreateServiceInput) => {
    if (activeTab === SERVICE_CREATION_TAB_NAME?.BASIC && (canPerformAction(PERMISSION_CONSTANTS.service.after_care) || canPerformAction(PERMISSION_CONSTANTS.service.rebook))) {
      setActiveTab(SERVICE_CREATION_TAB_NAME?.REMINDERS)
      return;
    }

    if (!input?.price) {
      addToast({
        message: 'Price is required',
        variant: 'error'
      })
      return;
    }

    if (isAfterCareEnabled && (input?.aftercareMessage === '' || !input?.aftercareMessage)) {
      addToast({
        message: 'Kindly enter instructions',
        variant: 'error'
      })
      return;
    }
    const duration = getFullTime(
      input.durationMinutes?.toString(),
      input.durationHours?.toString()
    )
    const extraTime = getFullTime(
      input.extraTimeMinutes?.toString(),
      input.extraTimeHours?.toString()
    )
    delete input.durationHours
    delete input.durationMinutes
    delete input.extraTimeMinutes
    delete input.extraTimeHours

    const categoryIdField = input?.serviceCategoryId as unknown as {
      label: string;
      value: string;
    };
    let categoryId = categoryIdField?.value;

    if (newCategory) {
      try {
        const { data } = await createCategory({
          variables: {
            input: {
              name: input?.category
            }
          }
        });

        if (data?.createCategory?.status === 200 && data?.createCategory?.category) {
          categoryId = data?.createCategory?.category?.id;
        } else {
          throw new Error('Failed to create category');
        }
      } catch (error) {
        addToast({
          message: error?.message,
          variant: 'error'
        });
        return; // Exit the function if category creation fails
      }
    }
    delete input?.category;
    delete input?.serviceCategoryId
    const salonStaffIds = input?.salonStaffIds ? convertStaffOptionsToCommaSeparatedStringForServices(input?.salonStaffIds as unknown as MultiSelectOption[], input?.price, serviceCustomPrices) : [];
    let rebookPeriodInt = input.rebookReminderPeriod && input?.rebookReminder
      ? Number(input.rebookReminderPeriod.toString().replace(/,/g, ''))
      : 0
    if (rebookPeriodType === 'weeks') {
      rebookPeriodInt = rebookPeriodInt * 7;
    }
    const payload: CreateServiceInput = {
      ...input,
      categoryId,
      duration,
      salonId: getSalonFieldValue('id'),
      extraTime,
      id: service ? service?.id : null,
      customerCanSchedule: true,
      depositValue: input.depositValue && input?.isDepositRequired
        ? Number(input.depositValue.toString().replace(/,/g, ''))
        : 0,
      salonStaffIds,
      price: Number(input.price.toString().replace(/,/g, '')),
      rebookReminderPeriod: rebookPeriodInt,
    } as CreateServiceInput
    createService({
      variables: {
        input: payload
      }
    }).then(({ data }) => {
      const { createService } = data;

      if (createService.status === 200) {
        addToast({
          message: 'Service saved successfully',
          variant: 'success'
        })
        resetServiceFormInput()
        closeModal()
      } else {
        addToast({
          message: createService.errors[0].message,
          variant: 'error'
        })
      }
    })
  }

  const handleCancelOrDelete = () => {
    if (service && actions?.deleteService) {
      openDeleteServiceDialogModal()
    } else {
      handleClose()
    }
  }

  const initiateCloseDeleteService = (deleteService: boolean) => {
    if (deleteService) {
      closeDeleteServiceDialogModal()
      handleClose()
    } else {
      closeDeleteServiceDialogModal()
    }
  }

  const enableOrDisableCustomPrice = () => {
    setAllowCustomPrice(!allowCustomPrice)
    if (Array?.isArray(serviceCustomPrices) && serviceCustomPrices?.length === 0) {
      setServiceCustomPrices([
        { price: 0, staff: [] }
      ])
    }
  }

  const addMoreCustomPrice = () => {
    setServiceCustomPrices([
      ...serviceCustomPrices,
      { price: 0, staff: [] }
    ])
  }

  const changeCustomPrice = (index, value) => {
    const newCustomPrices = [...serviceCustomPrices]
    newCustomPrices[index].price = value
    setServiceCustomPrices(newCustomPrices)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChangeCustomPriceStaff = (index: number) => (value: any) => {
    const newCustomPrices = [...serviceCustomPrices]
    newCustomPrices[index].staff = value
    setServiceCustomPrices(newCustomPrices)
  }

  const handlePrev = () => {
    if (activeTab === SERVICE_CREATION_TAB_NAME?.REMINDERS) {
      setActiveTab(SERVICE_CREATION_TAB_NAME?.BASIC)
      return;
    }
  }

  const handleSetBody = (msg: string) => {
    setValue('aftercareMessage', msg)
  }

  const handleRebookTypeChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    setRebookPeriodType(selectedValue)
  }

  return (
    <>
      <Modal
        show={isVisible}
        closeModal={handleClose}
        variant='right'
      >
        <form
          onSubmit={handleSubmit(handleCreateService)}
          className='w-full relative my-[80px]'
          autoComplete='off'
        >
          <div className='fixed top-0 w-full bg-white flex border-b border-grey-100 cursor-pointer z-10'>
            <div className='w-full hidden xl:flex space-x-2 px-6 py-5 items-start'>
              <Button
                variant='text'
                size='none'
                type='button'
                className='w-fit'
                fontSize='b4'
                onClick={handleClose}
              >
                <SvgArrowBack width='24px' height='24px' /> <span>Back</span>
              </Button>
            </div>
            <div className='w-full flex xl:hidden space-x-[69px] p-4 items-center'>
              <Button
                variant='icon'
                size='square'
                type='button'
                rounded='md'
                className='w-fit'
                fontSize='b4'
                onClick={handleClose}
              >
                <SvgChevronLeft width='24px' height='24px' />
              </Button>
              <Paragraph size='b3' weight='bold'>
                Service
              </Paragraph>
            </div>
          </div>

          <div className='w-full flex flex-col px-6 py-4 space-y-6 border-b border-grey-100'>
            <Heading variant='h1' size='h9' weight='semiBold'>
              {!service ? 'Add a new service' : 'Edit Service'}
            </Heading>
            <Paragraph size='b4'>
              {!service
                ? 'Add a new service to your location'
                : 'Edit the details of this service'}
            </Paragraph>
          </div>
          <div className="w-full border-b border-grey-100/70">
            <Tabs
              tabs={SERVICE_CREATION_TABS}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              variant='underline'
            />
          </div>
          {activeTab === SERVICE_CREATION_TAB_NAME?.BASIC ?
            <>
              <div className='w-full px-6 py-[30px] space-y-6'>
                <div className="w-full px-4 py-2 bg-grey-125/10">
                  <Heading variant='h2' size='h11' color={COLORS.GREY[300]} weight='semiBold'>
                    Basic Details
                  </Heading>
                </div>
                <Input
                  name='name'
                  label='Service name'
                  id='service-name'
                  type='text'
                  placeholder='E.g Manicure'
                  control={control}
                  rules={{
                    required: FORM_ERRORS.SERVICE_NAME_REQUIRED
                  }}
                  error={errors.name}
                />
                <Input
                  name='description'
                  label='Description'
                  id='description'
                  type='text'
                  placeholder='A brief description of this service'
                  control={control}
                  error={errors.description}
                />


                <Controller
                  control={control}
                  name='isPublic'
                  render={({ field: { onChange, value } }) => {
                    return (
                      <div
                        className='w-full flex flex-wrap gap-4 xl:gap-0 xl:space-x-4'
                        onClick={() => onChange(!value)}
                      >
                        <FormLabel htmlFor='isDepositRequired'>
                          Allow clients to book service via booking site
                        </FormLabel>
                        <div className='flex items-center cursor-pointer text-b5 text-grey-900 space-x-2'>
                          <ToggleOnly isChecked={value} />
                        </div>
                      </div>
                    )
                  }}
                />
              </div>
              <div className='w-full px-6 py-[20px] space-y-6'>
                <div className="w-full px-4 py-2 bg-grey-125/10">
                  <Heading variant='h2' size='h11' color={COLORS.GREY[300]} weight='semiBold'>
                    Category & Pricing
                  </Heading>
                </div>
                <div className='w-full flex flex-col xl:flex-row gap-2'>
                  <div className="w-full xl:w-1/2 flex flex-col space-y-3">
                    <Controller
                      control={control}
                      name="serviceCategoryId"
                      render={({
                        field: { onChange, value },
                        formState: { errors },
                      }) => {
                        const errorMessage: string = errors?.serviceCategoryId?.message;
                        return (
                          <>
                            <FormLabel htmlFor="service">
                              Select a category
                            </FormLabel>
                            <SingleSelect
                              selected={value || []}
                              options={categoriesOptions}
                              setSelected={onChange}
                            />
                            {errorMessage && (
                              <FormHelperText variant="error">
                                {errorMessage}
                              </FormHelperText>
                            )}
                          </>
                        );
                      }}
                      rules={{
                        required: "Select a category",
                      }}
                    />
                    {newCategory && (
                      <Input
                        name='category'
                        label='Category'
                        id='category'
                        type='text'
                        placeholder='Enter a category'
                        control={control}
                        rules={{
                          required: newCategory && FORM_ERRORS.CATEGORY_REQUIRED
                        }}
                        error={errors.category}
                      />
                    )}
                  </div>
                  <Controller
                    control={control}
                    name="salonStaffIds"
                    render={({
                      field: { onChange, value },
                      formState: { errors },
                    }) => {
                      const errorMessage: string = errors?.salonStaffIds?.message;
                      return (
                        <div className="w-full xl:w-1/2 flex flex-col space-y-2">
                          <FormLabel htmlFor="staff">
                            Add Staff
                          </FormLabel>
                          <MultiSelect
                            selected={value as unknown as [] || []}
                            options={staffMembersOptions}
                            setSelected={onChange}
                          />
                          {errorMessage && (
                            <FormHelperText variant="error">
                              {errorMessage}
                            </FormHelperText>
                          )}
                        </div>
                      );
                    }}
                  />
                </div>

                <div className="w-full flex flex-col xl:flex-row gap-2">
                  <div className="w-full xl:w-1/2 flex flex-col space-y-3">
                    <div className='w-full flex space-x-2'>
                      <Controller
                        control={control}
                        name='price'
                        rules={{ required: 'Please enter a price' }}
                        render={({ field, formState: { errors } }) => (
                          <div className='w-full flex flex-col space-y-2'>
                            <FormLabel htmlFor='price'>Price</FormLabel>
                            <input
                              className='w-full flex items-center appearance-none border border-grey-20 px-4 py-3 font-normal text-b6 xl:text-b4 text-grey-900 rounded-lg focus:outline-none focus:border-grey-20 focus:shadow-grey-100 placeholder:text-grey-400'
                              type='text'
                              {...field}
                              value={formatNumber(field.value)}
                              onChange={(e) => field.onChange(formatNumber(e.target.value))}
                              placeholder='Enter price'
                            />
                            {errors?.price?.message && (
                              <FormHelperText variant='error'>
                                {errors.price.message}
                              </FormHelperText>
                            )}
                          </div>
                        )}
                      />
                      <div className="w-30%">
                        <SelectInput
                          name='pricingType'
                          id='price-type'
                          label='Price Type'
                          control={control}
                          rules={{
                            required: ERRORS.PRICE_TYPE_REQUIRED
                          }}
                          value={watch('pricingType')}
                          error={errors.pricingType}
                          options={SERVICE_PRICE_TYPES}
                          placeholder='Select Price Type'
                        />
                      </div>
                    </div>
                    {showCustomButton ? (
                      <div className='w-full flex flex-col space-y-4'>
                        <div className='w-full flex flex-wrap gap-4 xl:gap-0 xl:space-x-4'>
                          <div className='flex' onClick={enableOrDisableCustomPrice}>
                            <div className='flex items-center cursor-pointer text-b5 text-grey-900 space-x-2'>
                              <Checkbox isChecked={allowCustomPrice} />
                              <span className='text-grey-900 whitespace-nowrap'>
                                Set custom price
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {allowCustomPrice ? (
                      <div className="flex flex-col space-y-3">
                        <FormLabel htmlFor='isDepositRequired'>
                          Custom price
                        </FormLabel>
                        {Array?.isArray(serviceCustomPrices) && serviceCustomPrices?.map((serviceCustomPrice, index) => {
                          return (
                            <div className='w-full flex gap-2'>
                              <input
                                className='w-full max-w-[40%] flex items-center appearance-none border border-grey-20 px-4 py-3 font-normal text-b6 xl:text-b4 text-grey-900 rounded-lg focus:outline-none focus:border-grey-20 focus:shadow-grey-100 placeholder:text-grey-400'
                                type='text'
                                value={formatNumber(serviceCustomPrice.price)}
                                onChange={(e) => changeCustomPrice(index, formatNumber(e.target.value))}
                                placeholder='Enter price'
                              />
                              <div className="w-full max-w-[60%]">
                                <MultiSelect
                                  selected={serviceCustomPrice?.staff || []}
                                  options={watch('salonStaffIds') as []}
                                  setSelected={onChangeCustomPriceStaff(index)}
                                />
                              </div>
                            </div>
                          )
                        })}

                        <Button
                          variant='light'
                          size='xs'
                          rounded='lg'
                          type="button"
                          className='w-fit border border-grey-100'
                          onClick={addMoreCustomPrice}
                        >
                          Add more
                          <SvgPlus width="14px" height="14px" />
                        </Button>
                      </div>
                    ) : null}
                  </div>
                  <div className='w-full xl:w-1/2 space-y-2'>
                    <div className='w-full flex flex-col space-y-2'>
                      <FormLabel htmlFor='duration'>Duration</FormLabel>
                      <div className='w-full flex items-center gap-x-4'>
                        <Input
                          name='durationHours'
                          id='duration-hours'
                          type='text'
                          placeholder='Hour(s)'
                          control={control}
                          rules={{
                            pattern: REGEX_PATTERNS.NUMBER,
                            required: !watch('durationMinutes') && ERRORS.TIME_REQUIRED
                          }}
                        />
                        <span className='text-b5 text-grey-900'>:</span>
                        <Input
                          name='durationMinutes'
                          id='duration-minutes'
                          type='text'
                          placeholder='Minutes'
                          control={control}
                          rules={{
                            pattern: REGEX_PATTERNS.NUMBER,
                            required: !watch('durationHours') && ERRORS.TIME_REQUIRED
                          }}
                        />
                      </div>
                      {errors?.durationHours || errors?.durationMinutes ? (
                        <FormHelperText key={ERRORS.TIME_REQUIRED} variant='error'>
                          {ERRORS.TIME_REQUIRED}
                        </FormHelperText>
                      ) : null}
                    </div>
                    <div className='w-full flex flex-col space-y-4'>
                      <div className='w-full flex flex-wrap gap-4 xl:gap-0 xl:space-x-4'>
                        <div className='flex' onClick={() => { setAllowServiceExtraTime(!allowServiceExtraTime) }}>
                          <div className='flex items-center cursor-pointer text-b5 text-grey-900 space-x-2'>
                            <Checkbox isChecked={allowServiceExtraTime} />
                            <span className='text-grey-900 whitespace-nowrap'>
                              Add extra time in-between
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    {allowServiceExtraTime ? (
                      <div className='w-full flex flex-col space-y-2'>
                        <FormLabel htmlFor='extraTime'>Extra time</FormLabel>
                        <div className='w-full flex items-center gap-x-4'>
                          <Input
                            name='extraTimeHours'
                            id='extra-time-hours'
                            type='text'
                            placeholder='Extra Time Hour(s)'
                            control={control}
                            rules={{
                              pattern: REGEX_PATTERNS.NUMBER,
                              required: !watch('extraTimeMinutes') && ERRORS.TIME_REQUIRED
                            }}
                          />
                          <span className='text-b5 text-grey-900'>:</span>
                          <Input
                            name='extraTimeMinutes'
                            id='extra-time-minutes'
                            type='text'
                            placeholder='Extra Time Minutes'
                            control={control}
                            rules={{
                              pattern: REGEX_PATTERNS.NUMBER,
                              required: !watch('extraTimeHours') && ERRORS.TIME_REQUIRED
                            }}
                          />
                        </div>
                        {allowServiceExtraTime && errors?.extraTimeHours || errors?.extraTimeMinutes ? (
                          <FormHelperText key={ERRORS.TIME_REQUIRED} variant='error'>
                            {ERRORS.TIME_REQUIRED}
                          </FormHelperText>
                        ) : null}
                      </div>
                    ) : null}
                  </div>
                </div>
                <Controller
                  control={control}
                  name='isDepositRequired'
                  render={({ field: { onChange, value } }) => {
                    return (
                      <div
                        className='w-full flex flex-wrap gap-4 xl:gap-0 xl:space-x-4'
                        onClick={() => onChange(!value)}
                      >
                        <FormLabel htmlFor='isDepositRequired'>
                          Is deposit required to book this service?
                        </FormLabel>
                        <div className='flex items-center cursor-pointer text-b5 text-grey-900 space-x-2'>
                          <ToggleOnly isChecked={value} />
                        </div>
                      </div>
                    )
                  }}
                />

                {watch('isDepositRequired') && (
                  <>
                    <div className='w-full flex items-center gap-x-4'>
                      <SelectInput
                        name='depositType'
                        id='depositValue-type'
                        label='How much deposit is required?'
                        control={control}
                        rules={{
                          required: ERRORS.DEPOSIT_TYPE_REQUIRED
                        }}
                        error={errors.depositType}
                        value={watch('depositType')}
                        options={DEPOSIT_TYPE_REQUIREMENTS}
                        placeholder='Select Requirement'
                      />
                      <Controller
                        control={control}
                        name='depositValue'
                        rules={{
                          required: 'How much deposit is required for this service?'
                        }}
                        render={({ field, formState: { errors } }) => (
                          <div
                            style={{ marginTop: '28px' }}
                            className='w-full flex flex-col mt-7'
                          >
                            <div className='flex w-full'>
                              <input
                                className='w-full flex items-center appearance-none border border-grey-20 px-4 py-3 font-normal text-b6 xl:text-b4 text-grey-900 rounded-tl-lg rounded-bl-lg focus:outline-none focus:border-grey-20 focus:shadow-grey-100 placeholder:text-grey-400'
                                type='text'
                                {...field}
                                value={formatNumber(field.value)}
                                onChange={(e) =>
                                  field.onChange(formatNumber(e.target.value))
                                }
                                placeholder='10'
                              />
                              <div className='px-5 py-3 bg-grey-20 rounded-tr-lg rounded-br-lg text-grey-900'>
                                {typeSign}
                              </div>
                            </div>
                            {errors?.depositValue?.message && (
                              <FormHelperText variant='error'>
                                {errors.depositValue.message}
                              </FormHelperText>
                            )}
                          </div>
                        )}
                      />
                    </div>
                  </>
                )}
              </div>
            </>
            : activeTab === SERVICE_CREATION_TAB_NAME?.REMINDERS ?
              <div className='w-full px-6 py-[60px] space-y-6'>
                {canPerformAction(PERMISSION_CONSTANTS.service.after_care) ?
                  <div className='w-full flex space-x-3'>
                    <div className='cursor-pointer' onClick={() => setIsAfterCareEnabled(!isAfterCareEnabled)}>
                      <Checkbox isChecked={isAfterCareEnabled} />
                    </div>
                    <div className='w-full flex flex-col gap-y-3'>
                      <div className='w-full flex flex-col gap-y-1'>
                        <Paragraph size="b5" color={COLORS.BLACK}>
                          Aftercare instructions
                        </Paragraph>
                        <Paragraph size="b5" color={COLORS.GREY[300]}>
                          Include aftercare instructions in your thank-you notifications to clients.
                        </Paragraph>
                      </div>
                      {isAfterCareEnabled ?
                        <Controller
                          control={control}
                          name="aftercareMessage"
                          render={({
                            field: { onChange, onBlur, name, ref, value },
                            formState: { errors },
                          }) => {
                            const maxLength = MAXIMUM_NOTE_LENGTH;
                            const formErrorsHelpTexts = getHelperTextForReactHookFormErrors(
                              errors?.aftercareMessage?.message as string
                            );
                            const helperTexts = [];

                            helperTexts.push(getHelpTextForCharacterLeft(maxLength, value));
                            if (formErrorsHelpTexts) helperTexts.push(formErrorsHelpTexts);

                            return (
                              <TextEditor handleSetBody={handleSetBody} defaultValue={value} />
                            );
                          }}
                        /> : null}
                    </div>
                  </div> : null}
                {canPerformAction(PERMISSION_CONSTANTS.service.rebook) ?
                  <div className='w-full flex items-start space-x-3'>
                    <Controller
                      control={control}
                      name='rebookReminder'
                      render={({ field: { onChange, value } }) => {
                        return (
                          <div
                            className='w-fit flex flex-wrap gap-4 xl:gap-0 xl:space-x-4'
                            onClick={() => onChange(!value)}
                          >
                            <div className='flex items-center cursor-pointer text-b5 text-grey-900 space-x-2'>
                              <Checkbox isChecked={value} />
                            </div>
                          </div>
                        )
                      }}
                    />
                    <div className='w-full flex flex-col gap-y-3'>
                      <div className='w-full flex flex-col gap-y-1'>
                        <Paragraph size="b5" color={COLORS.BLACK}>
                          Reminder to rebook
                        </Paragraph>
                        <Paragraph size="b5" color={COLORS.GREY[300]}>
                          Select when to notify clients to rebook this service.
                        </Paragraph>
                      </div>
                      {watch('rebookReminder') ?
                        <div className="w-full flex items-start space-x-2">
                          <Controller
                            control={control}
                            name='rebookReminderPeriod'
                            rules={{
                              required: watch('rebookReminder') ? 'Period is required' : null
                            }}
                            render={({ field }) => (
                              <div className='flex flex-col space-y-2'>
                                <div className='w-[55px] flex items-center border border-grey-20 px-4 py-3 font-normal text-b6 xl:text-b4 text-grey-900 rounded-lg focus:outline-none focus:border-grey-20 focus:shadow-grey-100 placeholder:text-grey-400 space-x-3'>
                                  <input
                                    className='w-full flex items-center appearance-none font-normal text-b6 xl:text-b4 text-grey-900 focus:outline-none focus:border-0 focus:shadow-none placeholder:text-grey-400'
                                    type='text'
                                    {...field}
                                    value={formatNumber(field.value)}
                                    onChange={(e) =>
                                      field.onChange(formatNumber(e.target.value))
                                    }
                                    placeholder='0'
                                  />
                                </div>

                                {errors?.rebookReminderPeriod?.message && (
                                  <FormHelperText variant='error'>
                                    {errors.rebookReminderPeriod.message}
                                  </FormHelperText>
                                )}
                              </div>
                            )}
                          />
                          <div className='w-fit border border-grey-100 flex items-center space-x-4 px-4 py-3 font-normal rounded-md'>
                            <select id="" className='w-fit text-grey-300 flex items-center appearance-none font-normal text-b5 xl:text-b4 focus:outline-none focus:border-0 focus:shadow-none placeholder:text-grey-400' value={rebookPeriodType} onChange={handleRebookTypeChange}>
                              {[{
                                label: 'Days after',
                                value: 'days'
                              }, {
                                label: 'Weeks after',
                                value: 'weeks'
                              }].map((option) => (
                                <option key={option.value} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </select>
                            <SvgArrowDown width="10px" height="10px" />
                          </div>
                        </div> : null}
                    </div>
                  </div> : null}
              </div>
              : null}

          <div className='w-full max-w-[850px] fixed bottom-0 bg-white flex justify-between border-t border-grey-100 cursor-pointer'>
            <div className='w-full flex justify-end py-4 px-8'>
              <div className='flex space-x-2'>
                <Button
                  variant='text'
                  className={`w-fit text-red-500`}
                  size='sm'
                  type='button'
                  disabled={false}
                  loading={isDeleting}
                  onClick={() => handleCancelOrDelete()}
                >
                  {service && actions?.deleteService ? 'Delete' : 'Cancel'}
                </Button>
                {activeTab === SERVICE_CREATION_TAB_NAME?.REMINDERS ?
                  <Button
                    variant='secondary'
                    className=''
                    disabled={loading}
                    loading={loading}
                    size='md'
                    rounded='lg'
                    type="button"
                    onClick={() => handlePrev()}
                  >
                    Prev
                  </Button>
                  : null}
                {activeTab === SERVICE_CREATION_TAB_NAME?.BASIC && (canPerformAction(PERMISSION_CONSTANTS.service.after_care) || canPerformAction(PERMISSION_CONSTANTS.service.rebook)) ?
                  <Button
                    variant='primary'
                    className=''
                    disabled={loading}
                    loading={loading}
                    size='md'
                    rounded='lg'
                  >
                    Proceed
                  </Button>
                  : !service ? <Button
                    variant='primary'
                    className=''
                    disabled={loading}
                    loading={loading}
                    size='md'
                    rounded='lg'
                  >
                    Save
                  </Button> : null}
                {service && actions?.editService && activeTab === SERVICE_CREATION_TAB_NAME?.BASIC && (!canPerformAction(PERMISSION_CONSTANTS.service.after_care) && !canPerformAction(PERMISSION_CONSTANTS.service.rebook)) ?
                  <Button
                    variant='primary'
                    className=''
                    disabled={loading}
                    loading={loading}
                    size='md'
                    rounded='lg'
                  >
                    Save
                  </Button> : null}
                {service && activeTab === SERVICE_CREATION_TAB_NAME?.REMINDERS && actions?.editService ?
                  <Button
                    variant='primary'
                    className=''
                    disabled={loading}
                    loading={loading}
                    size='md'
                    rounded='lg'
                  >
                    Save
                  </Button>
                  : null}
              </div>
            </div>
          </div>
        </form>
      </Modal>
      <DeleteServiceDialog
        isVisible={isDeleteServiceDialogModalVisible}
        closeModal={initiateCloseDeleteService}
        service={service}
        addToast={addToast}
      />
    </>
  )
}

type AddServiceCategoryInput = {
  name: string;
  description: string
  services: []
  staff: []
}
const AddServiceCategoryModal = ({
  isVisible,
  closeModal,
  addToast,
  category
}: {
  isVisible: boolean;
  closeModal: () => void;
  addToast: (toast: ToastProps) => void;
  category?: Category
}) => {
  const {
    isVisible: isServiceModalVisible,
    openModal: openAddServiceModal,
    closeModal: closeAddServiceModal
  } = useModal()
  const { getSalonFieldValue, getSalonData } = useSalonCache()
  const salonId = getSalonFieldValue('id')
  const salon = getSalonData()
  const {
    data: servicesData,
    refetch: refetchServices
  } = useGetServices(salonId)
  const services = useMemo(() => {
    return sortServices(servicesData?.services)
  }, [servicesData])
  const servicesOptions = useMemo(() => services?.map((service) => ({
    label: `${service?.name}`,
    value: service?.id,
  })), [services])

  const {
    data: staffListData,
    refetch: refetchStaffList
  } = useGetStaffList({
    salonId,
    active: true
  })
  const staff = useMemo(() => staffListData?.staffManagement?.staffs, [staffListData])
  const staffMembersOptions = useMemo(() => formatStaffToSelectField(staff), [staff])

  const handleClose = () => {
    closeModal()
    setValue('name', '')
    setValue('description', '')
    setValue('services', [])
    setValue('staff', [])
  }

  const actions = {
    addService: canPerformAction(`Catalogue::Service::${PERMISSION_CONSTANTS.service?.add}`),
    editService: canPerformAction(`Catalogue::Service::${PERMISSION_CONSTANTS.service?.edit}`),
    deleteService: canPerformAction(`Catalogue::Service::${PERMISSION_CONSTANTS.service.delete}`),
    viewService: canPerformAction(`Catalogue::Service::${PERMISSION_CONSTANTS.service.view}`),
  }

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors }
  } = useForm<AddServiceCategoryInput>({
    delayError: 100,
    mode: 'onChange',
  })
  const {
    loading,
    createCategory,
  } = useCreateCategory()

  const handleCreateServiceCategory = async (input: AddServiceCategoryInput) => {
    const servicesInput = input?.services as MultiSelectOption[];
    const staff = input?.staff as MultiSelectOption[];
    // process input
    const serviceIds = servicesInput ? servicesInput?.map((service) => service?.value) : []
    const staffIds = staff ? staff?.map((staff) => staff?.value) : []

    try {
      const { data } = await createCategory({
        variables: {
          input: {
            name: input?.name,
            description: input?.description,
            serviceIds,
            salonStaffIds: staffIds,
            id: category?.id || null
          }
        }
      });

      if (data?.createCategory?.status === 200 && data?.createCategory?.category) {
        closeModal()
      }

      if (data?.createCategory?.errors?.length) {
        addToast({
          message: data?.createCategory?.errors[0]?.message,
          variant: 'error'
        })
      }
    } catch (error) {
      addToast({
        message: error?.message || 'Something went wrong',
        variant: 'error'
      })
    }
  }

  const handleCancelOrDelete = () => {
    handleClose()
  }

  const handleCloseAddServiceModal = () => {
    closeAddServiceModal()
    refetchServices()
    refetchStaffList()
  }

  // setValue category
  useEffect(() => {
    if (category) {
      setValue('name', category?.name)
      // setValue('description', category?.description)
      setValue('services', category?.services?.length ? category?.services?.map((service) => ({
        label: service?.name,
        value: service?.id
      })) as [] : [])
      setValue('staff', category?.salonStaffs?.length ? formatStaffToSelectField(category?.salonStaffs) as [] : [])
    }
  }, [category, setValue])

  const removeService = (id: string) => {
    const _services = watch('services') as MultiSelectOption[]
    const newServices = _services.filter((service) => service.value !== id)
    setValue('services', newServices as [])
  }

  const servicesSummary = () => {
    return (
      <div className='w-full flex flex-wrap gap-2'>
        {watch('services') && Array?.isArray(watch('services')) && watch('services')?.length ? watch('services')?.map((selectedService: MultiSelectOption) => {
          const service = services?.find((s) => s?.id === selectedService?.value);
          return (
            <div className="flex space-x-2 items-center py-2 px-4 border border-grey-20 rounded-full bg-grey-20/20 cursor-pointer" onClick={() => removeService(service?.id)}>
              <Paragraph color={COLORS.GREY[300]} size="b4" >{service?.name} - {formatInToPrice(service?.price)}</Paragraph>
              <SvgClose width="16px" height="16px" />
            </div>
          )
        }) : null}
      </div>
    )
  }
  return (
    <>
      <Modal
        show={isVisible}
        closeModal={handleClose}
        variant='right'
      >
        <form
          onSubmit={handleSubmit(handleCreateServiceCategory)}
          className='w-full relative my-[80px]'
          autoComplete='off'
        >
          <div className='fixed top-0 w-full bg-white flex border-b border-grey-100 cursor-pointer z-10'>
            <div className='w-full hidden xl:flex space-x-2 px-6 py-5 items-start'>
              <Button
                variant='text'
                size='none'
                type='button'
                className='w-fit'
                fontSize='b4'
                onClick={handleClose}
              >
                <SvgArrowBack width='24px' height='24px' /> <span>Back</span>
              </Button>
            </div>
            <div className='w-full flex xl:hidden space-x-[69px] p-4 items-center'>
              <Button
                variant='icon'
                size='square'
                type='button'
                rounded='md'
                className='w-fit'
                fontSize='b4'
                onClick={handleClose}
              >
                <SvgChevronLeft width='24px' height='24px' />
              </Button>
              <Paragraph size='b3' weight='bold'>
                Category
              </Paragraph>
            </div>
          </div>

          <div className='w-full flex flex-col px-6 py-4 space-y-6'>
            <Heading variant='h1' size='h9' weight='semiBold'>
              {category ? 'Edit category details' : 'Create a new category'}
            </Heading>
          </div>

          <div className='w-full flex flex-col p-6 space-y-6'>
            <Disclaimer title="Assigned staff can perform all services within this category" type='orange' message="" />
            <Input
              name='name'
              label='Category name'
              id='service-name'
              type='text'
              placeholder='E.g Nails'
              control={control}
              rules={{
                required: 'Category is required'
              }}
              error={errors.name}
            />
            <Controller
              control={control}
              name="description"
              render={({
                field: { onChange, onBlur, name, ref, value },
                formState: { errors },
              }) => {
                const maxLength = MAXIMUM_NOTE_LENGTH;
                const formErrorsHelpTexts = getHelperTextForReactHookFormErrors(
                  errors?.description?.message as string
                );
                const helperTexts = [];

                helperTexts.push(getHelpTextForCharacterLeft(maxLength, value));
                if (formErrorsHelpTexts) helperTexts.push(formErrorsHelpTexts);

                return (
                  <FormTextarea
                    type="text"
                    id="about"
                    label="Description"
                    placeholder="A brief description of this category"
                    {...{
                      ref,
                      name,
                      value,
                      onChange,
                      onBlur,
                      maxLength,
                    }}
                    helperText={helperTexts}
                  />
                );
              }}
            />
            <div className='w-full flex flex-col space-y-2'>
              <Controller
                control={control}
                name='services'
                render={({
                  field: { onChange, value },
                  formState: { errors }
                }) => {
                  const errorMessage = errors?.services?.message
                  return (
                    <>
                      <div className="flex items-center space-x-2">
                        <FormLabel htmlFor='staff'>Services</FormLabel>
                        <Button
                          variant="light"
                          size="xs"
                          rounded="lg"
                          type="button"
                          className="border border-grey-100 text-grey-300 font-semibold"
                          onClick={openAddServiceModal}
                        >
                          <SvgPlus width="14px" height="14px" />
                          Add service
                        </Button>
                      </div>
                      <MultiSelect
                        selected={value || []}
                        options={servicesOptions}
                        setSelected={onChange}
                      />
                      {errorMessage && (
                        <FormHelperText variant='error'>
                          {errorMessage}
                        </FormHelperText>
                      )}
                    </>
                  )
                }}
              />
              {servicesSummary()}
            </div>
            <div className='w-full flex flex-col space-y-2'>
              <Controller
                control={control}
                name='staff'
                render={({
                  field: { onChange, value },
                  formState: { errors }
                }) => {
                  const errorMessage = errors?.staff?.message
                  return (
                    <>
                      <FormLabel htmlFor='staff'>Assign Staff</FormLabel>
                      <MultiSelect
                        selected={value || []}
                        options={staffMembersOptions}
                        setSelected={onChange}
                      />
                      {errorMessage && (
                        <FormHelperText variant='error'>
                          {errorMessage}
                        </FormHelperText>
                      )}
                    </>
                  )
                }}
              />
            </div>
          </div>

          <div className='w-full max-w-[850px] fixed bottom-0 bg-white flex justify-between border-t border-grey-100 cursor-pointer'>
            <div className='w-full flex justify-end py-4 px-8'>
              <div className='flex space-x-2'>
                <Button
                  variant='text'
                  className={` text-red-500`}
                  size='sm'
                  type='button'
                  disabled={loading}
                  loading={false}
                  onClick={() => handleCancelOrDelete()}
                >
                  Cancel
                </Button>
                <Button
                  variant='primary'
                  className=''
                  disabled={loading}
                  loading={loading}
                  size='md'
                  rounded='lg'
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        </form>
      </Modal>
      <AddServiceModal
        service={null}
        isVisible={isServiceModalVisible}
        closeModal={handleCloseAddServiceModal}
        actions={actions}
        addToast={addToast}
        category={category}
      />
    </>
  )
}

const ViewServiceCategoryModal = ({
  isVisible,
  closeModal,
  category,
  addToast
}: {
  isVisible: boolean;
  closeModal: () => void;
  addToast: (toast: ToastProps) => void;
  category: Category
}) => {
  const {
    isVisible: isViewServiceModalVisible,
    openModal: openViewServiceModal,
    closeModal: closeViewServiceModal
  } = useModal()
  const {
    isVisible: isServiceModalVisible,
    openModal: openAddServiceModal,
    closeModal: closeAddServiceModal
  } = useModal()
  const [service, setService] = useState<Service | null>(null)

  const actions = {
    addService: canPerformAction(`Catalogue::Service::${PERMISSION_CONSTANTS.service?.add}`),
    editService: canPerformAction(`Catalogue::Service::${PERMISSION_CONSTANTS.service?.edit}`),
    deleteService: canPerformAction(`Catalogue::Service::${PERMISSION_CONSTANTS.service.delete}`),
    viewService: canPerformAction(`Catalogue::Service::${PERMISSION_CONSTANTS.service.view}`),
  }

  const handleCloseViewServiceModal = () => {
    setService(null)
    closeViewServiceModal()
  }
  const handleCloseAddServiceModal = () => {
    setService(null)
    closeAddServiceModal()
  }
  return (
    <>
      <Modal
        show={isVisible}
        closeModal={closeModal}
        variant='right'
      >
        <div
          className='w-full relative my-[80px]'
        >
          <div className='fixed top-0 w-full bg-white flex border-b border-grey-100 cursor-pointer z-10'>
            <div className='w-full hidden xl:flex space-x-2 px-6 py-5 items-start'>
              <Button
                variant='text'
                size='none'
                type='button'
                className='w-fit'
                fontSize='b4'
                onClick={closeModal}
              >
                <SvgArrowBack width='24px' height='24px' /> <span>Back</span>
              </Button>
            </div>
            <div className='w-full flex xl:hidden space-x-[69px] p-4 items-center'>
              <Button
                variant='icon'
                size='square'
                type='button'
                rounded='md'
                className='w-fit'
                fontSize='b4'
                onClick={closeModal}
              >
                <SvgChevronLeft width='24px' height='24px' />
              </Button>
              <Paragraph size='b3' weight='bold'>
                Category
              </Paragraph>
            </div>
          </div>

          <div className='w-full flex flex-col px-6 py-4 space-y-6'>
            <Paragraph size='b4'>
              Category Details
            </Paragraph>
          </div>

          <div className='w-full flex flex-col p-6 space-y-6'>
            <div className="w-full flex flex-col border border-grey-100/70 rounded-md" key={category?.name}>
              <div className="w-full py-2 px-4 flex justify-between items-center border-b border-grey-100/70">
                <div className='flex flex-col'>
                  <Paragraph size='b3' weight='semiBold' className="capitalize" color={COLORS.GREY[900]}>
                    {category?.name}
                  </Paragraph>
                  <Paragraph size='b5' color={COLORS.GREY[300]}>
                    {category?.services?.length} services
                  </Paragraph>
                </div>
                {category?.salonStaffs?.length ?
                  <div className='w-fit border border-grey-50 px-3 py-1 rounded-full'>
                    <Paragraph size='b5' color={COLORS.GREY[300]}>
                      {category?.salonStaffs?.map((staff) => staff?.user?.firstName)}
                    </Paragraph>
                  </div> : <div className='w-fit border border-grey-50 px-3 py-1 rounded-full'>
                    <Paragraph size='b5' color={COLORS.GREY[300]}>
                      No staff assigned
                    </Paragraph>
                  </div>}
              </div>

              <div className="w-full flex flex-col space-y-4 p-6">
                {category?.services?.map((service) => {
                  return (
                    <div className="w-full bg-grey-20/40 border-grey-100/30 rounded-sm border border-l-4 py-3 px-4 flex justify-between items-center cursor-pointer">
                      <div className='flex flex-col space-y-1' onClick={() => {
                        setService(service)
                        openViewServiceModal()
                      }}>
                        <Paragraph size="b5" color={COLORS.GREY[300]}>
                          {service?.name}
                        </Paragraph>
                        <div className='flex items-center space-x-3'>
                          <Paragraph size="b6" color={COLORS.GREY[300]}>
                            {getHoursAndMinutesString(service?.duration)}
                          </Paragraph>
                          <Paragraph size="b6" color={COLORS.GREY[300]} weight='bold'>
                            {formatInToPrice(service?.price)}
                          </Paragraph>
                        </div>
                      </div>

                      <Button
                        variant="text"
                        size="xs"
                        rounded="lg"
                        fontWeight='semiBold'
                        type="button"
                        className="text-grey-300"
                        onClick={() => {
                          setService(service)
                          openAddServiceModal()
                        }}
                      >
                        <SvgEditRegular width="14px" height="14px" />
                        Edit
                      </Button>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <ViewServiceModal
        service={service}
        isVisible={isViewServiceModalVisible}
        closeModal={handleCloseViewServiceModal}
      />
      <AddServiceModal
        service={service}
        isVisible={isServiceModalVisible}
        closeModal={handleCloseAddServiceModal}
        actions={actions}
        addToast={addToast}
        category={category}
      />
    </>
  )
}

type ViewServiceModalProps = {
  service: Service
  isVisible: boolean
  closeModal: () => void
}

const ViewServiceModal = ({
  service,
  closeModal,
  isVisible,
}: ViewServiceModalProps) => {
  const cleanHtml = DOMPurify.sanitize(service?.aftercareMessage);

  const checkIfThereIsACustomPrice = () => {
    const price = service?.price;
  if (service?.serviceStaffs) {
      return false;
    }
    if (!service?.serviceStaffs?.length) {
      return false;
    }

    service?.serviceStaffs?.map((staff) => {
      if (staff?.price !== price) {
        return true;
      }
    })
  }
  return (
    <Modal
      show={isVisible}
      closeModal={closeModal}
      variant='right'
    >
      <div
        className='w-full relative my-[80px]'
      >
        <div className='fixed top-0 w-full bg-white flex border-b border-grey-100 cursor-pointer z-10'>
          <div className='w-full hidden xl:flex space-x-2 px-6 py-5 items-start'>
            <Button
              variant='text'
              size='none'
              type='button'
              className='w-fit'
              fontSize='b4'
              onClick={closeModal}
            >
              <SvgArrowBack width='24px' height='24px' /> <span>Back</span>
            </Button>
          </div>
          <div className='w-full flex xl:hidden space-x-[69px] p-4 items-center'>
            <Button
              variant='icon'
              size='square'
              type='button'
              rounded='md'
              className='w-fit'
              fontSize='b4'
              onClick={closeModal}
            >
              <SvgChevronLeft width='24px' height='24px' />
            </Button>
            <Paragraph size='b3' weight='bold'>
              Service
            </Paragraph>
          </div>
        </div>

        <div className='w-full flex flex-col px-6 py-4 space-y-6'>
          <Heading variant='h1' size='h9' weight='semiBold'>
            View service
          </Heading>
        </div>

        <div className='w-full flex flex-col p-6 space-y-2'>
          <div className="px-2 py-3 flex justify-between items-center space-x-4 bg-grey-10">
            <Paragraph size="b4" color="grey-300" className="text-left">
              Name
            </Paragraph>
            <Paragraph size="b4" color="grey-800" weight="semiBold" className="text-right">
              {service?.name}
            </Paragraph>
          </div>
          <div className="px-2 py-3 flex justify-between items-center space-x-4 bg-grey-10">
            <Paragraph size="b4" color="grey-300" className="text-left">
              Price
            </Paragraph>
            <div className='flex space-x-3'>
              {service?.pricingType === 'from' ? (<span className='text-grey-400 bg-grey-100 text-b7 px-1 py-0.5 rounded-full mr-2'>from</span>) : null}
              <Paragraph size="b4" color="grey-800" weight="semiBold" className="text-right">
                {formatInToPrice(service?.price)}
              </Paragraph>
            </div>
          </div>
          <div className="px-2 py-3 flex justify-between items-center space-x-4 bg-grey-10">
            <Paragraph size="b4" color="grey-300" className="text-left">
              Custom Prices
            </Paragraph>
            {checkIfThereIsACustomPrice() ?
              <div className='flex flex-wrap space-x-3'>
                {Array?.isArray(service?.serviceStaffs) && service?.serviceStaffs?.map((staff) => {
                  return (
                    <Paragraph size="b4" color="grey-800" weight="semiBold" className="text-right">
                      {staff?.salonStaff?.user?.firstName}: {formatInToPrice(staff?.price)}
                    </Paragraph>
                  )
                })}
              </div> : null}
          </div>
          <div className="px-2 py-3 flex justify-between items-center space-x-4 bg-grey-10">
            <Paragraph size="b4" color="grey-300" className="text-left">
              Duration
            </Paragraph>
            <Paragraph size="b4" color="grey-800" weight="semiBold" className="text-right">
              {getHoursAndMinutesString(service?.duration)}
            </Paragraph>
          </div>
          <div className="px-2 py-3 flex justify-between items-center space-x-4 bg-grey-10">
            <Paragraph size="b4" color="grey-300" className="text-left">
              Description
            </Paragraph>
            <Paragraph size="b4" color="grey-800" weight="semiBold" className="text-right">
              {service?.description}
            </Paragraph>
          </div>
          <div className="px-2 py-3 flex justify-between items-center space-x-4 bg-grey-10">
            <Paragraph size="b4" color="grey-300" className="text-left">
              Deposit Required
            </Paragraph>
            <Paragraph size="b4" color="grey-800" weight="semiBold" className="text-right">
              {!service?.isDepositRequired ? 'No' : service?.depositType === 'percentage' ? `${service?.depositValue}%` : `${formatInToPrice(service?.depositValue)}`}
            </Paragraph>
          </div>
          <div className="px-2 py-3 flex justify-between items-center space-x-4 bg-grey-10">
            <Paragraph size="b4" color="grey-300" className="text-left">
              Allow clients to book service via booking site
            </Paragraph>
            <Paragraph size="b4" color="grey-800" weight="semiBold" className="text-right">
              {!service?.isPublic ? 'No' : 'Yes'}
            </Paragraph>
          </div>
          <div className="px-2 py-3 flex justify-between items-center space-x-4 bg-grey-10">
            <Paragraph size="b4" color="grey-300" className="text-left">
              Staff Assigned
            </Paragraph>
            <Paragraph size="b4" color="grey-800" weight="semiBold" className="text-right">
              {service?.serviceStaffs?.length ? service.serviceStaffs.map(staff => staff?.salonStaff?.user?.firstName).filter(Boolean).join(', ') : ''}
            </Paragraph>
          </div>
          <div className="px-2 py-3 grid grid-cols-2 items-start space-x-4 bg-grey-10">
            <Paragraph size="b4" color="grey-300" className="text-left">
              After care instructions
            </Paragraph>
            <div dangerouslySetInnerHTML={{ __html: cleanHtml }} />
          </div>
        </div>
      </div>
    </Modal>
  )
}

type ServiceDialogProps<T extends string> = {
  service: Service
  isVisible: boolean
  closeModal: () => void
  actions: PermissionActionProp<T>;
  addToast: (toast: ToastProps) => void
  category?: Category,
}

type DeleteServiceDialogProps = {
  service?: Service,
  category?: Category
  isVisible: boolean
  closeModal: (deleteService?: boolean) => void
  addToast: (toast: ToastProps) => void
}
const DeleteServiceDialog = ({
  service,
  isVisible,
  closeModal,
  addToast,
}: DeleteServiceDialogProps) => {
  const { loading: isLoading, deleteService } = useDeleteService()

  const handleDelete = () => {
    deleteService({
      variables: {
        input: {
          id: service?.id
        },
      }
    }).then(({ data }) => {
      const { deleteService } = data
      if (deleteService?.status === 200) {
        addToast({
          message: 'Service Deleted successfully',
          variant: 'success',
        })
        closeModal(true)
      }

      if (deleteService?.errors?.length) {
        addToast({
          message: deleteService?.errors[0]?.message,
          variant: 'error',
        })
      }
    })
  }

  return isVisible ? (
    <div className='fixed top-0 left-0 flex items-center justify-center w-full h-screen bg-black/70 backdrop-blur-[2px] z-[999999]'>
      <div className='table-row'>
        <div className='table-cell align-middle'>
          <div className='w-full xl:border-none bg-white rounded-lg shadow-medium mx-auto py-12 px-9 max-w-[400px] xl:max-w-[500px]'>
            <div className='flex flex-col items-center space-y-6'>
              <Heading
                variant='h1'
                size='h8'
                weight='bold'
                color={COLORS.BLACK}
              >
                Delete Service
              </Heading>
              <Paragraph
                size='b5'
                weight='medium'
                className='w-full text-center max-w-[400px]'
                color={COLORS.GREY[400]}
              >
                Are you sure you want to delete {service?.name}?
              </Paragraph>

              <Button
                variant='danger'
                size='lg'
                rounded='lg'
                fontSize='b5'
                className='capitalize'
                onClick={() => handleDelete()}
                disabled={false}
                loading={isLoading}
              >
                Yes, I'm sure
              </Button>
              <Button
                variant='text'
                size='none'
                rounded='none'
                fontSize='b5'
                className='mx-0'
                onClick={() => closeModal()}
                disabled={false}
              >
                No, go back
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null
}

const DeleteCategoryDialog = ({
  category,
  isVisible,
  closeModal,
  addToast,
}: DeleteServiceDialogProps) => {
  const { loading: isLoading, deleteCategory } = useDeleteCategory()

  const handleDelete = () => {
    deleteCategory({
      variables: {
        input: {
          categoryId: category?.id
        },
      }
    }).then(({ data }) => {
      const { deleteCategory } = data
      if (deleteCategory?.status === 200) {
        addToast({
          message: 'Category Deleted successfully',
          variant: 'success',
        })
        closeModal(true)
      }

      if (deleteCategory?.errors?.length) {
        addToast({
          message: deleteCategory?.errors[0]?.message,
          variant: 'error',
        })
      }
    })
  }

  return isVisible ? (
    <div className='fixed top-0 left-0 flex items-center justify-center w-full h-screen bg-black/70 backdrop-blur-[2px] z-[999999]'>
      <div className='table-row'>
        <div className='table-cell align-middle'>
          <div className='w-full xl:border-none bg-white rounded-lg shadow-medium mx-auto py-12 px-9 max-w-[400px] xl:max-w-[500px]'>
            <div className='flex flex-col items-center space-y-6'>
              <Heading
                variant='h1'
                size='h8'
                weight='bold'
                color={COLORS.BLACK}
              >
                Delete Category
              </Heading>
              <Paragraph
                size='b5'
                weight='medium'
                className='w-full text-center max-w-[400px]'
                color={COLORS.GREY[400]}
              >
                Are you sure you want to delete {category?.name} category?
              </Paragraph>

              <Button
                variant='danger'
                size='lg'
                rounded='lg'
                fontSize='b5'
                className='capitalize'
                onClick={() => handleDelete()}
                disabled={false}
                loading={isLoading}
              >
                Yes, I'm sure
              </Button>
              <Button
                variant='text'
                size='none'
                rounded='none'
                fontSize='b5'
                className='mx-0'
                onClick={() => closeModal()}
                disabled={false}
              >
                No, go back
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null
}

const ArchiveServiceDialog = ({
  service,
  isVisible,
  closeModal
}: DeleteServiceDialogProps) => {
  const { addToast, toast } = useToast()
  const { loading: isLoading, archiveService } = useArchiveService()

  const handleDelete = () => {
    archiveService({
      variables: {
        input: {
          id: service.id
        },
      }
    }).then(({ data }) => {
      const { archiveService } = data
      if (archiveService?.status === 200) {
        addToast({
          message: 'Service Archived successfully',
          variant: 'success',
        })
        closeModal(true)
      }

      if (archiveService?.errors?.length) {
        addToast({
          message: archiveService?.errors[0]?.message,
          variant: 'error',
        })
      }
    })
  }

  return isVisible ? (
    <div className='fixed top-0 left-0 flex items-center justify-center w-full h-screen bg-black/70 backdrop-blur-[2px] z-[999999]'>
      <ToastWrapper toast={toast} />
      <div className='table-row'>
        <div className='table-cell align-middle'>
          <div className='w-full xl:border-none bg-white rounded-lg shadow-medium mx-auto py-12 px-9 max-w-[400px] xl:max-w-[500px]'>
            <div className='flex flex-col items-center space-y-6'>
              <Heading
                variant='h1'
                size='h8'
                weight='bold'
                color={COLORS.BLACK}
              >
                Archive Service
              </Heading>
              <Paragraph
                size='b5'
                weight='medium'
                className='w-full text-center max-w-[400px]'
                color={COLORS.GREY[400]}
              >
                Are you sure you want to archive {service?.name}?
              </Paragraph>

              <Button
                variant='danger'
                size='lg'
                rounded='lg'
                fontSize='b5'
                className='capitalize'
                onClick={() => handleDelete()}
                disabled={false}
                loading={isLoading}
              >
                Yes, I'm sure
              </Button>
              <Button
                variant='text'
                size='none'
                rounded='none'
                fontSize='b5'
                className='mx-0'
                onClick={() => closeModal()}
                disabled={false}
              >
                No, go back
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null
}

const RestoreServiceDialog = ({
  service,
  isVisible,
  closeModal
}: DeleteServiceDialogProps) => {
  const { addToast, toast } = useToast()
  const { loading: isLoading, restoreService } = useRestoreService()

  const handleRestoration = () => {
    restoreService({
      variables: {
        input: {
          id: service.id
        },
      }
    }).then(({ data }) => {
      const { restoreService } = data
      if (restoreService?.status === 200) {
        addToast({
          message: 'Service restored successfully',
          variant: 'success',
        })
        closeModal(true)
      }

      if (restoreService?.errors?.length) {
        addToast({
          message: restoreService?.errors[0]?.message,
          variant: 'error',
        })
      }
    })
  }

  return isVisible ? (
    <div className='fixed top-0 left-0 flex items-center justify-center w-full h-screen bg-black/70 backdrop-blur-[2px] z-[999999]'>
      <ToastWrapper toast={toast} />
      <div className='table-row'>
        <div className='table-cell align-middle'>
          <div className='w-full xl:border-none bg-white rounded-lg shadow-medium mx-auto py-12 px-9 max-w-[400px] xl:max-w-[500px]'>
            <div className='flex flex-col items-center space-y-6'>
              <Heading
                variant='h1'
                size='h8'
                weight='bold'
                color={COLORS.BLACK}
              >
                Restore Service
              </Heading>
              <Paragraph
                size='b5'
                weight='medium'
                className='w-full text-center max-w-[400px]'
                color={COLORS.GREY[400]}
              >
                Are you sure you want to restore {service?.name}? This means it'll be available for bookings.
              </Paragraph>

              <Button
                variant='primary'
                size='lg'
                rounded='lg'
                fontSize='b5'
                className='capitalize'
                onClick={() => handleRestoration()}
                disabled={false}
                loading={isLoading}
              >
                Yes, I'm sure
              </Button>
              <Button
                variant='text'
                size='none'
                rounded='none'
                fontSize='b5'
                className='mx-0'
                onClick={() => closeModal()}
                disabled={false}
              >
                No, go back
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null
}

export const formatServicesCategory = (services: Service[]) => {
  if (!Array.isArray(services) || services.length === 0) {
    return [];
  }

  const groupedServices: GroupedServicesIndex[] = Object.entries(
    services?.reduce((acc: Record<string, Service[]>, service: Service) => {
      const category = service.category?.name?.toLowerCase() ?? 'Uncategorized';

      if (!acc[category]) {
        acc[category] = [];
      }

      acc[category].push(service);

      return acc;
    }, {})
  ).map(([name, services]) => ({
    name,
    orderIndex: services[0].category?.orderIndex ?? null,
    categoryId: services[0].category?.id ?? '', // Include categoryId
    services: services.map((service) => ({ ...service })),
    category: services[0].category?.name ?? '',
  }));

  // Sort grouped services by name in A-Z order
  // groupedServices.sort((a, b) => a.name.localeCompare(b.name));
  // sort by orderIndex
  groupedServices.sort((a, b) => {
    if (a.orderIndex === null && b.orderIndex === null) {
      return 0;
    }
    if (a.orderIndex === null) {
      return 1;
    }
    if (b.orderIndex === null) {
      return -1;
    }
    return a.orderIndex - b.orderIndex;
  });

  return groupedServices;
};

const formatCategoryToOrderList = (categories: GroupedServicesIndex[]) => {
  if (!categories?.length) return []

  categories.sort((a, b) => a.name.localeCompare(b.name));
  categories.sort((a, b) => {
    if (a.orderIndex === null && b.orderIndex === null) {
      return 0;
    }
    if (a.orderIndex === null) {
      return 1;
    }
    if (b.orderIndex === null) {
      return -1;
    }
    return a.orderIndex - b.orderIndex;
  });

  return categories;
}

const formatServicesInCategory = (categories: GroupedServicesIndex[]): {
  id: string;
  content: string;
  services: Service[]
}[] => {
  if (!categories?.length) return []

  return categories.map((category) => ({
    id: category?.categoryId,
    content: category?.name,
    services: sortServices(category?.services)
  }))
}

export default Inventory
