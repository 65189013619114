import React from 'react'
import { SvgInfo } from 'ui'
import { Heading, Paragraph } from '../../../ui'
import { DisclaimerProps } from './types'
import { COLORS } from 'constants/colors'

const Disclaimer = (props: DisclaimerProps) => {
  return (
    <>
      <div className={`hidden w-full rounded-md p-3 xl:flex space-x-2 ${props.type === 'orange' ? 'bg-orange-25 text-orange-300' : 'bg-grey-100 text-grey-300'}`}>
        <SvgInfo width="25px" height="25px" />
        <div className="flex flex-col space-y-2">
          <Heading variant='h2' size='h11' weight='semiBold' color={props.type === 'orange' ? COLORS.ORANGE[300] : COLORS.GREY[300]}>{props.title}</Heading>
          <Paragraph size='b6' color={props.type === 'orange' ? COLORS.ORANGE[300] : COLORS.GREY[300]}>{props.message}</Paragraph>
        </div>
      </div>
      <div className={`w-full p-3 flex flex-col justify-center items-center xl:hidden space-x-2 ${props.type === 'orange' ? 'bg-orange-25 text-orange-300' : 'bg-grey-100 text-grey-300'}`}>
        <div className={`flex space-x-3 items-center ${props.type === 'orange' ? 'text-orange-300' : 'text-grey-300'}`}>
          <SvgInfo width="25px" height="25px" />
          <Heading variant='h2' size='h11' weight='semiBold' color={props.type === 'orange' ? COLORS.ORANGE[300] : COLORS.GREY[300]}>{props.title}</Heading>
        </div>
        <Paragraph size='b6' color={props.type === 'orange' ? COLORS.ORANGE[300] : COLORS.GREY[300]}>{props.message}</Paragraph>
      </div>
    </>
  )
}

export default Disclaimer