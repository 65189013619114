import * as React from 'react'
import { FaUser } from "react-icons/fa";
import { COLORS } from 'constants/colors';
import { UnassignedAppointmentProps } from './types';
import Paragraph from 'ui/atoms/paragraph/Paragraph';
import UnassignedAppointmentsLoadingComponent from './UnassignedAppointmentsLoadingComponent';
import { getNumberMonthAndYearFromDate, getTimeFromISODate, limitString } from '../../utils/misc';
import Button from 'ui/atoms/button/Button';
import { SvgCelebrationPana1 } from 'ui';
import Heading from 'ui/atoms/heading/Heading';

const UnassignedAppointmentList = (props: UnassignedAppointmentProps) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(true);
  const sortedAppointments = props.unassignedAppointments.sort((a, b) => {
    const aDate = new Date(a.dateString).getTime();
    const bDate = new Date(b.dateString).getTime();
    return bDate - aDate;
  });

  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };

  const RedDot = () => (
    <div
      className="ml-1"
      style={{
        width: "8px",
        height: "8px",
        borderRadius: "50%",
        backgroundColor: "red",
      }}
    ></div>
  );
  return (
    <>
      <div className="w-full h-[59px] 3xl:h-[50px] px-4 xl:p-0 flex justify-between xl:justify-center items-center border-r border-b border-grey-50">
        <Paragraph
          size="b5"
          weight="bold"
          color={COLORS.ORANGE[75]}
          className="p-2 bg-[#F8F5EF]"
        >
          Unassigned appointments
        </Paragraph>
        <div className='flex xl:hidden' onClick={toggleCollapse}>
          <svg
            className={`w-5 h-5 transition-transform ${isOpen ? 'transform rotate-180' : ''
              }`}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d={isOpen ? 'M5 15l7-7 7 7' : 'M19 9l-7 7-7-7'}
            />
          </svg>
        </div>
      </div>
      {isOpen ?
        <>
          {props?.isLoading ? (
            // Loading state
            <UnassignedAppointmentsLoadingComponent />
          ) : (
            Array.isArray(sortedAppointments) && sortedAppointments?.length > 0 ? (
              // Display appointments
              <div className="w-full h-full bg-grey-50 flex flex-col justify-start items-start overflow-y-scroll">
                <div className="w-full flex flex-col">
                  {sortedAppointments.map((x, idx) => (
                    <div>
                      <div
                        key={idx}
                        className="w-full flex justify-center items-center h-[30px] border-b border-t border-grey-100"
                      >
                        <Paragraph size="b7" weight="semiBold">
                          {getNumberMonthAndYearFromDate(x.dateString)}
                        </Paragraph>
                      </div>
                      <div className="w-full grid grid-cols-2 md:grid-cols-3 xl:flex xl:flex-col">
                        {x.appointments.map((appointment) => (
                          <div
                            key={appointment.id}
                            className="w-full flex-col flex h-[95px] border-b border-grey-100 justify-between p-2"
                          >
                            <div className="w-full flex h-full align-center justify-between">
                              <div>
                                <Paragraph className="flex items-center" size="b6">
                                  {`${appointment?.client?.firstName} ${appointment?.client?.lastName}`}{" "}
                                  <RedDot />
                                </Paragraph>
                                <div className="mt-2">
                                  <Paragraph size="b6">
                                    {Array?.isArray(appointment?.services) && appointment?.services?.length > 0 ? limitString(appointment?.services.map((x) => x.name).join(", "), 15) : 'No service selected'}
                                  </Paragraph>
                                </div>
                              </div>
                              <Paragraph className="p-[3px]" size="b6" weight="semiBold">
                                {getTimeFromISODate(appointment?.startAt)}
                              </Paragraph>
                            </div>
                            <div>
                              <Button
                                variant="transparent"
                                className="bg-white"
                                size="xs"
                                rounded="sm"
                                onClick={() => props.openAppointment('1', appointment.id)}
                              >
                                <FaUser width="24px" height="24px" />
                                Assign staff
                              </Button>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              // No appointments
              <div className='w-full h-[200px] flex flex-col py-4 px-3 gap-2 justify-center items-center'>
                <SvgCelebrationPana1 width="60px" height="60px" />
                <Heading variant="h2" size="b5">Yay! You’re all caught up!</Heading>
                <Paragraph size='b6' className="text-center" color={COLORS.GREY[300]}>You have no unassigned appointments.</Paragraph>
              </div>
            )
          )}
        </> : null}
    </>
  );
};

export default UnassignedAppointmentList;
