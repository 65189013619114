import * as React from 'react'
import { Logo } from 'ui/atoms/logo'
import axios from 'axios'
import Paragraph from 'ui/atoms/paragraph/Paragraph'
import { COLORS } from 'constants/colors'
import ToastWrapper from 'ui/molecules/toastWrapper/ToastWrapper'
import { useToast } from 'hooks'
import OtpInput from 'ui/molecules/input/OtpInput'
import { OTP_COUNTER } from 'constants/otpCounter'
import Button from 'ui/atoms/button/Button'
import {
  ResendOtpCodeDocument,
  VerifyOtpCodeDocument
} from 'core/generated'
import { PAGE_ROUTES } from 'constants/routes'
import FullStory from 'react-fullstory'
import { ToastProps } from 'ui'
import { useNavigate } from 'react-router-dom'
import { useManageAuth } from '../hooks/useManageAuth'
import { useLazyQuery } from '@apollo/client'
import { RESEND_OTP_CODE_QUERY, useVerifyOtp } from 'api/useOtp'

const VerifyOtpCodePage = () => {
  const navigate = useNavigate();
  const [otp, setOtp] = React.useState('')
  const [disabled, setDisabled] = React.useState(true)
  const { logout: _logout } = useManageAuth()
  const forgotPasswordData = localStorage.getItem('forgotEmailData');
  const forgotPasswordParsedData = forgotPasswordData ? JSON.parse(forgotPasswordData) : null;

  const {
    loading: isLoading,
    verifyOtpCode,
  } = useVerifyOtp()
  const [resendOtp, { loading, error, data, called }] = useLazyQuery(RESEND_OTP_CODE_QUERY);

  const { addToast, toast } = useToast()
  const email = forgotPasswordParsedData?.email;
  const token = forgotPasswordParsedData?.token;

  const handleChange = (otp: string) => {
    setOtp(otp)
    if (otp.length === OTP_COUNTER.INPUTS) {
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }

  const resendOtpCode = async () => {
    setDisabled(true)
    resendOtp();
  }

  const verifyOtp = async () => {
    verifyOtpCode({
      variables: { input: { otpCode: otp } }
    }).then(({ data }) => {
      const { verifyOtpCode } = data;
      if (verifyOtpCode.status === 200) {
        localStorage.setItem('forgotEmailData', JSON.stringify({ email, token, isVerified: true }))
        navigate(PAGE_ROUTES.GENERATE_PASSWORD, {
          replace: true,
        });
      }
      
      if (verifyOtpCode?.errors?.length) {
        addToast({ variant: 'error', message: verifyOtpCode?.errors[0].message })
      }
    })
    .catch((error) => {
      console.error('Error verifying OTP:', error);
    });
  }

  const logout = () => {
    _logout()
  }

  return (
    <>
      <main className='flex items-center justify-center h-screen bg-white xl:bg-grey-100'>
        <div className='table-row'>
          <div className='table-cell align-middle'>
            <div className='w-full xl:border-none bg-white rounded-lg shadow-medium mx-auto py-8 px-9 max-w-[400px] xl:max-w-[500px]'>
              <div className='flex flex-col items-center space-y-9'>
                <Logo />
                <Paragraph
                  size='b5'
                  weight='medium'
                  className='w-full text-center max-w-[400px]'
                  color={COLORS.GREY[400]}
                >
                  A One-time Passcode (OTP) has been sent to {email}. Enter code
                  below.
                </Paragraph>
                <ToastWrapper toast={toast as ToastProps} />
                <OtpInput
                  value={otp}
                  id="otp-code-input"
                  onChange={handleChange}
                  numInputs={OTP_COUNTER.INPUTS}
                  separator={<span></span>}
                  isInputNum={true}
                  className='custom-otp-input'
                />

                <div className='w-full flex justify-center space-x-2'>
                  <Paragraph size='b5' className='' color={COLORS.GREY[400]}>
                    Haven’t received an OTP?
                  </Paragraph>
                  <Button
                    variant='text'
                    className='text-b5 mx-0'
                    fontWeight='bold'
                    size='none'
                    type='button'
                    onClick={resendOtpCode}
                  >
                    Resend OTP
                  </Button>
                </div>

                <Button
                  variant='primary'
                  className=''
                  disabled={disabled}
                  loading={false}
                  size='lg'
                  rounded='lg'
                  onClick={verifyOtp}
                >
                  Submit
                </Button>
                <Button
                  variant='transparent'
                  className='mx-auto'
                  disabled={false}
                  loading={false}
                  size='none'
                  onClick={logout}
                >
                  Logout
                </Button>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default VerifyOtpCodePage
