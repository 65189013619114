import { usePauseClientMembership } from "api/useClient"
import { ClientMembershipProps } from "../types"
import { Button, Heading, Paragraph } from "ui"
import { COLORS } from "constants/colors"

const PauseClientMembership = ({ isVisible, closeModal, addToast, client }: ClientMembershipProps) => {
  const { loading: isLoading, pauseClientMembership } = usePauseClientMembership()
  const handleDelete = () => {
    pauseClientMembership({
      variables: {
        input: {
          id: client?.clientMembership?.id
        },
      }
    }).then(({ data }) => {
      const { pauseClientMembership } = data
      if (pauseClientMembership?.status === 200) {
        addToast({
          message: 'Client Membership Paused successfully',
          variant: 'success',
        })
        closeModal()
      }

      if (pauseClientMembership?.errors?.length) {
        addToast({
          message: pauseClientMembership?.errors[0]?.message,
          variant: 'error',
        })
      }
    })
  }
  return isVisible ? (
    <div className='fixed top-0 left-0 flex items-center justify-center w-full h-screen bg-black/70 backdrop-blur-[2px] z-[999999]'>
      <div className='table-row'>
        <div className='table-cell align-middle'>
          <div className='w-full xl:border-none bg-white rounded-lg shadow-medium mx-auto py-12 px-9 max-w-[400px] xl:min-w-[500px]'>
            <div className='flex flex-col items-center space-y-6'>
              <Heading
                variant='h1'
                size='h8'
                weight='bold'
                color={COLORS.BLACK}
              >
                Pause Client Membership
              </Heading>
              <Paragraph
                size='b5'
                weight='medium'
                className='w-full text-center max-w-[400px]'
                color={COLORS.GREY[400]}
              >
                Are you sure you want to pause {client?.clientMembership?.membership?.name}?
              </Paragraph>

              <Button
                variant='danger'
                size='lg'
                rounded='lg'
                fontSize='b5'
                className='capitalize'
                onClick={() => handleDelete()}
                disabled={false}
                loading={isLoading}
              >
                Yes, I'm sure
              </Button>
              <Button
                variant='text'
                size='none'
                rounded='none'
                fontSize='b5'
                className='mx-0'
                onClick={() => closeModal()}
                disabled={false}
              >
                No, go back
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null
}

export default PauseClientMembership