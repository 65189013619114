import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgMemberlove = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 18 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g clipPath="url(#memberlove_svg__a)">
      <path
        d="M6.667 10.667a2.667 2.667 0 1 0 0-5.334 2.667 2.667 0 0 0 0 5.334ZM12 15.667c0 1.656 0 3-5.334 3-5.333 0-5.333-1.344-5.333-3 0-1.657 2.388-3 5.333-3 2.946 0 5.334 1.343 5.334 3Z"
        stroke="#16796F"
      />
      <path
        d="m12.666 9.763-.355.352a.5.5 0 0 0 .71 0l-.355-.352Zm-.304 2.198c-.325-.247-.617-.445-.852-.702-.218-.238-.344-.483-.344-.793h-1c0 .633.276 1.109.607 1.47.314.341.718.62.982.82l.607-.795Zm-1.196-1.495c0-.275.16-.496.365-.587.178-.078.46-.086.78.236l.71-.705c-.58-.583-1.297-.71-1.895-.445a1.64 1.64 0 0 0-.96 1.501h1Zm.59 2.29c.098.076.227.174.363.251.135.078.324.16.547.16v-1c.023 0 .011.007-.055-.03a2.29 2.29 0 0 1-.249-.176l-.607.796Zm1.82 0c.265-.201.67-.479.983-.82.331-.361.607-.837.607-1.47h-1c0 .31-.126.555-.344.794-.235.256-.527.454-.852.701l.607.796Zm1.59-2.29c0-.666-.39-1.25-.96-1.501-.597-.265-1.315-.138-1.895.445l.71.705c.32-.322.602-.314.78-.236a.64.64 0 0 1 .365.588h1Zm-2.196 1.495a2.64 2.64 0 0 1-.25.176c-.065.038-.077.03-.054.03v1c.223 0 .411-.082.547-.16.136-.076.265-.175.364-.25l-.607-.796Z"
        fill="#16796F"
      />
    </g>
    <defs>
      <clipPath id="memberlove_svg__a">
        <path fill="#fff" transform="translate(0 4)" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgMemberlove;
