import { SalonStaff } from 'core/generated'
import React, { useContext } from 'react'
import { Button, Heading, Modal, Paragraph, SvgArrowRight, SvgClose } from 'ui'
import { BookingService } from '../types'
import { COLORS } from 'constants/colors'
import { formatTextIntoCaps } from 'components/utils/misc'
import BookContext from '../context'

const SelectStaff = ({
  isVisible,
  closeModal,
  staffList,
  service,
  selectedStaff,
  setSelectedService
}: {
  isVisible: boolean,
  closeModal: () => void,
  staffList: SalonStaff[],
  service: BookingService | null,
  selectedStaff: SalonStaff | null
  setSelectedService?: (service: BookingService | null) => void
}) => {
  const { selectService, setSelectedStaff, isMobile } = useContext(BookContext);
  
  const chooseStaff = (staff: SalonStaff) => {
    setSelectedStaff(staff)
    selectService(service?.id)
    closeModal()
  }

  const _closeModal = () => {
    if (setSelectedService) {
      setSelectedService(null)
    }
    closeModal()
  }
  return (
    <>
      {isMobile ?
        <Modal
          show={isVisible} closeModal={_closeModal} size='md' variant='popOver'
        >
          <div className='w-full h-fit flex flex-col xl:max-w-[40%] border border-grey-50 rounded-xl py-8 px-6 gap-8'>
            <div className='w-full flex justify-between'>
              <div className='flex flex-col space-y-4 w-full items-start'>
                <Heading variant='h1' size='h8' weight='bold'>Select staff</Heading>
                <Paragraph size='b4' color={COLORS.GREY[300]}>Select a staff member to book with</Paragraph>
              </div>
              <Button
                variant='text'
                size='none'
                className='h-fit'
                onClick={() => _closeModal()}
              ><SvgClose width="24px" height="24px" /></Button>
            </div>

            {Array?.isArray(service?.serviceStaffs) && service?.serviceStaffs?.length ?
              <div className='w-full flex flex-col gap-4'>
                {service?.serviceStaffs?.length && service?.serviceStaffs.map((staff, index) => {
                  const eachStaff = staffList?.find((staffMap: SalonStaff) => staffMap?.id === staff?.salonStaff?.id)
                  return (
                    <div
                      key={index}
                      className={
                        `relative cursor-pointer select-none px-2 py-5 ${selectedStaff?.id === eachStaff?.id ? 'border border-green-300 text-green-300' : 'text-grey-900 border border-grey-50'
                        }`
                      }
                      onClick={() => chooseStaff(eachStaff)}
                    >
                      <div className='w-full flex justify-between items-center space-x-2 text-grey-300'>
                        <div className='flex space-x-3'>
                          <img
                            src="https://res.cloudinary.com/dqqrgidob/image/upload/v1711326670/ebloupo48mkxqwn6nm5g.png"
                            alt="staff image"
                            className='w-[32px] h-[32px] rounded-sm'
                          />
                          <div className='flex flex-col'>
                            <span
                              className='text-b5 block truncate font-medium capitalize text-grey-900'
                            >
                              {eachStaff?.user?.fullName}
                            </span>
                            <span className='text-b6 block font-normal text-grey-400'>
                              {formatTextIntoCaps(eachStaff?.specialty || "")}
                            </span>
                          </div>
                        </div>
                        <SvgArrowRight width="14px" height="14px" />
                      </div>
                    </div>
                  )
                })}
              </div>
              : <div className='flex justify-center items-center'>
                <Paragraph size='b4' weight='normal' color={COLORS.BLACK}>No staff available</Paragraph>
              </div>}
          </div>
        </Modal>
        :
        <Modal
          show={isVisible}
          closeModal={() => _closeModal()}
        >
          <div className='w-full flex flex-col gap-6 items-center'>
            <div className='w-full flex justify-between'>
              <div className='flex flex-col space-y-4 w-full items-start'>
                <Heading variant='h1' size='h8' weight='bold'>Select staff</Heading>
                <Paragraph size='b4' color={COLORS.GREY[300]}>Select a staff member to book with</Paragraph>
              </div>
              <Button
                variant='text'
                size='none'
                className='h-fit'
                onClick={() => _closeModal()}
              ><SvgClose width="24px" height="24px" /></Button>
            </div>

            {Array?.isArray(service?.serviceStaffs) && service?.serviceStaffs?.length ?
              <div className='w-full flex flex-col gap-4'>
                {service?.serviceStaffs?.length && service?.serviceStaffs.map((staff, index) => {
                  const eachStaff = staffList?.find((staffMap: SalonStaff) => staffMap?.id === staff?.salonStaff?.id)
                  return (
                    <div
                      key={index}
                      className={
                        `relative cursor-pointer select-none px-2 py-5 ${selectedStaff?.id === eachStaff?.id ? 'border border-green-300 text-green-300' : 'text-grey-900 border border-grey-50'
                        }`
                      }
                      onClick={() => chooseStaff(eachStaff)}
                    >
                      <div className='w-full flex justify-between items-center space-x-2 text-grey-300'>
                        <div className='flex space-x-3'>
                          <img
                            src="https://res.cloudinary.com/dqqrgidob/image/upload/v1711326670/ebloupo48mkxqwn6nm5g.png"
                            alt="staff image"
                            className='w-[32px] h-[32px] rounded-sm'
                          />
                          <div className='flex flex-col'>
                            <span
                              className='text-b5 block truncate font-medium capitalize text-grey-900'
                            >
                              {eachStaff?.user?.fullName}
                            </span>
                            <span className='text-b6 block font-normal text-grey-400'>
                              {formatTextIntoCaps(eachStaff?.specialty || "")}
                            </span>
                          </div>
                        </div>
                        <SvgArrowRight width="14px" height="14px" />
                      </div>
                    </div>
                  )
                })}
              </div>
              : <div className='flex justify-center items-center'>
                <Paragraph size='b4' weight='normal' color={COLORS.BLACK}>No staff available</Paragraph>
              </div>}
          </div>
        </Modal>
      }
    </>
  )
}

export default SelectStaff
