export const REDEEM_VOUCHER_TAB_NAME = {
  ALL: 'All',
  REDEEMED: 'Redeemed',
  UNUSED: 'Unused',
};

export const REDEEM_VOUCHER_TABS = [
  {
    title: REDEEM_VOUCHER_TAB_NAME.ALL,
    key: REDEEM_VOUCHER_TAB_NAME.ALL,
  }, {
    title: REDEEM_VOUCHER_TAB_NAME.REDEEMED,
    key: REDEEM_VOUCHER_TAB_NAME.REDEEMED,
  }, {
    title: REDEEM_VOUCHER_TAB_NAME.UNUSED,
    key: REDEEM_VOUCHER_TAB_NAME.UNUSED,
  }
]

export const REDEEM_VOUCHER_REDEEM_TABLE_HEADERS = [
  {
    label: 'Package',
    value: 'name',
    show: true
  },
  {
    label: 'Services',
    value: 'services',
    show: true
  },
  {
    label: 'Expiry Date',
    value: 'validFor',
    show: true
  },
  {
    label: 'Code',
    value: 'code',
    show: true
  },
  {
    label: 'Services/Amount Remaining',
    value: 'remaining',
    show: true
  }
];

export const MOBILE_REDEEM_VOUCHER_REDEEM_TABLE_HEADERS = [
  {
    title: 'Package Name',
    key: 'name',
  },
  {
    title: 'Services/Amount Remaining',
    key: 'remaining',
  }
]

export const REDEEM_VOUCHER_ALL_TABLE_HEADERS = [
  {
    label: 'Voucher Name',
    value: 'packageName',
    show: true
  },
  {
    label: 'Services Covered',
    value: 'servicesCovered',
    show: true
  },
  {
    label: 'Expiry Date',
    value: 'validFor',
    show: true
  },
  {
    label: 'Amount',
    value: 'amount',
    show: true
  },
  {
    label: 'Amount Redeemed',
    value: 'amountRedeemed',
    show: true
  },
  {
    label: 'Voucher Code',
    value: 'code',
    show: true
  },
  {
    label: 'Status',
    value: 'status',
    show: true
  }
];

export const MOBILE_REDEEM_VOUCHER_ALL_TABLE_HEADERS = [
  {
    title: 'Voucher Code',
    key: 'code',
  },
  {
    title: 'Status',
    key: 'status',
  }
]

export const REDEEM_VOUCHER_UNUSED_TABLE_HEADERS = [
  {
    label: 'Service Name',
    value: 'services',
    show: true
  },
  {
    label: 'Package',
    value: 'name',
    show: true
  },
  {
    label: 'Voucher Code',
    value: 'code',
    show: true
  },
  {
    label: 'Expiry Date',
    value: 'validFor',
    show: true
  },
  {
    label: '',
    value: 'action',
    show: true
  }
];

export const MOBILE_REDEEM_VOUCHER_UNUSED_TABLE_HEADERS = [
  {
    title: 'Package',
    key: 'name',
  },
  {
    title: 'Voucher Code',
    key: 'code',
  },
]

export const LOYALTY_ACTIVITY_TAB = {
  REWARDS: 'Rewards',
  ACTIVITY: 'Activity',
}

export const LOYALTY_ACTIVITY_TABS = [
  {
    title: LOYALTY_ACTIVITY_TAB.REWARDS,
    key: LOYALTY_ACTIVITY_TAB.REWARDS,
  }, {
    title: LOYALTY_ACTIVITY_TAB.ACTIVITY,
    key: LOYALTY_ACTIVITY_TAB.ACTIVITY,
  }
]

export const LOYALTY_TAB_NAME = {
  ALL: 'All',
  USED: 'Used',
  ACTIVE: 'Active',
  EXPIRED: 'Expired',
}

export const LOYALTY_TABS = [
  {
    title: LOYALTY_TAB_NAME.ALL,
    key: LOYALTY_TAB_NAME.ALL,
  }, {
    title: LOYALTY_TAB_NAME.USED,
    key: LOYALTY_TAB_NAME.USED,
  }, {
    title: LOYALTY_TAB_NAME.ACTIVE,
    key: LOYALTY_TAB_NAME.ACTIVE,
  }, {
    title: LOYALTY_TAB_NAME.EXPIRED,
    key: LOYALTY_TAB_NAME.EXPIRED,
  }
]

export const ALL_LOYALTY_TABLE_HEADERS = [
  {
    label: 'Reward name',
    value: 'name',
    show: true
  },
  {
    label: 'Reward earned',
    value: 'pointsEarned',
    show: true
  },
  {
    label: 'Date unlocked',
    value: 'dateUsed',
    show: true
  },
  {
    label: 'Valid for',
    value: 'validityMonths',
    show: true
  },
  {
    label: 'Status',
    value: 'status',
    show: true
  }
];

export const MOBILE_ALL_LOYALTY_TABLE_HEADERS = [
  {
    title: 'Reward name',
    key: 'name',
  },
  {
    title: 'Reward earned',
    key: 'pointsEarned',
  },
  {
    title: 'Date unlocked',
    key: 'dateUsed',
  }
  , {
    title: 'Valid for',
    key: 'validityMonths',
  }
  , {
    title: 'Status',
    key: 'validityMonths',
  }
]

export const ACTVITY_LOYALTY_TABLE_HEADERS = [
  {
    label: 'Date',
    value: 'date',
    show: true
  },
  {
    label: 'Activity done',
    value: 'activityDone',
    show: true
  },
  {
    label: 'Points earned',
    value: 'pointsEarned',
    show: true
  },
  {
    label: 'Points balance',
    value: 'pointsBalance',
    show: true
  },
]

export const MOBILE_ACTIVITY_LOYALTY_TABLE_HEADERS = [
  {
    title: 'Date',
    key: 'date',
  },
  {
    title: 'Activity done',
    key: 'activityDone',
  },
  {
    title: 'Points earned',
    key: 'pointsEarned',
  },
  {
    title: 'Points balance',
    key: 'pointsBalance',
  },
]

export const MEMBERSHIP_ACTIVITY_TAB = {
  MEMBERSHIPS: 'Memberships',
  ARCHIVED: 'Archived Memberships',
}

export const MEMBERSHIP_ACTIVITY_TABS = [
  {
    title: MEMBERSHIP_ACTIVITY_TAB.MEMBERSHIPS,
    key: MEMBERSHIP_ACTIVITY_TAB.MEMBERSHIPS,
  }, {
    title: MEMBERSHIP_ACTIVITY_TAB.ARCHIVED,
    key: MEMBERSHIP_ACTIVITY_TAB.ARCHIVED,
  }
]



export const MOBILE_ACTIVITY_MEMBERSHIP_TABLE_HEADERS = [
  {
    title: 'Date',
    key: 'date',
  },
  {
    title: 'Services',
    key: 'services',
  },
  {
    title: 'Points',
    key: 'points',
  },
  {
    title: 'Payment',
    key: 'payment',
  },
  {
    title: 'Balance',
    key: 'balance',
  },
]

export const MEMBERSHIP_TABLE_HEADERS = [
  {
    label: 'Membership name',
    value: 'name',
    show: true
  },
  {
    label: 'Amount',
    value: 'amount',
    show: true
  },
  {
    label: 'Reward',
    value: 'reward',
    show: true
  },
  {
    label: 'Applicable Services',
    value: 'services',
    show: true
  },
  {
    label: 'Valid for',
    value: 'validityPeriod',
    show: true
  },
  {
    label: 'Clients applied to',
    value: 'clients',
    show: true
  }
];

export const MEMBERSHIP_TABLE_HEADERS_MOBILE = [
  {
    title: 'Membership name',
    key: 'name',
  },
  {
    title: 'Amount',
    key: 'amount',
  },
  {
    title: 'Reward',
    key: 'reward',
  },
  {
    title: 'Applicable Services',
    key: 'services',
  },
  {
    title: 'Valid for',
    key: 'validityMonths',
  },
  {
    title: 'Clients applied to',
    key: 'clients',
  }
];

export const ARCHIVED_MEMBERSHIP_TABLE_HEADERS = [
  {
    label: 'Membership name',
    value: 'name',
    show: true
  },
  {
    label: 'Amount',
    value: 'amount',
    show: true
  },
  {
    label: 'Reward',
    value: 'reward',
    show: true
  },
  {
    label: 'Applicable Services',
    value: 'services',
    show: true
  },
  {
    label: 'Valid for',
    value: 'validityPeriod',
    show: true
  },
  {
    label: 'Clients applied to',
    value: 'clients',
    show: true
  },
  {
    label: '',
    value: 'cta',
    show: true
  }
];

export const MEMBERSHIP_HISTORY = [
  {
    label: 'Date purchased',
    value: 'date',
    show: true
  },
  {
    label: 'Client',
    value: 'client',
    show: true
  },
  {
    label: 'Location',
    value: 'location',
    show: true
  },
  {
    label: 'Date of last use',
    value: 'usedDate',
    show: true
  }
];

export const MEMBERSHIP_HISTORY_MOBILE = [
  {
    title: 'Date purchased',
    key: 'date',
  },
  {
    title: 'Client',
    key: 'client',
  },
  {
    title: 'Location',
    key: 'location',
  },
  {
    title: 'Date of last use',
    key: 'usedDate',
  }
]

export const CUSTOMER_MEMBERSHIP_ACTIVITY_TAB = {
  ACTIVITY: 'Activity',
}

export const CUSTOMER_MEMBERSHIP_ACTIVITY_TABS = [
  {
    title: CUSTOMER_MEMBERSHIP_ACTIVITY_TAB.ACTIVITY,
    key: CUSTOMER_MEMBERSHIP_ACTIVITY_TAB.ACTIVITY,
  }
]

export const ACTVITY_MEMBERSHIP_TABLE_HEADERS = [
  {
    label: 'Date',
    value: 'date',
    show: true
  },
  {
    label: 'Services',
    value: 'services',
    show: true
  },
  {
    label: 'Points',
    value: 'points',
    show: true
  },
  {
    label: 'Payment',
    value: 'payment',
    show: true
  },
  {
    label: 'Balance',
    value: 'balance',
    show: true
  },
]