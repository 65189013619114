/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react'
import { MOBILE_SALES_REPORT_HEADINGS, SALES_REPORT_HEADINGS } from './constants';
import { Menu, Transition } from '@headlessui/react';
import TableSkeleton from 'ui/organism/tableSkeleton/TableSkeleton';
import SearchTerm from 'ui/organism/debounceQuery/SearchTerm';
import { formatDateToOriginalDate, formatInToPrice, formatTableHeadersFilterArray, getTodayQueryDates } from '../../utils/misc';
import Table from 'ui/organism/table/Table';
import CalendarPicker from 'ui/molecules/calendarPicker/CalendarPicker';
import { SvgCarbonView, SvgCharmMenuKebab, SvgDeleteRegular, SvgEditRegular, SvgIonCopyOutline, SvgTime, SvgUserBlock, SvgUserCheck, SvgVoid } from 'ui';
import { SalesPageProps } from './types';
import { Sale, SalesOverviewByService } from 'core/generated';
import { checkReportsTokenFromRoute } from '../../utils/token';
import axios from 'axios';
import { useToast } from 'hooks/useToast';
import ViewSaleReportModal from './ViewSaleReportModal';
import { useModal } from 'hooks';
import { Paragraph, ToastProps, ToastWrapper } from '../../../ui';
import { COLORS } from 'constants/colors';
import VoidSaleModal from './VoidSaleModal';
import ViewAppointmentModal from '../../modals/ViewAppointmentModal';
import { API_ERRORS } from 'constants/errors';
import CancelAppointmentModal from '../appointmentComponents/modals/CancelAppointmentModal';
import NoShowAppointmentModal from '../appointmentComponents/modals/NoShowAppointmentModal';
import DeleteAppointmentModal from '../appointmentComponents/modals/DeleteAppointmentModal';
import AddSaleModal from '../../modals/AddSale';
import { formatSaleStatusPill, generateSalesReportTableData } from './StatusUtils';
import ViewSaleReceiptModal from '../../modals/ViewSaleReceiptModal';
import { useSalesReports, useVoidAppointmentOrSale } from 'api/useSales';
import { useDeleteAppointment, useRevertAppointmentCancellation, useUpdateAppointmentStatus } from 'api/useAppointments';
import ContentLoader from 'ui/atoms/contentLoader/ContentLoader';

const SalesReport = (props: SalesPageProps) => {
  const token = checkReportsTokenFromRoute()
  const [selectedDates, setSelectedDates] = useState<[Date, Date] | null>(getTodayQueryDates());
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState<string>('');
  const [salesReportHeadings, setSalesReportHeadings] = useState(SALES_REPORT_HEADINGS);

  const [start, end] = selectedDates || getTodayQueryDates();
  const [saleReport, setSaleReport] = useState<SalesOverviewByService | null>(null);
  const [selectedSale, setSelectedSale] = useState<Sale | null>(null)
  const { toast, addToast } = useToast()

  const {
    voidAppointmentOrSale,
    loading: isVoidSaleLoading
  } = useVoidAppointmentOrSale()

  const {
    loading: deleteAppointmentIsLoading,
    deleteAppointment
  } = useDeleteAppointment()

  const {
    loading: updateAppointmentStatusIsLoading,
    updateAppointmentStatus
  } = useUpdateAppointmentStatus()

  const {
    loading: appointmentCancellationIsLoading,
    revertAppointmentCancellation
  } = useRevertAppointmentCancellation()

  const {
    openModal: openViewSaleReportModal,
    closeModal: closeViewSaleReportModal,
    isVisible: isViewSaleReportModalVisible
  } = useModal()

  const {
    isVisible: isVoidSaleDialogModalVisible,
    openModal: openVoidSaleDialogModal,
    closeModal: closeVoidSaleDialogModal
  } = useModal()

  const {
    isVisible: isViewAppointmentModalVisible,
    openModal: openViewAppointmentModal,
    closeModal: closeViewAppointmentModal,
  } = useModal();

  const {
    isVisible: isNoShowAppointmentModalVisible,
    openModal: openNoShowAppointmentModal,
    closeModal: closeNoShowAppointmentModal
  } = useModal();

  const {
    isVisible: isCancelAppointmentModalVisible,
    openModal: openCancelAppointmentModal,
    closeModal: closeCancelAppointmentModal,
  } = useModal();

  const {
    isVisible: isDeleteAppointmentModalVisible,
    openModal: openDeleteAppointmentModal,
    closeModal: closeDeleteAppointmentModal,
  } = useModal();

  const {
    isVisible: isAddSaleModalVisible,
    openModal: openAddSaleModal,
    closeModal: closeAddSaleModal,
  } = useModal();

  const {
    isVisible: isSaleReceiptModalVisible,
    openModal: openSaleReceiptModal,
    closeModal: closeSaleReceiptModal,
  } = useModal();

  const initiateCloseViewSaleReportModal = (action?: string) => {
    if (action === 'edit') {
      closeViewSaleReportModal()
      if (saleReport?.saleType === 'appointment') {
        openViewAppointmentModal();
        return;
      } else if (saleReport?.saleType === 'sale') {
        openAddSaleModal();
        return;
      }
    }
    setSaleReport(null);
    closeViewSaleReportModal()
  }

  const {
    data: salesReportsData,
    refetch,
    loading: isLoading,
  } = useSalesReports(
    formatDateToOriginalDate(start, "start"),
    formatDateToOriginalDate(end, "end"),
    props.salonId,
  )
  const salesReport = useMemo(() => salesReportsData?.salesReport, [salesReportsData])
  const showInitialLoadingShimmer = isLoading && !salesReport && !debouncedSearchQuery;

  useEffect(() => {
    if (props?.salonId) {
      refetch()
    }
  }, [props?.salonId, refetch])

  const handleHeadingCheckboxChange = (value: string) => {
    // search for the heading with that value in Headings then update the show
    const updatedHeadings = salesReportHeadings.map(heading => {
      if (heading.value === value) {
        return {
          ...heading,
          show: !heading.show
        }
      }
      return heading
    })
    setSalesReportHeadings(updatedHeadings)
  }

  useEffect(() => {
    if (props?.salonId) {
      refetch()
    }
  }, [selectedDates])

  useEffect(() => {
    if (props.refreshData) {
      refetch()
      props?.setRefreshData(false)
    }
  }, [props.refreshData])

  const openSaleReport = (id: number, action?: string) => {
    const report = Array.isArray(salesReport) ? salesReport[id] : undefined;

    if (!report) return;
    if (report && report?.saleType === 'voucher') {
      addToast({
        message: "You can't void a voucher that has already been sold",
        variant: 'error',
      })
      return
    }
    setSaleReport(report);
    switch (action) {
      case 'edit':
        if (report?.saleType === 'appointment') {
          openViewAppointmentModal();
        } else if (report?.saleType === 'sale' && (report?.saleStatus === 'unpaid' || report?.saleStatus === 'partially_paid')) {
          openAddSaleModal();
        } else {
          openViewSaleReportModal();
        }
        break;
      case 'void_sale':
        openVoidSaleDialogModal();
        break;
      default:
        openViewSaleReportModal();
        break;
    }
  }

  const tableOptions = {
    view: true,
    edit: true,
    void_sale: true,
    delete: false,
    duplicate: false
  }

  const renderIcon = (option: string) => {
    switch (option) {
      case 'view':
        return <SvgCarbonView width="24px" height="24px" />;
      case 'edit':
        return <SvgEditRegular width="24px" height="24px" />;
      case 'duplicate':
        return <SvgIonCopyOutline width="24px" height="24px" />;
      case 'delete':
        return <SvgDeleteRegular width="24px" height="24px" />;
      case 'activate':
        return <SvgUserCheck width="24px" height="24px" />;
      case 'deactivate':
        return <SvgUserBlock width="24px" height="24px" />;
      case 'add_time_off':
        return <SvgTime width="24px" height="24px" />;
      case 'void_sale':
        return <SvgVoid width="24px" height="24px" />;
      default:
        return null; // Default to null if the option doesn't match
    }
  };

  const getContent = () => {
    if (salesReport) {
      const tableClientsData = generateSalesReportTableData(salesReport, salesReportHeadings);
      return (
        <>
          <div className='hidden xl:flex flex-col w-full'>
            <Table
              headers={formatTableHeadersFilterArray(salesReportHeadings)}
              mobileHeaders={MOBILE_SALES_REPORT_HEADINGS}
              rows={tableClientsData}
              onClick={openSaleReport}
              tableOptions={tableOptions}
            />
          </div>
          <div className='flex flex-col w-full xl:hidden'>
            {Array?.isArray(salesReport) && salesReport?.length ? salesReport?.map((sale, index) => {
              return (
                <div className='w-full flex justify-between items-start py-4 px-6' key={index}>
                  <div className='w-full flex flex-col space-y-2'>
                    <div className='grid grid-cols-2'>
                      <Paragraph size='b5' color={COLORS.GREY['900']}>{sale?.client}</Paragraph>
                      {sale?.saleStatus !== 'partially_paid' ?
                        <Paragraph size='b5' color={COLORS.GREY['900']}>{formatInToPrice(sale?.totalSales)}</Paragraph>
                        : <Paragraph size='b5' color={COLORS.GREY['900']}>{formatInToPrice(sale?.totalSales)} &bull; Balance: <span className='font-semibold text-red-500'>{formatInToPrice(sale?.totalSales - sale?.amountClientPaid)}</span></Paragraph>}
                    </div>
                    <div className='grid grid-cols-2'>
                      <Paragraph size='b5' color={COLORS.GREY['300']}>{sale?.saleStatus ? formatSaleStatusPill(sale?.saleStatus) : "-"} </Paragraph>
                      <Paragraph size='b5' color={COLORS.GREY['300']}>{sale?.service} </Paragraph>
                    </div>
                  </div>
                  <Menu as="div" className="relative inline-block text-left">
                    <div>
                      <Menu.Button className="flex space-x-2 w-full items-center justify-center px-4 py-2 text-b5 font-medium text-grey-500 hover:bg-opacity-90 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-85 z-0">
                        <SvgCharmMenuKebab width="24px" height="24px" />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={React.Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opa city-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
                        <div className="flex flex-col p-4 space-y-4">
                          {Object.entries(tableOptions).map(([option, value]) => (
                            value && (
                              <Menu.Item key={option}>
                                <span className={`text-b4 cursor-pointer flex items-center space-x-2`} onClick={() => openSaleReport(index, option)}>
                                  {/* Your menu item content */}
                                  {renderIcon(option)}
                                  <span>{option.charAt(0).toUpperCase() + option?.slice(1)?.replaceAll("_", " ")}</span>
                                </span>
                              </Menu.Item>
                            )
                          ))}
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              )
            }) : null}
          </div>
        </>
      )
    }
  }

  const voidSaleAsync = async () => {
    if (!saleReport) return;
    voidAppointmentOrSale({
      variables: {
        input: {
          status: 'void',
          ...(saleReport?.saleType === 'appointment' ? {
            appointmentId: saleReport?.saleId
          } : {
            saleId: saleReport?.saleId
          }),
        }
      }
    }).then(({ data }) => {
      const voidSale = data?.voidAppointmentOrSale;
      if (voidSale?.status === 200) {
        closeVoidSaleDialogModal()
        refetch();
        addToast({
          message: "Void sale successfully",
          variant: 'success',
        })
      }
      if (voidSale?.errors?.length) {
        const message = voidSale?.errors[0]?.message || "Failed to void sale";
        addToast({
          message,
          variant: 'error',
        })
      }
    })
  }

  const finalizeVoidSaleAction = (action: string) => {
    if (action === 'void') {
      voidSaleAsync()
    } else {
      closeVoidSaleDialogModal()
    }
  }

  const initiateCloseViewAppointmentModal = (action: string) => {
    refetch()
    setSaleReport(null);
    closeViewAppointmentModal();
  }

  const initiateNoShowAppointment = () => {
    closeViewAppointmentModal();
    openNoShowAppointmentModal();
  }

  const initiateCancelAppointment = () => {
    closeViewAppointmentModal();
    openCancelAppointmentModal();
  }

  const initiateDeleteAppointment = () => {
    closeViewAppointmentModal();
    openDeleteAppointmentModal();
  }

  const revertAppointmentCancellationAsync = async () => {
    if (!saleReport) return;
    try {
      const payload = {
        appointmentId: saleReport?.appointmentId
      }
      revertAppointmentCancellation({
        variables: { input: { ...payload } }
      }).then(({ data }) => {
        if (data?.revertAppointmentCancellation?.status === 200) {
          refetch();
          closeViewAppointmentModal();
          addToast({
            message: "Appointment updated successfully",
            variant: 'success',
          })
        }
        if (data?.revertAppointmentCancellation?.errors?.length) {
          const message = data?.revertAppointmentCancellation?.errors[0]?.message || API_ERRORS.APPOINTMENT_FAILED_TO_UPDATE;
          addToast && addToast({
            variant: 'error',
            message,
          })
        }
      })
    } catch (error) {
      const message = error?.response?.data?.message || API_ERRORS.APPOINTMENT_FAILED_TO_UPDATE
      addToast({
        message,
        variant: 'error'
      })
    }
  }

  const checkInOrOutAppointmentAsync = async ({
    appointmentId, status, applyCancellationFee
  }: {
    appointmentId: string,
    status: string,
    applyCancellationFee: boolean
  }) => {
    try {
      const payload = {
        appointmentStatus: status,
        id: appointmentId,
        salonId: props?.salonId,
        applyCancellationFee
      }

      updateAppointmentStatus({
        variables: { input: { ...payload } }
      }).then(({ data }) => {
        if (data?.updateAppointmentStatus?.status === 200) {
          refetch();
          closeViewAppointmentModal();
          addToast({
            message: "Appointment updated successfully",
            variant: 'success',
          })
        }

        if (data?.updateAppointmentStatus?.errors?.length) {
          const message = data?.updateAppointmentStatus?.errors[0]?.message || API_ERRORS.APPOINTMENT_FAILED_TO_UPDATE;
          addToast && addToast({
            variant: 'error',
            message,
          })
        }
      })
    } catch (checkoutAppointmentError) {
      if (axios.isAxiosError(checkoutAppointmentError)) {
        const message = checkoutAppointmentError?.response?.data?.message || API_ERRORS.APPOINTMENT_FAILED_TO_UPDATE;
        addToast({
          message,
          variant: 'error',
        })
      }
    }
  };

  const finalizeAppointmentCancellation = (action: string, applyCancellationFee = false) => {
    if (action === 'cancel') {
      checkInOrOutAppointmentAsync({
        appointmentId: saleReport?.appointmentId as string, status: 'cancelled', applyCancellationFee
      })
      closeCancelAppointmentModal();
    } else if (action === 'no_show') {
      checkInOrOutAppointmentAsync({
        appointmentId: saleReport?.appointmentId as string, status: 'no_show', applyCancellationFee
      })
      closeNoShowAppointmentModal()
    }
    else {
      closeCancelAppointmentModal();
      closeNoShowAppointmentModal();
      openViewAppointmentModal();
    }
  }

  const deleteAppointmentAsync = async (id: string | null) => {
    if (!id) return;

    try {
      deleteAppointment({
        variables: {
          input: {
            salonId: props?.salonId,
            id,
          }
        }
      }).then(({ data }) => {
        const clientDetailsData = data?.deleteAppointment;
        if (clientDetailsData) {
          refetch();
          closeViewAppointmentModal();
          addToast({
            message: "Appointment removed successfully",
            variant: 'success',
          })
        } else {
          const message = API_ERRORS.APPOINTMENT_DELETION_FAILED;
          addToast({
            message,
            variant: 'error',
          })
        }
      })
    } catch (deleteAppointmentError) {
      if (axios.isAxiosError(deleteAppointmentError)) {
        const message = deleteAppointmentError?.response?.data?.message || API_ERRORS.APPOINTMENT_DELETION_FAILED;
        addToast({
          message,
          variant: 'error',
        })
      }
    }
  }

  const finalizeAppointmentDeletion = (action: string) => {
    if (action === 'delete') {
      deleteAppointmentAsync(saleReport?.appointmentId)
      closeDeleteAppointmentModal();
    } else {
      closeDeleteAppointmentModal();
      openViewAppointmentModal();
    }
  }

  const initiateCloseAddSaleModal = (action: string, sale?: Sale | null) => {
    refetch()
    closeAddSaleModal();
    closeViewSaleReportModal();
    if ((action === 'addedSale' || action === 'updatedSale') && sale) {
      setSelectedSale(sale);
      openSaleReceiptModal();
    }
    setSaleReport(null);
  }

  return (
    <>
      {showInitialLoadingShimmer ? <ContentLoader /> : null}
      <ToastWrapper toast={toast as ToastProps} />
      <div className='flex  justify-between items-center py-4 px-8 space-x-4'>
        <div className='w-full xl:w-6/12 flex items-center space-x-4'>
          <SearchTerm placeholder='Search' setDebouncedSearchQuery={setDebouncedSearchQuery} />
        </div>
        <CalendarPicker {...{ selectedDates, setSelectedDates }} />
      </div>
      {getContent()}
      <ViewSaleReportModal
        isOpen={isViewSaleReportModalVisible}
        onClose={initiateCloseViewSaleReportModal}
        bookingOrSaleData={saleReport}
      />
      <VoidSaleModal
        saleReport={saleReport}
        isLoading={isVoidSaleLoading}
        isVisible={isVoidSaleDialogModalVisible}
        closeModal={finalizeVoidSaleAction}
      />
      <ViewAppointmentModal
        isVisible={isViewAppointmentModalVisible}
        closeModal={initiateCloseViewAppointmentModal}
        cancelAppointment={initiateCancelAppointment}
        deleteAppointment={initiateDeleteAppointment}
        salonId={props?.salonId}
        addToast={addToast}
        appointmentId={saleReport?.appointmentId}
        markAsNoShow={initiateNoShowAppointment}
        refetchCalendarData={refetch}
        revertCancellation={revertAppointmentCancellationAsync}
      />
      <CancelAppointmentModal
        isVisible={isCancelAppointmentModalVisible}
        closeModal={finalizeAppointmentCancellation}
        salonId={props?.salonId}
        appointmentId={saleReport?.appointmentId}
      />
      <NoShowAppointmentModal
        isVisible={isNoShowAppointmentModalVisible}
        closeModal={finalizeAppointmentCancellation}
        salonId={props?.salonId}
        appointmentId={saleReport?.appointmentId}
      />
      <DeleteAppointmentModal
        isVisible={isDeleteAppointmentModalVisible}
        closeModal={finalizeAppointmentDeletion}
      />
      <AddSaleModal
        isVisible={isAddSaleModalVisible}
        closeModal={initiateCloseAddSaleModal}
        addToast={addToast}
        salonId={props?.salonId}
        saleId={saleReport?.saleId}
      />
      <ViewSaleReceiptModal sale={selectedSale} salonId={props?.salonId} closeModal={() => {
        closeSaleReceiptModal(), setSelectedSale(null)
      }} isVisible={isSaleReceiptModalVisible} addToast={addToast} />
    </>
  )
}

export default SalesReport