/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { Fragment, useEffect, useMemo, useState } from 'react'
import { MOBILE_PAYMENT_TRANSACTIONS_HEADINGS, PAYMENT_TRANSACTIONS_HEADINGS } from './constants';
import { Menu, Transition } from '@headlessui/react';
import TableSkeleton from 'ui/organism/tableSkeleton/TableSkeleton';
import Label from 'ui/atoms/formLabel/Label';
import SearchTerm from 'ui/organism/debounceQuery/SearchTerm';
import Table from 'ui/organism/table/Table';
import { cleanUpText, formatDateToOriginalDate, formatInToPrice, formatNumber, formatTableHeadersFilterArray, getTodayQueryDates, validateDecimalInput } from '../../utils/misc';
import Checkbox from 'ui/atoms/checkbox/Checkbox';
import CalendarPicker from 'ui/molecules/calendarPicker/CalendarPicker';
import { Heading, SvgClose, SvgCreditCard, SvgFilter, SvgPaymentSalesSvg } from 'ui';
import { useSalonCache } from 'hooks/useSalonCache';
import { PaymenntsFilterInput, SalesPageProps } from './types';
import { generatePaymentTransactionsTableData, getHighestMode, paymentModeSummary } from './utils'
import { useModal } from 'hooks/useModal';
import ViewPaymentTransactionModal from './ViewPaymentTransactionModal';
import { Payment } from 'core/generated';
import { Button, Modal, Paragraph, SelectInput } from '../../../ui';
import { COLORS } from 'constants/colors';
import { useForm } from 'react-hook-form';
import { useGetPayments } from 'api/useMoney';
import { DEFAULT_CURRENCY } from 'constants/currency';
import HomeEmptyCard from '../homeComponents/HomeEmptyCard';
import { createPaymentsChart, paymentModeLabels } from '../homeComponents/utils';
import { Pie } from 'react-chartjs-2';

const PaymentTransactions = (props: SalesPageProps) => {
  const { getSalonData } = useSalonCache();
  const salon = getSalonData();
  const [selectedDates, setSelectedDates] = useState<[Date, Date] | null>(getTodayQueryDates());
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState<string>('');
  const [paymentHeadings, setPaymentHeadings] = useState(PAYMENT_TRANSACTIONS_HEADINGS);

  const [filteredPaymentData, setFilteredPaymentData] = useState<Payment[]>([]);
  const [start, end] = selectedDates || getTodayQueryDates();

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<PaymenntsFilterInput>({});

  const {
    openModal: openViewPaymentModal,
    closeModal: closeViewPaymentModal,
    isVisible: isViewPaymentModalVisible
  } = useModal()

  const {
    openModal: openFilterDialogModal,
    closeModal: closeFilterDialogModal,
    isVisible: isFilterDialogModalVisible
  } = useModal()

  const {
    data: paymentsData,
    loading: isLoading,
    refetch: refetchPayments,
  } = useGetPayments({
    salonId: salon?.id,
    startDate: formatDateToOriginalDate(selectedDates?.[0], 'start'),
    endDate: formatDateToOriginalDate(selectedDates?.[1], 'end'),
    q: debouncedSearchQuery,
  })
  const payments = useMemo(() => {
    if (Array?.isArray(paymentsData?.payments) && paymentsData?.payments?.length) {
      setFilteredPaymentData(paymentsData?.payments);

      return paymentsData?.payments
    }
  }, [paymentsData])
  const [payment, setPayment] = useState<Payment | null>(null);
  const showInitialLoadingShimmer = isLoading && !payments && !debouncedSearchQuery;

  const openPayment = (id: number) => {
    const report = Array.isArray(payments) ? payments[id] : undefined;

    if (!report) return;
    setPayment(report)
    openViewPaymentModal()
  }

  const initiateCloseViewPaymentModal = () => {
    closeViewPaymentModal()
    setPayment(null)
  }

  const getContent = () => {
    if (showInitialLoadingShimmer) {
      return (
        <div className='flex flex-col xl:flex-row px-5 py-4'>
          <TableSkeleton />
        </div>
      )
    }

    if (Array?.isArray(filteredPaymentData) || debouncedSearchQuery) {
      const tableClientsData = generatePaymentTransactionsTableData(filteredPaymentData || [], paymentHeadings, salon?.countryCode)
      return (
        <>
          <Table
            headers={formatTableHeadersFilterArray(paymentHeadings)}
            mobileHeaders={MOBILE_PAYMENT_TRANSACTIONS_HEADINGS}
            rows={tableClientsData}
            onClick={openPayment}
          />
        </>
      )
    }
  }

  useEffect(() => {
    if (props.salonBranchName) {
      refetchPayments()
    }
  }, [props.salonBranchName])

  useEffect(() => {
    if (props.refreshData) {
      refetchPayments()
      props?.setRefreshData(false)
    }
  }, [props.refreshData])

  useEffect(() => {
    refetchPayments()
  }, [debouncedSearchQuery, selectedDates])

  const [paymentMethodFIlterOptions, setPaymentMethodFilterOptions] = useState([
    {
      value: "bank_transfer",
      label: "Bank Transfer",
      show: true,
    },
    {
      value: "pos",
      label: "POS",
      show: true,
    },
    {
      value: "cash",
      label: "Cash",
      show: true,
    },
    {
      value: "giftcard",
      label: "Gift Card",
      show: true,
    },
    {
      value: "voucher",
      label: "Voucher",
      show: true,
    }, {
      value: "client_wallet",
      label: "Client Wallet",
      show: true,
    }
  ])

  const handleHeadingCheckboxChange = (value: string) => {
    // search for the heading with that value in Headings then update the show
    const updatedHeadings = paymentMethodFIlterOptions.map(heading => {
      if (heading.value === value) {
        return {
          ...heading,
          show: !heading.show
        }
      }
      return heading
    })
    setPaymentMethodFilterOptions(updatedHeadings)
  }

  useEffect(() => {
    if (Array?.isArray(payments) && paymentMethodFIlterOptions?.length && payments?.length) {
      const filteredData = payments?.filter(payment => paymentMethodFIlterOptions?.some(heading => heading.value === payment.mode && heading.show)) || []
      setFilteredPaymentData(filteredData)
    }
  }, [paymentMethodFIlterOptions])

  const onSubmitData = (input: PaymenntsFilterInput) => {
    closeFilterDialogModal()
    if (input.paymentMethod === "all") {
      setFilteredPaymentData(payments)
      return
    } else if (input.paymentMethod) {
      const filteredData = payments?.filter(payment => payment.mode === input.paymentMethod) || []
      setFilteredPaymentData(filteredData)
      return
    }
  }

  const totalSum = filteredPaymentData?.reduce(
    (sum, payment) => sum + (payment?.amount || 0),
    0
  );

  const paymentModes = paymentModeSummary(filteredPaymentData)
  const paymentModesChart = createPaymentsChart(paymentModes)

  return (
    <>
      <div className='w-full flex flex-col xl:flex-row border-b border-grey-100 rounded-md'>
        <div className='w-full flex flex-col xl:w-[40%] gap-2 px-8 py-6'>
          <div className='w-full flex flex-col justify-between space-y-3 border border-grey-100 bg-grey-50/20 p-4 rounded-md'>
            <span className='w-[32px] h-[32px] flex justify-center items-center rounded-sm bg-grey-50'>
              <SvgCreditCard width="18px" height="18px" />
            </span>

            <div className='w-full flex flex-col'>
              <Paragraph size='b4' color={COLORS.GREY[400]} weight='bold' className=''>
                Total payments received ({DEFAULT_CURRENCY})
              </Paragraph>
              <Paragraph size='b1' weight='semiBold' className=''>
                {validateDecimalInput(totalSum) || 0}
              </Paragraph>
            </div>
          </div>
          <div className='w-full flex flex-col justify-between space-y-3 border border-grey-100 bg-grey-50/20 p-4 rounded-md'>
            <span className='w-[32px] h-[32px] flex justify-center items-center rounded-sm bg-grey-50'>
              <SvgPaymentSalesSvg width="32px" height="32px" />
            </span>

            <div className='w-full flex flex-col'>
              <Paragraph size='b4' color={COLORS.GREY[400]} weight='bold' className=''>
                Top payment method
              </Paragraph>
              <Paragraph size='b1' weight='semiBold' className='capitalize'>
                {cleanUpText(getHighestMode(filteredPaymentData)) || "-"}
              </Paragraph>
            </div>
          </div>
        </div>
        <div className='w-full flex flex-col space-y-4 xl:w-[60%] bg-[#F3F6F666] border border-grey-100 gap-2 px-8 py-6'>
          <Heading variant='h2' color={COLORS.GREY[300]} size='h11' weight='bold'>
            Payment methods
          </Heading>
          {paymentModes ? (
            <div className='w-full flex space-x-3'>
              <div className='w-full h-[200px]'>
                <Pie data={paymentModesChart} options={
                  {
                    responsive: true,
                    plugins: {
                      legend: {
                        position: 'bottom', // Hide the legend
                        display: false,
                      },
                      tooltip: {
                        enabled: true,
                      },
                    },
                  }
                } />
              </div>
              <div className='flex flex-wrap gap-3'>
                {paymentModeLabels?.map((paymentModeLabel, index) => (
                  <div className='flex flex-col space-y-2'>
                    <div key={index} className='flex space-x-2 items-center'>
                      <span style={{
                        backgroundColor: paymentModeLabel.color, // Use backgroundColor instead of bgColor
                      }} className={`w-[12px] h-[12px] rounded-sm`}></span>
                      <Paragraph size='b5' color={COLORS.GREY[300]}>{paymentModeLabel.label}</Paragraph>
                    </div>
                    <Paragraph size='b5' color={COLORS.GREY[300]}>{formatInToPrice(paymentModes[index]?.amount || 0)}</Paragraph>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <HomeEmptyCard variant='appointment' title="Payment records is empty" />
          )}
        </div>
      </div>
      <div className='flex flex-col xl:flex-row justify-between items-center py-4 px-8 space-x-4'>
        <div className='w-full xl:w-6/12 flex items-center space-x-4'>
          <SearchTerm placeholder='Search' setDebouncedSearchQuery={setDebouncedSearchQuery} />
          <Menu as='div' className='relative inline-block text-left'>
            <div>
              <Menu.Button className='flex space-x-2 w-full items-center justify-center rounded-md bg-grey-50 border border-grey-20 px-4 py-2 text-b5 font-medium text-grey-300 hover:bg-opacity-90 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-85'>
                <SvgFilter width='20px' height='20px' />
                <span className='hidden xl:inline'>Filter</span>
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter='transition ease-out duration-100'
              enterFrom='transform opacity-0 scale-95'
              enterTo='transform opacity-100 scale-100'
              leave='transition ease-in duration-75'
              leaveFrom='transform opacity-100 scale-100'
              leaveTo='transform opacity-0 scale-95'
            >
              <Menu.Items className='absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-[9999]'>
                <div className='flex flex-col p-4 space-y-4'>
                  {Array.isArray(paymentMethodFIlterOptions) &&
                    paymentMethodFIlterOptions.length &&
                    paymentMethodFIlterOptions.map((heading, index) => (
                      <Label
                        className='flex space-x-2 items-center cursor-pointer'
                        key={index}
                        htmlFor={heading.value}
                        onClick={() =>
                          handleHeadingCheckboxChange(heading.value)
                        }
                      >
                        <Checkbox
                          isChecked={heading.show}
                          borderType='Black'
                          size='Sm'
                        />
                        <span>{heading.label}</span>
                      </Label>
                    ))}
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
        <CalendarPicker {...{ selectedDates, setSelectedDates }} />
      </div>
      {getContent()}
      <ViewPaymentTransactionModal
        isVisible={isViewPaymentModalVisible}
        closeModal={initiateCloseViewPaymentModal}
        payment={payment}
      />
      <Modal show={isFilterDialogModalVisible} closeModal={closeFilterDialogModal}>
        <div className='w-full flex flex-col space-y-6'>
          <div className='w-full flex justify-between'>
            <Paragraph size='b1' color={COLORS.GREY[900]}>
              Filter
            </Paragraph>
            <Button
              variant='icon'
              className='border-0'
              size='xs'
              onClick={closeFilterDialogModal}
            >
              <SvgClose width="24px" height="24px" />
            </Button>
          </div>

          <form onSubmit={handleSubmit(onSubmitData)} className="w-full flex flex-col space-y-6 pt-6">
            <SelectInput name="paymentMethod" id="payment-method" label="Payment Method" control={control} options={paymentMethodFIlterOptions} placeholder="Select Payment Method" />
            <Button
              variant='primary'
              className=''
              size='lg'
              rounded='lg'
            >
              Apply filter
            </Button>
            <Button
              variant='text'
              size='none'
              type='button'
              fontWeight='semiBold'
              className="text-red-500"
              onClick={closeFilterDialogModal}
            >
              Cancel
            </Button>
          </form>
        </div>
      </Modal>
    </>
  )
}

export default PaymentTransactions