import React, { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form';
import axios from 'axios';
// import { useSalonCache } from 'hooks/useSalonCache';
import { BankInput } from '../uicomponents/types';
import { API_ERRORS, ERRORS } from 'constants/errors';
import { Modal } from 'ui/templates/modal/Modal';
import { REGEX_PATTERNS } from 'constants/pattern';
import Input from 'ui/molecules/input/Input';
import SelectInput from 'ui/molecules/input/SelectInput';
import Button from 'ui/atoms/button/Button';
import { MoneyModalProps } from './types';
import { useSalonCache } from 'hooks/useSalonCache';
import { BanksDocument, CreateTransferAccountDocument, DeleteTransferAccountDocument, UpdateTransferAccountDocument } from 'core/generated';
import { useAddTransferAccount, useDeleteTransferAccount, useGetBanks, useUpdateTransferAccount } from 'api/useMoney';

const AddOrEditTransferAccount = (props: MoneyModalProps) => {
  const { getSalonFieldValue } = useSalonCache();
  const salonId = getSalonFieldValue('id');

  const {
    loading: isLoading,
    addTransferAccount
  } = useAddTransferAccount()

  const {
    loading: isUpdating,
    updateTransferAccount
  } = useUpdateTransferAccount()

  const {
    loading: deleteTransferAccountIsLoading,
    deleteTransferAccount
  } = useDeleteTransferAccount()
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<BankInput>({});

  const {
    data: banksData,
  } = useGetBanks()
  const banks = banksData?.banks || []
  const bankListOptions = useMemo(() => {
    return banks.map((bank) => ({
      value: bank.code,
      label: bank.name,
    }))
  }, [banks])


  const onSubmitData = async (input: BankInput) => {
    try {
      // update or create bank account transfer
      if (!props.transferInfo) {
        addTransferAccount({
          variables: { input: { accountNumber: input?.accountNumber, bankCode: input?.bankCode, salonId, 
            ...(props?.transferCount === 0 ? { onboardingStep: 'created_bank_transfers' } : {}) } },
        }).then(({ data }) => {
          if (data?.createTransferAccount?.status === 201) {
            closeBankModal()
            props?.addToast && props.addToast({
              message: 'Account created successfully',
              variant: 'success',
            })
          }

          if (data?.createTransferAccount?.errors?.length) {
            const message = data?.createTransferAccount?.errors[0]?.message || API_ERRORS.BANK_ACCOUNT_FAILED;
            props?.addToast && props?.addToast({
              variant: 'error',
              message,
            })
          }
        })
      } else {
        updateTransferAccount({
          variables: { input: { id: props?.transferInfo?.id, accountNumber: input?.accountNumber, bankCode: input?.bankCode, salonId } },
        }).then(({ data }) => {
          if (data?.updateTransferAccount?.status === 200) {
            closeBankModal()
            props?.addToast && props.addToast({
              message: 'Account updated successfully',
              variant: 'success',
            })
          }

          if (data?.updateTransferAccount?.errors?.length) {
            const message = data?.updateTransferAccount?.errors[0]?.message || API_ERRORS.BANK_ACCOUNT_FAILED;
            props?.addToast && props?.addToast({
              variant: 'error',
              message,
            })
          }
        })
      }
    } catch (createBusinessTransferError) {
      if (axios.isAxiosError(createBusinessTransferError)) {
        const message = createBusinessTransferError?.response?.data?.message || API_ERRORS.BANK_ACCOUNT_FAILED;
        props?.addToast && props.addToast({
          variant: "error",
          message,
        });
      }
    }
  };

  const closeBankModal = () => {
    props.refetch && props.refetch();
    props.closeModal();
    resetFormInput();
  }

  const resetFormInput = () => {
    setValue('accountNumber', '');
    setValue('bankCode', '');
  }

  useEffect(() => {
    if (props.transferInfo) {
      setValue('accountNumber', props.transferInfo.accountNumber)
      setValue('bankCode', props.transferInfo.bankCode)
    }
  }, [props.transferInfo, setValue])

  const deleteTransferAccountAsync = async () => {
    try {
      deleteTransferAccount({
        variables: { input: { id: props.transferInfo?.id, salonId } },
      }).then(({ data }) => {
        if (data?.deleteTransferAccount?.status === 200) {
          props?.addToast && props.addToast({
            message: 'Transfer Account deleted successfully',
            variant: 'success',
          })
          closeBankModal();
        }

        if (data?.deleteTransferAccount?.errors?.length) {
          const message = data?.deleteTransferAccount?.errors[0]?.message || API_ERRORS.SALON_BANK_ACCOUNT_DELETION_FAILED;
          props?.addToast && props?.addToast({
            variant: 'error',
            message,
          })
        }
      })
    } catch (deletePosError) {
      if (axios.isAxiosError(deletePosError)) {
        const message = deletePosError?.response?.data?.message || API_ERRORS.SALON_BANK_ACCOUNT_DELETION_FAILED;
        props?.addToast && props.addToast({
          message,
          variant: 'error',
        })
      }
    }
  }

  return (
    <Modal
      title={props?.expense ? 'Edit Bank Account' : 'Add Bank Account'}
      show={props.isVisible}
      closeModal={!props?.expense ? props.closeModal : () => closeBankModal()}
    >
      <form onSubmit={handleSubmit(onSubmitData)} className="w-full space-y-6 pt-6">
        <Input name="accountNumber" id="account-number" label="Account Number" type="text" placeholder='Enter account number here' control={control} rules={{
          required: ERRORS.ACCOUNT_NUMBER_REQUIRED,
          pattern: REGEX_PATTERNS.ACCOUNT_NUMBER
        }} error={errors.accountNumber} />
        <SelectInput name="bankCode" id="bank-name" label="Bank Name " control={control} rules={{
          required: ERRORS.BANK_NAME_REQUIRED,
        }} error={errors.bankCode} options={bankListOptions} placeholder="Select Bank" />

        <Button
          variant='primary'
          className=''
          disabled={isLoading || isUpdating || deleteTransferAccountIsLoading}
          loading={isLoading || isUpdating}
          size='lg'
          rounded='lg'
        >
          Save
        </Button>
        <Button
          variant='text'
          className={`mx-auto ${props?.transferInfo && 'text-red-500'}`}
          disabled={deleteTransferAccountIsLoading || isLoading || isUpdating}
          loading={deleteTransferAccountIsLoading}
          size='none'
          type='button'
          fontWeight="semiBold"
          onClick={props?.transferInfo ? deleteTransferAccountAsync : props.closeModal}
        >
          {props?.transferInfo ? 'Delete' : 'Cancel'}
        </Button>
      </form>
    </Modal>
  )
}

export default AddOrEditTransferAccount