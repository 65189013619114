/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ChangeEvent, Fragment, useEffect, useMemo, useState } from 'react';
import {
  Button,
  Checkbox,
  CollapseRow,
  FormHelperText,
  FormLabel,
  FormPhone,
  Heading,
  Input,
  Layout,
  Modal,
  MultiSelect,
  MultiSelectOption,
  NameAvatar,
  Paragraph,
  ParagraphShimmer,
  Pill,
  Rating,
  SearchTerm,
  SelectInput,
  Skeleton,
  SvgGreenSignal,
  SvgYellowsignal,
  Table,
  TableFilterProps,
  Tabs,
  ToastProps,
  ToggleOnly,
} from 'ui';
import {
  cleanUpText,
  convert12HourTo24Hour,
  convert24HourTo12Hour,
  convertFullDateStringToDate,
  convertFullDateStringToTime,
  formatDateToOriginalDate,
  formatInToPrice,
  formatNumber,
  formatSnakeCaseText,
  formatTableHeadersFilterArray,
  formatTime,
  getDayAndMonthFromDate,
  getDayMonthAndNumberFromDateString,
  getNumberMonthAndYearFromDate,
  getStaffRoleName,
  limitString,
  sanitizePhoneNumber,
  sortOptions,
} from '../components/utils/misc';
import { addDays, isSameDay, startOfWeek, endOfWeek } from 'date-fns';
import {
  SvgArrowBack,
  SvgArrowBox,
  SvgArrowRight,
  SvgCalendar,
  SvgChevronLeft,
  SvgChevronRight,
  SvgClose,
  SvgDeleteRegular,
  SvgFilter,
  SvgHideEye,
  SvgInfo,
  SvgNoImageBig,
  SvgPercentageBox,
  SvgPlus,
  SvgSelectDropDown,
  SvgTrash,
} from 'ui';
import { Menu, Transition } from '@headlessui/react';
import { useModal, useToast } from 'hooks';
import { Controller, useForm } from 'react-hook-form';
import {
  CreateStaffTimeOffInput,
  Role,
  RoleEnum,
  RolesDocument,
  SalonHour,
  SalonHoursDocument,
  SalonStaff,
  SalonStaffDetails,
  SalonStaffInput,
  StaffHour,
  StaffManagementDocument,
} from 'core/generated';
import moment from 'moment-timezone';
import { ERRORS } from 'constants/errors';
import {
  CSV_UPLOAD_PATTERN,
  IMAGE_UPLOAD_PATTERN,
  REGEX_PATTERNS,
} from 'constants/pattern';
import { TimePicker } from 'antd';
import dayjs from 'dayjs';
import ToastWrapper from 'ui/molecules/toastWrapper/PlainToast';
import { AddClosedPeriodModal } from './BusinessClosedPeriod';
import {
  ACCOUNT_SETUP_ERRORS,
  STAFF_ROLES,
  STAFF_SPECIALTIES,
  YES_OR_NO_SETTINGS,
} from 'constants/information';
import {
  formatStaffUpdateData,
  getHelperTextForReactHookFormErrors,
} from '../components/utils/form';
import { COLORS } from 'constants/colors';
import ImportFileLink from '../components/uicomponents/ImportFileLink';
import { useSalonCache } from '../hooks/useSalonCache';
import Label from 'ui/atoms/formLabel/Label';
import FullTable from 'ui/organism/table/Table';
import { formatServicesToSelectField } from '../components/utils/utils';
import SingleSelect from 'ui/molecules/singleSelect/SingleSelect';
import axios from 'axios';
import ImageAvatar from 'ui/atoms/nameAvatar/ImageAvatar';
import { PAYMENT_TYPE_NAME_AND_VALUE } from '../components/uicomponents/bookingSales/constants';
import { canPerformAction } from '../components/utils/permission';
import { PERMISSION_CONSTANTS } from 'constants/permission';
import { DEFAULT_CURRENCY, DEFAULT_CURRENCY_CODE } from 'constants/currency';
import { PAGE_ROUTES } from 'constants/routes';
import { useNavigate } from 'react-router-dom';
import { useClosedPeriods, useCreateSalonStaff, useCreateStaffTimeOff, useDeleteStaff, useDeleteStaffTimeOff, useGetStaff, useGetStaffList, useUpdateStaff, useUploadStaff } from 'api/useStaff';
import { useSalon, useSalonHours, useSalons } from 'api/useSalon';
import { useGetServices } from 'api/useCatalogue';
import { checkStaffWorkingSchedule, convertGroupHoursToSingleTime, getWorkingHoursByStaffId, groupBusinessSalonHoursForStaffCreation } from 'components/uicomponents/utils';
import { useUploadFile } from 'api/useAccount';
import ViewAppointmentShimmer from 'components/uicomponents/appointmentComponents/modals/ViewAppointmentShimmer';
import { BatchUploadProps } from 'components/uicomponents/types';

const PERMISSION_LEVELS = [
  {
    label: 'Level 1',
    value: '0',
    description: 'Has access to only own calendar',
  },
  {
    label: 'Level 2',
    value: '1',
    description: 'Has access to location, bookings & clients etc',
  },
  {
    label: 'Level 3',
    value: '2',
    description: 'Has access to everything, except reports',
  },
  {
    label: 'Level 4',
    value: '3',
    description: 'Has access to everything',
  },
];

const DAYS: string[] = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];

const STAFF_TAB_NAME = {
  MANAGE_STAFF: 'Manage Staff',
  WORKING_SCHEDULE: 'Work schedule',
  STAFF_PERFORMANCE: 'Staff performance',
};

const STAFF_TABS = [
  {
    key: STAFF_TAB_NAME.WORKING_SCHEDULE,
    title: STAFF_TAB_NAME.WORKING_SCHEDULE,
  },
  {
    key: STAFF_TAB_NAME.MANAGE_STAFF,
    title: STAFF_TAB_NAME.MANAGE_STAFF,
  },
  // {
  //   key: STAFF_TAB_NAME.STAFF_PERFORMANCE,
  //   title: STAFF_TAB_NAME.STAFF_PERFORMANCE
  // }
];

const STAFF_DETAILS_TAB_NAME = {
  PERSONAL_INFORMATION: 'Personal information',
  EMPLOYMENT_DETAILS: 'Employment details',
  WORK_SCHEDULE: 'Work schedule',
  LEAVE_AND_ATTENDANCE: 'Leave and attendance',
  SALARY_INFORMATION: 'Salary information',
  NOTIFICATIONS_AND_REMINDERS: 'Notifications and reminders',
};

const Staff = () => {
  const [activeTab, setActiveTab] = useState(STAFF_TAB_NAME.WORKING_SCHEDULE);
  // const { toast } = useToast()

  const getTabView = () => {
    switch (activeTab) {
      case STAFF_TAB_NAME.MANAGE_STAFF:
        return <StaffManagement />;
      case STAFF_TAB_NAME.WORKING_SCHEDULE:
        return <WorkingHours />;
      case STAFF_TAB_NAME.STAFF_PERFORMANCE:
        return <h1>Staff performance</h1>;
    }
  };

  return (
    <Layout pageTitle="Staff">
      {/* <ToastWrapper toast={toast as ToastProps} /> */}
      <Tabs
        tabs={STAFF_TABS}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      {getTabView()}
    </Layout>
  );
};

type StaffAvatarProps = {
  staff: SalonStaff;
};

const StaffAvatarAndNameView = ({ staff }: StaffAvatarProps) => {
  return (
    <span className="flex items-center space-x-2">
      <NameAvatar
        name={
          (staff?.user?.firstName?.charAt(0)?.toLocaleUpperCase() ?? '') +
          (staff?.user?.lastName
            ? staff.user.lastName.charAt(0).toLocaleUpperCase()
            : staff?.user?.firstName?.charAt(1)?.toLocaleUpperCase())
        }
        size="lg"
      />
      <div className="flex flex-col">
        <Paragraph size="b4" color={COLORS.GREY[900]}>
          {staff?.user?.fullName}
        </Paragraph>
        <Paragraph size="b6" color={COLORS.GREY[300]}>
          {cleanUpText(staff?.specialty)}
        </Paragraph>
      </div>
    </span>
  );
};

const StaffAvatarView = ({ user, role }: ICreateStaffDetail) => {
  return (
    <span className="flex items-center space-x-2">
      <ImageAvatar imageUrl={user?.profileUrl} size="lg" />
      <div className="flex flex-col">
        <Paragraph size="b4" color={COLORS.GREY[900]}>
          {user?.firstName} {user?.lastName}
        </Paragraph>
        <Paragraph size="b6" color={COLORS.GREY[300]}>
          {role}
        </Paragraph>
      </div>
    </span>
  );
};

type ViewStaffManagementModalProps = {
  isVisible: boolean;
  closeModal: (action?: string) => void;
  openEditStaff: () => void;
  openDeleteStaff: () => void;
  openDisableStaff: () => void;
  staff?: SalonStaff;
};

type IWorkHistory = {
  services: string;
  client: string;
  payment?: string;
  rating?: number;
  transactionDate: string;
  staff?: string;
  note?: string;
};

type ILeaveHistory = {
  leavePeriod: string;
  reason: string;
  totalLeaveDays?: string;
  daysTaken?: number;
  daysLeft: string;
};

const ViewWorkHistoryDetails = ({
  isVisible,
  closeModal,
  workHistory,
}: {
  isVisible: boolean;
  closeModal: () => void;
  workHistory: IWorkHistory;
}) => {
  return (
    <>
      <Modal show={isVisible} closeModal={closeModal} variant="right">
        <div className="relative">
          <div className="fixed bg-white w-full flex border-b border-grey-100 cursor-pointer">
            <div className="w-full hidden xl:flex space-x-2 px-6 py-5 items-start">
              <Button
                variant="text"
                size="none"
                type="button"
                className="w-fit"
                fontSize="b4"
                onClick={closeModal}
              >
                <SvgArrowBack width="24px" height="24px" /> <span>Back</span>
              </Button>
            </div>
            <div className="w-full flex xl:hidden space-x-[69px] p-4 items-center">
              <Button
                variant="icon"
                size="square"
                type="button"
                rounded="md"
                className="w-fit"
                fontSize="b4"
                onClick={closeModal}
              >
                <SvgChevronLeft width="24px" height="24px" />
              </Button>
            </div>
          </div>

          <div
            style={{ marginTop: '70px' }}
            className="w-full max-w-5xl flex flex-col space-y-6 h-fit p-6"
          >
            <>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'left',
                }}
                className="hidden xl:flex flex-col xl:justify-between xl:items-left space-x-4"
              >
                <div className="flex flex-col space-y-4">
                  <div>
                    <Paragraph size="b3" weight="semiBold">
                      Appointment history details
                    </Paragraph>
                    <Paragraph size="b5" color={COLORS.GREY[500]}>
                      Find the details here
                    </Paragraph>
                  </div>
                  <div className="w-full flex flex-col border border-grey-50 rounded-md p-4">
                    <div className="flex w-full">
                      <div className="flex m-2 w-1/4">
                        <div
                          className="mt-1 mr-2"
                          style={{
                            backgroundColor: '#DDDDDD',
                            width: '10px',
                            height: '10px',
                          }}
                        ></div>
                        <div className="flex flex-col">
                          <Paragraph size="b5" color={COLORS.GREY[500]}>
                            Services
                          </Paragraph>
                          <Paragraph>{workHistory?.services}</Paragraph>
                        </div>
                      </div>
                      <div className="flex m-2 w-1/4">
                        <div
                          className="mt-1 mr-2"
                          style={{
                            backgroundColor: '#DDDDDD',
                            width: '10px',
                            height: '10px',
                          }}
                        ></div>
                        <div className="flex flex-col">
                          <Paragraph size="b5" color={COLORS.GREY[500]}>
                            Client
                          </Paragraph>
                          <Paragraph>{workHistory?.client}</Paragraph>
                        </div>
                      </div>

                      <div className="flex m-2 w-1/4">
                        <div
                          className="mt-1 mr-2"
                          style={{
                            backgroundColor: '#DDDDDD',
                            width: '10px',
                            height: '10px',
                          }}
                        ></div>
                        <div className="flex flex-col">
                          <Paragraph size="b5" color={COLORS.GREY[500]}>
                            Payment
                          </Paragraph>
                          <Paragraph>
                            {workHistory?.payment
                              ? formatInToPrice(parseFloat(workHistory.payment))
                              : '-'}
                          </Paragraph>
                        </div>
                      </div>

                      <div className="flex m-2 w-1/4">
                        <div
                          className="mt-1 mr-2"
                          style={{
                            backgroundColor: '#DDDDDD',
                            width: '10px',
                            height: '10px',
                          }}
                        ></div>
                        <div className="flex flex-col">
                          <Paragraph size="b5" color={COLORS.GREY[500]}>
                            Rating
                          </Paragraph>
                          <div>
                            {workHistory?.rating ? (
                              <div className="flex items-center justify-between">
                                <div className="">
                                  <Paragraph weight="semiBold">
                                    {workHistory.rating.toFixed(1)}
                                  </Paragraph>
                                </div>
                                <div className="ml-1">
                                  <Rating initialRating={workHistory?.rating} />
                                </div>
                              </div>
                            ) : (
                              '-'
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="flex w-full">
                      <div className="flex m-2 w-1/4">
                        <div
                          className="mt-1 mr-2"
                          style={{
                            backgroundColor: '#DDDDDD',
                            width: '10px',
                            height: '10px',
                          }}
                        ></div>
                        <div className="flex flex-col">
                          <Paragraph size="b5" color={COLORS.GREY[500]}>
                            Transaction Date
                          </Paragraph>
                          <Paragraph>{workHistory?.transactionDate}</Paragraph>
                        </div>
                      </div>
                      <div className="flex m-2 w-1/4">
                        <div
                          className="mt-1 mr-2"
                          style={{
                            backgroundColor: '#DDDDDD',
                            width: '10px',
                            height: '10px',
                          }}
                        ></div>
                        <div className="flex flex-col">
                          <Paragraph size="b5" color={COLORS.GREY[500]}>
                            Staff
                          </Paragraph>
                          <Paragraph>{workHistory?.staff}</Paragraph>
                        </div>
                      </div>

                      <div className="flex m-2 w-1/4">
                        <div
                          className="mt-1 mr-2"
                          style={{
                            backgroundColor: '#DDDDDD',
                            width: '10px',
                            height: '10px',
                          }}
                        ></div>
                        <div className="flex flex-col">
                          <Paragraph size="b5" color={COLORS.GREY[500]}>
                            Note
                          </Paragraph>
                          <Paragraph>{workHistory?.note ?? '-'}</Paragraph>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          </div>
        </div>
      </Modal>
    </>
  );
};

const ViewLeaveHistoryDetails = ({
  isVisible,
  closeModal,
  leaveHistory,
}: {
  isVisible: boolean;
  closeModal: () => void;
  leaveHistory: ILeaveHistory;
}) => {
  return (
    <>
      <Modal show={isVisible} closeModal={closeModal} variant="right">
        <div className="relative">
          <div className="fixed bg-white w-full flex border-b border-grey-100 cursor-pointer">
            <div className="w-full hidden xl:flex space-x-2 px-6 py-5 items-start">
              <Button
                variant="text"
                size="none"
                type="button"
                className="w-fit"
                fontSize="b4"
                onClick={closeModal}
              >
                <SvgArrowBack width="24px" height="24px" /> <span>Back</span>
              </Button>
            </div>
            <div className="w-full flex xl:hidden space-x-[69px] p-4 items-center">
              <Button
                variant="icon"
                size="square"
                type="button"
                rounded="md"
                className="w-fit"
                fontSize="b4"
                onClick={closeModal}
              >
                <SvgChevronLeft width="24px" height="24px" />
              </Button>
            </div>
          </div>

          <div
            style={{ marginTop: '70px' }}
            className="w-full max-w-5xl flex flex-col space-y-6 h-fit p-6"
          >
            <>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'left',
                }}
                className="hidden xl:flex flex-col xl:justify-between xl:items-left space-x-4"
              >
                <div className="flex flex-col space-y-4">
                  <div>
                    <Paragraph size="b3" weight="semiBold">
                      Leave history details
                    </Paragraph>
                    <Paragraph size="b5" color={COLORS.GREY[500]}>
                      Find the details here
                    </Paragraph>
                  </div>
                  <div className="w-full flex flex-col border border-grey-50 rounded-md p-4">
                    <div className="flex w-full">
                      <div className="flex m-2 w-1/3">
                        <div
                          className="mt-1 mr-2"
                          style={{
                            backgroundColor: '#DDDDDD',
                            width: '10px',
                            height: '10px',
                          }}
                        ></div>
                        <div className="flex flex-col">
                          <Paragraph size="b5" color={COLORS.GREY[500]}>
                            Leave period
                          </Paragraph>
                          <Paragraph>{leaveHistory?.leavePeriod}</Paragraph>
                        </div>
                      </div>
                      <div className="flex m-2 w-1/3">
                        <div
                          className="mt-1 mr-2"
                          style={{
                            backgroundColor: '#DDDDDD',
                            width: '10px',
                            height: '10px',
                          }}
                        ></div>
                        <div className="flex flex-col">
                          <Paragraph size="b5" color={COLORS.GREY[500]}>
                            Reason
                          </Paragraph>
                          <Paragraph>{leaveHistory?.reason}</Paragraph>
                        </div>
                      </div>

                      <div className="flex m-2 w-1/3">
                        <div
                          className="mt-1 mr-2"
                          style={{
                            backgroundColor: '#DDDDDD',
                            width: '10px',
                            height: '10px',
                          }}
                        ></div>
                        <div className="flex flex-col">
                          <Paragraph size="b5" color={COLORS.GREY[500]}>
                            Total leave days
                          </Paragraph>
                          <Paragraph>{leaveHistory?.totalLeaveDays}</Paragraph>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="flex w-full">
                      <div className="flex m-2 w-1/3">
                        <div
                          className="mt-1 mr-2"
                          style={{
                            backgroundColor: '#DDDDDD',
                            width: '10px',
                            height: '10px',
                          }}
                        ></div>
                        <div className="flex flex-col">
                          <Paragraph size="b5" color={COLORS.GREY[500]}>
                            Days left
                          </Paragraph>
                          <Paragraph>{leaveHistory?.daysLeft}</Paragraph>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          </div>
        </div>
      </Modal>
    </>
  );
};

type DeleteStaffModalProps = {
  isVisible: boolean;
  closeModal: () => void;
  staff: SalonStaff;
  salonHours: SalonHour[];
};

const DeleteStaffModal = ({
  isVisible,
  closeModal,
  staff,
}: DeleteStaffModalProps) => {
  const { addToast, toast } = useToast()
  const {
    loading,
    deleteStaff
  } = useDeleteStaff()

  const deleteStaffAsync = async () => {
    const input = { salonStaffId: staff.id };
    deleteStaff({
      variables: { input: { ...input } }
    }).then(({ data }) => {
      const { deleteSalonStaff } = data
      if (deleteSalonStaff?.status === 200) {
        addToast({
          message: 'Staff deleted successfully',
          variant: 'success'
        })
        closeModal()
      }

      if (deleteSalonStaff?.errors?.length) {
        addToast({
          message: deleteSalonStaff?.errors[0]?.message,
          variant: 'error'
        })
      }
    })
  };

  return isVisible ? (
    <div className="fixed top-0 left-0 flex items-center justify-center w-full h-screen bg-black/70 backdrop-blur-[2px] z-[999999]">
      <ToastWrapper toast={toast} />
      <div className="table-row">
        <div className="table-cell align-middle">
          <div className="w-full xl:border-none bg-white rounded-lg shadow-medium mx-auto py-12 px-9 max-w-[400px] xl:max-w-[500px]">
            <div className="flex flex-col items-center space-y-6">
              <Heading
                variant="h1"
                size="h8"
                weight="bold"
                color={COLORS.BLACK}
              >
                Delete Staff
              </Heading>
              <Paragraph
                size="b5"
                weight="medium"
                className="w-full text-center max-w-[400px]"
                color={COLORS.GREY[400]}
              >
                Are you sure you want to delete {staff?.user?.firstName}? This
                means that this staff will be permanently deleted.
              </Paragraph>

              <Button
                variant="danger"
                size="lg"
                rounded="lg"
                fontSize="b5"
                className="capitalize"
                onClick={deleteStaffAsync}
                disabled={loading}
                loading={loading}
              >
                Yes, I'm sure
              </Button>
              <Button
                variant="text"
                size="none"
                rounded="none"
                fontSize="b5"
                className="mx-0"
                onClick={closeModal}
                disabled={loading}
              >
                No, go back
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

const DisableStaffModal = ({
  isVisible,
  closeModal,
  staff,
  salonHours,
}: DeleteStaffModalProps) => {
  const { addToast, toast } = useToast();

  const {
    data: staffData,
    loading: gettingStaffDetails,
    refetch: refetchStaffData,
  } = useGetStaff({ salonStaffId: staff?.id })
  const currentSalonStaff = useMemo(() => staffData?.salonStaff, [staffData])

  const {
    loading,
    updateSalonStaff
  } = useUpdateStaff()

  const update = async () => {
    const getWorkingSchedule = () => {
      if (
        currentSalonStaff &&
        currentSalonStaff?.workingSchedule &&
        !currentSalonStaff?.workingSchedule?.workScheduleType
      ) {
        const workingType = checkStaffWorkingSchedule(
          salonHours,
          currentSalonStaff?.workingSchedule?.workSchedule
        );
        return {
          workScheduleType: workingType,
          workSchedule:
            currentSalonStaff?.workingSchedule?.workSchedule?.filter(
              (schedule) => schedule?.openTime && schedule?.closeTime
            )?.map(({ __typename, ...rest }) => rest),
        };
      }

      return {
        workScheduleType: currentSalonStaff?.workingSchedule?.workScheduleType,
        workSchedule: currentSalonStaff?.workingSchedule?.workSchedule?.filter(
          (schedule) => schedule?.openTime && schedule?.closeTime
        )?.map(({ __typename, ...rest }) => rest),
      };
    };

    // Creating a new object without the specified keys
    const { __typename: _, ...salaryInformation } = currentSalonStaff?.salaryInformation || {};
    const { __typename: __, services, ...employmentDetails } = currentSalonStaff?.employmentDetails || {};
    // remove typename in services array for each item
    const updatedServices = services?.map(({ __typename, ...rest }) => rest);
    const data = {
      salaryInformation,
      reminder: {
        birthday: currentSalonStaff?.reminder?.birthday || false,
        workAnniversary: currentSalonStaff?.reminder?.workAnniversary || false,
      },
      workingSchedule: getWorkingSchedule(),
      employmentDetails: {
        ...employmentDetails,
        services: updatedServices,
        salonId: currentSalonStaff?.salonId,
        level: currentSalonStaff?.employmentDetails?.level?.toString(),
      },
      salonId: currentSalonStaff?.salonId,
      active: !staff?.active,
      id: currentSalonStaff?.id,
      leave: { daysEntitled: currentSalonStaff?.leave?.daysEntitled || '0' },
      user: {
        firstName: currentSalonStaff?.user?.firstName,
        lastName: currentSalonStaff?.user?.lastName,
        email: currentSalonStaff?.user?.email,
        countryCode: currentSalonStaff?.user?.countryCode,
        phone: currentSalonStaff?.user?.phone,
        dateOfBirth: currentSalonStaff?.user?.dateOfBirth,
        profileUrl: currentSalonStaff?.user?.profileUrl,
        callingCode: currentSalonStaff?.user?.callingCode,
      },
    };
    updateSalonStaff({
      variables: { input: { ...data } }
    }).then(({ data }) => {
      const { updateSalonStaffDetails } = data
      if (updateSalonStaffDetails?.status === 200) {
        addToast({
          message: staff?.active ? 'Staff deactivated successfully' : 'Staff activated successfully',
          variant: 'success'
        })
        closeModal()
        refetchStaffData()
      }

      if (updateSalonStaffDetails?.errors?.length) {
        addToast({
          message: updateSalonStaffDetails?.errors[0]?.message,
          variant: 'error'
        })
      }
    })
  };

  return isVisible ? (
    <div className="fixed top-0 left-0 flex items-center justify-center w-full h-screen bg-black/70 backdrop-blur-[2px] z-[999999]">
      <ToastWrapper toast={toast as ToastProps} />
      <div className="table-row">
        <div className="table-cell align-middle">
          <div className="w-full xl:border-none bg-white rounded-lg shadow-medium mx-auto py-12 px-9 max-w-[400px] xl:max-w-[500px]">
            <div className="flex flex-col items-center space-y-6">
              <Heading
                variant="h1"
                size="h8"
                weight="bold"
                color={COLORS.BLACK}
              >
                {staff?.active ? 'Deactivate' : 'Activate'} Staff
              </Heading>
              <Paragraph
                size="b5"
                weight="medium"
                className="w-full text-center max-w-[400px]"
                color={COLORS.GREY[400]}
              >
                Are you sure you want to{' '}
                {staff?.active ? 'deactivate' : 'activate'}{' '}
                {staff?.user?.firstName}?
              </Paragraph>

              <Button
                variant="danger"
                size="lg"
                rounded="lg"
                fontSize="b5"
                className="capitalize"
                onClick={update}
                disabled={loading || gettingStaffDetails}
                loading={loading || gettingStaffDetails}
              >
                Yes, I'm sure
              </Button>
              <Button
                variant="text"
                size="none"
                rounded="none"
                fontSize="b5"
                className="mx-0"
                onClick={closeModal}
                disabled={false}
              >
                No, go back
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

const ViewStaffManagementPage = ({
  isVisible,
  closeModal,
  openEditStaff,
  openDeleteStaff,
  openDisableStaff,
  staff,
}: ViewStaffManagementModalProps) => {
  const {
    data: staffData,
    loading,
    refetch: refetchStaffData,
  } = useGetStaff({
    salonStaffId: staff?.id
  })
  const currentSalonStaff = useMemo(() => staffData?.salonStaff, [staffData]);

  useEffect(() => {
    refetchStaffData()
  }, [staff?.id, refetchStaffData]);

  const nameMap = {
    firstName: 'First Name',
    lastName: 'Last Name',
    phoneNumber: 'Phone Number',
    email: 'Email Address',
    birthDay: 'Birthday',
  };

  const STAFF_HISTORY_TAB_NAMES = {
    WORK_HISTORY: 'Work History',
    LEAVE_HISTORY: 'Leave History',
  };
  const STAFF_HISTORY_TABS = [
    {
      key: STAFF_HISTORY_TAB_NAMES.WORK_HISTORY,
      title: STAFF_HISTORY_TAB_NAMES.WORK_HISTORY,
    },
    {
      key: STAFF_HISTORY_TAB_NAMES.LEAVE_HISTORY,
      title: STAFF_HISTORY_TAB_NAMES.LEAVE_HISTORY,
    },
  ];

  const WORK_HISTORY_HEADERS = [
    { label: 'Transaction Date', value: 'date' },
    { label: 'Service', value: 'service' },
    { label: 'Client', value: 'client' },
    { label: 'Payment', value: 'payment' },
    { label: 'Rating', value: 'rating' },
    { label: 'Payment status', value: 'paymentMethod' },
  ];

  const WORK_HISTORY_MOBILE_HEADERS = [
    { title: 'Transaction Date', key: 'date' },
    { title: 'Payment', key: 'payment' },
    { title: 'Rating', key: 'rating' },
    { title: 'Payment status', key: 'paymentMethod' },
  ]

  const LEAVE_HISTORY_HEADERS = [
    { label: 'Leave Period', value: 'leavePeriod' },
    { label: 'Reason', value: 'reason' },
    { label: 'Days Taken', value: 'daysTaken' },
    { label: 'Total Days', value: 'totalDays' },
    { label: 'Days Left', value: 'daysLeft' },
  ];

  const LEAVE_HISTORY_MOBILE_HEADERS = [
    { title: 'Leave Period', key: 'leavePeriod' },
    { title: 'Days Taken', key: 'daysTaken' },
    { title: 'Total Days', key: 'totalDays' },
    { title: 'Days Left', key: 'daysLeft' },
  ];

  const [activeHistoryTab, setActiveHistoryTab] = useState(
    STAFF_HISTORY_TAB_NAMES.WORK_HISTORY as string
  );
  const getPaymentStatusPill = (paymentMethod, depositPaymentMethod) => {
    if (paymentMethod !== PAYMENT_TYPE_NAME_AND_VALUE.NOT_PAID.value) {
      return <Pill variant="success">Paid</Pill>;
    }

    if (depositPaymentMethod !== PAYMENT_TYPE_NAME_AND_VALUE.NOT_PAID.value) {
      return <Pill variant="light">Deposit Paid</Pill>;
    }

    return <Pill variant="danger">Not Paid</Pill>;
  };
  const workHistories = currentSalonStaff?.workHistory.map(
    ({ staff, note, ...rest }) => rest
  );
  const workHistoryRows = workHistories?.map(
    ({ payment, rating, paymentMethod, depositPaymentMethod, ...row }) => {
      return {
        transactionDate: row?.transactionDate,
        services: row?.services,
        client: row?.client,
        payment: formatInToPrice(+payment),
        rating: rating ? parseFloat(rating?.toString()).toFixed(1) : '-',
        paymentMethod: getPaymentStatusPill(
          paymentMethod,
          depositPaymentMethod
        ),
      };
    }
  );

  const {
    isVisible: workHistoryModalIsVisible,
    openModal: openWorkHistoryDetailsModal,
    closeModal: closeWorkHistoryDetailsModal,
  } = useModal();

  const {
    isVisible: leaveHistoryModalIsVisible,
    openModal: openLeaveHistoryDetailsModal,
    closeModal: closeLeaveHistoryDetailsModal,
  } = useModal();

  const [workHistory, setWorkHistory] = useState<IWorkHistory>();

  const openWorkHistory = (id: number) => {
    const history = Array.isArray(currentSalonStaff.workHistory)
      ? currentSalonStaff.workHistory[id]
      : undefined;

    if (!history) {
      return;
    }
    setWorkHistory({
      services: history.services,
      client: history.client,
      payment: history?.payment,
      rating: history?.rating,
      transactionDate: history.transactionDate,
      staff: history?.staff,
      note: history?.note,
    });

    openWorkHistoryDetailsModal();
  };

  const [leaveHistory, setLeaveHistory] = useState<ILeaveHistory>();

  const openLeaveHistory = (id: number) => {
    const history = Array.isArray(currentSalonStaff.leaveHistory)
      ? currentSalonStaff.leaveHistory[id]
      : undefined;

    if (!history) {
      return;
    }
    setLeaveHistory({
      leavePeriod: history?.leavePeriod,
      reason: history?.reason,
      totalLeaveDays: history?.totalLeaveDays?.toString(),
      daysTaken: +history?.daysTaken,
      daysLeft: history?.daysLeft?.toString(),
    });

    openLeaveHistoryDetailsModal();
  };

  const getSpecialty = (specialty) => {
    const specialtyObj = STAFF_SPECIALTIES.find(
      (obj) => obj.value == specialty
    );
    return specialtyObj?.label;
  };

  const handleEditModal = () => {
    closeModal('edit');
    openEditStaff();
  };

  return (
    <>
      <Modal show={isVisible} closeModal={closeModal} variant="right">
        <div className="relative">
          <div className="fixed bg-white w-full flex border-b border-grey-100 cursor-pointer">
            <div className="w-full hidden xl:flex space-x-2 px-6 py-5 items-start">
              <Button
                variant="text"
                size="none"
                type="button"
                className="w-fit"
                fontSize="b4"
                onClick={() => closeModal()}
              >
                <SvgArrowBack width="24px" height="24px" /> <span>Back</span>
              </Button>
            </div>
            <div className="w-full flex xl:hidden space-x-[69px] p-4 items-center">
              <Button
                variant="icon"
                size="square"
                type="button"
                rounded="md"
                className="w-fit"
                fontSize="b4"
                onClick={() => closeModal()}
              >
                <SvgChevronLeft width="24px" height="24px" />
              </Button>
            </div>
          </div>

          <div
            style={{ marginTop: '70px' }}
            className="w-full max-w-5xl flex flex-col space-y-6 h-fit p-6"
          >
            <>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'left',
                }}
                className="hidden xl:flex flex-col xl:justify-between xl:items-left space-x-4"
              >
                <div className="flex flex-col space-y-4">
                  <div className="flex justify-between items-center">
                    <div>
                      <div>
                        {currentSalonStaff?.user?.profileUrl ? (
                          <StaffAvatarView
                            user={currentSalonStaff?.user}
                            role={staff?.role}
                          />
                        ) : (
                          <StaffAvatarAndNameView staff={staff} />
                        )}
                      </div>
                      <div></div>
                    </div>
                    <div className="flex">
                      <Button
                        variant="secondary"
                        type="button"
                        rounded="md"
                        size="sm"
                        onClick={handleEditModal}
                      >
                        Edit
                      </Button>
                      <Button
                        variant="text"
                        className="text-red-500"
                        type="button"
                        rounded="md"
                        size="sm"
                        onClick={openDeleteStaff}
                        id="delete-staff"
                      >
                        <SvgTrash width="24px" height="24px" />
                      </Button>
                    </div>
                  </div>

                  {staff && (
                    <>
                      <div className="w-full flex border border-grey-50 rounded-md p-4 flex-col space-y-3">
                        <Paragraph
                          size="b4"
                          weight="semiBold"
                          color={COLORS.GREY[300]}
                          className="text-left"
                        >
                          Personal information
                        </Paragraph>

                        <div className="px-2 flex justify-between items-center space-x-4 bg-grey-10">
                          <div className="flex flex-col space-y-1 p-2">
                            <Paragraph
                              size="b4"
                              color="grey-300"
                              className="text-left"
                            >
                              First name
                            </Paragraph>
                          </div>
                          <div className="flex flex-col space-y-1">
                            <Paragraph
                              size="b4"
                              color="grey-800"
                              weight="semiBold"
                              className="text-right"
                            >
                              {staff?.user?.firstName}
                            </Paragraph>
                          </div>
                        </div>
                        <div className="px-2 flex justify-between items-center space-x-4 bg-grey-10">
                          <div className="flex flex-col space-y-1 p-2">
                            <Paragraph
                              size="b4"
                              color="grey-300"
                              className="text-left"
                            >
                              Last name
                            </Paragraph>
                          </div>
                          <div className="flex flex-col space-y-1">
                            <Paragraph
                              size="b4"
                              color="grey-800"
                              weight="semiBold"
                              className="text-right"
                            >
                              {staff?.user?.lastName}
                            </Paragraph>
                          </div>
                        </div>
                        <div className="px-2 flex justify-between items-center space-x-4 bg-grey-10">
                          <div className="flex flex-col space-y-1 p-2">
                            <Paragraph
                              size="b4"
                              color="grey-300"
                              className="text-left"
                            >
                              Phone number
                            </Paragraph>
                          </div>
                          <div className="flex flex-col space-y-1">
                            <Paragraph
                              size="b4"
                              color="grey-800"
                              weight="semiBold"
                              className="text-right"
                            >
                              {staff?.user?.callingCode}
                              {staff?.user?.phone}
                            </Paragraph>
                          </div>
                        </div>
                        <div className="px-2 flex justify-between items-center space-x-4 bg-grey-10">
                          <div className="flex flex-col space-y-1 p-2">
                            <Paragraph
                              size="b4"
                              color="grey-300"
                              className="text-left"
                            >
                              Email
                            </Paragraph>
                          </div>
                          <div className="flex flex-col space-y-1">
                            <Paragraph
                              size="b4"
                              color="grey-800"
                              weight="semiBold"
                              className="text-right"
                            >
                              {staff?.user?.email}
                            </Paragraph>
                          </div>
                        </div>
                        <div className="px-2 flex justify-between items-center space-x-4 bg-grey-10">
                          <div className="flex flex-col space-y-1 p-2">
                            <Paragraph
                              size="b4"
                              color="grey-300"
                              className="text-left"
                            >
                              Birthday
                            </Paragraph>
                          </div>
                          <div className="flex flex-col space-y-1">
                            <Paragraph
                              size="b4"
                              color="grey-800"
                              weight="semiBold"
                              className="text-right"
                            >
                              {getDayAndMonthFromDate(staff?.user?.dateOfBirth)}
                            </Paragraph>
                          </div>
                        </div>
                        {/* Is this a customer facing staff? */}
                        <div className="px-2 flex justify-between items-center space-x-4 bg-grey-10">
                          <div className="flex flex-col space-y-1 p-2">
                            <Paragraph
                              size="b4"
                              color="grey-300"
                              className="text-left"
                            >
                              Is this a customer facing staff
                            </Paragraph>
                          </div>
                          <div className="flex flex-col space-y-1">
                            <Paragraph
                              size="b4"
                              color="grey-800"
                              weight="semiBold"
                              className="text-right uppercase"
                            >
                              {currentSalonStaff?.employmentDetails
                                ? currentSalonStaff?.employmentDetails
                                  ?.canServeCustomers
                                  ? 'Yes'
                                  : 'No'
                                : '-'}
                            </Paragraph>
                          </div>
                        </div>
                      </div>

                      <div>
                        {/* <Table headers={['Service Name','Staffs Assigned', 'Date of Use','Location']} rows={packageSale.packagesSalesHistory} /> */}
                      </div>
                    </>
                  )}

                  <div className="w-full flex border border-grey-50 rounded-md p-4 flex-col space-y-3">
                    <Paragraph
                      size="b4"
                      weight="semiBold"
                      color={COLORS.GREY[300]}
                      className="text-left"
                    >
                      Work schedule
                    </Paragraph>

                    <div className="px-2 flex justify-between items-start space-x-4">
                      <div className="flex w-1/3">
                        <div>
                          <SvgCalendar
                            height="2.5em"
                            width="2.5em"
                            className="p-2 rounded-lg"
                            style={{ backgroundColor: '#f2f8f4' }}
                          />
                        </div>
                        <div className="ml-2">
                          <Paragraph size="b4" color={COLORS.GREY[300]}>
                            Availability
                          </Paragraph>
                          <Paragraph size="b4" weight="semiBold">
                            {currentSalonStaff?.formattedWorkingHours}
                          </Paragraph>
                        </div>
                      </div>
                      <div className="flex w-1/3">
                        <div>
                          <SvgCalendar
                            height="2.5em"
                            width="2.5em"
                            className="p-2 rounded-lg"
                            style={{ backgroundColor: '#f2f8f4' }}
                          />
                        </div>
                        <div className="ml-2">
                          <Paragraph size="b4" color={COLORS.GREY[300]}>
                            Total leave days
                          </Paragraph>
                          <Paragraph size="b3" weight="semiBold">
                            {currentSalonStaff?.totalLeaveDays}
                          </Paragraph>
                        </div>
                      </div>
                      <div className="flex w-1/3">
                        <div>
                          <SvgCalendar
                            height="2.5em"
                            width="2.5em"
                            className="p-2 rounded-lg"
                            style={{ backgroundColor: '#f2f8f4' }}
                          />
                        </div>

                        <div className="ml-2">
                          <Paragraph size="b4" color={COLORS.GREY[300]}>
                            Leave days left
                          </Paragraph>
                          <Paragraph size="b3" weight="semiBold">
                            {currentSalonStaff?.daysLeft}
                          </Paragraph>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="w-full flex border border-grey-50 rounded-md p-4 flex-col space-y-3">
                    <Paragraph
                      size="b4"
                      weight="semiBold"
                      color={COLORS.GREY[300]}
                      className="text-left"
                    >
                      Performance statistics
                    </Paragraph>

                    <div className="px-2 flex justify-between items-start space-x-4">
                      <div className="flex max-w-1/3">
                        <div>
                          <SvgCalendar
                            height="2.5em"
                            width="2.5em"
                            className="p-2 rounded-lg"
                            style={{ backgroundColor: '#f2f8f4' }}
                          />
                        </div>
                        <div className="ml-2">
                          <Paragraph size="b4" color={COLORS.GREY[300]}>
                            Appointments completed
                          </Paragraph>
                          <Paragraph size="b3" weight="semiBold">
                            {currentSalonStaff?.appointmentsCompleted}
                          </Paragraph>
                        </div>
                      </div>
                      <div className="flex max-w-1/3">
                        <div>
                          <SvgCalendar
                            height="2.5em"
                            width="2.5em"
                            className="p-2 rounded-lg"
                            style={{ backgroundColor: '#f2f8f4' }}
                          />
                        </div>
                        <div className="ml-2">
                          <Paragraph size="b4" color={COLORS.GREY[300]}>
                            Total sales in value
                          </Paragraph>
                          <Paragraph size="b3" weight="semiBold">
                            {currentSalonStaff?.totalSalesValue
                              ? formatInToPrice(
                                currentSalonStaff?.totalSalesValue
                              )
                              : '-'}
                          </Paragraph>
                        </div>
                      </div>
                      <div className="flex max-w-1/3">
                        <div className="">
                          <SvgPercentageBox
                            height="2.5em"
                            width="2.5em"
                            className="p-1 rounded-lg"
                            style={{ backgroundColor: '#f2f8f4' }}
                          />
                        </div>

                        <div className="ml-2">
                          <Paragraph size="b4" color={COLORS.GREY[300]}>
                            Customer ratings
                          </Paragraph>

                          <div className="flex">
                            {currentSalonStaff?.rating && (
                              <>
                                <Paragraph
                                  size="b3"
                                  weight="semiBold"
                                  className="mr-2"
                                >
                                  {parseFloat(
                                    currentSalonStaff?.rating?.toString()
                                  ).toFixed(1)}
                                </Paragraph>
                                <Rating
                                  initialRating={currentSalonStaff?.rating}
                                />
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="w-full flex border border-grey-50 rounded-md p-4 flex-col space-y-3">
                    <div className="flex justify-between">
                      <Paragraph
                        size="b4"
                        weight="semiBold"
                        color={COLORS.GREY[300]}
                      >
                        Services Covered
                      </Paragraph>
                      <Paragraph size="b4" weight="semiBold">
                        {currentSalonStaff?.assignedServices}
                      </Paragraph>
                    </div>
                  </div>

                  <div className="w-full flex border border-grey-50 rounded-md p-4 flex-col space-y-3">
                    <Paragraph
                      size="b4"
                      weight="bold"
                      color={COLORS.GREY[300]}
                      className="text-left"
                    >
                      Employment details
                    </Paragraph>
                    <div className="px-2 flex justify-between items-center space-x-4 bg-grey-10">
                      <div className="flex flex-col space-y-1 p-2">
                        <Paragraph
                          size="b4"
                          color="grey-300"
                          className="text-left"
                        >
                          Role
                        </Paragraph>
                      </div>
                      <div className="flex flex-col space-y-1">
                        <Paragraph
                          size="b4"
                          weight="semiBold"
                          className="text-right capitalize"
                        >
                          Level {currentSalonStaff?.level + 1}
                        </Paragraph>
                      </div>
                    </div>

                    <div className="px-2 flex justify-between items-center space-x-4 bg-grey-10">
                      <div className="flex flex-col space-y-1 p-2">
                        <Paragraph
                          size="b4"
                          color="grey-300"
                          className="text-left"
                        >
                          Specialty
                        </Paragraph>
                      </div>
                      <div className="flex flex-col space-y-1">
                        <Paragraph
                          size="b4"
                          weight="semiBold"
                          className="text-right"
                        >
                          {currentSalonStaff?.employmentDetails?.specialty &&
                            getSpecialty(
                              currentSalonStaff?.employmentDetails?.specialty
                            )}
                        </Paragraph>
                      </div>
                    </div>

                    <div className="px-2 flex justify-between items-center space-x-4 bg-grey-10">
                      <div className="flex flex-col space-y-1 p-2">
                        <Paragraph
                          size="b4"
                          color="grey-300"
                          className="text-left"
                        >
                          Start Date
                        </Paragraph>
                      </div>
                      <div className="flex flex-col space-y-1">
                        <Paragraph
                          size="b4"
                          weight="semiBold"
                          className="text-right"
                        >
                          {getDayMonthAndNumberFromDateString(
                            currentSalonStaff?.employmentDetails?.startDate
                          )}
                        </Paragraph>
                      </div>
                    </div>

                    <div className="px-2 flex justify-between items-center space-x-4 bg-grey-10">
                      <div className="flex flex-col space-y-1 p-2">
                        <Paragraph
                          size="b4"
                          color="grey-300"
                          className="text-left"
                        >
                          End Date
                        </Paragraph>
                      </div>
                      <div className="flex flex-col space-y-1">
                        <Paragraph
                          size="b4"
                          weight="semiBold"
                          className="text-right"
                        >
                          {currentSalonStaff?.employmentDetails?.endDate
                            ? getDayMonthAndNumberFromDateString(
                              currentSalonStaff?.employmentDetails?.endDate
                            )
                            : 'Present'}
                        </Paragraph>
                      </div>
                    </div>
                  </div>

                  <div className="w-full flex border border-grey-50 rounded-md p-4 flex-col space-y-3">
                    <div className="flex justify-between">
                      <Paragraph
                        size="b4"
                        weight="semiBold"
                        color={COLORS.GREY[300]}
                        className="text-left"
                      >
                        Salary Information
                      </Paragraph>
                      <Pill variant="light">
                        <Paragraph
                          size="b5"
                          color={COLORS.GREY[300]}
                          weight="semiBold"
                          className="rounded-full capitalize"
                        >
                          {currentSalonStaff?.salaryInformation?.period}
                        </Paragraph>
                      </Pill>
                    </div>

                    <div className="px-2 flex justify-between items-center space-x-4 bg-grey-10">
                      <div className="flex flex-col space-y-1 p-2">
                        <Paragraph
                          size="b4"
                          color="grey-300"
                          className="text-left"
                        >
                          Base Salary
                        </Paragraph>
                      </div>
                      <div className="flex flex-col space-y-1">
                        <Paragraph
                          size="b4"
                          weight="semiBold"
                          className="text-right"
                        >
                          {formatInToPrice(
                            Number(currentSalonStaff?.salaryInformation?.baseSalary)
                          )}
                        </Paragraph>
                      </div>
                    </div>

                    <div className="px-2 flex justify-between items-center space-x-4 bg-grey-10">
                      <div className="flex flex-col space-y-1 p-2">
                        <Paragraph
                          size="b4"
                          color="grey-300"
                          className="text-left"
                        >
                          Bonuses
                        </Paragraph>
                      </div>
                      <div className="flex flex-col space-y-1">
                        <Paragraph
                          size="b4"
                          weight="semiBold"
                          className="text-right"
                        >
                          {currentSalonStaff?.salaryInformation?.bonusType
                            ? currentSalonStaff?.salaryInformation?.bonusType ==
                              'amount'
                              ? `${formatInToPrice(
                                Number(currentSalonStaff?.salaryInformation?.bonus)
                              )}`
                              : `${currentSalonStaff?.salaryInformation?.bonus} %`
                            : '-'}
                        </Paragraph>
                      </div>
                    </div>

                    <div className="px-2 flex justify-between items-center space-x-4 bg-grey-10">
                      <div className="flex flex-col space-y-1 p-2">
                        <Paragraph
                          size="b4"
                          color="grey-300"
                          className="text-left"
                        >
                          Tax Deductions
                        </Paragraph>
                      </div>
                      <div className="flex flex-col space-y-1">
                        <Paragraph
                          size="b4"
                          weight="semiBold"
                          className="text-right"
                        >
                          {currentSalonStaff?.salaryInformation?.deductionType
                            ? currentSalonStaff?.salaryInformation
                              ?.deductionType == 'amount'
                              ? `${formatInToPrice(
                                Number(currentSalonStaff?.salaryInformation
                                  ?.deduction)
                              )}`
                              : `${currentSalonStaff?.salaryInformation?.deduction} %`
                            : '-'}
                        </Paragraph>
                      </div>
                    </div>
                  </div>

                  <div className="w-full flex border border-grey-50 rounded-md p-4 flex-col space-y-3">
                    <div className="flex justify-between">
                      <Paragraph
                        size="b4"
                        weight="semiBold"
                        color={COLORS.GREY[300]}
                      >
                        Notifications and Reminders
                      </Paragraph>
                      <Paragraph size="b4" weight="semiBold">
                        {currentSalonStaff?.reminder?.birthday
                          ? 'Birthday, '
                          : ''}
                        {currentSalonStaff?.reminder?.workAnniversary
                          ? 'Work anniversary'
                          : ''}
                      </Paragraph>
                    </div>
                    <div className="flex justify-between">
                      <Paragraph
                        size="b4"
                        weight="semiBold"
                        color={COLORS.GREY[300]}
                      >
                        Receive appointment booked and reminder notifications
                      </Paragraph>
                      <Paragraph size="b4" weight="semiBold">
                        {currentSalonStaff?.receivePushNotifications
                          ? 'Yes'
                          : 'No'}
                      </Paragraph>
                    </div>
                    <div className="flex justify-between">
                      <Paragraph
                        size="b4"
                        weight="semiBold"
                        color={COLORS.GREY[300]}
                      >
                        Receive business reports
                      </Paragraph>
                      <Paragraph size="b4" weight="semiBold">
                        {currentSalonStaff?.receiveBusinessReports
                          ? 'Yes'
                          : 'No'}
                      </Paragraph>
                    </div>
                  </div>

                  <div>
                    <Tabs
                      tabs={STAFF_HISTORY_TABS}
                      activeTab={activeHistoryTab}
                      setActiveTab={setActiveHistoryTab}
                      variant="underline"
                    />
                    {activeHistoryTab ===
                      STAFF_HISTORY_TAB_NAMES.WORK_HISTORY ? (
                      <Table
                        headers={WORK_HISTORY_HEADERS}
                        mobileHeaders={WORK_HISTORY_MOBILE_HEADERS}
                        rows={workHistoryRows}
                        onClick={openWorkHistory}
                      />
                    ) : (
                      <Table
                        headers={LEAVE_HISTORY_HEADERS}
                        mobileHeaders={LEAVE_HISTORY_MOBILE_HEADERS}
                        rows={currentSalonStaff?.leaveHistory}
                        onClick={openLeaveHistory}
                      />
                    )}
                  </div>
                </div>
              </div>
            </>
          </div>
          <ViewWorkHistoryDetails
            isVisible={workHistoryModalIsVisible}
            closeModal={closeWorkHistoryDetailsModal}
            workHistory={workHistory}
          />
          <ViewLeaveHistoryDetails
            isVisible={leaveHistoryModalIsVisible}
            closeModal={closeLeaveHistoryDetailsModal}
            leaveHistory={leaveHistory}
          />
        </div>
      </Modal>
    </>
  );
};

const StaffManagement = () => {
  const [staff, setStaff] = useState<SalonStaff>();
  const actions = {
    addStaff: canPerformAction(PERMISSION_CONSTANTS.staff?.add),
    editStaff: canPerformAction(PERMISSION_CONSTANTS.staff?.edit),
    deleteStaff: canPerformAction(PERMISSION_CONSTANTS.staff?.delete),
    viewStaff: canPerformAction(PERMISSION_CONSTANTS.staff?.view),
    activateStaff: canPerformAction(PERMISSION_CONSTANTS.staff?.activate_staff),
    deactivateStaff: canPerformAction(
      PERMISSION_CONSTANTS.staff?.deactivate_staff
    ),
    addTimeOff: canPerformAction(PERMISSION_CONSTANTS.staff?.add_time_off),
  };
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState<string>('');
  const { getSalonData } = useSalonCache()
  const salon = getSalonData()
  const {
    loading: isLoading,
    data: staffListData,
    refetch
  } = useGetStaffList({
    salonId: salon?.id,
    q: debouncedSearchQuery
  })
  const salonStaffManagement = useMemo(() => staffListData?.staffManagement, [staffListData])
  const {
    data: hoursData,
    loading: hoursLoading
  } = useSalonHours(salon?.id)
  const salonHours = useMemo(() => hoursData?.salonHours, [hoursData])

  const STAFF_MANAGEMENT_HEADINGS = [
    { value: 'name', label: 'Name', show: true },
    { value: 'roleType', label: 'Role Type', show: true },
    { value: 'phoneNumber', label: 'Phone Number', show: true },
    { value: 'email', label: 'Email Address', show: true },
    { value: 'specialization', label: 'Specialization', show: true },
    { value: 'workingHours', label: 'Working Hours', show: true },
    { value: 'status', label: 'Status', show: true },
  ];

  const MOBIL_STAFF_HEADINGS = [
    { key: 'name', title: 'Name' },
    { key: 'phoneNumber', title: 'Phone Number' },
    { key: 'email', title: 'Email Address' },
    { key: 'specialization', title: 'Specialization' },
    { key: 'workingHours', title: 'Working Hours' },
    { key: 'status', title: 'Status' },
  ];

  const [staffManagementHeadings, setStaffManagementHeadings] = useState(
    STAFF_MANAGEMENT_HEADINGS
  );

  const handleHeadingCheckboxChange = (value: string) => {
    const updatedHeadings = staffManagementHeadings.map((heading) => {
      if (heading.value === value) {
        return {
          ...heading,
          show: !heading.show,
        };
      }
      return heading;
    });
    setStaffManagementHeadings(updatedHeadings);
  };

  const ResponsiveTimeDisplay = ({ time }) => {
    return (
      <p className="
        antialiased 
        whitespace-nowrap
        text-b5 md:text-b4
        font-normal 
        uppercase 
        py-1 px-2 
        rounded-full 
        bg-grey-50
        inline-block
        max-w-full
        overflow-hidden
        text-ellipsis
      "
        style={{
          color: 'rgb(102, 115, 104)',
          minWidth: 'min-content'
        }}>
        {time}
      </p>
    );
  };

  const getStaffHour = (staff: SalonStaff) => {
    const daysOfWeek = [
      'sunday',
      'monday',
      'tuesday',
      'wednesday',
      'thursday',
      'friday',
      'saturday',
    ];
    const today = new Date();

    // Use getDay() to find the current day as a number (0-6)
    const dayNumber = today.getDay();
    const staffHour = staff?.staffHours?.find(
      (hour) => hour.day == daysOfWeek[dayNumber]
    );
    return staffHour && staffHour?.openTime && staffHour?.closeTime
      ? `${staffHour?.openTime
        ? convert24HourTo12Hour(staffHour?.openTime)
        : null
      } - ${staffHour?.closeTime
        ? convert24HourTo12Hour(staffHour?.closeTime)
        : null
      }`
      : 'OFF';
  };

  const generateStaffsTableData = (
    staffs: SalonStaff[],
    tableHeadings: TableFilterProps
  ) => {
    return staffs?.map?.((staff) => {
      const rowData = {}; // Using 'any' here, you can replace it with a more specific type
      tableHeadings.forEach((heading) => {
        if (heading.show) {
          switch (heading.value) {
            case 'name':
              rowData[heading.value] = (
                <div className="flex space-x-2 items-center">
                  {staff?.user?.profileUrl ? (
                    <img
                      src={staff?.user?.profileUrl}
                      alt="staff avatar"
                      className="w-full max-w-[36px] h-[36px] rounded-full"
                      loading="lazy"
                    />
                  ) : (
                    <NameAvatar
                      name={
                        (staff?.user?.firstName?.charAt(0)?.toLocaleUpperCase() ?? '') +
                        (staff?.user?.lastName
                          ? staff.user.lastName.charAt(0).toLocaleUpperCase()
                          : staff?.user?.firstName?.charAt(1)?.toLocaleUpperCase())
                      }
                    />
                  )}
                  <div className="flex space-y-2 flex-col">
                    <Paragraph size="b5" className="uppercase">
                      {staff?.user?.fullName}
                    </Paragraph>
                    <Paragraph
                      size="b5"
                      color={COLORS.GREY[300]}
                      className="capitalize"
                    >
                      {cleanUpText(staff?.specialty)}
                    </Paragraph>
                  </div>
                </div>
              );
              break;
            case 'roleType':
              rowData[heading.value] = (
                <>
                  {staff?.canServeCustomers ? (
                    <div className="flex space-x-2">
                      <SvgGreenSignal width="24px" height="24px" />
                      <Paragraph
                        size="b5"
                        color={COLORS.GREY[300]}
                        className="capitalize"
                      >
                        {limitString('Customer-Facing Staff', 10)}
                      </Paragraph>
                    </div>
                  ) : (
                    <div className="flex space-x-2">
                      <SvgYellowsignal width="24px" height="24px" />
                      <Paragraph
                        size="b5"
                        color={COLORS.GREY[300]}
                        className="capitalize"
                      >
                        {limitString('Non-Customer-Facing Staff', 10)}
                      </Paragraph>
                    </div>
                  )}
                </>
              );
              break;
            case 'email':
              rowData[heading.value] = staff?.user?.email;
              break;
            case 'phoneNumber':
              rowData[heading.value] = staff?.user?.phone;
              break;
            case 'specialization':
              rowData[heading.value] = formatSnakeCaseText(
                staff?.specialty ?? ''
              );
              break;
            case 'workingHours':
              rowData[heading.value] = (
                <ResponsiveTimeDisplay time={getStaffHour(staff)} />
              );
              break;
            case 'status':
              rowData[heading.value] = (
                <Pill variant={staff?.active ? 'success' : 'light'}>
                  {staff?.active ? 'Active' : 'Inactive'}
                </Pill>
              );
              break;
          }
        }
      });
      return rowData;
    });
  };

  const [action, setAction] = useState<'create' | 'update' | 'delete'>(
    'create'
  );

  const { openModal: openViewStaffModal, closeModal, isVisible } = useModal();

  const {
    openModal: openAddEditStaffModal,
    closeModal: closeAddEditStaffModalDefault,
    isVisible: isAddEditStaffModalVisible,
  } = useModal();

  const {
    openModal: openDeleteStaffModal,
    closeModal: closeDeleteStaffModalDefault,
    isVisible: isDeleteStaffModalVisible,
  } = useModal();

  const {
    openModal: openDisableStaffModal,
    closeModal: closeDisableStaffModalDefault,
    isVisible: isDisableStaffModalVisible,
  } = useModal();

  const {
    isVisible: isAddTimeOffModalVisible,
    openModal: openAddTimeOffModal,
    closeModal: closeAddTimeOffModal,
  } = useModal();

  const { toast, addToast } = useToast()

  const closeAddEditStaffModal = () => {
    refetch()
    setStaff(null);
    closeAddEditStaffModalDefault();
  };

  const closeViewStaffModal = (action?: string) => {
    closeModal();
    refetch()
    if (action === 'edit') return;
    setStaff(null);
  };

  const closeDeleteStaffModal = () => {
    closeDeleteStaffModalDefault();
    refetch()
    if (!isVisible) {
      setStaff(null);
    }
  };

  const closeDisableStaffModal = () => {
    refetch()
    closeDisableStaffModalDefault();
    if (!isVisible) {
      setStaff(null);
    }
  };

  const openAddStaffModal = () => {
    setAction('create');
    openAddEditStaffModal();
  };

  const openEditStaffModal = () => {
    setAction('update');
    openAddEditStaffModal();
  };

  const initiateCloseAddTimeOffModal = () => {
    setStaff(null);
    closeAddTimeOffModal();
  };

  const handleViewStaff = (id: number, action?: string) => {
    if (!action && !actions.viewStaff) return;

    setStaff(salonStaffManagement.staffs[id]);

    switch (action) {
      case 'view':
        openViewStaffModal();
        break;
      case 'edit':
        openEditStaffModal();
        break;
      case 'delete':
        openDeleteStaffModal();
        break;
      case 'deactivate':
        openDisableStaffModal();
        break;
      case 'activate':
        openDisableStaffModal();
        break;
      case 'add_time_off':
        openAddTimeOffModal();
        break;
      default:
        openViewStaffModal();
        break;
    }
  };

  const {
    isVisible: isUploadStaffModalVisible,
    openModal: openUploadStaffModal,
    closeModal: closeUploadStaffModal,
  } = useModal();

  useEffect(() => {
    refetch();
  }, [debouncedSearchQuery]);

  const rows = generateStaffsTableData(
    salonStaffManagement?.staffs,
    staffManagementHeadings
  );

  return (
    <div>
      <ToastWrapper toast={toast} />
      <div className="flex flex-col md:flex-row py-4 px-8 justify-between md:items-center gap-4 border-b border-grey-20">
        <Heading variant="h2" size="h11" weight="bold">
          Staff List
        </Heading>
        {actions?.addStaff ? (
          <div className="flex md:items-center space-x-2">
            <Button
              variant="secondary"
              size="sm"
              disabled={false}
              loading={false}
              type="button"
              rounded="md"
              fontSize="b5"
              onClick={openUploadStaffModal}
            >
              Import Staff
            </Button>
            <Button
              variant="primary"
              size="sm"
              disabled={false}
              loading={false}
              type="button"
              rounded="md"
              fontSize="b5"
              onClick={openAddStaffModal}
            >
              Add Staff
            </Button>
          </div>
        ) : null}
      </div>
      <div className="grid grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 w-full p-3 md:p-6 gap-2 border-b border-grey-50">
        <div
          style={{ maxWidth: '360px', minHeight: '120px' }}
          className="w-full flex p-4 gap-4 cursor-pointer border border-grey-100 rounded-md"
        >
          <SvgPercentageBox width="32px" height="32px" />
          <div className="flex flex-col h-full w-full justify-between">
            <div className="flex flex-col">
              <Paragraph size="b5" color={COLORS.GREY[300]} weight="semiBold">
                Total staff
              </Paragraph>
              <Paragraph size="b5" color={COLORS.GREY[300]}>
                Total number of staff
              </Paragraph>
            </div>

            <Paragraph size="b1" color={COLORS.GREY[900]} weight="semiBold">
              {salonStaffManagement?.totalStaffs}
            </Paragraph>
          </div>
        </div>
        <div
          style={{ maxWidth: '360px', minHeight: '120px' }}
          className="w-full flex p-4 gap-4 cursor-pointer border border-grey-100 rounded-md"
        >
          <SvgPercentageBox width="32px" height="32px" />
          <div className="flex flex-col h-full w-full justify-between">
            <div className="flex flex-col">
              <Paragraph size="b5" color={COLORS.GREY[300]} weight="semiBold">
                Active staff
              </Paragraph>
              <Paragraph size="b5" color={COLORS.GREY[300]}>
                Number of active staff
              </Paragraph>
            </div>

            <Paragraph size="b1" color={COLORS.GREY[900]} weight="semiBold">
              {salonStaffManagement?.staffs?.filter((staff) => staff?.active)
                ?.length || 0}
            </Paragraph>
          </div>
        </div>
        <div
          style={{ maxWidth: '360px', minHeight: '120px', gap: '1rem' }}
          className="w-full flex p-4 cursor-pointer border border-grey-100 rounded-md"
        >
          <SvgArrowBox width="32px" height="32px" />
          <div className="flex flex-col h-full w-full justify-between">
            <div className="flex flex-col">
              <Paragraph size="b5" color={COLORS.GREY[300]} weight="semiBold">
                Total sales
              </Paragraph>
              <Paragraph size="b5" color={COLORS.GREY[300]}>
                Total number of sales
              </Paragraph>
            </div>

            <Paragraph
              size="b1"
              color={COLORS.GREY[900]}
              weight="semiBold"
              className="uppercase"
            >
              {salonStaffManagement?.totalSales}
            </Paragraph>
          </div>
        </div>
        <div
          style={{ maxWidth: '360px', minHeight: '120px', gap: '1rem' }}
          className="w-full flex p-4 cursor-pointer border border-grey-100 rounded-md"
        >
          <SvgArrowBox width="32px" height="32px" />
          <div className="flex flex-col h-full w-full justify-between">
            <div className="flex flex-col">
              <Paragraph size="b5" color={COLORS.GREY[300]} weight="semiBold">
                Average rating
              </Paragraph>
              <Paragraph size="b5" color={COLORS.GREY[300]}>
                Cumulative rating of staff
              </Paragraph>
            </div>

            <Paragraph
              size="b1"
              color={COLORS.GREY[900]}
              weight="semiBold"
              className="uppercase"
            >
              {salonStaffManagement?.averageRating ?? '-'}
            </Paragraph>
          </div>
        </div>
      </div>

      <div className="flex flex-col xl:flex-row py-4 px-8 space-x-4">
        <div
          style={{ width: '50%' }}
          className="w-full xl:w-6/12 flex items-center space-x-4"
        >
          <SearchTerm
            placeholder="Search by name, specialty"
            setDebouncedSearchQuery={setDebouncedSearchQuery}
          />
          <Menu as="div" className="relative inline-block text-left">
            <div>
              <Menu.Button className="flex space-x-2 w-full items-center justify-center rounded-md bg-grey-50 border border-grey-20 px-4 py-2 text-b5 font-medium text-grey-300 hover:bg-opacity-90 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-85">
                <SvgFilter width="20px" height="20px" />
                <span className="hidden xl:inline">Filter</span>
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-[9999]">
                <div className="flex flex-col p-4 space-y-4">
                  {Array.isArray(staffManagementHeadings) &&
                    staffManagementHeadings.length &&
                    staffManagementHeadings.map((heading, index) => (
                      <Label
                        className="flex space-x-2 items-center cursor-pointer"
                        key={index}
                        htmlFor={heading.value}
                        onClick={() =>
                          handleHeadingCheckboxChange(heading.value)
                        }
                      >
                        <Checkbox
                          isChecked={heading.show}
                          borderType="Black"
                          size="Sm"
                        />
                        <span>{heading.label}</span>
                      </Label>
                    ))}
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>

      <FullTable
        headers={formatTableHeadersFilterArray(staffManagementHeadings)}
        mobileHeaders={MOBIL_STAFF_HEADINGS}
        rows={rows}
        onClick={handleViewStaff}
        tableOptions={{
          edit: actions?.editStaff,
          delete: actions?.deleteStaff,
          deactivate: actions?.deactivateStaff,
          activate: actions?.activateStaff,
          duplicate: false,
          view: actions?.viewStaff,
          add_time_off: actions?.addTimeOff,
        }}
      />
      <ViewStaffManagementPage
        staff={staff}
        closeModal={closeViewStaffModal}
        openEditStaff={openEditStaffModal}
        openDeleteStaff={openDeleteStaffModal}
        openDisableStaff={openDisableStaffModal}
        isVisible={isVisible}
      />
      <AddEditStaffModal
        staff={staff}
        closeModal={closeAddEditStaffModal}
        isVisible={isAddEditStaffModalVisible}
        action={action}
        salonHours={salonHours}
      />
      <AddTimeOffModal
        isVisible={isAddTimeOffModalVisible}
        closeModal={initiateCloseAddTimeOffModal}
        staffId={staff?.id}
        addToast={addToast}
        staffList={salonStaffManagement?.staffs}
      />
      <UploadStaffModal
        isVisible={isUploadStaffModalVisible}
        closeModal={closeUploadStaffModal}
      />
      <DeleteStaffModal
        isVisible={isDeleteStaffModalVisible}
        closeModal={closeDeleteStaffModal}
        staff={staff}
        salonHours={salonHours}
      />
      <DisableStaffModal
        isVisible={isDisableStaffModalVisible}
        closeModal={closeDisableStaffModal}
        staff={staff}
        salonHours={salonHours}
      />
    </div>
  );
};

type AddEditStaffModalProps = {
  isVisible: boolean;
  closeModal: () => void;
  staff: SalonStaff | null;
  action: 'create' | 'update' | 'delete';
  salonHours: SalonHour[] | [];
};

const AddEditStaffModal = ({
  isVisible,
  closeModal,
  staff,
  action,
  salonHours,
}: AddEditStaffModalProps) => {
  const { addToast, toast } = useToast();
  const STAFF_TABS = [
    {
      key: STAFF_DETAILS_TAB_NAME.PERSONAL_INFORMATION,
      title: STAFF_DETAILS_TAB_NAME.PERSONAL_INFORMATION,
    },
    {
      key: STAFF_DETAILS_TAB_NAME.EMPLOYMENT_DETAILS,
      title: STAFF_DETAILS_TAB_NAME.EMPLOYMENT_DETAILS,
    },
    {
      key: STAFF_DETAILS_TAB_NAME.WORK_SCHEDULE,
      title: STAFF_DETAILS_TAB_NAME.WORK_SCHEDULE,
    },
    {
      key: STAFF_DETAILS_TAB_NAME.LEAVE_AND_ATTENDANCE,
      title: STAFF_DETAILS_TAB_NAME.LEAVE_AND_ATTENDANCE,
    },
    {
      key: STAFF_DETAILS_TAB_NAME.SALARY_INFORMATION,
      title: STAFF_DETAILS_TAB_NAME.SALARY_INFORMATION,
    },
    {
      key: STAFF_DETAILS_TAB_NAME.NOTIFICATIONS_AND_REMINDERS,
      title: STAFF_DETAILS_TAB_NAME.NOTIFICATIONS_AND_REMINDERS,
    },
  ];
  const [activeTab, setActiveTab] = useState(
    STAFF_DETAILS_TAB_NAME.PERSONAL_INFORMATION as string
  );

  const [staffFormValues, setStaffFormValues] = useState<ICreateStaffDetail>();
  const {
    data: staffData,
    loading,
    refetch: refetchStaffData,
  } = useGetStaff({ salonStaffId: staff?.id })

  useEffect(() => {
    if (staff?.id) {
      refetchStaffData()
    }
  }, [staff?.id, refetchStaffData])

  const currentSalonStaff = useMemo(() => {
    if (staffData?.salonStaff && isVisible) {
      const currentSalonStaff = staffData?.salonStaff;
      setStaffFormValues({
        ...currentSalonStaff,
        // @ts-expect-error - no error tho 
        employmentDetails: currentSalonStaff?.employmentDetails
          ? {
            ...currentSalonStaff?.employmentDetails,
          }
          : {
            startDate: currentSalonStaff?.createdAt
              ? currentSalonStaff?.createdAt?.split('T')[0]
              : '',
            specialty: currentSalonStaff?.specialty,
            salonId: currentSalonStaff?.salonId,
            level: currentSalonStaff?.level,
            canServeCustomers: currentSalonStaff?.canServeCustomers,
            canLogin: currentSalonStaff?.canLogin,
            present: true,
            services: []
          },
        leave: { daysEntitled: currentSalonStaff?.leave?.daysEntitled },
        user: {
          firstName: currentSalonStaff?.user?.firstName,
          lastName: currentSalonStaff?.user?.lastName,
          email: currentSalonStaff?.user?.email,
          countryCode: currentSalonStaff?.user?.countryCode,
          phone: currentSalonStaff?.user?.phone,
          dateOfBirth: currentSalonStaff?.user?.dateOfBirth,
          profileUrl: currentSalonStaff?.user?.profileUrl,
          callingCode: currentSalonStaff?.user?.callingCode,
        },
        reminder: {
          birthday: currentSalonStaff?.reminder?.birthday,
          workAnniversary: currentSalonStaff?.reminder?.workAnniversary,
        }
      });
      return staffData?.salonStaff
    }

    return null;
  }, [staffData, isVisible])

  const validatePage = (requiredFields: string[], page): boolean => {
    for (const field of requiredFields) {
      if (
        page[field] === undefined ||
        page[field] === null ||
        page[field] === ''
      ) {
        return false;
      }
    }
    return true;
  };

  const [submitPage, setSubmitPage] = useState<string>();

  const validateForm = (
    staffFormValues: ICreateStaffDetail,
    setActiveTab: (tab: string) => void
  ): boolean => {
    const setAndActivatePage = (
      page: string,
      setActiveTab: (tab: string) => void
    ): boolean => {
      setActiveTab(page);
      setSubmitPage(page);
      return false;
    };
    //personal information
    if (staffFormValues?.user) {
      const requiredFields = [
        'firstName',
        'lastName',
        'callingCode',
        'countryCode',
      ]; // Define required fields

      const valid = validatePage(requiredFields, staffFormValues?.user);
      if (!valid) {
        return setAndActivatePage(
          STAFF_DETAILS_TAB_NAME.PERSONAL_INFORMATION,
          setActiveTab
        );
      }
    } else {
      return setAndActivatePage(
        STAFF_DETAILS_TAB_NAME.PERSONAL_INFORMATION,
        setActiveTab
      );
    }
    //employment details
    if (staffFormValues?.employmentDetails) {
      const requiredFields = ['startDate', 'specialty', 'salonId']; // Define required fields
      const valid = validatePage(
        requiredFields,
        staffFormValues?.employmentDetails
      );
      if (!valid) {
        return setAndActivatePage(
          STAFF_DETAILS_TAB_NAME.EMPLOYMENT_DETAILS,
          setActiveTab
        );
      }
    } else {
      return setAndActivatePage(
        STAFF_DETAILS_TAB_NAME.EMPLOYMENT_DETAILS,
        setActiveTab
      );
    }
    //working schedule
    if (staffFormValues?.workingSchedule) {
      const requiredFields = staff ? [] : ['workScheduleType']; // Define required fields
      const valid = validatePage(
        requiredFields,
        staffFormValues?.workingSchedule
      );
      if (!valid) {
        return setAndActivatePage(
          STAFF_DETAILS_TAB_NAME.WORK_SCHEDULE,
          setActiveTab
        );
      }
    } else {
      return setAndActivatePage(
        STAFF_DETAILS_TAB_NAME.WORK_SCHEDULE,
        setActiveTab
      );
    }
    //leave and attendance
    // if (staffFormValues?.leave) {
    //   const requiredFields = []; // Define required fields
    //   const valid = validatePage(requiredFields,  staffFormValues?.leave)
    //   if (!valid){
    //     return setAndActivatePage(STAFF_DETAILS_TAB_NAME.LEAVE_AND_ATTENDANCE, setActiveTab)
    //   }
    // }else{
    //   return setAndActivatePage(STAFF_DETAILS_TAB_NAME.LEAVE_AND_ATTENDANCE, setActiveTab)
    // }
    //salary information
    // if (staffFormValues?.salaryInformation) {
    //   const requiredFields = ['period','baseSalary']; // Define required fields
    //   const valid = validatePage(requiredFields,  staffFormValues?.salaryInformation)
    //   if (!valid){
    //     return setAndActivatePage(STAFF_DETAILS_TAB_NAME.SALARY_INFORMATION, setActiveTab)
    //   }
    // }else{
    //   return setAndActivatePage(STAFF_DETAILS_TAB_NAME.SALARY_INFORMATION, setActiveTab)
    // }

    return true;
  };

  const closeModal_ = () => {
    setStaffFormValues(null)
    setActiveTab(STAFF_DETAILS_TAB_NAME.PERSONAL_INFORMATION as string)
    closeModal()
  }
  const getTabView = () => {
    switch (activeTab) {
      case STAFF_DETAILS_TAB_NAME.PERSONAL_INFORMATION:
        return (
          <PersonalInformation
            staffFormValues={staffFormValues}
            setStaffFormValues={setStaffFormValues}
            setTab={setActiveTab}
            closeModal={closeModal_}
            submitPage={submitPage}
            activeTab={activeTab}
            salonHours={salonHours}
            addToast={addToast}
            currentSalonStaff={currentSalonStaff}
          />
        );
      case STAFF_DETAILS_TAB_NAME.EMPLOYMENT_DETAILS:
        return (
          <EmploymentDetails
            staffFormValues={staffFormValues}
            setStaffFormValues={setStaffFormValues}
            setTab={setActiveTab}
            closeModal={closeModal_}
            submitPage={submitPage}
            activeTab={activeTab}
            salonHours={salonHours}
            addToast={addToast}
            currentSalonStaff={currentSalonStaff}
          />
        );
      case STAFF_DETAILS_TAB_NAME.WORK_SCHEDULE:
        return (
          <WorkingSchedule
            staffFormValues={staffFormValues}
            setStaffFormValues={setStaffFormValues}
            closeModal={closeModal_}
            setTab={setActiveTab}
            submitPage={submitPage}
            activeTab={activeTab}
            addToast={addToast}
            currentSalonStaff={currentSalonStaff}
          />
        );
      case STAFF_DETAILS_TAB_NAME.LEAVE_AND_ATTENDANCE:
        return (
          <LeaveAndAttendance
            staffFormValues={staffFormValues}
            setStaffFormValues={setStaffFormValues}
            setTab={setActiveTab}
            closeModal={closeModal_}
            submitPage={submitPage}
            activeTab={activeTab}
            salonHours={salonHours}
            addToast={addToast}
            currentSalonStaff={currentSalonStaff}
          />
        );
      case STAFF_DETAILS_TAB_NAME.SALARY_INFORMATION:
        return (
          <SalaryInformation
            staffFormValues={staffFormValues}
            setStaffFormValues={setStaffFormValues}
            closeModal={closeModal_}
            setTab={setActiveTab}
            submitPage={submitPage}
            activeTab={activeTab}
            salonHours={salonHours}
            addToast={addToast}
            currentSalonStaff={currentSalonStaff}
          />
        );
      case STAFF_DETAILS_TAB_NAME.NOTIFICATIONS_AND_REMINDERS:
        return (
          <Reminders
            staffFormValues={staffFormValues}
            setStaffFormValues={setStaffFormValues}
            setTab={setActiveTab}
            closeModal={closeModal_}
            submitPage={submitPage}
            validateForm={validateForm}
            activeTab={activeTab}
            salonHours={salonHours}
            addToast={addToast}
            currentSalonStaff={currentSalonStaff}
          />
        );
    }
  };
  return (
    <>
      <ToastWrapper toast={toast} />
      <Modal show={isVisible} closeModal={closeModal_} variant="right">
        <div className="relative">
          <div className="fixed bg-white w-full flex border-b border-grey-100 cursor-pointer">
            <div className="w-full hidden xl:flex space-x-2 px-6 py-5 items-start">
              <Button
                variant="text"
                size="none"
                type="button"
                className="w-fit"
                fontSize="b4"
                onClick={closeModal_}
              >
                <SvgArrowBack width="24px" height="24px" /> <span>Back</span>
              </Button>
            </div>
            <div className="w-full flex xl:hidden space-x-[69px] p-4 items-center">
              <Button
                variant="icon"
                size="square"
                type="button"
                rounded="md"
                className="w-fit"
                fontSize="b4"
                onClick={closeModal_}
              >
                <SvgChevronLeft width="24px" height="24px" />
              </Button>
            </div>
          </div>

          <div className="w-full flex flex-col px-6 py-4 space-y-6 mt-[70px]">
            <Heading variant="h1" size="h9" weight="semiBold">
              {staff ? 'Edit staff' : 'Add new staff'}
            </Heading>
            <Paragraph size="b4">
              {staff ? 'Edit existing staff' : 'Create a new staff'}
            </Paragraph>
          </div>


          <div className="w-full">
            {loading ?
              <ViewAppointmentShimmer />
              : <>
                <Tabs
                  tabs={STAFF_TABS}
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                />
                <div className="w-full px-6">
                  {getTabView()}
                </div>
              </>}
          </div>
        </div>
      </Modal>
    </>
  );
};

type IEmploymentDetails = {
  startDate?: Date | string;
  endDate?: Date | string;
  canServeCustomers?: boolean;
  specialty?: string;
  salonId?: string;
  canLogin?: boolean;
  level?: string;
  services?: { label?: string; value?: string }[];
  present?: boolean;
};

type ILeave = {
  leaveType?: string;
  daysEntitled: number | string;
};

type ISalaryInformation = {
  period: string;
  baseSalary: number | string;
  bonus: number | string;
  bonusType: string;
  deduction: number | string;
  deductionType: string;
};

type IReminder = {
  birthday: boolean;
  workAnniversary: boolean;
};

type ICreateStaffDetail = {
  id?: string;
  user?: IUser;
  employmentDetails?: IEmploymentDetails;
  workingSchedule?: IWorkingSchedule;
  leave?: ILeave;
  salaryInformation?: ISalaryInformation;
  reminder?: IReminder;
  role?: string;
  rating?: number;
  receiveBusinessReports?: boolean;
  receivePushNotifications?: boolean;
};

type IUser = {
  firstName?: string;
  lastName?: string;
  email?: string;
  countryCode?: string;
  phone?: string;
  dateOfBirth?: string;
  profileUrl?: string;
  callingCode?: string;
};

const PersonalInformation = ({
  staffFormValues,
  setStaffFormValues,
  setTab,
  submitPage,
  closeModal,
  activeTab,
  salonHours,
  currentSalonStaff,
  addToast
}: StaffTabProps) => {
  const {
    handleSubmit,
    control,
    setValue,
    register,
    reset,
    getValues,
    watch,
    trigger,
    formState: { errors },
  } = useForm<IUser>();
  const country = JSON.parse(localStorage.getItem('country'));
  const [avatar, setAvatar] = useState(null);
  const [countryCode, setCountryCode] = useState(country?.code);
  const [callingCode, setCallingCode] = useState(country?.countryCode);
  const [updateStaff, setUpdateStaff] = useState(false);

  const {
    loading,
    updateSalonStaff
  } = useUpdateStaff()

  const {
    loading: savingAttachment,
    uploadFile
  } = useUploadFile();
  const handleFileUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = async (event) => {
      const base64DataUri = event.target.result;
      uploadFile({
        variables: { input: { data: base64DataUri } }
      }).then(({ data }) => {
        const { uploadFile } = data;
        if (uploadFile?.status === 200) {
          setAvatar(uploadFile?.url);
          setValue('profileUrl', uploadFile?.url);
        }

        if (uploadFile?.errors?.length) {
          addToast({
            message: uploadFile?.errors[0].message,
            variant: 'error'
          })
        }
      }).catch((error) => {
        addToast({
          message: error?.message,
          variant: 'error'
        })
      })
    };

    reader.readAsDataURL(file);
  };

  const _sanitizePhoneNumber = (phoneNumber: string) => {
    const _phoneNumber = sanitizePhoneNumber(phoneNumber, callingCode);
    setValue('phone', _phoneNumber);
  };

  const update = async (data) => {
    const inputData = formatStaffUpdateData(data, salonHours);
    updateSalonStaff({
      variables: {
        input: inputData
      }
    }).then(({ data }) => {
      const { updateSalonStaffDetails } = data;

      if (updateSalonStaffDetails?.status === 200) {
        addToast({
          variant: 'success',
          message: 'Staff updated successfully'
        })
        closeModal();
      }

      if (updateSalonStaffDetails?.errors?.length) {
        addToast({
          variant: 'error',
          message: updateSalonStaffDetails?.errors[0]?.message
        })
      }
    })
  };

  const resetStaffForm = () => {
    // setValue('firstName', '')
    // setValue('lastName', '')
    // setValue('email', '')
    // setValue('phone', '')
    // setValue('countryCode', 'NG')
    // setValue('callingCode', '+234')
    // setValue('dateOfBirth', null)
    // setValue('profileUrl', '')
    reset();
    setAvatar(null);
  };

  // useEffect(() => {
  //   const handler = setTimeout(() => {
  //     // eslint-disable-next-line @typescript-eslint/no-unused-vars
  //     const {profileUrl, ...allValues} = watch()

  //     const personalData = {...staffFormValues, user: {profileUrl: avatar as string, countryCode: countryCode, callingCode: callingCode, ...allValues } }
  //     setStaffFormValues(personalData)
  // }, DEBOUNCE_DELAY);

  // return () => {
  //     clearTimeout(handler);
  // };
  // }, [staffFormValues?.user])

  useEffect(() => {
    if (submitPage === STAFF_DETAILS_TAB_NAME.PERSONAL_INFORMATION) {
      trigger();
    }
  }, [submitPage]);

  useEffect(() => {
    if (staffFormValues?.user) {
      const {
        firstName,
        lastName,
        email,
        phone,
        profileUrl,
        countryCode,
        dateOfBirth,
      } = staffFormValues?.user;
      setValue('firstName', firstName);
      setValue('lastName', lastName);
      setValue('email', email);
      setValue('phone', phone);
      setValue('profileUrl', profileUrl);
      setAvatar(profileUrl);
      setValue('callingCode', callingCode);
      setValue('countryCode', countryCode);
      setValue('dateOfBirth', dateOfBirth);
    } else {
      resetStaffForm();
    }
  }, [staffFormValues]);

  const handleNextStep = (data: IUser) => {
    const newData = {
      ...staffFormValues,
      user: {
        ...data,
        email: data?.email || '',
        countryCode,
        callingCode,
        profileUrl: avatar as string,
      },
    };
    setStaffFormValues(newData);
    return newData;
  };

  const handleClick = (data: IUser) => {
    const newData = handleNextStep(data);
    if (updateStaff && newData) {
      update(newData);
      return;
    }
    setTab(STAFF_DETAILS_TAB_NAME.EMPLOYMENT_DETAILS);
  };
  return (
    <div className="flex flex-col" style={{ height: '50%' }}>
      <div className="flex items-center bg-grey-50 p-4 mt-[20px]">
        <Paragraph size="b5" weight="semiBold" color={COLORS.GREY[400]}>
          PERSONAL INFORMATION
        </Paragraph>
      </div>

      <form onSubmit={handleSubmit(handleClick)}>
        <div className="flex relative">
          <div
            className={`border cursor-pointer border-dashed border-grey-400 mt-4 rounded-full flex flex-col items-center justify-center w-[200px] h-[200px]`}
            style={{
              backgroundImage: `url(${savingAttachment ? '' : avatar})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          >
            {!avatar ? (
              <>
                <SvgNoImageBig height="2.5em" width="2.5em" />
                <Paragraph
                  weight="semiBold"
                  color={COLORS.GREY[300]}
                  className="text-center"
                >
                  Upload an image
                </Paragraph>
                <Paragraph color={COLORS.GREY[400]}>
                  Use as profile pic
                </Paragraph>
              </>
            ) : savingAttachment ? (
              <div className="flex justify-center gap-x-2 cursor-pointer">
                {[1, 2, 3].map((item, index) => (
                  <div
                    key={index}
                    className="h-1 w-1 rounded-full bg-gradient-to-r from-grey-200 via-grey-300 to-grey-200 animate-pulse"
                  />
                ))}
              </div>
            ) : null}

            <input
              style={{ height: '200px' }}
              className="cursor-pointer absolute block opacity-0 top-0 w-full"
              type="file"
              {...register('profileUrl')}
              accept={IMAGE_UPLOAD_PATTERN}
              onChange={(e) => handleFileUpload(e)}
            />
          </div>
        </div>

        <div className="flex gap-2 mt-4">
          <Input
            control={control}
            name="firstName"
            type="text"
            id="firstName"
            label="First Name"
            placeholder="First Name"
            rules={{
              required: 'First name is required',
            }}
            error={errors?.firstName}
          />
          <Input
            control={control}
            name="lastName"
            type="text"
            id="lastName"
            label="Last Name"
            placeholder="Last Name"
            rules={{
              required: 'Last name is required',
            }}
            error={errors.lastName}
          />
        </div>
        <div className="flex gap-2 mt-4">
          <FormPhone
            country={{
              disabled: true,
              onSelect: (code, country: string) => {
                setCountryCode(country);
                setCallingCode(code);
              },
              value: countryCode || staffFormValues?.user?.countryCode,
            }}
            phone={{
              name: 'phone',
              type: 'text',
              placeholder: '9151930463',
              rules: {
                required: 'Phone number is required',
                pattern: REGEX_PATTERNS.NUMBER,
                onChange: (e) => {
                  _sanitizePhoneNumber(e.target.value);
                },
                disabled: false,
              },
              register,
              id: 'phone-number',
              label: 'Phone Number',
            }}
            helperText={getHelperTextForReactHookFormErrors(
              errors?.phone?.message as string
            )}
          />
          <Input
            control={control}
            name="email"
            type="text"
            id="email"
            label="Email Address"
            placeholder="Email Address"
            rules={{
              pattern: REGEX_PATTERNS.EMAIL,
            }}
          />
        </div>
        <div className="flex gap-2 mt-4 w-1/2">
          <Input
            control={control}
            name="dateOfBirth"
            type="date"
            id="dateOfBirth"
            label="Birthday"
            placeholder="1999-01-01"
          />
        </div>

        <div className="relative h-[100px]">
          <div className="w-full max-w-[800px] absolute bottom-0 bg-white flex space-x-3 border-t border-grey-100 cursor-pointer py-2 px-8 justify-end">
            {currentSalonStaff ? (
              <Button
                type="submit"
                onClick={() => setUpdateStaff(true)}
                loading={loading}
                disabled={loading}
                variant="secondary"
                className="rounded-lg"
              >
                Save
              </Button>
            ) : null}
            <Button type="submit" variant="primary" className="rounded-lg" disabled={loading}>
              Proceed
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

type StaffTabProps = {
  setStaffFormValues: (staffFormValues: ICreateStaffDetail) => void;
  setTab: (tab: string) => void;
  closeModal?: () => void;
  submitPage?: string;
  activeTab?: string;
  validateForm?: (
    staffFormValues: ICreateStaffDetail,
    setActiveTab: (tab: string) => void
  ) => boolean;
  staffFormValues: ICreateStaffDetail;
  salonHours?: SalonHour[];
  roles?: Role[];
  currentSalonStaff?: SalonStaffDetails
  addToast: (toast: ToastProps) => void;
};

const EmploymentDetails = ({
  setStaffFormValues,
  staffFormValues,
  setTab,
  closeModal,
  submitPage,
  activeTab,
  salonHours,
  currentSalonStaff,
  addToast
}: StaffTabProps) => {
  const [location, setLocation] = useState<string>(); //salon
  const [staffPresent, setStaffPresent] = useState(false);
  const { getSalonFieldValue } = useSalonCache();
  const [roleName, setRoleName] = useState<string | null>(null);
  const salonId = getSalonFieldValue('id');
  const {
    handleSubmit,
    control,
    setValue,
    register,
    trigger,
    watch,
    formState: { errors },
  } = useForm<IEmploymentDetails>({
    defaultValues: {
      services: staffFormValues?.employmentDetails?.services,
    },
  });
  const {
    isVisible: permissionModalIsvisible,
    openModal: openPermissionModal,
    closeModal: closePermissionModal,
  } = useModal();
  const [updateStaff, setUpdateStaff] = useState(false);

  const handleNextStep = (data: IEmploymentDetails) => {
    const newData = {
      ...staffFormValues,
      employmentDetails: { ...data, canLogin: data?.canLogin },
    };
    setStaffFormValues(newData);
    return newData;
  };
  const {
    loading,
    updateSalonStaff
  } = useUpdateStaff()

  const update = async (data) => {
    const inputData = formatStaffUpdateData(data, salonHours as SalonHour[]);
    updateSalonStaff({
      variables: {
        input: inputData
      }
    }).then(({ data }) => {
      const { updateSalonStaffDetails } = data;

      if (updateSalonStaffDetails?.status === 200) {
        addToast({
          variant: 'success',
          message: 'Staff updated successfully'
        })
        closeModal();
      }

      if (updateSalonStaffDetails?.errors?.length) {
        addToast({
          variant: 'error',
          message: updateSalonStaffDetails?.errors[0]?.message
        })
      }
    })
  };

  const handleClick = (data: IEmploymentDetails) => {
    const newData = handleNextStep(data);
    if (updateStaff && newData) {
      update(newData);
      return;
    }
    setTab(STAFF_DETAILS_TAB_NAME.WORK_SCHEDULE);
  };

  const {
    data: salonsData,
    loading: salonsLoading,
  } = useSalons()
  const salons = useMemo(() => salonsData?.salons, [salonsData])

  const {
    data: servicesData,
  } = useGetServices(salonId)
  const services = useMemo(() => servicesData?.services || [], [servicesData])
  const servicesOptions = useMemo(() => Array?.isArray(services) ? formatServicesToSelectField(services) : [], [services])

  const resetStaffForm = () => {
    setValue('startDate', null);
    setValue('endDate', null);
    setValue('services', []);
    setValue('canLogin', false);
    setValue('canServeCustomers', false);
    setValue('level', null);
    setValue('present', null);
    setValue('specialty', '');
    salonId && setValue('salonId', salonId);
  };

  useEffect(() => {
    return () => {
      trigger().then((isValid) => {
        // if (isValid) {
        // handleSubmit(handleNextStep)();
        // }
      });
    };
  }, []);

  // useEffect(() => {
  //     const handler = setTimeout(() => {
  //       const allValues = watch()
  //       setStaffFormValues({...staffFormValues, employmentDetails: allValues})
  //   }, DEBOUNCE_DELAY);

  //   return () => {
  //       clearTimeout(handler);
  //   };
  // }, [staffFormValues?.employmentDetails])

  useEffect(() => {
    if (submitPage === STAFF_DETAILS_TAB_NAME.EMPLOYMENT_DETAILS) {
      trigger();
    }
  }, [submitPage]);

  useEffect(() => {
    if (staffFormValues?.employmentDetails) {
      const {
        startDate,
        endDate,
        services,
        canLogin,
        canServeCustomers,
        level,
        present,
        salonId,
        specialty,
      } = staffFormValues?.employmentDetails;
      setValue('startDate', startDate);
      setValue('endDate', endDate);
      setValue('services', services);
      setValue('canLogin', canLogin);
      setValue('canServeCustomers', canServeCustomers || false);
      setValue('level', level);
      setValue('present', present);
      setValue('salonId', salonId);
      setValue('specialty', specialty ? specialty?.toLowerCase() : '');
    } else {
      resetStaffForm();
    }
  }, [staffFormValues?.employmentDetails, services]);

  useEffect(() => {
    if (!staffFormValues?.id) {
      const level = watch('level');
      if (watch('canLogin') && level === null) {
        openPermissionModal();
      } else {
        setValue('canLogin', false);
        setValue('level', null);
      }
    }
  }, [watch('canLogin')]);

  const getRoleNameByLevel = (level: string) => {
    if (!level) return '';

    const role = PERMISSION_LEVELS?.find((role) => role?.value === level);
    return role?.label;
  };

  return (
    <>
      <div className="flex flex-col" style={{ height: '100%' }}>
        <div
          className="flex items-center bg-grey-50 h-[50px]"
          style={{ marginTop: '20px' }}
        >
          <Paragraph className="p-4" weight="semiBold" color={COLORS.GREY[400]}>
            EMPLOYMENT DETAILS
          </Paragraph>
        </div>

        <form onSubmit={handleSubmit(handleClick)}>
          <div className="flex gap-2 mt-4">
            <div className="w-1/2">
              <Input
                name="startDate"
                type="date"
                id="startDate"
                label="Start Date"
                control={control}
                rules={{
                  required: 'Start Date is required',
                }}
                error={errors.startDate}
              />
            </div>
            <div className="w-1/2">
              <Input
                name="endDate"
                type="date"
                id="endDate"
                label="End Date"
                control={control}
                disabled={staffPresent}
              />
              <Controller
                control={control}
                name="present"
                render={({ field: { onChange, value } }) => {
                  return (
                    <div className="w-full flex space-y-4 my-2">
                      <div
                        className="flex"
                        onClick={() => {
                          onChange(!value);
                          setValue('endDate', null);
                          setStaffPresent(!staffPresent);
                        }}
                      >
                        <div className="flex justify-center items-center cursor-pointer text-b5 text-grey-900 space-x-2">
                          <Checkbox isChecked={value} />
                          <span className="text-grey-900 whitespace-nowrap">
                            Present - Staff is currently occupying this role
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                }}
              />
            </div>
          </div>
          <div className="flex gap-2 mt-4">
            <div className="flex w-1/2">
              <SelectInput
                name="specialty"
                id="specialty"
                label="Select specialty"
                rules={{
                  required: 'Specialty required',
                }}
                control={control}
                value={watch('specialty')}
                options={[...sortOptions(STAFF_SPECIALTIES)]}
                placeholder="Select Specialty"
                error={errors.specialty}
              />
            </div>

            <div className="flex w-1/2">
              <Controller
                control={control}
                name="services"
                render={({
                  field: { onChange, value },
                  formState: { errors },
                }) => {
                  const errorMessage = errors?.services?.message;
                  return (
                    <div className="w-full flex flex-col space-y-2">
                      <FormLabel htmlFor="service">Assign Service(s)</FormLabel>
                      <MultiSelect
                        selected={value as [] || []}
                        options={servicesOptions}
                        setSelected={onChange}
                      />
                      {errorMessage && (
                        <FormHelperText variant="error">
                          {errorMessage}
                        </FormHelperText>
                      )}
                    </div>
                  );
                }}
              />
            </div>
          </div>

          <div className="flex gap-2 mt-4">
            <SelectInput
              name="salonId"
              id="salonId"
              label="Select location"
              rules={{
                required: 'Location is required',
              }}
              error={errors.salonId}
              value={watch('salonId')}
              control={control}
              options={salons.map((salon) => {
                return { label: salon.branchName, value: salon.id };
              })}
              placeholder="Select Location"
            />
            <Controller
              control={control}
              name="canServeCustomers"
              render={({ field: { onChange, value } }) => {
                const errorMessage = errors.canServeCustomers?.message;
                return (
                  <div className="w-full flex flex-col space-y-2">
                    <label className="text-black dark:text-grey-500 font-normal w-full subpixel-antialiased flex space-x-4 items-center">
                      Is this a customer facing staff?
                      <div className="group flex relative text-grey-300">
                        <SvgInfo width="15px" height="15px" />
                        <span className="w-[200px] group-hover:opacity-100 transition-opacity bg-grey-50 p-2 text-b6 text-grey-300 rounded-md absolute left-2 -translate-x-2 opacity-0 m-4 mx-auto z-30">
                          Do they provide services to customers?
                        </span>
                      </div>
                    </label>
                    <div className="w-full flex flex-col gap-4 xl:gap-0 space-y-2">
                      {Array.isArray(YES_OR_NO_SETTINGS) &&
                        YES_OR_NO_SETTINGS.length
                        ? YES_OR_NO_SETTINGS.map(
                          (canServeCustomers: {
                            label: string;
                            value: boolean;
                          }) => {
                            return (
                              <div
                                className="flex"
                                onClick={() =>
                                  onChange(canServeCustomers?.value)
                                }
                                key={canServeCustomers?.label}
                              >
                                <div className="flex items-center cursor-pointer text-b5 text-grey-900 space-x-2">
                                  <Checkbox
                                    isChecked={
                                      value === canServeCustomers?.value
                                    }
                                  />
                                  <span className="text-grey-900 whitespace-nowrap">
                                    {canServeCustomers?.label}
                                  </span>
                                </div>
                              </div>
                            );
                          }
                        )
                        : null}
                    </div>
                    {errorMessage && (
                      <FormHelperText variant="error">
                        {errorMessage}
                      </FormHelperText>
                    )}
                  </div>
                );
              }}
            />
          </div>
          <div className="flex gap-2 mt-4">
            <Controller
              control={control}
              name="canLogin"
              render={({ field: { onChange, value } }) => {
                return (
                  <div className="w-full flex space-y-4 my-2">
                    <div className="flex" onClick={() => onChange(!value)}>
                      <div className="flex justify-center items-center cursor-pointer text-b5 text-grey-900 space-x-2">
                        <Checkbox isChecked={value} />
                        <span className="text-grey-900 whitespace-nowrap">
                          Do you want to invite this staff to Splice
                        </span>
                      </div>
                    </div>
                  </div>
                );
              }}
            />

            {watch('canLogin') ? (
              <div
                className="w-full flex flex-col space-y-2 cursor-pointer"
                onClick={openPermissionModal}
              >
                <span className="text-grey-900 whitespace-nowrap">
                  Permission level
                </span>
                <div className="w-full flex justify-between border-grey-300 border rounded-md bg-grey-50 p-3 items-center">
                  <Paragraph size="b4" className="">
                    {roleName || getRoleNameByLevel(currentSalonStaff?.employmentDetails?.level)}
                  </Paragraph>

                  <div className="flex space-x-2 items-center">
                    <Paragraph size="b6" className="" color={COLORS.GREY[300]}>
                      Manage
                    </Paragraph>
                    <SvgArrowRight width="24px" height="24px" />
                  </div>
                </div>
              </div>
            ) : null}
          </div>

          <div className="relative h-[100px]">
            <div className="w-full max-w-[800px] absolute bottom-0 bg-white flex space-x-3 border-t border-grey-100 cursor-pointer py-2 px-8 justify-end">
              <Button
                type="button"
                size='sm'
                variant="text"
                className="text-red-600"
                onClick={() =>
                  setTab(STAFF_DETAILS_TAB_NAME.PERSONAL_INFORMATION)
                }
              >
                Prev
              </Button>
              {currentSalonStaff ? (
                <Button
                  type="submit"
                  onClick={() => setUpdateStaff(true)}
                  loading={loading}
                  disabled={loading}
                  variant="secondary"
                  className="rounded-lg"
                >
                  Save
                </Button>
              ) : null}
              <Button
                type="submit"
                disabled={loading}
                variant="primary"
                className="rounded-lg"
              >
                Proceed
              </Button>
            </div>
          </div>
        </form>
      </div>
      {permissionModalIsvisible ? (
        <div className="fixed top-0 left-0 flex items-center justify-center w-full h-screen bg-black/70 backdrop-blur-[2px] z-50">
          <div className="w-full bg-white rounded-lg shadow-medium py-12 px-9 max-w-[500px]">
            <div className="w-full flex flex-col space-y-6">
              <div className="w-full flex justify-between items-center">
                <Heading variant="h2" size="h9">
                  Select permission levels
                </Heading>
                <Button
                  variant="text"
                  size="none"
                  type="button"
                  className="w-fit"
                  fontSize="b4"
                  onClick={() => {
                    closePermissionModal();
                  }}
                >
                  <SvgClose width="24px" height="24px" />
                </Button>
              </div>

              {Array?.isArray(PERMISSION_LEVELS) &&
                PERMISSION_LEVELS?.map((level) => {
                  return (
                    <div
                      className="w-full flex items-start justify-start space-x-3 cursor-pointer"
                      onClick={() => {
                        setValue('level', level.value);
                        setRoleName(getRoleNameByLevel(level.value));
                      }}
                    >
                      <Checkbox isChecked={watch('level') === level.value} />
                      <div className="w-full flex flex-col space-y-2">
                        <Heading
                          variant="h6"
                          size="h11"
                          color={COLORS.GREY[900]}
                          weight="semiBold"
                        >
                          {level.label}
                        </Heading>
                        <Paragraph size="b4" color={COLORS.GREY[300]}>
                          {level.description}
                        </Paragraph>
                      </div>
                    </div>
                  );
                })}

              <div className="w-full flex justify-end">
                <Button
                  variant="text"
                  size="sm"
                  type="button"
                  rounded="lg"
                  onClick={() => {
                    setValue('canLogin', false);
                    setValue('level', null);
                    closePermissionModal();
                  }}
                  className="w-fit text-red-500"
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  size="sm"
                  type="button"
                  rounded="lg"
                  onClick={() => {
                    closePermissionModal();
                  }}
                  className=""
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

const LeaveAndAttendance = ({
  setStaffFormValues,
  staffFormValues,
  setTab,
  closeModal,
  submitPage,
  salonHours,
  currentSalonStaff,
  addToast
}: StaffTabProps) => {
  const {
    handleSubmit,
    control,
    setValue,
    watch,
    trigger,
    reset,
    formState: { errors },
  } = useForm<ILeave>({
    // Define the default values structure
  });
  const [updateStaff, setUpdateStaff] = useState(false);
  const {
    loading,
    updateSalonStaff
  } = useUpdateStaff()
  const handleNextStep = (data: ILeave) => {
    const newData = {
      ...staffFormValues,
      leave: { ...data, daysEntitled: data?.daysEntitled || '0' },
    };
    setStaffFormValues(newData);
    return newData;
  };

  const update = async (data) => {
    const inputData = formatStaffUpdateData(data, salonHours);
    updateSalonStaff({
      variables: {
        input: inputData
      }
    }).then(({ data }) => {
      const { updateSalonStaffDetails } = data;

      if (updateSalonStaffDetails?.status === 200) {
        addToast({
          variant: 'success',
          message: 'Staff updated successfully'
        })
        closeModal();
      }

      if (updateSalonStaffDetails?.errors?.length) {
        addToast({
          variant: 'error',
          message: updateSalonStaffDetails?.errors[0]?.message
        })
      }
    })
  };

  const handleClick = (data: ILeave) => {
    const newData = handleNextStep(data);
    if (updateStaff && newData) {
      update(newData);
      return;
    }
    setTab(STAFF_DETAILS_TAB_NAME.SALARY_INFORMATION);
  };

  const resetStaffForm = () => {
    // setStaffFormValues({ ...staffFormValues, leave: { leaveType: '', daysEntitled: null } })
    reset();
  };

  useEffect(() => {
    return () => {
      trigger().then((isValid) => {
        // if (isValid) {
        // handleSubmit(handleNextStep)();
        // }
      });
    };
  }, []);

  // useEffect(() => {
  //     const handler = setTimeout(() => {
  //       const allValues = watch()
  //       setStaffFormValues({...staffFormValues, leave: allValues})
  //   }, DEBOUNCE_DELAY);

  //   return () => {
  //       clearTimeout(handler);
  //   };
  // }, [staffFormValues?.leave])

  useEffect(() => {
    if (submitPage === STAFF_DETAILS_TAB_NAME.LEAVE_AND_ATTENDANCE) {
      trigger();
    }
  }, [submitPage]);

  useEffect(() => {
    if (staffFormValues?.leave) {
      const leave = staffFormValues?.leave;

      setValue('daysEntitled', leave.daysEntitled);
    } else {
      reset();
    }
  }, [staffFormValues?.leave]);

  return (
    <div className="flex flex-col" style={{ height: '100%' }}>
      <div
        className="flex items-center bg-grey-50 h-[50px]"
        style={{ marginTop: '20px' }}
      >
        <Paragraph className="p-4" weight="semiBold" color={COLORS.GREY[400]}>
          LEAVE AND ATTENDANCE
        </Paragraph>
      </div>

      <form onSubmit={handleSubmit(handleClick)}>
        <div className="w-full flex flex-col space-y-3">
          <div className="w-full flex items-center gap-2">
            {/* <SelectInput
                  name={`leave.${index}.leaveType`}
                  id='leaveType'
                  label='Select Type'
                  value={field.leaveType}
                  control={control}
                  rules={{
                    required: 'Leave Type required'
                  }}
                  //error={errors.staffInput?.specialty}
                  options={LEAVE_TYPE}
                  placeholder='Select TYPE'
                /> */}
            <Input
              control={control}
              name="daysEntitled"
              type="text"
              id="daysEntitled"
              error={errors.daysEntitled}
              label="Days Entitled"
              placeholder="20"
            />
          </div>
        </div>
        {/* <Button
          variant='light'
          size='xs'
          rounded='lg'
          type="button"
          className='w-fit border border-grey-100'
          onClick={() => append({ leaveType: '', daysEntitled: null })}
        >
          Add new type
          <SvgPlus width="14px" height="14px" />
        </Button> */}

        <div className="relative h-[100px]">
          <div className="w-full max-w-[800px] absolute bottom-0 bg-white flex space-x-3 border-t border-grey-100 cursor-pointer py-2 px-8 justify-end">
            <Button
              type="button"
              size='sm'
              variant="text"
              className="text-red-600"
              onClick={() => setTab(STAFF_DETAILS_TAB_NAME.WORK_SCHEDULE)}
            >
              Prev
            </Button>
            {currentSalonStaff ? (
              <Button
                type="submit"
                onClick={() => setUpdateStaff(true)}
                loading={false}
                disabled={false}
                variant="secondary"
                className="rounded-lg"
              >
                Save
              </Button>
            ) : null}
            <Button
              type="submit"
              loading={false}
              disabled={false}
              variant="primary"
              className="rounded-lg"
            >
              Proceed
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

const SalaryInformation = ({
  setStaffFormValues,
  staffFormValues,
  setTab,
  closeModal,
  submitPage,
  salonHours,
  currentSalonStaff,
  addToast
}: StaffTabProps) => {
  const {
    handleSubmit,
    control,
    setValue,
    watch,
    trigger,
    formState: { errors },
  } = useForm<ISalaryInformation>();
  const [updateStaff, setUpdateStaff] = useState(false);
  const {
    loading,
    updateSalonStaff
  } = useUpdateStaff()
  const PERIOD_TYPE = [
    { value: 'monthly', label: 'Monthly' },
    { value: 'weekly', label: 'Weekly' },
    { value: 'biweekly', label: 'Bi-Weekly' },
  ];

  const CURRENCY_TYPE = [
    { value: 'amount', label: DEFAULT_CURRENCY_CODE + " " + '(' + DEFAULT_CURRENCY + ')' },
    { value: 'percent', label: 'PER (%)' },
  ];

  const handleClick = (data: ISalaryInformation) => {
    const newData = handleNextStep(data);
    if (updateStaff && newData) {
      update(newData);
      return;
    }
    setTab(STAFF_DETAILS_TAB_NAME.NOTIFICATIONS_AND_REMINDERS);
  };

  const handleNextStep = (data: ISalaryInformation) => {
    const newData = {
      ...staffFormValues,
      salaryInformation: {
        ...data,
        baseSalary: data?.baseSalary
          ? data?.baseSalary?.toString()?.replace(/,/g, '')?.toString()
          : '0',
        bonus: data?.bonus
          ? data?.bonus?.toString()?.replace(/,/g, '')?.toString()
          : '0',
        deduction: data?.deduction
          ? data?.deduction?.toString()?.replace(/,/g, '')?.toString()
          : '0',
      },
    };
    setStaffFormValues(newData);
    return newData;
  };

  const resetStaffForm = () => {
    setValue('period', '');
    setValue('baseSalary', null);
    setValue('bonus', null);
    setValue('bonusType', '');
    setValue('deduction', null);
    setValue('deductionType', '');

    // setValue('staffInput.role', RoleEnum.Staff)
    // setValue('staffInput.specialty', '')
    // setValue('staffInput.canLogin', false)
    // setValue('staffInput.canServeCustomers', false)
    // setValue('staffInput.level', 0)
    // setFormValues(generateFormValues([]))
  };

  const update = async (data) => {
    const inputData = formatStaffUpdateData(data, salonHours);
    updateSalonStaff({
      variables: {
        input: inputData
      }
    }).then(({ data }) => {
      const { updateSalonStaffDetails } = data;

      if (updateSalonStaffDetails?.status === 200) {
        addToast({
          variant: 'success',
          message: 'Staff updated successfully'
        })
        closeModal();
      }

      if (updateSalonStaffDetails?.errors?.length) {
        addToast({
          variant: 'error',
          message: updateSalonStaffDetails?.errors[0]?.message
        })
      }
    })
  };

  useEffect(() => {
    return () => {
      // handleSubmit(handleNextStep)();
    };
  }, []);

  // useEffect(() => {
  //     const handler = setTimeout(() => {
  //       const allValues = watch()
  //       setStaffFormValues({...staffFormValues, salaryInformation: allValues})
  //   }, DEBOUNCE_DELAY);

  //   return () => {
  //       clearTimeout(handler);
  //   };
  // }, [staffFormValues?.salaryInformation])

  useEffect(() => {
    if (submitPage === STAFF_DETAILS_TAB_NAME.SALARY_INFORMATION) {
      trigger();
    }
  }, [submitPage]);

  useEffect(() => {
    if (staffFormValues?.salaryInformation) {
      const { period, baseSalary, bonus, bonusType, deduction, deductionType } =
        staffFormValues?.salaryInformation;
      setValue('period', period);
      setValue('baseSalary', baseSalary);
      setValue('bonus', bonus);
      setValue('bonusType', bonusType);
      setValue('deduction', deduction);
      setValue('deductionType', deductionType);
    } else {
      resetStaffForm();
    }
  }, [staffFormValues?.salaryInformation]);
  return (
    <div className="flex flex-col" style={{ height: '100%' }}>
      <div
        className="flex items-center bg-grey-50 h-[50px]"
        style={{ marginTop: '20px' }}
      >
        <Paragraph className="p-4" weight="semiBold" color={COLORS.GREY[400]}>
          SALARY INFORMATION
        </Paragraph>
      </div>

      <form onSubmit={handleSubmit(handleClick)}>
        <div className="flex gap-2 mt-4">
          <div className=" w-1/2">
            <SelectInput
              name="period"
              id="period"
              label="Period"
              control={control}
              error={errors.period}
              options={PERIOD_TYPE}
              placeholder="Select Period"
            />
          </div>

          <div className="flex w-1/2">
            <div className="w-3/4">
              <Controller
                control={control}
                name="baseSalary"
                render={({ field, formState: { errors } }) => (
                  <div className="w-full flex flex-col space-y-2">
                    <FormLabel htmlFor="price">Base salary</FormLabel>
                    <div className="flex w-full">
                      <input
                        className="w-full flex items-center appearance-none border-t border-b border-l border-grey-20 px-4 py-3 font-normal text-b6 xl:text-b4 text-grey-900 rounded-tl-lg rounded-bl-lg focus:outline-none focus:border-grey-20 focus:shadow-grey-100 placeholder:text-grey-400"
                        type="text"
                        {...field}
                        value={formatNumber(field.value)}
                        onChange={(e) =>
                          field.onChange(formatNumber(e.target.value))
                        }
                        placeholder="10000"
                      />
                    </div>
                    {errors?.baseSalary?.message && (
                      <FormHelperText variant="error">
                        {errors.baseSalary.message}
                      </FormHelperText>
                    )}
                  </div>
                )}
                rules={{
                  required: watch('period') ? 'Enter salary' : false,
                }}
              />
            </div>

            <div className="w-1/4 mt-7">
              <SelectInput
                name="salaryType"
                id="salaryType"
                label=""
                control={control}
                // error={errors.salaryType}
                className="rounded-tl-none rounded-bl-none text-grey-300 border-l-0"
                options={CURRENCY_TYPE}
                defaultValue={CURRENCY_TYPE[0].value}
                placeholder={''}
                disabled={true}
              />
            </div>
          </div>
        </div>

        <div className="flex gap-2 mt-4">
          <div className="flex w-1/2">
            <div className="w-3/4">
              <Controller
                control={control}
                name="bonus"
                render={({ field, formState: { errors } }) => (
                  <div className="w-full flex flex-col space-y-2">
                    <FormLabel htmlFor="price">Bonuses</FormLabel>
                    <div className="flex w-full">
                      <input
                        className="w-full flex items-center appearance-none border-t border-b border-l border-grey-20 px-4 py-3 font-normal text-b6 xl:text-b4 text-grey-900 rounded-tl-lg rounded-bl-lg focus:outline-none focus:border-grey-20 focus:shadow-grey-100 placeholder:text-grey-400"
                        type="text"
                        {...field}
                        value={formatNumber(field.value)}
                        onChange={(e) =>
                          field.onChange(formatNumber(e.target.value))
                        }
                        placeholder="10000"
                      />
                    </div>
                    {errors?.bonus?.message && (
                      <FormHelperText variant="error">
                        {errors.bonus.message}
                      </FormHelperText>
                    )}
                  </div>
                )}
              />
            </div>

            <div className="w-1/4 mt-7">
              <SelectInput
                name="bonusType"
                id="bonusType"
                label=""
                control={control}
                rules={{
                  required:
                    watch('bonus') && watch('bonus') === 0
                      ? 'bonus type required'
                      : false,
                }}
                error={errors.bonusType}
                className="rounded-tl-none rounded-bl-none text-grey-300 border-l-0"
                options={CURRENCY_TYPE}
                placeholder={''}
              />
            </div>
          </div>

          <div className="flex w-1/2">
            <div className="w-3/4">
              <Controller
                control={control}
                name="deduction"
                render={({ field }) => (
                  <div className="w-full flex flex-col space-y-2">
                    <FormLabel htmlFor="price">Tax Deductions</FormLabel>
                    <div className="flex w-full">
                      <input
                        className="w-full flex items-center appearance-none border-t border-b border-l border-grey-20 px-4 py-3 font-normal text-b6 xl:text-b4 text-grey-900 rounded-tl-lg rounded-bl-lg focus:outline-none focus:border-grey-20 focus:shadow-grey-100 placeholder:text-grey-400"
                        type="text"
                        {...field}
                        value={formatNumber(field.value)}
                        onChange={(e) =>
                          field.onChange(formatNumber(e.target.value))
                        }
                        placeholder="10"
                      />
                    </div>
                  </div>
                )}
              />
            </div>

            <div className="w-1/4 mt-7">
              <SelectInput
                name="deductionType"
                id="deductionType"
                label=""
                control={control}
                className="rounded-tl-none rounded-bl-none text-grey-300 border-l-0"
                options={CURRENCY_TYPE}
                defaultValue={CURRENCY_TYPE[0].value}
                placeholder={''}
              />
            </div>
          </div>
        </div>
        <div className="relative h-[100px]">
          <div className="w-full max-w-[800px] absolute bottom-0 bg-white flex space-x-3 border-t border-grey-100 cursor-pointer py-2 px-8 justify-end">
            <Button
              type="button"
              size='sm'
              variant="text"
              className="text-red-600"
              onClick={() =>
                setTab(STAFF_DETAILS_TAB_NAME.LEAVE_AND_ATTENDANCE)
              }
            >
              Prev
            </Button>
            {currentSalonStaff ? (
              <Button
                type="submit"
                onClick={() => setUpdateStaff(true)}
                loading={false}
                disabled={false}
                variant="secondary"
                className="rounded-lg"
              >
                Save
              </Button>
            ) : null}
            <Button
              type="submit"
              loading={false}
              disabled={false}
              variant="primary"
              className="rounded-lg"
            >
              Proceed
            </Button>
          </div>
        </div>
      </form>
      {/* <div className='flex mt-4'>
        <Button variant='light' size='xs' className='rounded-full'>
          <Paragraph color={COLORS.GREY[300]}>Add another type +</Paragraph>
        </Button>
      </div> */}
    </div>
  );
};

const Reminders = ({
  setStaffFormValues,
  staffFormValues,
  setTab,
  closeModal,
  submitPage,
  activeTab,
  validateForm,
  salonHours,
  currentSalonStaff,
  addToast
}: StaffTabProps) => {
  const {
    handleSubmit,
    control,
    setValue,
    watch,
    trigger,
    formState: { errors },
  } = useForm<IReminder & {
    receivePushNotifications: boolean;
    receiveBusinessReports: boolean;
  }>();

  const {
    loading,
    updateSalonStaff
  } = useUpdateStaff()
  const {
    loading: creatingStaff,
    createSalonStaff
  } = useCreateSalonStaff()
  const [send, setSend] = useState(false);

  const handleNextStep = (data: IReminder & {
    receivePushNotifications: boolean;
    receiveBusinessReports: boolean;
  }) => {
    console.log("data: ", data);
    const newData = {
      ...staffFormValues, reminder: {
        birthday: data?.birthday,
        workAnniversary: data?.workAnniversary,
      },
      receivePushNotifications: data?.receivePushNotifications,
      receiveBusinessReports: data?.receiveBusinessReports,
    };
    setStaffFormValues(newData);
    // console.log("data: ", data);
    // console.log(staffFormValues);
    // //setTab(STAFF_DETAILS_TAB_NAME.NOTIFICATIONS_AND_REMINDERS);
    // //also push to the api
    if (validateForm(staffFormValues, setTab)) {
      setSend(true);
    }
  };

  const handleTabSwitch = (data: IReminder) => {
    const newData = { ...staffFormValues, reminder: data };
    setStaffFormValues(newData);
  };

  const create = async () => {
    const data = {
      ...staffFormValues,
      employmentDetails: {
        ...staffFormValues?.employmentDetails,
        level: staffFormValues?.employmentDetails?.level?.toString(),
        role: getStaffRoleName(
          staffFormValues?.employmentDetails?.level?.toString()
        ),
      },
      salonId: staffFormValues?.employmentDetails?.salonId
    };
    createSalonStaff({
      variables: {
        input: data
      }
    }).then(({ data }) => {
      const { createSalonStaff } = data;

      if (createSalonStaff?.status === 201 || createSalonStaff?.status === 200) {
        addToast({
          variant: 'success',
          message: 'Staff created successfully'
        })
        closeModal();
      }

      if (createSalonStaff?.errors?.length) {
        addToast({
          variant: 'error',
          message: createSalonStaff?.errors[0]?.message
        })
      }
    })
  };

  const update = async () => {
    const inputData = formatStaffUpdateData(staffFormValues, salonHours as SalonHour[]);
    updateSalonStaff({
      variables: {
        input: inputData
      }
    }).then(({ data }) => {
      const { updateSalonStaffDetails } = data;

      if (updateSalonStaffDetails?.status === 200) {
        addToast({
          variant: 'success',
          message: 'Staff updated successfully'
        })
        closeModal();
      }

      if (updateSalonStaffDetails?.errors?.length) {
        addToast({
          variant: 'error',
          message: updateSalonStaffDetails?.errors[0]?.message
        })
      }
    })
  };

  const resetStaffForm = () => {
    setValue('birthday', null);
    setValue('workAnniversary', null);
  };

  useEffect(() => {
    return () => {
      trigger().then((isValid) => {
        // if(isValid){
        handleSubmit(handleTabSwitch)();
        // }
      });
    };
  }, []);

  // useEffect(() => {
  //     const handler = setTimeout(() => {
  //       const allValues = watch()
  //       setStaffFormValues({...staffFormValues, reminder: allValues})
  //   }, DEBOUNCE_DELAY);

  //   return () => {
  //       clearTimeout(handler);
  //   };
  // }, [staffFormValues?.reminder])

  useEffect(() => {
    if (send) {
      if (staffFormValues?.id) {
        update();
      } else {
        create();
      }
    }
  }, [send]);

  useEffect(() => {
    if (staffFormValues?.reminder) {
      const { birthday, workAnniversary } = staffFormValues?.reminder;
      setValue('birthday', birthday);
      setValue('workAnniversary', workAnniversary);
      setValue('receiveBusinessReports', staffFormValues?.receiveBusinessReports)
      setValue('receivePushNotifications', staffFormValues?.receivePushNotifications)
    } else {
      resetStaffForm;
    }
  }, [staffFormValues?.reminder]);

  return (
    <div className="flex flex-col" style={{ height: '100%' }}>
      <div
        className="flex items-center bg-grey-50 h-[50px]"
        style={{ marginTop: '20px' }}
      >
        <Paragraph className="p-4" weight="semiBold" color={COLORS.GREY[400]}>
          NOTIFICATIONS AND REMINDERS
        </Paragraph>
      </div>

      <form onSubmit={handleSubmit(handleNextStep)}>
        <div className="grid grid-cols-1 gap-2 mt-4">
          <Controller
            control={control}
            name='workAnniversary'
            render={({ field: { onChange, value } }) => {
              return (
                <div
                  className='w-full flex flex-wrap gap-4 xl:gap-0 xl:space-x-4'
                  onClick={() => onChange(!value)}
                >
                  <FormLabel htmlFor='optOutEmail'>
                    Set reminders on work anniversary ?
                  </FormLabel>
                  <div className='flex items-center cursor-pointer text-b5 text-grey-900 space-x-2'>
                    <ToggleOnly isChecked={value} />
                  </div>
                </div>
              )
            }}
          />
          <Controller
            control={control}
            name='birthday'
            render={({ field: { onChange, value } }) => {
              return (
                <div
                  className='w-full flex flex-wrap gap-4 xl:gap-0 xl:space-x-4'
                  onClick={() => onChange(!value)}
                >
                  <FormLabel htmlFor='optOutEmail'>
                    Set reminders on birthday ?
                  </FormLabel>
                  <div className='flex items-center cursor-pointer text-b5 text-grey-900 space-x-2'>
                    <ToggleOnly isChecked={value} />
                  </div>
                </div>
              )
            }}
          />
          <Controller
            control={control}
            name='receiveBusinessReports'
            render={({ field: { onChange, value } }) => {
              return (
                <div
                  className='w-full flex flex-wrap gap-4 xl:gap-0 xl:space-x-4'
                  onClick={() => onChange(!value)}
                >
                  <FormLabel htmlFor='receiveBusinessReports'>
                    Allow staff to receive business reports emails
                  </FormLabel>
                  <div className='flex items-center cursor-pointer text-b5 text-grey-900 space-x-2'>
                    <ToggleOnly isChecked={value} />
                  </div>
                </div>
              )
            }}
          />
          <Controller
            control={control}
            name='receivePushNotifications'
            render={({ field: { onChange, value } }) => {
              return (
                <div
                  className='w-full flex flex-wrap gap-4 xl:gap-0 xl:space-x-4'
                  onClick={() => onChange(!value)}
                >
                  <FormLabel htmlFor='receivePushNotifications'>
                    Allow staff to receive appointment booked and reminder notifications
                  </FormLabel>
                  <div className='flex items-center cursor-pointer text-b5 text-grey-900 space-x-2'>
                    <ToggleOnly isChecked={value} />
                  </div>
                </div>
              )
            }}
          />
        </div>
        <div className="relative h-[100px]">
          <div className="w-full max-w-[800px] absolute bottom-0 bg-white flex border-t border-grey-100 cursor-pointer py-2 px-8 justify-end">
            <Button
              type="button"
              size='sm'
              variant="text"
              className="text-red-600"
              onClick={() => setTab(STAFF_DETAILS_TAB_NAME.SALARY_INFORMATION)}
            >
              Prev
            </Button>
            <Button
              type="submit"
              loading={loading || creatingStaff}
              disabled={loading || creatingStaff}
              variant="primary"
              className="rounded-lg"
            >
              {currentSalonStaff ? 'SAVE' : 'FINISH'}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export type WorkingScheduleHoursForm = {
  id?: string;
  day: string;
  openTime: string;
  closeTime: string;
};

type IWorkingSchedule = {
  workSchedule: WorkingScheduleHoursForm[];
  workScheduleType: string;
};

const WorkingSchedule = ({
  setStaffFormValues,
  staffFormValues,
  setTab,
  closeModal,
  submitPage,
  currentSalonStaff,
  addToast
}: StaffTabProps) => {
  const [updateStaff, setUpdateStaff] = useState(false);
  const { getSalonData } = useSalonCache();
  const salon = getSalonData()
  const {
    handleSubmit,
    control,
    setValue,
    watch,
    trigger,
    formState: { errors },
  } = useForm<IWorkingSchedule>();

  const [businessHours, setBusinessHours] = useState<
    WorkingScheduleHoursForm[]
  >([]);
  const [customHours, setCustomHours] = useState<WorkingScheduleHoursForm[]>(
    []
  );
  const {
    loading,
    updateSalonStaff
  } = useUpdateStaff()
  const [staffHourWorkScheduleType, setStaffHourWorkScheduleType] =
    useState<string>('business');
  const DAY_CHOICES = [
    {
      label: 'Everyday',
      value: 'everyday',
      isDisabled: false,
    },
    {
      label: 'Mon-Fri',
      value: 'mon-fri',
      isDisabled: false,
    },
    {
      label: 'Sat-Sun',
      value: 'sat-sun',
      isDisabled: false,
    },
    {
      label: 'Mon',
      value: 'monday',
      isDisabled: false,
    },
    {
      label: 'Tue',
      value: 'tuesday',
      isDisabled: false,
    },
    {
      label: 'Wed',
      value: 'wednesday',
      isDisabled: false,
    },
    {
      label: 'Thu',
      value: 'thursday',
      isDisabled: false,
    },
    {
      label: 'Fri',
      value: 'friday',
      isDisabled: false,
    },
    {
      label: 'Sat',
      value: 'saturday',
      isDisabled: false,
    },
    {
      label: 'Sun',
      value: 'sunday',
      isDisabled: false,
    },
  ];

  const {
    data: hoursData,
    loading: salonHoursLoading,
    refetch: refetchSalonHours,
  } = useSalonHours(salon?.id);
  const salonHours = useMemo(() => hoursData?.salonHours, [hoursData]);

  useEffect(() => {
    if (salon?.id) {
      refetchSalonHours()
    }
  }, [refetchSalonHours, salon?.id])

  const update = async (data) => {
    const inputData = formatStaffUpdateData(data, salonHours);
    updateSalonStaff({
      variables: {
        input: inputData
      }
    }).then(({ data }) => {
      const { updateSalonStaffDetails } = data;

      if (updateSalonStaffDetails?.status === 200) {
        addToast({
          variant: 'success',
          message: 'Staff updated successfully'
        })
        closeModal();
      }

      if (updateSalonStaffDetails?.errors?.length) {
        addToast({
          variant: 'error',
          message: updateSalonStaffDetails?.errors[0]?.message
        })
      }
    })
  };

  const handleClick = () => {
    const newData = handleNextStep();
    if (updateStaff && newData) {
      update(newData);
      return;
    }
    setTab(STAFF_DETAILS_TAB_NAME.LEAVE_AND_ATTENDANCE);
  };
  const handleNextStep = () => {
    const hours =
      staffHourWorkScheduleType === 'business'
        ? convertGroupHoursToSingleTime(businessHours)
        : convertGroupHoursToSingleTime(customHours);
    const newData = {
      ...staffFormValues,
      workingSchedule: {
        workScheduleType: staffHourWorkScheduleType,
        workSchedule: hours,
      },
    };
    setStaffFormValues(newData);
    return newData;
  };

  useMemo(() => {
    if (
      currentSalonStaff?.workingSchedule && currentSalonStaff?.workingSchedule?.workScheduleType &&
      currentSalonStaff?.workingSchedule === staffFormValues?.workingSchedule
    ) {
      setStaffHourWorkScheduleType(
        currentSalonStaff?.workingSchedule?.workScheduleType
      );
      if (currentSalonStaff?.workingSchedule?.workScheduleType == 'custom') {
        setCustomHours(
          groupBusinessSalonHoursForStaffCreation(
            currentSalonStaff?.workingSchedule?.workSchedule as any
          )
        );
      } else {
        setBusinessHours(currentSalonStaff?.workingSchedule?.workSchedule as any);
      }
    } else if (
      currentSalonStaff?.workingSchedule && staffFormValues?.workingSchedule?.workScheduleType && staffFormValues?.workingSchedule &&
      currentSalonStaff?.workingSchedule !== staffFormValues?.workingSchedule
    ) {
      setStaffHourWorkScheduleType(
        staffFormValues?.workingSchedule?.workScheduleType
      );
      if (staffFormValues?.workingSchedule?.workScheduleType == 'custom') {
        setCustomHours(
          groupBusinessSalonHoursForStaffCreation(
            staffFormValues?.workingSchedule?.workSchedule as any
          )
        );
      } else {
        setBusinessHours(currentSalonStaff?.workingSchedule?.workSchedule as any);
      }
    } else if (
      currentSalonStaff &&
      !currentSalonStaff?.workingSchedule?.workScheduleType
    ) {
      const staffWorkingHourType = checkStaffWorkingSchedule(
        salonHours,
        currentSalonStaff?.workingSchedule?.workSchedule
      );
      setStaffHourWorkScheduleType(staffWorkingHourType);
      if (staffWorkingHourType == 'custom') {
        setCustomHours(
          groupBusinessSalonHoursForStaffCreation(
            staffFormValues.workingSchedule.workSchedule as SalonHour[]
          )
        );
      } else {
        setBusinessHours(staffFormValues.workingSchedule.workSchedule);
      }

      return;
    } else if (currentSalonStaff) {
      setStaffHourWorkScheduleType(
        staffFormValues?.workingSchedule?.workScheduleType
      );
      if (staffFormValues?.workingSchedule?.workScheduleType == 'custom') {
        setCustomHours(
          groupBusinessSalonHoursForStaffCreation(
            staffFormValues.workingSchedule.workSchedule as SalonHour[]
          )
        );
      } else {
        setBusinessHours(staffFormValues.workingSchedule.workSchedule);
      }
      return;
    }
    if (
      !currentSalonStaff &&
      staffFormValues?.workingSchedule?.workScheduleType === 'custom'
    ) {
      setCustomHours(
        groupBusinessSalonHoursForStaffCreation(
          staffFormValues.workingSchedule.workSchedule as SalonHour[]
        )
      );
      setStaffHourWorkScheduleType(
        staffFormValues.workingSchedule.workScheduleType
      );
      return;
    }
  }, [salonHours]);

  // useEffect(() => {
  //   setStaffFormValues({...staffFormValues, workingSchedule: {workScheduleType: staffHourWorkScheduleType, workSchedule: staffHourWorkScheduleType === 'business' ? convertGroupHoursToSingleTime(businessHours) : convertGroupHoursToSingleTime(customHours) }})
  // }, [staffFormValues?.workingSchedule])

  useEffect(() => {
    return () => {
      trigger().then((isValid) => {
        // handleSubmit(handleNextStep)();
      });
    };
  }, []);

  useEffect(() => {
    if (submitPage === STAFF_DETAILS_TAB_NAME.WORK_SCHEDULE) {
      trigger();
    }
  }, [submitPage]);

  useEffect(() => {
    if (salonHours?.length > 0) {
      const businessHoursFormatted =
        groupBusinessSalonHoursForStaffCreation(salonHours);
      setBusinessHours(businessHoursFormatted);
    }
  }, [salonHours]);

  const checkIfDayChoosenIsValid = (index: number | null, day?: string) => {
    // Filter custom hours to exclude the current index if provided
    const filteredCustomHours = index !== null
      ? customHours.filter((_, i) => i !== index && customHours[i]?.day)
      : customHours.filter(hour => hour?.day);

    // Count occurrences of the selected day in filtered custom hours
    const dayCount = filteredCustomHours.reduce((count, hour) => {
      if (hour.day === day) {
        return count + 1;
      }
      return count;
    }, 0);

    // Return false if the selected day appears twice already
    if (dayCount >= 2) {
      return false;
    }

    // Additional day-specific conditions
    if (filteredCustomHours.some(hour => hour.day === 'everyday')) {
      return false;
    }

    if (filteredCustomHours.some(hour => hour.day === 'mon-fri') &&
      ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'mon-fri'].includes(day)) {
      return false;
    }

    if (filteredCustomHours.some(hour => hour.day === 'sat-sun') &&
      ['saturday', 'sunday', 'sat-sun'].includes(day)) {
      return false;
    }

    return true;
  };

  const chooseStaffWorkingHours = (scheduleType: string) => {
    setStaffHourWorkScheduleType(scheduleType);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleTimeChange =
    (index: number, fieldName: 'openTime' | 'closeTime') =>
      (value: any, dateString: string) => {
        if (dateString) {
          const timeString = convert12HourTo24Hour(dateString);
          // update custom hours openTime or closeTime based off the index
          const updatedCustomHours = [...customHours];
          updatedCustomHours[index][fieldName] = timeString;
          setCustomHours(updatedCustomHours);
          setStaffHourWorkScheduleType('custom');
        }
      };

  const addMoreCustomTime = () => {
    // check if all the field in custome hours are not empty
    const allFieldsFilled = customHours.every(
      (hour) => hour.day && hour.openTime && hour.closeTime
    );
    if (!allFieldsFilled) {
      addToast({
        variant: 'error',
        message: 'Please fill in all the fields in custom hours',
      });
      return;
    }
    setStaffHourWorkScheduleType('custom');
    setCustomHours([
      ...customHours,
      {
        day: null,
        openTime:
          Array?.isArray(businessHours) && businessHours?.length
            ? businessHours[0]?.openTime
            : '08:00',
        closeTime:
          Array?.isArray(businessHours) && businessHours?.length
            ? businessHours[0]?.closeTime
            : '20:00',
      },
    ]);
  };

  const removeCustomHour = (index: number) => {
    // remove hour by index from customHours
    const updatedCustomHours = [...customHours];
    updatedCustomHours.splice(index, 1);
    setCustomHours(updatedCustomHours);
  };

  const getDayChoiceFromValue = (value: string) => {
    const dayChoice = DAY_CHOICES.find((choice) => choice.value === value);
    return dayChoice ? [dayChoice] : [];
  };

  const onChangeDayChoices =
    (index: number) => (day: { label: string; value: string }) => {
      // check if this index has a day set before
      let isValid = true;
      const hasDaySet = customHours[index]?.day;
      if (hasDaySet) {
        isValid = checkIfDayChoosenIsValid(index, day?.value);
      } else {
        isValid = checkIfDayChoosenIsValid(null, day?.value);
      }

      if (!isValid) {
        addToast({
          variant: 'error',
          message: 'Please choose a valid day',
        });
        return;
      }
      // update custom hours day based off the index
      const updatedCustomHours = [...customHours];
      updatedCustomHours[index].day = day?.value;
      setCustomHours(updatedCustomHours);
    };

  return (
    <div className="w-full flex flex-col space-y-4">
      {/* <ToastWrapper toast={toast} /> */}
      <div className="w-full bg-grey-50 p-4 mt-[20px]">
        <Paragraph size="b5" weight="semiBold" color={COLORS.GREY[400]}>
          WORK SCHEDULE
        </Paragraph>
      </div>
      <form onSubmit={handleSubmit(handleClick)}>
        {/* busineess option */}
        <CollapseRow title="Business Hours">
          <div className="w-full flex space-x-4">
            <div
              className="cursor-pointer"
              onClick={() => chooseStaffWorkingHours('business')}
            >
              <Checkbox
                isChecked={
                  staffHourWorkScheduleType === 'business' ? true : false
                }
              />
            </div>
            <div className="flex flex-col space-y-4">
              <div className="flex flex-col">
                <Paragraph size="b4" weight="semiBold" color={COLORS.GREY[900]}>
                  Based on business working hours
                </Paragraph>
                <Paragraph size="b4" color={COLORS.GREY[300]}>
                  Staff is available during normal business working hours
                </Paragraph>
              </div>
              <div className="w-full flex flex-col space-y-3">
                {/* shimmer */}
                {salonHoursLoading ? (
                  <div className="w-full flex items-center gap-2">
                    <div className="animate-shimmer w-full h-12 bg-gray-200 rounded-md"></div>
                    <span className="text-grey-900 text-b5">from</span>
                    <div className="animate-shimmer w-full h-12 bg-gray-200 rounded-md"></div>
                    <span className="text-grey-900 text-b5">to</span>
                    <div className="animate-shimmer w-full h-12 bg-gray-200 rounded-md"></div>
                  </div>
                ) : null}
                {Array?.isArray(businessHours) && businessHours?.length
                  ? businessHours?.map((businessHour, index) => {
                    return (
                      businessHour && (
                        <div
                          className="w-full flex items-center gap-2"
                          key={index}
                        >
                          <div className="w-full flex items-center text-b4 text-grey-400 max-w-[150px] border border-grey-20 p-2 h-[48px] rounded-md capitalize">
                            {businessHour?.day}
                          </div>
                          <span className="text-grey-900 text-b5">from</span>
                          <div className="w-full flex items-center text-b4 text-grey-900 max-w-[110px] border border-grey-20 p-2 h-[48px] rounded-md">
                            {convert24HourTo12Hour(businessHour?.openTime)}
                          </div>
                          <span className="text-grey-900 text-b5">to</span>
                          <div className="w-full flex items-center text-b4 text-grey-900 max-w-[110px] border border-grey-20 p-2 h-[48px] rounded-md">
                            {convert24HourTo12Hour(businessHour?.closeTime)}
                          </div>
                        </div>
                      )
                    );
                  })
                  : null}
              </div>
            </div>
          </div>
        </CollapseRow>

        {/* custom */}
        <CollapseRow title="Custom Hours">
          <div className="w-full flex space-x-4">
            <div
              className="cursor-pointer"
              onClick={() => chooseStaffWorkingHours('custom')}
            >
              <Checkbox
                isChecked={
                  staffHourWorkScheduleType === 'custom' ? true : false
                }
              />
            </div>
            <div className="flex flex-col space-y-4">
              <div className="flex flex-col">
                <Paragraph size="b4" weight="semiBold" color={COLORS.GREY[900]}>
                  Based on custom working hours
                </Paragraph>
                <Paragraph size="b4" color={COLORS.GREY[300]}>
                  Staff is available during these working hours
                </Paragraph>
              </div>
              <div className="w-full flex flex-col space-y-3">
                {Array?.isArray(customHours) && customHours?.length
                  ? customHours?.map((customHour, index) => {
                    const selectedCustomHour = getDayChoiceFromValue(customHour?.day)
                    return (
                      <div
                        className="w-full flex items-center gap-2"
                        key={index}
                      >
                        <div className="max-w-[110px] w-full">
                          <SingleSelect
                            selected={selectedCustomHour}
                            options={DAY_CHOICES}
                            setSelected={onChangeDayChoices(index)}
                            placeholder="Select"
                            isOptionDisabled={(option) => option.isDisabled}
                          />
                        </div>
                        <span className="text-grey-900 text-b5">from</span>
                        <TimePicker
                          className="splice-time-input w-full max-w-[110px]"
                          use12Hours
                          format="h:mm a"
                          value={dayjs(customHour?.openTime, 'HH:mm')}
                          onChange={handleTimeChange(index, 'openTime')}
                        />
                        <span className="text-grey-900 text-b5">to</span>
                        <TimePicker
                          className="splice-time-input w-full max-w-[110px]"
                          use12Hours
                          format="h:mm a"
                          value={dayjs(customHour?.closeTime, 'HH:mm')}
                          onChange={handleTimeChange(index, 'closeTime')}
                        />
                        <Button
                          variant="icon"
                          size="square"
                          rounded="md"
                          type="button"
                          className="border-0"
                          onClick={() => removeCustomHour(index)}
                        >
                          <SvgClose width="24px" height="24px" />
                        </Button>
                      </div>
                    );
                  })
                  : null}
              </div>
              <Button
                variant="light"
                size="xs"
                rounded="lg"
                type="button"
                className="w-fit border border-grey-100"
                onClick={addMoreCustomTime}
              >
                Add new time
                <SvgPlus width="14px" height="14px" />
              </Button>
            </div>
          </div>
        </CollapseRow>

        <div className="relative h-[100px]">
          <div className="w-full max-w-[800px] absolute bottom-0 bg-white flex space-x-3 border-t border-grey-100 cursor-pointer py-2 px-8 justify-end">
            <Button
              type="button"
              size='sm'
              variant="text"
              className="text-red-600"
              onClick={() => setTab(STAFF_DETAILS_TAB_NAME.EMPLOYMENT_DETAILS)}
            >
              Prev
            </Button>
            {currentSalonStaff ? (
              <Button
                type="submit"
                onClick={() => setUpdateStaff(true)}
                loading={loading}
                disabled={loading}
                variant="secondary"
                className="rounded-lg"
              >
                Save
              </Button>
            ) : null}
            <Button
              type="submit"
              loading={false}
              disabled={loading}
              variant="primary"
              className="rounded-lg"
            >
              Proceed
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

const WorkingHours = () => {
  const [width] = useState<number>(window.innerWidth);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const isMobile = width <= 768;

  return <WorkingHoursCalendar />;
};

const StaffListShimmer = () => {
  return (
    <>
      {[1, 2, 3].map((item, index) => (
        <div
          style={{ padding: '10px 16px 10px 16px' }}
          key={index}
          className="flex px-4 py-2.5 border-b border-grey-100 space-x-4 items-center cursor-pointer h-[69px]"
        >
          <Skeleton width="40px" height="40px" className="rounded-full" />
          <div className="flex flex-col">
            <ParagraphShimmer size="md" />
            <ParagraphShimmer size="md" />
          </div>
        </div>
      ))}
    </>
  );
};

const WorkingHoursShimmer = () => {
  return (
    <>
      {[1, 2, 3].map((item, staffIndex) => (
        <div
          key={staffIndex}
          className="w-full flex border-b border-grey-100 h-[69px] overflow-x-scroll"
        >
          {[1, 2, 3, 4, 5, 6, 7].map((item, dayIndex) => (
            <div
              className="flex p-1 justify-center items-center border-r border-grey-100 flex-1 min-w-[68px] h-[68px]"
              key={dayIndex}
            >
              <div className={`w-full h-full bg-slate-300 animate-pulse`}></div>
            </div>
          ))}
        </div>
      ))}
    </>
  );
};

const WorkingHoursCalendar = () => {
  const navigate = useNavigate();
  const { getSalonData } = useSalonCache()
  const salon = getSalonData()
  const [staffWorkingHoursLoading, setStaffWorkingHoursLoading] = useState(false);
  const [days, setDays] = useState<Date[]>([])
  const {
    data: closedPeriodData,
    loading: closedPeriodLoading,
    refetch: refetchClosedPeriod,
  } = useClosedPeriods({
    salonId: salon?.id
  })
  const closedPeriods = useMemo(() => closedPeriodData?.closedPeriods, [closedPeriodData])

  const {
    data: hoursData,
    loading: salonHoursLoading,
    refetch: refetchSalonHours,
  } = useSalonHours(salon?.id);
  const salonHours = useMemo(() => hoursData?.salonHours, [hoursData]);


  const startDate = Array.isArray(days) && days?.length ? formatDateToOriginalDate(days[0], 'start') as string : null;
  const endDate = Array.isArray(days) && days?.length ? formatDateToOriginalDate(days[days.length - 1], "end") : null;
  const {
    loading: isLoading,
    data: staffListData,
    refetch
  } = useGetStaffList({
    salonId: salon?.id,
    ...(startDate && { startDate }),  // Add startDate only if it is not null
    ...(endDate && { endDate })
  })
  const salonStaffs = useMemo(() => staffListData?.staffManagement?.staffs, [staffListData])

  const [salonStaff, setSalonStaff] = useState<SalonStaff | null>(null);
  const [timeOffId, setTimeOffId] = useState<string | null>(null);
  const { toast, addToast } = useToast()
  const actions = {
    editStaff: canPerformAction(PERMISSION_CONSTANTS.staff.edit),
    addStaff: canPerformAction(PERMISSION_CONSTANTS.staff.add),
    deleteStaff: canPerformAction(PERMISSION_CONSTANTS.staff.delete),
    addTimeOff: canPerformAction(PERMISSION_CONSTANTS.staff.add_time_off),
    editTimeOff: canPerformAction(PERMISSION_CONSTANTS.staff.edit_time_off),
    addClosedPeriod: canPerformAction(
      PERMISSION_CONSTANTS.staff.add_closed_period
    ),
  };
  const businessCountry = JSON.parse(localStorage.getItem('country'))
  const currentDateTimeZone = moment().tz(businessCountry?.timezone || 'Africa/Lagos');

  // Get the date components in the local time zone
  const currentYear = currentDateTimeZone.year();
  const currentMonth = currentDateTimeZone.month(); // 0-based index for month
  const currentDay = currentDateTimeZone.date();
  // Create a new Date object using the local time components
  const currentBusinessDate = new Date(Date.UTC(currentYear, currentMonth, currentDay));
  const [currentDate, setCurrentDate] = useState(currentBusinessDate);

  const refetchData = () => {
    refetchClosedPeriod();
    refetch();
    refetchSalonHours();
  }
  useEffect(() => {
    if (Array?.isArray(days) && days?.length > 0) {
      refetchData()
    }
  }, [days]);


  const getWeekDays = (startDate: Date, endDate: Date) => {
    const days = [];
    let day = startDate;
    while (day <= endDate) {
      days.push(day);
      day = addDays(day, 1);
    }
    return days;
  };


  useEffect(() => {
    const startDate = startOfWeek(currentDate);
    const endDate = endOfWeek(currentDate);
    const days = getWeekDays(startDate, endDate);
    setDays(days);
  }, [currentDate]);

  const {
    isVisible: isAddTimeOffModalVisible,
    openModal: openAddTimeOffModal,
    closeModal: closeAddTimeOffModal,
  } = useModal();

  const {
    isVisible: isAddClosedPeriodModalVisible,
    openModal: openAddClosedPeriodModal,
    closeModal: closeAddClosedPeriodModal,
  } = useModal();

  const {
    isVisible: isUploadStaffModalVisible,
    openModal: openUploadStaffModal,
    closeModal: closeUploadStaffModal,
  } = useModal();

  const {
    isVisible: isAddStaffModalVisible,
    openModal: openAddStaffModal,
    closeModal: closeAddStaffModal,
  } = useModal();

  const {
    isVisible: isStaffFormModalVisible,
    openModal: openStaffFormModal,
    closeModal: closeStaffFormModal,
  } = useModal();

  // const getPrevWeek = () => {
  //   const zonedDate = toZonedTime(currentDate, businessCountry?.timezone || 'Africa/Lagos');
  //   setCurrentDate(addDays(zonedDate, -7));
  // };
  // const fixDateToCurrentDate = () => {
  //   const zonedDate = toZonedTime(new Date(), businessCountry?.timezone || 'Africa/Lagos');
  //   setCurrentDate(zonedDate);
  // };
  // const getNextWeek = () => {
  //   const zonedDate = toZonedTime(currentDate, businessCountry?.timezone || 'Africa/Lagos');
  //   setCurrentDate(addDays(zonedDate, 7));
  // };

  const getPrevWeek = () => {
    const zonedDate = moment(currentDate).tz(businessCountry?.timezone || 'Africa/Lagos');
    setCurrentDate(zonedDate.subtract(7, 'days').toDate());
  };

  const fixDateToCurrentDate = () => {
    const zonedDate = moment().tz(businessCountry?.timezone || 'Africa/Lagos');
    setCurrentDate(zonedDate.toDate());
  };

  const getNextWeek = () => {
    const zonedDate = moment(currentDate).tz(businessCountry?.timezone || 'Africa/Lagos');
    setCurrentDate(zonedDate.add(7, 'days').toDate());
  };

  const getDayAndNumber = (date: Date | string) => {
    const day = new Date(date).toLocaleString('en-us', { weekday: 'short' });
    const number = new Date(date).getDate();
    return `${day} ${number}`;
  };

  const handleOpenStaff = (staff: SalonStaff) => {
    if (!actions.editStaff) return;
    setSalonStaff(staff);
    openAddStaffModal();
  };

  const editTimeOff = (staff: SalonStaff, timeOffId: string) => {
    setSalonStaff(staff);
    setTimeOffId(timeOffId);
    openAddTimeOffModal();
  };

  const initiateCloseAddTimeOffModal = () => {
    setSalonStaff(null);
    setTimeOffId(null);
    closeAddTimeOffModal();
    refetchData();
  };

  const initiateAddClosedPeriodModal = () => {
    closeAddClosedPeriodModal();
    refetchData();
  };


  const showWorkingHoursInitialLoading = staffWorkingHoursLoading || isLoading;

  const getWorkingHoursContent = () => {
    if (showWorkingHoursInitialLoading) {
      return (
        <>
          <div className='flex flex-col w-3/12 border-r border-grey-100'>
            <StaffListShimmer />
          </div>
          <div className='flex flex-col w-9/12'>
            <WorkingHoursShimmer />
          </div>
        </>
      )
    }
    if (Array.isArray(salonStaffs) && salonStaffs.length) {
      return (
        <div className="flex flex-col h-full w-full">
          {Array.isArray(salonStaffs) &&
            salonStaffs
              .filter((staff: SalonStaff) => staff?.active) // Filter first for active staff
              .map((staff: SalonStaff, staffIndex) => {
                const workingHours = staff.staffHours;
                const timeOffs = staff.staffTimeOffs;
                const staffHours = getWorkingHoursByStaffId(
                  days,
                  staff?.id,
                  workingHours,
                  timeOffs,
                  closedPeriods
                );

                return (
                  <div
                    style={{ minHeight: '69px' }}
                    className="flex border-b border-grey-100 min-h-[69px]"
                    key={staffIndex}
                  >
                    <div
                      style={{ width: '25%' }}
                      className="flex flex-col w-6/12 xl:w-3/12 h-full border-r border-grey-100"
                    >
                      <div
                        className="flex px-4 py-2.5 space-x-0 xl:space-x-4 items-center cursor-pointer h-[69px]"
                        onClick={() => handleOpenStaff(staff)}
                      >
                        <NameAvatar
                          name={
                            (staff?.user?.firstName?.charAt(0)?.toLocaleUpperCase() ?? '') +
                            (staff?.user?.lastName
                              ? staff.user.lastName.charAt(0).toLocaleUpperCase()
                              : staff?.user?.firstName?.charAt(1)?.toLocaleUpperCase())
                          }
                        />

                        <div className="flex flex-col">
                          <div className="flex items-start space-x-3">
                            <Paragraph size="b4" color={COLORS.GREY[900]}>
                              {staff?.user?.fullName}
                            </Paragraph>
                            {staff?.canServeCustomers ? (
                              <SvgGreenSignal width="18px" height="18px" />
                            ) : (
                              <SvgYellowsignal width="18px" height="18px" />
                            )}
                          </div>
                          <Paragraph size="b4" color={COLORS.GREY[400]}>
                            {cleanUpText(staff?.specialty)}
                          </Paragraph>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{ width: '75%' }}
                      className="flex flex-col w-9/12"
                    >
                      <div className="w-full flex min-h-[69px] overflow-x-scroll">
                        {Array.isArray(staffHours) &&
                          staffHours?.map((hours, hoursIndex) => {
                            const cleanedHoursArray = hours.flat().filter(item => item !== null);
                            return (
                              <div
                                className="flex flex-col flex-1 min-w-[68px]"
                                key={hoursIndex}
                              >
                                {Array.isArray(cleanedHoursArray) &&
                                  cleanedHoursArray?.map((hour, hourIndex) => {
                                    const handleHourClick = () => {
                                      if (
                                        hour &&
                                        hour?.type === 'off' &&
                                        hour?.id
                                      ) {
                                        actions?.editTimeOff &&
                                          editTimeOff(staff, hour?.id);
                                      } else {
                                        actions?.editStaff &&
                                          handleOpenStaff(staff);
                                      }
                                    };
                                    return (
                                      hour && (
                                        <div
                                          className="flex p-1 cursor-pointer justify-center items-center border-r border-grey-100 flex-1 w-full h-[68px]"
                                          onClick={handleHourClick}
                                          key={hourIndex}
                                        >
                                          <div
                                            className={`w-full h-full flex flex-col p-2 justify-between ${hour?.status === 'open'
                                              ? 'bg-green-100'
                                              : 'bg-grey-100'
                                              }`}
                                          >
                                            <Paragraph
                                              size="b6"
                                              weight="semiBold"
                                              className="capitalize"
                                              color={
                                                hour?.status === 'open'
                                                  ? COLORS.GREEN[700]
                                                  : COLORS.GREY[400]
                                              }
                                            >
                                              {hour?.status === 'open'
                                                ? 'Working'
                                                : hour?.status || 'Off'}
                                            </Paragraph>

                                            {hour?.openTime && hour?.closeTime ? (
                                              <Paragraph
                                                size="b7"
                                                color={COLORS.GREY[200]}
                                              >{`${formatTime(
                                                hour?.openTime
                                              )} - ${formatTime(
                                                hour?.closeTime
                                              )}`}</Paragraph>
                                            ) : null}
                                          </div>
                                        </div>
                                      )
                                    );
                                  })}
                              </div>
                            )
                          })}
                      </div>
                    </div>
                  </div>
                );
              })}
        </div>
      );
    }
  };

  const initiateCloseStaffFormModal = () => {
    closeStaffFormModal();
    refetchData();
  };

  const initiateCloseModifyStaffHoursModal = (action?: string) => {
    closeAddStaffModal();
    setSalonStaff(null);
    refetchData();
  };

  const goToClosedPeriod = () => {
    navigate(PAGE_ROUTES.CLOSED_PERIOD);
  };

  return (
    <>
      <ToastWrapper toast={toast as ToastProps} />
      <div className="flex flex-col w-full h-full">
        <div className="flex flex-col">
          <div className="flex items-center justify-between px-6 py-3 border-b border-grey-100">
            <Paragraph size="b5" weight="bold">
              {getNumberMonthAndYearFromDate(currentDate)}
            </Paragraph>

            <div className="flex items-center">
              <Button
                variant="secondary"
                size="sm"
                type="button"
                rounded="md"
                fontWeight="bold"
                className="border-0"
                onClick={getPrevWeek}
              >
                <SvgChevronLeft width="24px" height="24px" />
              </Button>
              <Button
                variant="light"
                size="sm"
                type="button"
                rounded="md"
                fontWeight="bold"
                className=""
                onClick={fixDateToCurrentDate}
              >
                Today
              </Button>
              <Button
                variant="secondary"
                size="sm"
                type="button"
                rounded="md"
                fontWeight="bold"
                className="border-0"
                onClick={getNextWeek}
              >
                <SvgChevronRight width="24px" height="24px" />
              </Button>
            </div>

            <div className="relative space-x-2">
              {actions?.addClosedPeriod ? (
                <Menu as="div" className="relative inline-block text-left">
                  <div>
                    <Menu.Button className="flex space-x-2 w-full items-center justify-center rounded-md bg-white border border-grey-50 px-4 py-2 text-b5 text-grey-900 font-semibold hover:bg-opacity-90 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-85">
                      <span>Manage</span>
                      <SvgSelectDropDown width="10px" height="10px" />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="flex flex-col p-4 space-y-4">
                        <Menu.Item>
                          <span
                            className="font-semibold text-b4 cursor-pointer"
                            onClick={() => goToClosedPeriod()
                            }
                            role='button'
                          >
                            Business Closed Period
                          </span>
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              ) : null}
              {actions?.addTimeOff ||
                actions?.addStaff ||
                actions?.addClosedPeriod ? (
                <Menu as="div" className="relative inline-block text-left">
                  <div>
                    <Menu.Button className="flex space-x-2 w-full items-center justify-center rounded-md bg-green-300 px-4 py-2 text-b5 font-semibold text-white hover:bg-opacity-90 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-85">
                      <span>Add</span>
                      <SvgSelectDropDown width="10px" height="10px" />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="flex flex-col p-4 space-y-4">
                        {actions?.addStaff ? (
                          <Menu.Item>
                            <span
                              className="font-semibold text-b4 cursor-pointer"
                              onClick={openStaffFormModal}
                            >
                              Add Staff
                            </span>
                          </Menu.Item>
                        ) : null}
                        {actions?.addTimeOff ? (
                          <Menu.Item>
                            <span
                              className="font-semibold text-b4 cursor-pointer"
                              onClick={openAddTimeOffModal}
                            >
                              Time Off
                            </span>
                          </Menu.Item>
                        ) : null}
                        {actions?.addClosedPeriod ? (
                          <Menu.Item>
                            <span
                              className="font-semibold text-b4 cursor-pointer"
                              onClick={openAddClosedPeriodModal}
                            >
                              Business Closed Period
                            </span>
                          </Menu.Item>
                        ) : null}
                        {actions?.addStaff ? (
                          <Menu.Item>
                            <span
                              className="font-semibold text-b4 cursor-pointer"
                              onClick={openUploadStaffModal}
                            >
                              Upload Staff
                            </span>
                          </Menu.Item>
                        ) : null}
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              ) : null}
            </div>
          </div>
        </div>
        <div className="flex w-full">
          <div
            style={{ width: '25%' }}
            className="flex flex-col w-6/12 xl:w-3/12 h-full border-r border-grey-100"
          >
            <div className="hidden xl:flex px-4 py-2.5 border-b border-grey-100">
              <Paragraph size="b4" weight="bold" color={COLORS.GREY[300]}>
                {salonStaffs?.length} staff members
              </Paragraph>
            </div>
          </div>
          <div
            style={{ width: '75%' }}
            className="flex flex-col h-full w-6/12 xl:w-9/12"
          >
            <div className="hidden xl:flex px-4 py-2.5 border-b border-grey-100 overflow-x-scroll">
              {days?.map((day, index) => (
                <div
                  style={{ minWidth: '68px' }}
                  key={index}
                  className="flex-1 min-w-[68px]"
                >
                  <Paragraph
                    size="b4"
                    weight="bold"
                    color={
                      isSameDay(day, new Date())
                        ? COLORS.RED[400]
                        : COLORS.GREY[300]
                    }
                  >
                    {getDayAndNumber(day)}
                  </Paragraph>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="w-full h-full flex">{getWorkingHoursContent()}</div>
      </div>
      <AddTimeOffModal
        isVisible={isAddTimeOffModalVisible}
        closeModal={initiateCloseAddTimeOffModal}
        timeOffId={timeOffId}
        staff={salonStaff}
        addToast={addToast}
        staffList={salonStaffs}
      />
      <AddClosedPeriodModal
        isVisible={isAddClosedPeriodModalVisible}
        closeModal={initiateAddClosedPeriodModal}
        addToast={addToast}
      />
      <ModifyStaffWorkingSchedule
        staff={salonStaff}
        closeModal={initiateCloseModifyStaffHoursModal}
        isVisible={isAddStaffModalVisible}
      />
      <AddEditStaffModal
        staff={null}
        closeModal={initiateCloseStaffFormModal}
        isVisible={isStaffFormModalVisible}
        action={'create'}
        salonHours={salonHours}
      />
      <UploadStaffModal
        isVisible={isUploadStaffModalVisible}
        closeModal={closeUploadStaffModal}
      />
    </>
  );
};

type AddTimeOffModalProps = {
  isVisible: boolean;
  closeModal: () => void;
  timeOffId?: string;
  staff?: SalonStaff;
  staffList?: SalonStaff[];
  staffId?: string;
  addToast: (toast: ToastProps) => void;
};

const AddTimeOffModal = ({
  isVisible,
  closeModal,
  staff,
  timeOffId,
  staffId,
  staffList,
}: AddTimeOffModalProps) => {
  const { addToast, toast } = useToast()
  const { getSalonData } = useSalonCache()
  const salon = getSalonData()
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<CreateStaffTimeOffInput>({ delayError: 100, mode: 'onChange' });

  const {
    loading: isCreating,
    createStaffTimeOff,
  } = useCreateStaffTimeOff()

  const {
    loading: deleting,
    deleteStaffTimeOff
  } = useDeleteStaffTimeOff()

  const submitData = async (input: CreateStaffTimeOffInput) => {
    createStaffTimeOff({
      variables: {
        input: {
          ...input,
          salonId: salon?.id,
          id: timeOffId || null,
        }
      }
    }).then(({ data }) => {
      const { createStaffTimeOff } = data;
      if (createStaffTimeOff?.status === 200) {
        addToast({
          message: 'Staff time off saved successfuly',
          variant: 'success'
        })
        resetTimeOffForm();
        closeModal();
      }

      if (createStaffTimeOff?.errors?.length) {
        addToast({
          message: createStaffTimeOff?.errors[0]?.message,
          variant: 'error'
        })
      }
    }).catch((error) => {
      addToast({
        message: error?.message,
        variant: 'error'
      })
    })
  };

  const deleteTimeOff = async () => {
    deleteStaffTimeOff({
      variables: {
        input: { id: timeOffId }
      }
    }).then(({ data }) => {
      const { deleteStaffTimeOff } = data;
      if (deleteStaffTimeOff?.status === 200) {
        addToast({
          message: 'Staff time off deleted successfuly',
          variant: 'success'
        })
        resetTimeOffForm();
        closeModal();
      }

      if (deleteStaffTimeOff?.errors?.length) {
        addToast({
          message: deleteStaffTimeOff?.errors[0]?.message,
          variant: 'error'
        })
      }
    }).catch((error) => {
      addToast({
        message: error?.message,
        variant: 'error'
      })
    })
  };

  const resetTimeOffForm = () => {
    setValue('title', '');
    setValue('salonStaffId', '');
    setValue('startDate', '');
    setValue('startTime', '');
    setValue('endDate', '');
    setValue('endTime', '');
  };

  const staffMembersOptions =
    Array?.isArray(staffList) ?
      staffList?.length &&
      (staffList as SalonStaff[])
        ?.filter((staff) => staff?.active)
        ?.map((x) => ({
          value: x?.id,
          label: x?.user?.fullName,
        })) : [];

  useEffect(() => {
    if (staff && timeOffId) {
      const staffTimeOffData = staff?.staffTimeOffs?.find(
        (x) => x.id === timeOffId
      );
      if (staffTimeOffData) {
        setValue(
          'startDate',
          convertFullDateStringToDate(staffTimeOffData.startAt)
        );
        setValue(
          'endDate',
          convertFullDateStringToDate(staffTimeOffData.endAt)
        );
        setValue(
          'endTime',
          convertFullDateStringToTime(staffTimeOffData.endAt)
        );
        setValue(
          'startTime',
          convertFullDateStringToTime(staffTimeOffData.startAt)
        );
        setValue('salonStaffId', staff?.id);
        setValue('title', staffTimeOffData.title);
      }
    }
  }, [staff, timeOffId]);

  useEffect(() => {
    if (staffId) {
      setValue('salonStaffId', staffId);
    }
  }, [staffId]);

  const handleTimeChange =
    (fieldName: 'startTime' | 'endTime') =>
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (_value: any, dateString: string) => {
        if (dateString) {
          const timeString = convert12HourTo24Hour(dateString);
          if (timeString) {
            setValue(fieldName, timeString);
          }
        }
      };

  return (
    <Modal show={isVisible} title="Add time off" closeModal={closeModal}>
      <ToastWrapper toast={toast} />
      <form
        onSubmit={handleSubmit(submitData)}
        className="w-full mt-6 space-y-6"
        autoComplete="off"
      >
        <Input
          name="title"
          label="Title"
          id="title"
          type="text"
          placeholder="Enter title"
          control={control}
          rules={{
            required: ERRORS.TITLE_REQUIRED,
            pattern: REGEX_PATTERNS.ALPHANUMERIC_WITH_SPACE_OR_DASH,
          }}
          error={errors.title}
        />
        <SelectInput
          name="salonStaffId"
          label="Staff"
          id="staff"
          disabled={false}
          control={control}
          rules={{
            required: ERRORS.STAFF_REQUIRED,
          }}
          error={errors.salonStaffId}
          options={staffMembersOptions}
          placeholder="Select Staff"
        />
        <div className="w-full flex gap-x-4">
          <Input
            name="startDate"
            label="Start Date"
            id="start-at"
            type="date"
            placeholder="DD/MM/YY"
            control={control}
            rules={{
              required: ERRORS.DATE_REQUIRED,
            }}
            error={errors.startDate}
          />
          <Controller
            control={control}
            name="startTime"
            render={({ field, formState: { errors } }) => {
              const errorMessage: string = errors?.startTime?.message;
              return (
                <div className="w-full flex flex-col space-y-2">
                  <FormLabel htmlFor="start-time">Start Time</FormLabel>
                  <TimePicker
                    className="splice-time-input"
                    use12Hours
                    format="h:mm a"
                    value={
                      field.value ? dayjs(field.value, 'HH:mm') : undefined
                    }
                    onChange={handleTimeChange('startTime')}
                  />
                  {errorMessage && (
                    <FormHelperText variant="error">
                      {errorMessage}
                    </FormHelperText>
                  )}
                </div>
              );
            }}
            rules={{
              required: ERRORS.TIME_REQUIRED,
            }}
          />
        </div>
        <div className="w-full flex gap-x-4">
          <Input
            name="endDate"
            label="End Date"
            id="end-at"
            type="date"
            placeholder="DD/MM/YY"
            control={control}
            rules={{
              required: ERRORS.DATE_REQUIRED,
            }}
            error={errors.endDate}
          />
          <Controller
            control={control}
            name="endTime"
            render={({ field: { value }, formState: { errors } }) => {
              const errorMessage: string = errors?.endTime?.message;
              const timeValue = value || null;
              return (
                <div className="w-full flex flex-col space-y-2">
                  <FormLabel htmlFor="end-time">End Time</FormLabel>
                  <TimePicker
                    className="splice-time-input"
                    use12Hours
                    format="h:mm a"
                    value={timeValue ? dayjs(value, 'HH:mm') : undefined}
                    onChange={handleTimeChange('endTime')}
                  />
                  {errorMessage && (
                    <FormHelperText variant="error">
                      {errorMessage}
                    </FormHelperText>
                  )}
                </div>
              );
            }}
            rules={{
              required: ERRORS.TIME_REQUIRED,
            }}
          />
        </div>
        <Button
          variant="primary"
          className=""
          disabled={isCreating || deleting}
          loading={isCreating}
          size="lg"
          rounded="lg"
        >
          Save
        </Button>
        <Button
          variant="text"
          size="none"
          rounded="none"
          fontSize="b5"
          className="mx-auto text-red-500"
          onClick={closeModal}
          type="button"
        >
          Cancel
        </Button>
        {timeOffId && (
          <Button
            variant="text"
            size="none"
            rounded="none"
            fontSize="b5"
            disabled={isCreating || deleting}
            loading={deleting}
            className="mx-auto text-red-600"
            onClick={deleteTimeOff}
            type="button"
          >
            Delete
          </Button>
        )}
      </form>
    </Modal>
  );
};

type AddStaffModalProps = {
  isVisible: boolean;
  closeModal: () => void;
  salonStaff?: SalonStaff;
};

type ICreateStaff = {
  staffInput: SalonStaffInput;
};

const UploadStaffModal = ({ isVisible, closeModal }) => {
  const { addToast, toast } = useToast()
  return (
    <Modal
      title="Upload Staff"
      subTitle=""
      show={isVisible}
      closeModal={closeModal}
    >
      <ToastWrapper toast={toast as ToastProps} />
      <div className="flex space-x-6 w-full items-center justify-center my-12">
        <ImportStaff addToast={addToast} refetch={closeModal} />
        <ImportFileLink modules="staff" />
      </div>
      <Button
        variant="text"
        size="none"
        rounded="none"
        fontSize="b5"
        className="mx-auto"
        onClick={closeModal}
        type="button"
      >
        Close
      </Button>
    </Modal>
  );
};

const ImportStaff = (props: BatchUploadProps) => {
  const { addToast, toast } = useToast()
  const { getSalonFieldValue } = useSalonCache();
  const { uploadStaff, loading: isLoading } = useUploadStaff();

  const uploadStaffAsync = (url: string) => {
    const payload = {
      salonId: getSalonFieldValue('id'),
      url: url
    }

    uploadStaff({
      variables: {
        input: {
          ...payload
        }
      }
    }).then(({ data }) => {
      const { uploadStaff } = data;
      if (uploadStaff?.status === 200) {
        props?.addToast({ message: 'Staff uploaded successfully. This may take a few minutes to process', variant: 'success' })
        props?.refetch()
      }

      if (uploadStaff?.errors?.length) {
        addToast({
          variant: 'error',
          message: uploadStaff?.errors[0]?.message
        })
      }
    }).catch((error) => {
      addToast({
        variant: 'error',
        message: error?.message
      })
    })
  }

  const {
    loading: uploadIsLoading,
    uploadFile
  } = useUploadFile();

  const handleCsvUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    const reader = new FileReader()
    reader.onload = async (event) => {
      const base64DataUri = event.target.result
      uploadFile({
        variables: { input: { data: base64DataUri } }
      }).then(({ data }) => {
        const { uploadFile } = data;
        if (uploadFile?.status === 200) {
          uploadStaffAsync(uploadFile?.url);
        }

        if (uploadFile?.errors?.length) {
          addToast({
            message: uploadFile?.errors[0].message,
            variant: 'error'
          })
        }
      }).catch((error) => {
        addToast({
          message: error?.message,
          variant: 'error'
        })
      })
    }

    reader.readAsDataURL(file)
  };

  return (
    <Button
      variant="success"
      className="relative"
      size="sm"
      rounded="md"
      fontSize="b5"
      type="button"
    >
      {!isLoading || !uploadIsLoading ? (
        <>
          Import Staff
          <input
            className="cursor-pointer absolute block opacity-0 top-0 w-[128px] h-full"
            type="file"
            accept={CSV_UPLOAD_PATTERN}
            onChange={handleCsvUpload}
          />
        </>
      ) : (
        <div className="flex justify-center gap-x-2 cursor-pointer">
          {[1, 2, 3].map((item, index) => (
            <div
              key={index}
              className="h-1 w-1 rounded-full bg-gradient-to-r from-grey-200 via-grey-300 to-grey-200 animate-pulse"
            />
          ))}
        </div>
      )}
    </Button>
  );
};

const ModifyStaffWorkingSchedule = ({ staff, isVisible, closeModal }) => {
  const { addToast, toast } = useToast()
  const [businessHours, setBusinessHours] = useState<
    WorkingScheduleHoursForm[]
  >([]);
  const [customHours, setCustomHours] = useState<WorkingScheduleHoursForm[]>(
    []
  );
  const [staffHourWorkScheduleType, setStaffHourWorkScheduleType] =
    useState<string>('business');
  const { getSalonFieldValue } = useSalonCache();
  const DAY_CHOICES = [
    {
      label: 'Everyday',
      value: 'everyday',
      isDisabled: false,
    },
    {
      label: 'Mon-Fri',
      value: 'mon-fri',
      isDisabled: false,
    },
    {
      label: 'Sat-Sun',
      value: 'sat-sun',
      isDisabled: false,
    },
    {
      label: 'Mon',
      value: 'monday',
      isDisabled: false,
    },
    {
      label: 'Tue',
      value: 'tuesday',
      isDisabled: false,
    },
    {
      label: 'Wed',
      value: 'wednesday',
      isDisabled: false,
    },
    {
      label: 'Thu',
      value: 'thursday',
      isDisabled: false,
    },
    {
      label: 'Fri',
      value: 'friday',
      isDisabled: false,
    },
    {
      label: 'Sat',
      value: 'saturday',
      isDisabled: false,
    },
    {
      label: 'Sun',
      value: 'sunday',
      isDisabled: false,
    },
  ];
  const salonId = getSalonFieldValue('id');

  const {
    data: hoursData,
    loading: hoursLoading,
    refetch: refetchSalonHours,
  } = useSalonHours(salonId)
  const salonHours = useMemo(() => hoursData?.salonHours, [hoursData])

  const {
    data: staffData,
    loading: loadingStaffData,
    refetch: refetchStaffData,
  } = useGetStaff({ salonStaffId: staff?.id })
  const currentSalonStaff = useMemo(() => staffData?.salonStaff, [staffData])

  useEffect(() => {
    if (staff?.id) {
      refetchStaffData()
      refetchSalonHours()
    }
  }, [staff?.id, refetchStaffData, refetchSalonHours])

  const {
    loading: isLoading,
    updateSalonStaff
  } = useUpdateStaff()

  const closeHoursModal = () => {
    setBusinessHours([]);
    setCustomHours([]);
    setStaffHourWorkScheduleType('business');
    closeModal();
  };

  const closeHoursModalAndRefreshData = () => {
    setBusinessHours([]);
    setCustomHours([]);
    setStaffHourWorkScheduleType('business');
    closeModal('refresh');
  }

  const update = async () => {
    const hours =
      staffHourWorkScheduleType === 'business'
        ? convertGroupHoursToSingleTime(businessHours)
        : convertGroupHoursToSingleTime(customHours);
    // Creating a new object without the specified keys
    const { __typename: _, ...salaryInformation } = currentSalonStaff?.salaryInformation || {};
    const { __typename, services, ...employmentDetails } = currentSalonStaff?.employmentDetails || {};
    const updatedServices = services?.map(({ __typename, ...rest }) => rest);
    const data = {
      salaryInformation,
      reminder: {
        birthday: currentSalonStaff?.reminder?.birthday || false,
        workAnniversary: currentSalonStaff?.reminder?.workAnniversary || false,
      },
      workingSchedule: {
        workScheduleType: staffHourWorkScheduleType,
        workSchedule: hours,
      },
      employmentDetails: {
        ...employmentDetails,
        services: updatedServices,
        salonId: currentSalonStaff?.salonId,
        level: currentSalonStaff?.employmentDetails?.level?.toString(),
      },
      receiveBusinessReports: currentSalonStaff?.receiveBusinessReports,
      receivePushNotifications: currentSalonStaff?.receivePushNotifications,
      salonId: currentSalonStaff?.salonId,
      active: staff?.active,
      id: currentSalonStaff?.id,
      leave: { daysEntitled: currentSalonStaff?.leave?.daysEntitled || '0' },
      user: {
        firstName: currentSalonStaff?.user?.firstName,
        lastName: currentSalonStaff?.user?.lastName,
        email: currentSalonStaff?.user?.email,
        countryCode: currentSalonStaff?.user?.countryCode,
        phone: currentSalonStaff?.user?.phone,
        dateOfBirth: currentSalonStaff?.user?.dateOfBirth,
        profileUrl: currentSalonStaff?.user?.profileUrl,
        callingCode: currentSalonStaff?.user?.callingCode,
      },
    };

    updateSalonStaff({
      variables: {
        input: data
      }
    }).then(({ data }) => {
      const { updateSalonStaffDetails } = data;

      if (updateSalonStaffDetails?.status === 200) {
        addToast({
          variant: 'success',
          message: 'Staff updated successfully'
        })
        closeHoursModalAndRefreshData();
      }

      if (updateSalonStaffDetails?.errors?.length) {
        addToast({
          variant: 'error',
          message: updateSalonStaffDetails?.errors[0]?.message
        })
      }
    })
  };

  useEffect(() => {
    if (currentSalonStaff && currentSalonStaff?.workingSchedule) {
      setStaffHourWorkScheduleType(
        currentSalonStaff?.workingSchedule?.workScheduleType
      );
      if (currentSalonStaff?.workingSchedule?.workScheduleType == 'custom') {
        setCustomHours(
          groupBusinessSalonHoursForStaffCreation(
            currentSalonStaff?.workingSchedule?.workSchedule as any
          )
        );
      } else {
        setBusinessHours(groupBusinessSalonHoursForStaffCreation(salonHours));
      }
    }
  }, [currentSalonStaff]);

  useEffect(() => {
    if (
      Array?.isArray(salonHours) &&
      salonHours?.length &&
      currentSalonStaff &&
      !currentSalonStaff?.workingSchedule?.workScheduleType
    ) {
      const staffWorkingHourType = checkStaffWorkingSchedule(
        salonHours,
        currentSalonStaff?.workingSchedule?.workSchedule
      );
      setStaffHourWorkScheduleType(staffWorkingHourType);
      if (staffWorkingHourType == 'custom') {
        setCustomHours(
          groupBusinessSalonHoursForStaffCreation(
            currentSalonStaff.workingSchedule.workSchedule as any
          )
        );
      } else {
        setBusinessHours(groupBusinessSalonHoursForStaffCreation(salonHours));
      }
    }
  }, [salonHours, currentSalonStaff]);

  useEffect(() => {
    if (salonHours?.length > 0) {
      const businessHoursFormatted =
        groupBusinessSalonHoursForStaffCreation(salonHours);
      setBusinessHours(businessHoursFormatted);
    }
  }, [salonHours]);

  // const checkIfDayChoosenIsValid = (index: number | null, day?: string) => {
  //   // get custom hours where day !== day
  //   const filteredCustomHours =
  //     index || index === 0
  //       ? customHours?.filter((hour, i) => i !== index && hour?.day)
  //       : customHours?.filter((hour) => hour?.day);

  //   if (filteredCustomHours.length > 0) {
  //     const everydayIdex = filteredCustomHours.findIndex(
  //       (hour) => hour.day === 'everyday'
  //     );
  //     if (everydayIdex !== -1) {
  //       return false;
  //     }
  //     const monFriIndex = filteredCustomHours.findIndex(
  //       (hour) => hour.day === 'mon-fri'
  //     );
  //     if (
  //       monFriIndex !== -1 &&
  //       (day === 'monday' ||
  //         day === 'tuesday' ||
  //         day === 'wednesday' ||
  //         day === 'thursday' ||
  //         day === 'friday' ||
  //         day === 'mon-fri')
  //     ) {
  //       return false;
  //     }
  //     const satSunIndex = filteredCustomHours.findIndex(
  //       (hour) => hour.day === 'sat-sun'
  //     );
  //     if (
  //       satSunIndex !== -1 &&
  //       (day === 'saturday' || day === 'sunday' || day === 'sat-sun')
  //     ) {
  //       return false;
  //     }
  //     const monIndex = filteredCustomHours.findIndex(
  //       (hour) => hour.day === 'monday'
  //     );
  //     if (monIndex !== -1 && (day === 'monday' || day === 'mon-fri')) {
  //       return false;
  //     }
  //     const tueIndex = filteredCustomHours.findIndex(
  //       (hour) => hour.day === 'tuesday'
  //     );
  //     if (tueIndex !== -1 && (day === 'tuesday' || day === 'mon-fri')) {
  //       return false;
  //     }
  //     const wedIndex = filteredCustomHours.findIndex(
  //       (hour) => hour.day === 'wednesday'
  //     );
  //     if (wedIndex !== -1 && (day === 'wednesday' || day === 'mon-fri')) {
  //       return false;
  //     }
  //     const thuIndex = filteredCustomHours.findIndex(
  //       (hour) => hour.day === 'thursday'
  //     );
  //     if (thuIndex !== -1 && (day === 'thursday' || day === 'mon-fri')) {
  //       return false;
  //     }
  //     const friIndex = filteredCustomHours.findIndex(
  //       (hour) => hour.day === 'friday'
  //     );
  //     if (friIndex !== -1 && (day === 'friday' || day === 'mon-fri')) {
  //       return false;
  //     }
  //     const satIndex = filteredCustomHours.findIndex(
  //       (hour) => hour.day === 'saturday'
  //     );
  //     if (satIndex !== -1 && (day === 'saturday' || day === 'sat-sun')) {
  //       return false;
  //     }
  //     const sunIndex = filteredCustomHours.findIndex(
  //       (hour) => hour.day === 'sunday'
  //     );
  //     if (sunIndex !== -1 && (day === 'sunday' || day === 'sat-sun')) {
  //       console.log('prob9');
  //       return false;
  //     }
  //   }

  //   return true;
  // };

  const checkIfDayChoosenIsValid = (index: number | null, day?: string) => {
    // Filter custom hours to exclude the current index if provided
    const filteredCustomHours = index !== null
      ? customHours.filter((_, i) => i !== index && customHours[i]?.day)
      : customHours.filter(hour => hour?.day);

    // Count occurrences of the selected day in filtered custom hours
    const dayCount = filteredCustomHours.reduce((count, hour) => {
      if (hour.day === day) {
        return count + 1;
      }
      return count;
    }, 0);

    // Return false if the selected day appears twice already
    if (dayCount >= 2) {
      return false;
    }

    // Additional day-specific conditions
    if (filteredCustomHours.some(hour => hour.day === 'everyday')) {
      return false;
    }

    if (filteredCustomHours.some(hour => hour.day === 'mon-fri') &&
      ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'mon-fri'].includes(day)) {
      return false;
    }

    if (filteredCustomHours.some(hour => hour.day === 'sat-sun') &&
      ['saturday', 'sunday', 'sat-sun'].includes(day)) {
      return false;
    }

    return true;
  };

  const chooseStaffWorkingHours = (scheduleType: string) => {
    setStaffHourWorkScheduleType(scheduleType);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleTimeChange =
    (index: number, fieldName: 'openTime' | 'closeTime') =>
      (value: any, dateString: string) => {
        if (dateString) {
          const timeString = convert12HourTo24Hour(dateString);
          // update custom hours openTime or closeTime based off the index
          const updatedCustomHours = [...customHours];
          updatedCustomHours[index][fieldName] = timeString;
          setCustomHours(updatedCustomHours);
          setStaffHourWorkScheduleType('custom');
        }
      };

  const addMoreCustomTime = () => {
    // check if all the field in custome hours are not empty
    const allFieldsFilled = customHours.every(
      (hour) => hour.day && hour.openTime && hour.closeTime
    );
    if (!allFieldsFilled) {
      addToast({
        variant: 'error',
        message: 'Please fill in all the fields in custom hours',
      });
      return;
    }
    setStaffHourWorkScheduleType('custom');
    setCustomHours([
      ...customHours,
      {
        day: null,
        openTime:
          Array?.isArray(businessHours) && businessHours?.length
            ? businessHours[0]?.openTime
            : '08:00',
        closeTime:
          Array?.isArray(businessHours) && businessHours?.length
            ? businessHours[0]?.closeTime
            : '20:00',
      },
    ]);
  };

  const removeCustomHour = (index: number) => {
    // remove hour by index from customHours
    const updatedCustomHours = [...customHours];
    updatedCustomHours.splice(index, 1);
    setCustomHours(updatedCustomHours);
  };

  const getDayChoiceFromValue = (value: string) => {
    const dayChoice = DAY_CHOICES.find((choice) => choice.value === value);
    return [dayChoice];
  };

  const onChangeDayChoices =
    (index: number) => (day: { label: string; value: string }) => {
      // check if this index has a day set before
      let isValid = true;
      const hasDaySet = customHours[index]?.day;
      if (hasDaySet) {
        isValid = checkIfDayChoosenIsValid(index, day?.value);
      } else {
        isValid = checkIfDayChoosenIsValid(null, day?.value);
      }

      if (!isValid) {
        addToast({
          variant: 'error',
          message: 'Please choose a valid day',
        });
        return;
      }
      // update custom hours day based off the index
      const updatedCustomHours = [...customHours];
      updatedCustomHours[index].day = day?.value;
      setCustomHours(updatedCustomHours);
    };

  return (
    <Modal show={isVisible} closeModal={closeHoursModal} variant="right">
      <div className="w-full relative my-[80px]">
        <ToastWrapper toast={toast} />
        <div className="fixed top-0 w-full bg-white flex border-b border-grey-100 cursor-pointer z-10">
          <div className="w-full hidden xl:flex space-x-2 px-6 py-5 items-start">
            <Button
              variant="text"
              size="none"
              type="button"
              className="w-fit"
              fontSize="b4"
              onClick={closeHoursModal}
            >
              <SvgArrowBack width="24px" height="24px" /> <span>Back</span>
            </Button>
          </div>
          <div className="w-full flex xl:hidden space-x-[69px] p-4 items-center">
            <Button
              variant="icon"
              size="square"
              type="button"
              rounded="md"
              className="w-fit"
              fontSize="b4"
              onClick={closeHoursModal}
            >
              <SvgChevronLeft width="24px" height="24px" />
            </Button>
            <Paragraph size="b3" weight="bold">
              Modify Working schedule
            </Paragraph>
          </div>
        </div>
        <div className="w-full flex justify-between items-start px-6 py-4">
          <div className="w-full xl:w-1/2 flex flex-col space-y-6">
            <Heading variant="h1" size="h9" weight="semiBold">
              Staff Working Schedule
            </Heading>
            <Paragraph size="b4">Update staff working schedule</Paragraph>
          </div>
        </div>
        {loadingStaffData || hoursLoading ? (
          <ViewAppointmentShimmer />
        ) :
          <>
            <div className="w-full p-6 space-y-6">
              {/* busineess option */}
              <CollapseRow title="Business Hours">
                <div className="w-full flex space-x-4">
                  <div
                    className="cursor-pointer"
                    onClick={() => chooseStaffWorkingHours('business')}
                  >
                    <Checkbox
                      isChecked={
                        staffHourWorkScheduleType === 'business' ? true : false
                      }
                    />
                  </div>
                  <div className="flex flex-col space-y-4">
                    <div className="flex flex-col">
                      <Paragraph
                        size="b4"
                        weight="semiBold"
                        color={COLORS.GREY[900]}
                      >
                        Based on business working hours
                      </Paragraph>
                      <Paragraph size="b4" color={COLORS.GREY[300]}>
                        Staff is available during normal business working hours
                      </Paragraph>
                    </div>
                    <div className="w-full flex flex-col space-y-3">
                      {Array?.isArray(businessHours) && businessHours?.length
                        ? businessHours?.map((businessHour, index) => {
                          return (
                            <div
                              className="w-full flex items-center gap-2"
                              key={index}
                            >
                              <div className="w-full flex items-center text-b4 text-grey-400 max-w-[150px] border border-grey-20 p-2 h-[48px] rounded-md capitalize">
                                {businessHour?.day}
                              </div>
                              <span className="text-grey-900 text-b5">from</span>
                              <div className="w-full flex items-center text-b4 text-grey-900 max-w-[110px] border border-grey-20 p-2 h-[48px] rounded-md">
                                {convert24HourTo12Hour(businessHour?.openTime)}
                              </div>
                              <span className="text-grey-900 text-b5">to</span>
                              <div className="w-full flex items-center text-b4 text-grey-900 max-w-[110px] border border-grey-20 p-2 h-[48px] rounded-md">
                                {convert24HourTo12Hour(businessHour?.closeTime)}
                              </div>
                            </div>
                          );
                        })
                        : null}
                    </div>
                  </div>
                </div>
              </CollapseRow>

              {/* custom */}
              <CollapseRow title="Custom Hours">
                <div className="w-full flex space-x-4">
                  <div
                    className="cursor-pointer"
                    onClick={() => chooseStaffWorkingHours('custom')}
                  >
                    <Checkbox
                      isChecked={
                        staffHourWorkScheduleType === 'custom' ? true : false
                      }
                    />
                  </div>
                  <div className="flex flex-col space-y-4">
                    <div className="flex flex-col">
                      <Paragraph
                        size="b4"
                        weight="semiBold"
                        color={COLORS.GREY[900]}
                      >
                        Based on custom working hours
                      </Paragraph>
                      <Paragraph size="b4" color={COLORS.GREY[300]}>
                        Staff is available during these working hours
                      </Paragraph>
                    </div>
                    <div className="w-full flex flex-col space-y-3">
                      {Array?.isArray(customHours) && customHours?.length
                        ? customHours?.map((customHour, index) => {
                          return (
                            <div
                              className="w-full flex items-center gap-2"
                              key={index}
                            >
                              <div className="max-w-[110px] w-full">
                                <SingleSelect
                                  selected={
                                    getDayChoiceFromValue(customHour?.day) || []
                                  }
                                  options={DAY_CHOICES}
                                  setSelected={onChangeDayChoices(index)}
                                  placeholder="Select"
                                  isOptionDisabled={(option) => option.isDisabled}
                                />
                              </div>
                              <span className="text-grey-900 text-b5">from</span>
                              <TimePicker
                                className="splice-time-input w-full max-w-[110px]"
                                use12Hours
                                format="h:mm a"
                                value={dayjs(customHour?.openTime, 'HH:mm')}
                                onChange={handleTimeChange(index, 'openTime')}
                              />
                              <span className="text-grey-900 text-b5">to</span>
                              <TimePicker
                                className="splice-time-input w-full max-w-[110px]"
                                use12Hours
                                format="h:mm a"
                                value={dayjs(customHour?.closeTime, 'HH:mm')}
                                onChange={handleTimeChange(index, 'closeTime')}
                              />
                              <Button
                                variant="icon"
                                size="square"
                                rounded="md"
                                type="button"
                                className="border-0"
                                onClick={() => removeCustomHour(index)}
                              >
                                <SvgClose width="24px" height="24px" />
                              </Button>
                            </div>
                          );
                        })
                        : null}
                    </div>
                    <Button
                      variant="light"
                      size="xs"
                      rounded="lg"
                      type="button"
                      className="w-fit border border-grey-100"
                      onClick={addMoreCustomTime}
                    >
                      Add new time
                      <SvgPlus width="14px" height="14px" />
                    </Button>
                  </div>
                </div>
              </CollapseRow>
            </div>

            <div className="w-full max-w-[850px] fixed bottom-0 bg-white flex justify-between border-t border-grey-100 cursor-pointer">
              <div className="w-full flex justify-end py-4 px-8">
                <div className="flex space-x-2">
                  <Button
                    variant="text"
                    size="sm"
                    type="button"
                    rounded="lg"
                    onClick={() => {
                      closeHoursModal();
                    }}
                    className="w-fit text-red-500"
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    onClick={() => update()}
                    loading={isLoading}
                    disabled={isLoading}
                    variant="primary"
                    className="rounded-lg"
                  >
                    Save
                  </Button>
                </div>
              </div>
            </div>
          </>}
      </div>
    </Modal>
  );
};

export default Staff;