/* eslint-disable @typescript-eslint/no-explicit-any */
import { OutstandingPayments, Payment, PaymentCollectedItem, TransactionSummaryItem } from "core/generated";
import { BookingPackages } from "../../modals/types";
import { TableFilterProps } from "ui/organism/table/types";
import { formatInToNumber, formatInToPrice, getNumberMonthYearAndTimeFromDate } from "../../utils/misc";



export const generatePaymentTransactionsTableData = (sales: Payment[], tableHeadings: TableFilterProps, countryCode?: string) => {
  return sales?.map?.((sale) => {
    const rowData: any = {};
    tableHeadings.forEach((heading) => {
      if (heading.show) {
        switch (heading.value) {
          case 'date':
            rowData[heading.value] = getNumberMonthYearAndTimeFromDate(sale?.createdAt);
            break;
          case 'method':
            rowData[heading.value] = standardizePaymentMethod(sale?.mode)
            break;
          case 'client':
            rowData[heading.value] = `${sale.client?.firstName} ${sale.client?.lastName}`
            break;
          case 'staff':
            rowData[heading.value] = sale?.staff
            break;
          case 'price':
            rowData[heading.value] = formatInToPrice(sale?.amount)
            break;
        }
      }
    });
    return rowData;
  })
}

export const generateTransactionSummaryTableData = (summary: TransactionSummaryItem[], tableHeadings: TableFilterProps) => {
  if (!summary) return [];

  const data = summary?.map?.((sale) => {
    const rowData: any = {};
    tableHeadings.forEach((heading) => {
      if (heading.show) {
        switch (heading.value) {
          case 'item':
            rowData[heading.value] = sale.itemType
            break;
          case 'quantity':
            rowData[heading.value] = formatInToNumber(sale?.salesValue)
            break;
          case 'gross':
            rowData[heading.value] = formatInToPrice(sale?.grossValue)
            break;
        }
      }
    });
    return rowData;
  })

  const total = {
    item: 'Total',
    quantity: formatInToNumber(summary?.reduce((sum, item) => sum + item.salesValue, 0)),
    gross: formatInToPrice(summary?.reduce((sum, item) => sum + item.grossValue, 0)),
  }

  return [...data, total]
}

export const generateOutstandingPaymentsSummaryTableData = (summary: OutstandingPayments[], tableHeadings: TableFilterProps) => {
  if (!summary) return [];

  const data = summary?.map?.((sale) => {
    const rowData: any = {};
    tableHeadings.forEach((heading) => {
      if (heading.show) {
        switch (heading.value) {
          case 'item':
            rowData[heading.value] = sale.itemType
            break;
          case 'quantity':
            rowData[heading.value] = formatInToNumber(sale?.quantity)
            break;
          case 'gross':
            rowData[heading.value] = formatInToPrice(sale?.amountOutstanding)
            break;
        }
      }
    });
    return rowData;
  })

  const total = {
    item: 'Total',
    quantity: formatInToNumber(summary?.reduce((sum, item) => sum + item.quantity, 0)),
    gross: formatInToPrice(summary?.reduce((sum, item) => sum + item.amountOutstanding, 0)),
  }

  return [...data, total]
}

export const generatePaymentCollectedTableData = (summary: PaymentCollectedItem[], tableHeadings: TableFilterProps) => {
  if (!summary) return [];

  const data = summary?.map?.((sale) => {
    const rowData: any = {};
    tableHeadings.forEach((heading) => {
      if (heading.show) {
        switch (heading.value) {
          case 'payment':
            rowData[heading.value] = sale?.paymentType
            break;
          case 'amount':
            rowData[heading.value] = formatInToPrice(sale?.amount)
            break;
          case 'quantity':
            rowData[heading.value] = formatInToNumber(sale?.quantity)
            break;
        }
      }
    });
    return rowData;
  })

  const total = {
    payment: 'Total',
    amount: formatInToPrice(summary?.reduce((sum, item) => sum + item.amount, 0)),
    quantity: formatInToNumber(summary?.reduce((sum, item) => sum + item.quantity, 0)),
  }

  return [...data, total]
}

export const convertPackagesToCommaSeparatedString = (packages: BookingPackages) => {
  // return an array of each package and their quantity e.g [{ packageId: string, quantity: number }]
  return packages?.map?.((packageItem) => {
    return { packageId: packageItem.id, quantity: packageItem.quantity }
  })
}

export const standardizePaymentMethod = (method: string) => {
  // Convert underscores to spaces
  let formattedMethod = method?.replace(/_/g, ' ');

  // Capitalize the first letter of each word
  formattedMethod = formattedMethod?.split(' ').map(word => {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  }).join(' ');

  return formattedMethod;
}

export const getHighestMode = (payments: Payment[]): string | undefined => {
  if (!payments || payments.length === 0) return undefined;

  // Count occurrences of each mode
  const modeCounts = payments?.reduce((acc, payment) => {
    const mode = payment?.mode;
    if (mode) {
      acc[mode] = (acc[mode] || 0) + 1;
    }
    return acc;
  }, {} as Record<string, number>);

  // Find the mode with the highest count
  const highestMode = Object.entries(modeCounts).reduce(
    (highest, [mode, count]) =>
      count > highest?.count ? { mode, count } : highest,
    { mode: undefined, count: 0 } as { mode: string | undefined; count: number }
  );

  return highestMode.mode || '-';
};

export const paymentModeSummary = (payments: Payment[]) => {
  if (!payments || payments.length === 0) return undefined;

  // Sum amounts for each mode
  const modeSums = payments?.reduce((acc, payment) => {
    const mode = payment?.mode;
    const amount = payment?.amount || 0;
    if (mode) {
      acc[mode] = (acc[mode] || 0) + amount;
    }
    return acc;
  }, {} as Record<string, number>);

  return [{
    value: 'cash',
    amount: modeSums?.cash || 0
  }, {
    value: 'pos',
    amount: modeSums?.pos || 0
  }, {
    value: 'gift_card',
    amount: modeSums?.gift_card || 0
  }, {
    value: 'voucher',
    amount: modeSums?.voucher || 0
  }, {
    value: 'bank_transfer',
    amount: modeSums?.bank_transfer || 0
  }, {
    value: 'client_wallet',
    amount: modeSums?.client_wallet || 0
  }];
};