import { PAGE_ROUTES } from "./routes";
import { canPerformAction, canShowPage, forfietRight } from "components/utils/permission";
import HomeIcon from "assets/icons/HomeIcon";
import SvgCalendar from "assets/icons/Calendar";
import SvgSale from "assets/icons/Sale";
import SvgUser from "assets/icons/User";
import SvgUsers from "assets/icons/Users";
import SvgMessageSquare from "assets/icons/MessageSquare";
import SvgPercent from "assets/icons/Percent";
import SvgShoppingBag from "assets/icons/ShoppingBag";
import SvgBarChart2 from "assets/icons/BarChart2";
import SvgCreditCard from "assets/icons/CreditCard";
import SvgSettings from "assets/icons/Settings";
import SvgCustomerSupport from "assets/icons/CustomerSupport";
import SvgLogOut from "assets/icons/LogOut";


export const NAV_BAR_ITEMS = [
  {
    title: "Home",
    icon: HomeIcon,
    slug: "menu-home",
    path: PAGE_ROUTES.HOME,
    show: canPerformAction('Home::ViewPage'),
  },
  {
    title: "Calendar",
    icon: SvgCalendar,
    slug: "menu-calendar",
    path: PAGE_ROUTES.CALENDAR,
    show: canShowPage('Calendar::')
  },
  {
    title: "Sales",
    icon: SvgSale,
    slug: "menu-sales",
    path: PAGE_ROUTES.SALES,
    show: canPerformAction('Sales::ViewAppointmentsList')
  },
  {
    title: "Staff",
    icon: SvgUser,
    slug: "menu-staffs",
    path: PAGE_ROUTES.STAFF,
    show: canShowPage('Staff::')
  },
  {
    title: "Clients",
    icon: SvgUsers,
    slug: "menu-clients",
    path: PAGE_ROUTES.CLIENTS,
    show: canShowPage('Clients::')
  },
  {
    title: "Messaging",
    icon: SvgMessageSquare,
    slug: "menu-message",
    path: PAGE_ROUTES.MESSAGE,
    show: forfietRight('owner') || forfietRight('manager') || forfietRight('receptionist') || canShowPage('Messaging::')
  },
  {
    title: "Rewards",
    icon: SvgPercent,
    slug: "menu-loyalty-and-promos",
    path: PAGE_ROUTES.LOYALTY_AND_PROMOS,
    show: canPerformAction('Promos::ViewPromo')
  },
  {
    title: "Catalogue",
    icon: SvgShoppingBag,
    slug: "menu-inventory",
    path: PAGE_ROUTES.INVENTORY,
    show: canPerformAction('Catalogue::Product::ViewProduct') || canPerformAction('Catalogue::Service::ViewService') || canPerformAction('Catalogue::Packages::ViewPackage')
  },
  {
    title: "Money",
    icon: SvgCreditCard,
    slug: "menu-money",
    path: PAGE_ROUTES.MONEY,
    show: canShowPage("Money::")
  },
  {
    title: "Reports",
    icon: SvgBarChart2,
    slug: "menu-reports",
    path: PAGE_ROUTES.BUSINESS_PERFORMANCE,
    show: canShowPage('Reports::'),
    subs: [
      {
        title: "Business Performance",
        path: PAGE_ROUTES.BUSINESS_PERFORMANCE,
        show: true
      },
      {
        title: "Vouchers",
        path: PAGE_ROUTES.PACKAGES_REPORTS,
        show: true
      },
      {
        title: "Clients",
        path: PAGE_ROUTES.CLIENTS_REPORTS,
        show: true
      },
      {
        title: "Appointments",
        path: PAGE_ROUTES.APPOINTMENT_REPORTS,
        show: true
      },
      {
        title: "Loyalty",
        path: PAGE_ROUTES.LOYALTY_REPORTS,
        show: true
      },
      {
        title: "Tips",
        path: PAGE_ROUTES.TIPS_REPORTS,
        show: true
      },
      {
        title: "Memberships",
        path: PAGE_ROUTES.MEMBERSHIPS_REPORTS,
        show: true
      },
      {
        title: "Staff",
        path: PAGE_ROUTES.STAFF_REPORTS,
        show: true
      },
      {
        title: "Inventory",
        path: PAGE_ROUTES.INVENTORY_REPORTS,
        show: true
      },
      {
        title: "Sales",
        path: PAGE_ROUTES.SALES_REPORTS,
        show: true
      },
    ],
  },
  {
    title: "Settings",
    icon: SvgSettings,
    slug: "menu-settings",
    path: PAGE_ROUTES.SETTINGS,
    show: true
  },
  {
    title: "Support",
    icon: SvgCustomerSupport,
    slug: "menu-support",
    path: PAGE_ROUTES.SUPPORT,
    show: true
  },
  {
    title: "Logout",
    icon: SvgLogOut,
    slug: "menu-logout",
    path: null,
    show: true
  },
];