import { useQuery, gql, useMutation } from "@apollo/client";
import { Appointment, AppointmentPreview, AppointmentService, AppointmentSummary } from "core/generated";

const APPOINTMENTS = gql`
  query Appointments(
    $salonId: ID!
    $startDate: ISO8601Date
    $endDate: ISO8601Date
    $status: AppointmentStatusEnum
  ) {
    appointments(
      salonId: $salonId
      startDate: $startDate
      endDate: $endDate
      status: $status
    ) {
      addOnAmount
      addOnReason
      address
      actualTotalPaid
      appointmentStatus
      appointmentStatusTransitions
      appointmentType
      bookingId
      cancelledAt
      checkedInAt
      client {
        addedById
        address
        amountSpent
        businessId
        createdAt
        deletedAt
        dob
        id
        lastVisit
        pointsEarned
        salonId
        updatedAt
        altCallingCode
        altCountryCode
        altPhone
        callingCode
        countryCode
        email
        firstName
        lastName
        phone
        walletBalance
      }
      clientNoteId
      createdAt
      depositAmount
      outstandingFee
      depositPaymentMethod
      depositPaymentMethodId
      endAt
      id
      isActive
      locationType
      paymentMethod
      paymentMethodId
      processingFeeAmount
      refundReason
      refundedAt
      salonStaff {
        addedById
        businessId
        canLogin
        canServeCustomers
        createdAt
        deletedAt
        id
        level
        role
        salonId
        specialty
        updatedAt
        user {
          altCallingCode
          altCountryCode
          altPhone
          callingCode
          countryCode
          createdAt
          deletedAt
          email
          emailVerified
          firstName
          fullName
          id
          lastLoggedInAt
          lastName
          note
          phone
          phoneVerified
          updatedAt
          userType
        }
      }
      startAt
      taxAmount
      tipAmount
      timezone
      totalAmountPaid
      totalAmountRefunded
      totalDuration
      totalPaid
      totalPoints
      totalPrice
      totalProductsAmount
      totalServicesAmount
      updatedAt
      appointmentProducts {
        appointmentId
        createdAt
        id
        name
        price
        productId
        quantity
        updatedAt
      }
      services {
        name
        price
        id
      }
      appointmentServices {
        appointmentId
        createdAt
        duration
        endAt
        id
        name
        price
        serviceId
        startAt
        updatedAt
        quantity
        appointmentServiceStaff {
          appointmentId
          appointmentServiceId
          createdAt
          id
          salonStaffId
          updatedAt
          salonStaff {
            addedById
            businessId
            canLogin
            canServeCustomers
            createdAt
            deletedAt
            id
            level
            role
            salonId
            specialty
            updatedAt
            user {
              altCallingCode
              altCountryCode
              altPhone
              callingCode
              countryCode
              createdAt
              deletedAt
              email
              emailVerified
              firstName
              fullName
              id
              lastLoggedInAt
              lastName
              note
              phone
              phoneVerified
              updatedAt
              userType
            }
          }
        }
      }
    }
  }
`;

export const useAppointments = ({
  salonId,
  startDate,
  endDate,
  status,
}: {
  salonId: string;
  startDate?: string;
  endDate?: string;
  status?: string;
}) => {
  return useQuery<{
    appointments: Appointment[]
  }>(APPOINTMENTS, {
    variables: {
      salonId,
      startDate,
      endDate,
      status,
    },
    skip: !salonId,
  });
}; 

const APPOINTMENT = gql`
  query Appointment($id: ID!, $salonId: ID!) {
    appointment(id: $id, salonId: $salonId) {
      addOnAmount
      addOnReason
      address
      appointmentStatus
      appointmentStatusTransitions
      appointmentType
      bookingId
      cancelledAt
      checkedInAt
      clientId
      clientNoteId
      createdAt
      depositAmount
      depositPaymentMethod
      depositPaymentMethodId
      discountValue
      discountType
      discountAmount
      actualTotalPaid
      endAt
      id
      isActive
      locationType
      paymentMethod
      paymentMethodId
      processingFeeAmount
      refundReason
      refundedAt
      startAt
      taxAmount
      tipAmount
      depositLink
      timezone
      totalAmountPaid
      totalAmountRefunded
      totalDuration
      totalPaid
      totalPoints
      totalPrice
      totalProductsAmount
      totalServicesAmount
      outstandingFee
      updatedAt
      membership {
        amount
        fee
        name
        rewardPercentage
        services {
          id
          name
        }
      }
      milestone {
        createdAt
        customerPointsAttained
        customerReceives
        customerReceivesType
        id
        loyaltyProgramId
        name
        updatedAt
      }
      appointmentPromo {
        amount
        appointmentId
        createdAt
        id
        promoId
        title
        updatedAt
      }
      appointmentVoucher {
        amount
        appointmentId
        code
        createdAt
        id
        packageVoucher {
          id
          redemptionMode
          package {
            name
          }
          voucherServices {
            serviceId
            isRedeemed
          }
        }
        updatedAt
      }
      clientNote {
        addedBy
        clientId
        createdAt
        deletedAt
        id
        note
        title
        updatedAt
      }
      appointmentProducts {
        appointmentId
        createdAt
        id
        name
        price
        productId
        quantity
        updatedAt
      }
      appointmentServices {
        appointmentId
        createdAt
        duration
        endAt
        id
        name
        price
        quantity
        serviceId
        startAt
        updatedAt
        membershipDiscountAmount
        appointmentServiceStaff {
          appointmentId
          appointmentServiceId
          createdAt
          id
          salonStaffId
          updatedAt
          salonStaff {
            addedById
            businessId
            canLogin
            canServeCustomers
            createdAt
            deletedAt
            id
            level
            role
            salonId
            specialty
            updatedAt
            user {
              altCallingCode
              altCountryCode
              altPhone
              callingCode
              countryCode
              createdAt
              deletedAt
              email
              emailVerified
              firstName
              fullName
              id
              lastLoggedInAt
              lastName
              note
              phone
              phoneVerified
              updatedAt
              userType
            }
          }
        }
      }
      services {
        name
        price
        id
        pricingType
        isDepositRequired
        duration
        serviceStaffs {
          id
          serviceId
          price
          salonStaff {
            id
            role
            specialty
            user {
              fullName
              firstName
              lastName
            }
          }
        }
      }
      products {
        id
        name
        retailPrice
      }
      client {
        addedById
        address
        amountSpent
        businessId
        createdAt
        deletedAt
        dob
        id
        lastVisit
        pointsEarned
        salonId
        updatedAt
        altCallingCode
        altCountryCode
        altPhone
        callingCode
        countryCode
        email
        firstName
        lastName
        phone
        walletBalance
      }
      salonStaff {
        addedById
        businessId
        canLogin
        canServeCustomers
        createdAt
        deletedAt
        id
        level
        role
        salonId
        specialty
        updatedAt
        user {
          altCallingCode
          altCountryCode
          altPhone
          callingCode
          countryCode
          createdAt
          deletedAt
          email
          emailVerified
          firstName
          fullName
          id
          lastLoggedInAt
          lastName
          note
          phone
          phoneVerified
          updatedAt
          userType
        }
      }
      salon {
        business {
          timezone
          country {
            currency {
              code
              id
              name
              symbol
            }
          }
        }
      }
    }
  }
`;

export const useGetAppointment = (id: string, salonId: string) => {
  return useQuery<{
    appointment: Appointment
  }>(APPOINTMENT, {
    variables: {
      id,
      salonId,
    },
    skip: !id || id === null,
  });
};

const APPOINTMENT_PREVIEW = gql`
  mutation AppointmentPreview($input: AppointmentPreviewInput!) {
    appointmentPreview(input: $input) {
      clientMutationId
      data {
        addOnAmount
        depositAmount
        cancellationFeeMessages
        processingFeeAmount
        taxAmount
        totalPaid
        totalPoints
        totalPrice
        totalProductsAmount
        totalServicesAmount
        loyaltyDiscountAmount
        discountAmount
        cancellationFee
        cancellationFeeMessage
        appointmentPromo {
          amount
          title
        }
        feedbackMessages {
          message
          type
        }
      }
      errors {
        message
        property
      }
      status
    }
  }
`;

export const useAppointmentPreview = () => {
  const [appointmentPreview, options] = useMutation<{
    appointmentPreview: {
      data: AppointmentPreview
      status: number
      errors: {
        message: string
        property: string
      }[]
    }
  }>(APPOINTMENT_PREVIEW);

  return {
    appointmentPreview,
    ...options,
  }
};

const ADD_APPOINTMENT = gql`
  mutation CreateAppointment($input: CreateAppointmentInput!) {
    createAppointment(input: $input) {
      clientMutationId
      status
      paymentAuthorization {
        accessCode
        authorizationUrl
        reference
      }
      appointment {
        addOnAmount
        addOnReason
        address
        appointmentStatus
        appointmentStatusTransitions
        appointmentType
        bookingId
        cancelledAt
        checkedInAt
        clientId
        clientNoteId
        createdAt
        actualTotalPaid
        appointmentPromo {
          amount
          id
          title
        }
        membership {
          amount
          fee
          name
          rewardPercentage
          services {
            id
            name
          }
        }
        client {
          addedById
          address
          amountSpent
          businessId
          createdAt
          deletedAt
          dob
          id
          lastVisit
          pointsEarned
          salonId
          updatedAt
          altCallingCode
          altCountryCode
          altPhone
          callingCode
          countryCode
          email
          firstName
          lastName
          phone
        }
        depositAmount
        depositPaymentMethod
        depositPaymentMethodId
        endAt
        id
        isActive
        locationType
        paymentMethod
        paymentMethodId
        processingFeeAmount
        refundReason
        refundedAt
        salonStaff {
          addedById
          businessId
          canLogin
          canServeCustomers
          createdAt
          deletedAt
          id
          level
          role
          specialty
          updatedAt
          user {
            altCallingCode
            altCountryCode
            altPhone
            callingCode
            countryCode
            email
            firstName
            fullName
            id
            lastName
            note
            phone
          }
        }
        startAt
        taxAmount
        timezone
        totalAmountPaid
        totalAmountRefunded
        totalDuration
        totalPaid
        totalPoints
        totalPrice
        totalProductsAmount
        totalServicesAmount
        updatedAt
        appointmentPromo {
          amount
        }
        discountAmount
        discountType
        discountValue
			appointmentServices {
				appointmentId
				createdAt
				duration
				endAt
				id
				name
				price
				serviceId
				startAt
				updatedAt
				appointmentServiceStaff {
					appointmentId
					appointmentServiceId
					createdAt
					id
					salonStaffId
					updatedAt
					salonStaff {
						addedById
						businessId
						canLogin
						canServeCustomers
						createdAt
						deletedAt
						id
						level
						role
						salonId
						specialty
						updatedAt
					}
				}
			}
      }
      errors {
        message
        property
      }
    }
  }
`;

export const useAddAppointment = () => {
  const [createAppointment, options] = useMutation<{
    createAppointment: {
      status: number;
      appointment: Appointment
      paymentAuthorization: {
        accessCode: string;
        authorizationUrl: string;
        reference: string;
      };
      errors: {
        message: string;
        property: string;
      }[];
    }
  }>(ADD_APPOINTMENT);

  return {
    createAppointment,
    ...options,
  }
};

const UPDATE_APPOINTMENT = gql`
  mutation UpdateAppointment($input: UpdateAppointmentInput!) {
    updateAppointment(input: $input) {
      clientMutationId
      status
      appointment {
        addOnAmount
        addOnReason
        address
        appointmentStatus
        appointmentStatusTransitions
        appointmentType
        bookingId
        cancelledAt
        checkedInAt
        clientId
        clientNoteId
        createdAt
        actualTotalPaid
        appointmentPromo {
          amount
          id
          title
        }
        depositAmount
        depositPaymentMethod
        depositPaymentMethodId
        endAt
        id
        isActive
        locationType
        paymentMethod
        paymentMethodId
        processingFeeAmount
        refundReason
        refundedAt
        membership {
          amount
          fee
          name
          rewardPercentage
          services {
            id
            name
          }
        }
        client {
          addedById
          address
          amountSpent
          businessId
          createdAt
          deletedAt
          dob
          id
          lastVisit
          pointsEarned
          salonId
          updatedAt
          altCallingCode
          altCountryCode
          altPhone
          callingCode
          countryCode
          email
          firstName
          lastName
          phone
        }
        salonStaff {
          addedById
          businessId
          canLogin
          canServeCustomers
          createdAt
          deletedAt
          id
          level
          role
          specialty
          updatedAt
          user {
            altCallingCode
            altCountryCode
            altPhone
            callingCode
            countryCode
            email
            firstName
            fullName
            id
            lastName
            note
            phone
          }
        }
        startAt
        taxAmount
        tipAmount
        timezone
        totalAmountPaid
        totalAmountRefunded
        totalDuration
        totalPaid
        totalPoints
        totalPrice
        totalProductsAmount
        totalServicesAmount
        updatedAt
        appointmentPromo {
          amount
        }
        discountAmount
        discountType
        discountValue
			appointmentProducts {
				appointmentId
				createdAt
				id
				name
				price
				productId
				quantity
				updatedAt
			}
			appointmentPromo {
				amount
				appointmentId
				createdAt
				id
				promoId
				title
				updatedAt
			}
			appointmentServices {
				appointmentId
				createdAt
				duration
				endAt
				id
				name
				price
				serviceId
				startAt
				updatedAt
				appointmentServiceStaff {
					appointmentId
					appointmentServiceId
					createdAt
					id
					salonStaffId
					updatedAt
					salonStaff {
						addedById
						businessId
						canLogin
						canServeCustomers
						createdAt
						deletedAt
						id
						level
						role
						salonId
						specialty
						updatedAt
					}
				}
			}
      }
      errors {
        message
        property
      }
    }
  }
`;

export const useUpdateAppointment = () => {
  const [updateAppointment, options] = useMutation<{
    updateAppointment: {
      status: number;
      appointment: Appointment
      errors: {
        message: string;
        property: string;
      }[];
    }
  }>(UPDATE_APPOINTMENT);

  return {
    updateAppointment,
    ...options,
  }
};

const DELETE_APPOINTMENT = gql`
  mutation DeleteAppointment($input: DeleteAppointmentInput!) {
    deleteAppointment(input: $input) {
      clientMutationId
      status
      errors {
        message
        property
      }
    }
  }
`

export const useDeleteAppointment = () => {
  const [deleteAppointment, options] = useMutation<{
    deleteAppointment: {
      status: number;
      errors: {
        message: string;
        property: string;
      }[];
    }
  }>(DELETE_APPOINTMENT);

  return {
    deleteAppointment,
    ...options,
  }
}

const DELETE_APPOINTMENT_MILESTONE = gql`
  mutation DeleteAppointmentMilestone($input: DeleteAppointmentMilestoneInput!) {
    deleteAppointmentMilestone(input: $input) {
      clientMutationId
      status
      errors {
        message
        property
      }
    }
  }
`;

export const useDeleteAppointmentMilestone = () => {
  const [deleteAppointmentMilestone, options] = useMutation<{
    deleteAppointmentMilestone: {
      status: number;
      errors: {
        message: string;
        property: string;
      }[];
    }
  }>(DELETE_APPOINTMENT_MILESTONE);

  return {
    deleteAppointmentMilestone,
    ...options,
  }
};

const EMAIL_RECEIPT = gql`
  mutation EmailAppointmentReceipt($input: EmailAppointmentReceiptInput!) {
    emailAppointmentReceipt(input: $input) {
      clientMutationId
      status
      errors {
        message
        property
      }
    }
  }
`;

export const useEmailAppointmentReceipt = () => {
  const [emailAppointmentReceipt, options] = useMutation<{
    emailAppointmentReceipt: {
      status: number;
      errors: {
        message: string;
        property: string;
      }[];
    }
  }>(EMAIL_RECEIPT);

  return {
    emailAppointmentReceipt,
    ...options,
  }
};

const UPDATE_APPOINTMENT_STATUS = gql`
  mutation UpdateAppointmentStatus($input: UpdateAppointmentStatusInput!) {
    updateAppointmentStatus(input: $input) {
      clientMutationId
      status
      errors {
        message
        property
      }
    }
  }
`;

export const useUpdateAppointmentStatus = () => {
  const [updateAppointmentStatus, options] = useMutation<{
    updateAppointmentStatus: {
      status: number;
      errors: {
        message: string;
        property: string;
      }[];
    }
  }>(UPDATE_APPOINTMENT_STATUS);

  return {
    updateAppointmentStatus,
    ...options,
  }
};

const REVERT_APPOINTMENT_CANCELLATION = gql`
    mutation RevertAppointmentCancellation($input: RevertAppointmentCancellationInput!) {
    revertAppointmentCancellation(input: $input) {
      status
      errors {
        message
        property
      }
      appointment {
        id
        appointmentStatus
      }
    }
  }
`;

export const useRevertAppointmentCancellation = () => {
  const [revertAppointmentCancellation, options] = useMutation<{
    revertAppointmentCancellation: {
      status: number;
      appointment: Appointment
      errors: {
        message: string;
        property: string;
      }[];
    }
  }>(REVERT_APPOINTMENT_CANCELLATION);

  return {
    revertAppointmentCancellation,
    ...options,
  }
};

const UPDATE_APPOINTMENT_SERVICE = gql`
  mutation UpdateAppointmentService($input: UpdateAppointmentServiceInput!) {
    updateAppointmentService(input: $input) {
      clientMutationId
      status
      errors {
        message
        property
      }
      appointmentService {
        id
        name
        startAt
      }
    }
  }
`;

export const useUpdateAppointmentService = () => {
  const [updateAppointmentService, options] = useMutation<{
    updateAppointmentService: {
      status: number;
      appointmentService: AppointmentService
      errors: {
        message: string;
        property: string;
      }[];
    }
  }>(UPDATE_APPOINTMENT_SERVICE);

  return {
    updateAppointmentService,
    ...options,
  }
};

const APPOINTMENT_SUMMARY = gql`
  query AppointmentSummary($bookingId: ID!) {
    appointmentSummary(bookingId: $bookingId) {
      appointment {
        addOnAmount
        addOnReason
        address
        appointmentStatus
        appointmentStatusTransitions
        appointmentType
        bookingId
        cancelledAt
        checkedInAt
        clientId
        clientNoteId
        createdAt
        depositAmount
        depositPaymentMethod
        depositPaymentMethodId
        endAt
        id
        isActive
        locationType
        paymentMethod
        paymentMethodId
        processingFeeAmount
        refundReason
        refundedAt
        startAt
        taxAmount
        tipAmount
        timezone
        totalAmountPaid
        totalAmountRefunded
        totalDuration
        totalPaid
        totalPoints
        totalPrice
        totalProductsAmount
        totalServicesAmount
        updatedAt
        appointmentProducts {
          appointmentId
          createdAt
          id
          name
          price
          productId
          quantity
          updatedAt
        }
        appointmentServices {
          appointmentId
          createdAt
          duration
          endAt
          id
          name
          price
          serviceId
          startAt
          updatedAt
          appointmentServiceStaff {
            salonStaff {
              id
              user {
                firstName
                fullName
                lastName
              }
            }
          }
        }
        client {
          addedById
          address
          amountSpent
          businessId
          createdAt
          deletedAt
          dob
          id
          lastVisit
          pointsEarned
          salonId
          updatedAt
          altCallingCode
          altCountryCode
          altPhone
          callingCode
          countryCode
          email
          firstName
          lastName
          phone
        }
        reviews {
          appointmentId
          client {
            id
          }
          createdAt
          id
          message
          salonId
          stars
          updatedAt
        }
        salon {
          address
          bearsProcessingFee
          branchName
          callingCode
          city
          countryCode
          createdAt
          createdById
          deletedAt
          depositLinkValidityHours
          depositType
          depositValue
          email
          id
          intervalMinutes
          isDepositRequired
          isTaxVisible
          locationType
          onboardingStatus
          phone
          state
          status
          taxInclusivePricing
          updatedAt
          business {
            about
            acceptsOnlineBookings
            addedById
            bookingManagement
            bookingUrlIdentifier
            bvn
            callingCode
            cancellationPolicy
            countryCode
            createdAt
            deletedAt
            headerImageUrl
            id
            isAccountSetupComplete
            isAppointmentLinkSetup
            isBvnVerified
            isDeleted
            logoUrl
            name
            note
            ownerId
            phone
            rcNumber
            slug
            teamSize
            updatedAt
            country {
              code
              collectionsSupported
              countryCode
              emojiFlag
              id
              name
              payoutsSupported
              timezone
              utcOffset
              currency {
                code
                id
                name
                symbol
              }
            }
          }
        }
        clientNote {
          addedBy
          clientId
          createdAt
          deletedAt
          id
          note
          title
          updatedAt
        }
      }
      paymentAuthorization {
        accessCode
        authorizationUrl
        reference
      }
    }
  }
`;

export const useAppointmentSummary = (bookingId: string) => {
  return useQuery<{
    appointmentSummary: AppointmentSummary
  }>(APPOINTMENT_SUMMARY, {
    variables: {
      bookingId,
    },
    skip: !bookingId,
  })
}

const CREATE_REVIEW = gql`
  mutation CreateReview($input: CreateReviewInput!) {
    createReview(input: $input) {
      errors {
        message
        property
      }
      status
    }
  }
`

export const useCreateReview = () => {
  const [createReview, options] = useMutation<{
    createReview: {
      errors: {
        message: string;
        property: string;
      }[];
      status: number;
    }
  }>(CREATE_REVIEW);

  return {
    createReview,
    ...options,
  }
}

const CANCELLED_APPOINTMENT_SUMMARY = gql`
query CancelledAppointmentSummary($appointmentId: ID!) {
  cancelledAppointmentSummary(appointmentId: $appointmentId) {
    appointment {
      addOnAmount
      addOnReason
      outstandingFee
      address
      appointmentStatus
      appointmentStatusTransitions
      appointmentType
      bookingId
      cancelledAt
      checkedInAt
      clientId
      clientNoteId
      createdAt
      depositAmount
      depositPaymentMethod
      depositPaymentMethodId
      endAt
      id
      isActive
      locationType
      paymentMethod
      paymentMethodId
      processingFeeAmount
      refundReason
      refundedAt
      startAt
      taxAmount
      timezone
      totalAmountPaid
      totalAmountRefunded
      totalDuration
      totalPaid
      totalPoints
      totalPrice
      totalProductsAmount
      totalServicesAmount
      updatedAt
      appointmentProducts {
        appointmentId
        createdAt
        id
        name
        price
        productId
        quantity
        updatedAt
      }
      appointmentServices {
        appointmentId
        createdAt
        duration
        endAt
        id
        name
        price
        serviceId
        startAt
        updatedAt
        appointmentServiceStaff {
          salonStaff {
            user {
              firstName
              fullName
              lastName
            }
          }
        }
      }
      client {
        addedById
        address
        amountSpent
        businessId
        createdAt
        deletedAt
        dob
        id
        lastVisit
        pointsEarned
        salonId
        updatedAt
        altCallingCode
        altCountryCode
        altPhone
        callingCode
        countryCode
        email
        firstName
        lastName
        phone
      }
      reviews {
        appointmentId
        client {
          id
        }
        createdAt
        id
        message
        salonId
        stars
        updatedAt
      }
      salon {
        address
        bearsProcessingFee
        branchName
        callingCode
        city
        countryCode
        createdAt
        createdById
        deletedAt
        depositLinkValidityHours
        depositType
        depositValue
        email
        id
        intervalMinutes
        isDepositRequired
        isTaxVisible
        locationType
        onboardingStatus
        phone
        state
        status
        taxInclusivePricing
        updatedAt
        business {
          about
          acceptsOnlineBookings
          addedById
          bookingManagement
          bookingUrlIdentifier
          bvn
          callingCode
          cancellationPolicy
          countryCode
          createdAt
          deletedAt
          headerImageUrl
          id
          isAccountSetupComplete
          isAppointmentLinkSetup
          isBvnVerified
          isDeleted
          logoUrl
          name
          note
          ownerId
          phone
          rcNumber
          slug
          teamSize
          updatedAt
        }
      }
      clientNote {
        addedBy
        clientId
        createdAt
        deletedAt
        id
        note
        title
        updatedAt
      }
    }
    paymentAuthorization {
      accessCode
      authorizationUrl
      reference
    }
    message
    cancellationFee
  }
}
`;

export const useCancelledAppointmentSummary = (appointmentId: string) => {
  return useQuery<{
    cancelledAppointmentSummary: AppointmentSummary
  }>(CANCELLED_APPOINTMENT_SUMMARY, {
    variables: {
      appointmentId,
    },
    skip: !appointmentId,
  })
}