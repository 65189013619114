import React from "react";
import { RadioButtonProps } from "./types";

const RadioButton = ({ isChecked, disabled }: RadioButtonProps) => {
  const radioClass =
    "w-[16px] h-[16px] flex justify-center items-center rounded-full border w-[16px] h-[16px] flex justify-center items-center rounded-full border";

  return (
    <label className={`flex items-center ${disabled ? "opacity-50 cursor-not-allowed" : "cursor-pointer"} text-b5 text-grey-900 space-x-2 relative`}>
      {isChecked ? (
        <span className={`${radioClass} shadow-md border-green-300`}>
          <span className="w-[10px] h-[10px] bg-green-300 rounded-full"></span>
        </span>
      ) : (
        <span className={`${radioClass} border-grey-15`}></span>
      )}
    </label>
  );
};

export default RadioButton;
