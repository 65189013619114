import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgAppointmentDeleete = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <rect width={48} height={48} rx={4} fill="#FFFCFC" />
    <rect
      x={0.5}
      y={0.5}
      width={47}
      height={47}
      rx={3.5}
      stroke="#E23C51"
      strokeOpacity={0.05}
    />
    <path
      d="M22.125 16.875v.375h3.75v-.375a1.875 1.875 0 1 0-3.75 0Zm-1.5.375v-.375a3.375 3.375 0 0 1 6.75 0v.375H33a.75.75 0 1 1 0 1.5h-1.131L30.45 31.176a3.75 3.75 0 0 1-3.727 3.324h-5.445a3.75 3.75 0 0 1-3.728-3.324L16.131 18.75H15a.75.75 0 1 1 0-1.5h5.625Zm-1.584 13.755A2.25 2.25 0 0 0 21.276 33h5.447a2.25 2.25 0 0 0 2.235-1.995L30.36 18.75H17.64l1.401 12.255Zm2.709-9.63a.75.75 0 0 1 .75.75v7.5a.75.75 0 1 1-1.5 0v-7.5a.75.75 0 0 1 .75-.75Zm5.25.75a.75.75 0 1 0-1.5 0v7.5a.75.75 0 1 0 1.5 0v-7.5Z"
      fill="#E23C51"
    />
  </svg>
);
export default SvgAppointmentDeleete;
