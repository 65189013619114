import React, { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import axios from 'axios';
import { HoursFormInput, HoursWeekInput, TimeBufferIntervalInput } from './types';
import { TimePicker } from 'antd';
import dayjs from 'dayjs';
import { API_ERRORS, ERRORS } from 'constants/errors';
import Button from 'ui/atoms/button/Button';
import { DAYS, SALON_INTERVAL } from 'constants/information';
import SelectInput from 'ui/molecules/input/SelectInput';
import Paragraph from 'ui/atoms/paragraph/Paragraph';
import { COLORS } from 'constants/colors';
import { convert12HourTo24Hour, limitString } from '../utils/misc';
import Checkbox from 'ui/atoms/checkbox/Checkbox';
import ToastWrapper from 'ui/molecules/toastWrapper/ToastWrapper';
import { ToastProps } from 'ui/atoms/toast/types';
import { Modal } from 'ui/templates/modal/Modal';
import { useToast } from 'hooks/useToast';
import { useSalonCache } from 'hooks/useSalonCache';
import { useModal } from 'hooks/useModal';
import { convertSchedule, setDefaultCloseTimeForWeekDays, setDefaultOpenTimeForWeekDays, setTrueForWeekDays } from './utils';
import { Salon, SalonHour, SalonHoursDocument, UpdateSalonDocument, UpdateSalonHourDocument, User } from 'core/generated';
import { useSalon, useSalonHours, useUpdateSalon, useUpdateSalonHours } from 'api/useSalon';
import { SvgCircleGreenAddition, SvgClose } from 'ui';

const BusinessHoursForm = ({
  salonId,
  onNextPage,
  onPrevPage,
  buttonText,
  accountSetup
}: {
  businessId?: string | null;
  salonId: string;
  onNextPage?: () => void;
  onPrevPage?: () => void;
  buttonText?: string;
  accountSetup?: boolean
}) => {
  const { addToast, toast } = useToast();
  const { control, handleSubmit } = useForm<HoursFormInput>();
  const [formValues, setFormValues] = useState<HoursFormInput>({});
  const { getSalonData, setSalonData } = useSalonCache();
  const salon = getSalonData() as Salon;
  const [timeInterval, setTimeInterval] = useState<number | null>(salon?.intervalMinutes || null);
  const {
    control: timeBufferControl,
    handleSubmit: timeBufferHandleSubmit,
    formState: { errors: intervalErrors },
    watch: timeBufferWatch
  } = useForm<TimeBufferIntervalInput>({
    defaultValues: {
      interval: salon?.intervalMinutes
    }
  });

  const [userData, setUser] = useState<User>(
    JSON.parse(localStorage.getItem('userData')) as User
  )

  const { loading: isLoading, updateSalonHour } = useUpdateSalonHours()

  const {
    data,
    loading,
    refetch
  } = useSalonHours(salonId)

  useEffect(() => {
    if (salonId) {
      refetch()
    }
  }, [salonId, refetch])

  const {
    loading: bufferIsLoading,
    updateSalon,
  } = useUpdateSalon()

  const daysOrder = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ];

  const salonHours = useMemo(() => {
    if (Array.isArray(data?.salonHours) && data.salonHours.length) {
      const defaultValues: HoursFormInput = {};
      const hours = data?.salonHours || []
      const sortedData = [...hours]?.sort((a, b) => {
        // Compare days based on order
        const dayDiff = daysOrder.indexOf(a.day) - daysOrder.indexOf(b.day);
        if (dayDiff !== 0) return dayDiff;
  
        // Compare openTime within the same day, nulls last
        if (!a.openTime && b.openTime) return 1;
        if (a.openTime && !b.openTime) return -1;
        if (!a.openTime && !b.openTime) return 0;
  
        return a.openTime.localeCompare(b.openTime);
      });
      // Use data.salonHours directly in the forEach loop
      sortedData.forEach(({ day, openTime, closeTime }) => {
        if (!defaultValues[day]) {
          defaultValues[day] = {
            isAvailable: !!(openTime && closeTime),
            times: []
          };
        }
        defaultValues[day].times.push({
          openTime: openTime || setDefaultOpenTimeForWeekDays(day),
          closeTime: closeTime || setDefaultCloseTimeForWeekDays(day)
        });
      });

      setFormValues(defaultValues);
    }

    // Return data.salonHours or an empty array if not available
    return data?.salonHours || [];
  }, [accountSetup, data]);

  const {
    isVisible,
    openModal,
    closeModal,
  } = useModal();

  const onSubmitData = async () => {
    const updatedHours = convertSchedule(formValues, salonHours);
    if (updatedHours?.length === 0) {
      addToast({
        variant: 'error',
        message: 'Please select at least one day',
      })
      return;
    }
    // filter through updatedHours
    const filteredHours = updatedHours.filter((day) => day?.openTime && day?.closeTime);
    if (filteredHours?.length === 0) {
      addToast({
        variant: 'error',
        message: 'Please select open and close time for at least one day',
      })
      return;
    }
    updateSalonHour({
      variables: { input: { salonHours: updatedHours, salonId } },
    }).then(({ data }) => {
      const { updateSalonHour } = data;
      if (updateSalonHour?.status === 200) {
        refetch()
        onNextPage ? onNextPage() :
          addToast({
            variant: 'success',
            message: 'Business hours updated successfully',
          })
      }

      if (updateSalonHour?.errors) {
        const message = updateSalonHour?.errors[0]?.message || API_ERRORS.BUSINESS_HOURS_FAILED;
        addToast({
          variant: 'error',
          message,
        })
      }
    }).catch(createHoursError => {
      if (axios.isAxiosError(createHoursError)) {
        const message = createHoursError?.response?.data?.message || API_ERRORS.BUSINESS_HOURS_FAILED;
        addToast({
          variant: 'error',
          message,
        })
      }
    })
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleCheckboxChange = (day: string) => (_e: ChangeEvent<HTMLInputElement>) => {
    const dayValue = formValues[day];
    const updatedFormValues = {
      ...formValues,
      [day]: {
        isAvailable: !dayValue?.isAvailable,
        times: [{
          openTime: dayValue?.isAvailable ? "" : "08:00",
          closeTime: dayValue?.isAvailable ? "" : "20:00",
        }]
      },
    };
    setFormValues(updatedFormValues);
  };

  // const handleInputChange = (day: string, fieldName: "openTime" | "closeTime") => (
  //   e: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   const { value } = e.target;
  //   setFormValues((prevValues) => ({
  //     ...prevValues,
  //     [day]: {
  //       ...prevValues[day],
  //       [fieldName]: value,
  //     },
  //   }));
  // };
  const handleTimeChange = (
    day: string,
    index: number,
    fieldName: "openTime" | "closeTime"
  ) => (value: any, dateString: string) => {
    if (dateString) {
      const timeString = convert12HourTo24Hour(dateString);

      setFormValues((prevValues) => {
        // Get the current times array for the day
        const currentTimes = [...(prevValues[day]?.times || [])];

        // Update the specific time slot
        currentTimes[index] = {
          ...currentTimes[index],
          [fieldName]: timeString,
        };

        // Return the updated form values
        return {
          ...prevValues,
          [day]: {
            ...prevValues[day],
            times: currentTimes,
          },
        };
      });
    }
  };

  const timeBufferSubmit = async (input: TimeBufferIntervalInput) => {
    if (!salon) return;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { createdAt, updatedAt, deletedAt, clients, salonStaffs, products, services, onboardingStatus, createdById, business, wallet, totalNumberOfClients, __typename, ...rest } = salon
    updateSalon({
      variables: { input: { ...rest, logoUrl: userData.business.logoUrl, intervalMinutes: Number(input.interval) } }
    }).then(({ data }) => {
      if (data?.updateSalon?.status === 200) {
        const newInterval = Number(input?.interval);
        setTimeInterval(newInterval);
        const updatedSalon = { ...salon, intervalMinutes: newInterval };
        setSalonData(updatedSalon);
        closeModal();
      }

      if (data?.updateSalon?.errors) {
        const message = data?.updateSalon?.errors[0]?.message || API_ERRORS.BUSINESS_TIME_BUFFER_FAILED;
        addToast({
          variant: 'error',
          message,
        })
      }
    }).catch(bufferTimeError => {
      if (axios.isAxiosError(bufferTimeError)) {
        const message = bufferTimeError?.response?.data?.message || API_ERRORS.BUSINESS_TIME_BUFFER_FAILED;
        addToast({
          variant: 'error',
          message,
        })
      }
    })
  }

  const addMoreTimeSlot = (day?: string) => {
    if (!day) return; // Guard clause for undefined day

    const dayValue: HoursWeekInput = formValues[day] || {
      isAvailable: true,
      times: []
    };

    const updatedFormValues: HoursFormInput = {
      ...formValues,
      [day]: {
        ...dayValue,
        times: [...(dayValue.times || []), { openTime: '08:00', closeTime: '15:00' }]
      },
    };

    setFormValues(updatedFormValues);
  }

  const removeTimeSlot = (day?: string, index?: number) => {
    if (!day || index === undefined) return; // Guard clause for undefined day or index

    const dayValue: HoursWeekInput = formValues[day] || {
      isAvailable: true,
      times: []
    };

    const updatedTimes = [...(dayValue.times || [])];
    updatedTimes.splice(index, 1);

    const updatedFormValues: HoursFormInput = {
      ...formValues,
      [day]: {
        ...dayValue,
        times: updatedTimes
      },
    };

    setFormValues(updatedFormValues);
  }

  return (
    <>
      <ToastWrapper toast={toast as ToastProps} />
      <form onSubmit={handleSubmit(onSubmitData)} className="w-full h-full space-y-6 pt-6">
        <div className="w-full flex flex-col space-y-4">
          {DAYS.map((day) => (
            <div className="w-full flex items-start" key={day}>
              <div className="relative w-1/5 flex items-center cursor-pointer text-b5 text-grey-900 space-x-2">
                <Checkbox
                  isChecked={formValues[day]?.isAvailable || false}
                />
                <input
                  type="checkbox"
                  onChange={handleCheckboxChange(day)}
                  className="absolute left-0 w-[24px] h-[24px] opacity-0 cursor-pointer"
                />
                <span className="text-grey-900 font-semibold uppercase whitespace-nowrap">
                  {limitString(day, 3, false)}
                </span>
              </div>
              <div className="w-4/5 flex space-y-2">
                {!formValues[day]?.isAvailable ? (
                  <span className='text-b4 text-grey-400'>
                    Unavailable
                  </span>
                ) : (
                  <div className='w-full flex items-start'>
                    <div className='flex flex-col space-y-2'>
                      {Array?.isArray(formValues[day]?.times) && formValues[day]?.times?.length ? formValues[day]?.times?.map((time, index) => {
                        return (
                          <div className="w-fit flex items-center" key={index}>
                            <div className="w-fit flex items-center gap-x-4">
                              <Controller
                                control={control}
                                name={`${day}.times.${index}.openTime`}
                                render={({
                                  field,
                                }) => {
                                  return (
                                    <div className="flex flex-col space-y-2">
                                      <TimePicker className="splice-time-input" use12Hours format="h:mm a" value={dayjs(field.value || time?.openTime, 'HH:mm')} onChange={
                                        handleTimeChange(day, index, 'openTime')} />
                                    </div>
                                  );
                                }}
                              />
                              <span className='text-b5 text-grey-900'>to</span>
                              <Controller
                                control={control}
                                name={`${day}.times.${index}.closeTime`}
                                render={({
                                  field,
                                }) => {
                                  return (
                                    <div className="flex flex-col space-y-2">
                                      <TimePicker className="splice-time-input" use12Hours format="h:mm a" value={dayjs(field.value || time?.closeTime, 'HH:mm')} onChange={
                                        handleTimeChange(day, index, 'closeTime')} />
                                    </div>
                                  );
                                }}
                              />
                            </div>
                            {Array?.isArray(formValues[day]?.times) && formValues[day]?.times?.length > 1 ? (
                              <Button
                                variant='icon'
                                size='md'
                                className='border-0'
                                type='button'
                                onClick={() => removeTimeSlot(day, index)}
                              >
                                <SvgClose width="20px" height="20px" />
                              </Button>
                            ) : null}
                          </div>
                        )
                      }) : null}
                    </div>
                    {Array?.isArray(formValues[day]?.times) && formValues[day]?.times?.length < 2 ? (
                      <Button
                        variant='icon'
                        size='md'
                        className='border-0'
                        type='button'
                        onClick={() => addMoreTimeSlot(day)}
                      >
                        <SvgCircleGreenAddition width="20px" height="20px" />
                      </Button>
                    ) : null}
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
        <div className='w-full flex justify-center space-x-2'>
          {timeInterval ? (
            <Paragraph size="b5" weight='normal' className='' color={COLORS.BLACK}>
              Time Interval: {timeInterval && `${timeBufferWatch('interval')} mins`} between appointments
            </Paragraph>
          ) : null}
          <Button
            variant='text'
            disabled={false}
            loading={false}
            size='none'
            fontSize='b5'
            fontWeight='bold'
            className={timeInterval ? 'mx-0' : ''}
            type='button'
            onClick={openModal}
          >
            {timeInterval ? "Edit" : "Set Time Interval"}
          </Button>
        </div>
        <div className='w-full flex space-x-2'>
          {onPrevPage && (
            <Button
              variant='secondary'
              className=''
              disabled={false}
              onClick={onPrevPage}
              size='lg'
              rounded='lg'
              type='button'
            >
              Prev
            </Button>
          )}
          <Button
            variant='primary'
            className=''
            size='lg'
            rounded='lg'
            disabled={isLoading}
            loading={isLoading}
          >
            {buttonText || "Next"}
          </Button>
        </div>
      </form>
      <Modal
        title="Time interval"
        subTitle="This is the time in between appointments"
        show={isVisible}
        closeModal={closeModal}
      >
        <form onSubmit={timeBufferHandleSubmit(timeBufferSubmit)} className="w-full space-y-12 mt-12" autoComplete='off'>
          <div className='w-full max-w-[300px] mx-auto'>
            <SelectInput name="interval" id="interval" label="Select interval" control={timeBufferControl} rules={{
              required: ERRORS.INTERVAL_REQUIRED,
            }} value={timeBufferWatch('interval')} error={intervalErrors.interval} options={SALON_INTERVAL} placeholder="Select Interval" />
          </div>
          <div className='space-y-6'>
            <Button
              variant='primary'
              className=''
              size='lg'
              rounded='lg'
              disabled={bufferIsLoading}
              loading={bufferIsLoading}
            >
              Save
            </Button>
            <Button
              variant='text'
              className='mx-auto text-red-500'
              size='none'
              onClick={closeModal}
            >
              Cancel
            </Button>
          </div>
        </form>
      </Modal>
    </>
  )
}

export default BusinessHoursForm