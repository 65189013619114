import { ClientMembershipProps, MembershipProps } from 'api/types/general'
import { useActiveClientMemberships, useMergedClientMemberships } from 'api/useLoyaltyAndPromos'
import { MEMBERSHIP_HISTORY, MEMBERSHIP_HISTORY_MOBILE } from 'components/uicomponents/customerDasboard/constants'
import ExportDataToCsvOrXlsx from 'components/uicomponents/ExportDataToCsvOrXlsx'
import { createFileNameFromDates, formatDateToOriginalDate, formatInToPrice, formatTableHeadersFilterArray, getDefaultQueryDates, getNumberMonthAndYearFromDate, handleDownload, handleExcelDownload } from 'components/utils/misc'
import { COLORS } from 'constants/colors'
import { Client, SalonStaff, Service } from 'core/generated'
import { useModal, useSalonCache } from 'hooks'
import React, { useEffect, useMemo, useState } from 'react'
import { Button, CalendarPicker, Heading, Layout, Modal, Paginator, Paragraph, Pill, SvgArrowBack, SvgCalendar, SvgChevronLeft, SvgLocationPin, SvgMember, SvgTips, SvgUser, Tabs } from 'ui'
import ContentLoader from 'ui/atoms/contentLoader/ContentLoader'
import FullTable from 'ui/organism/table/Table'

const MEMBERSHIPS_REPORTS_TAB_NAME = {
  MEMBERSHIP_OVERVIEW: 'Memberships Overview',
  MEMEBRSHIP_SALES: 'Memberships sales',
} as const

const MembershipsReports = () => {
  const MEMBERSHIP_REPORTS_TABS = [
    {
      title: MEMBERSHIPS_REPORTS_TAB_NAME.MEMBERSHIP_OVERVIEW,
      key: MEMBERSHIPS_REPORTS_TAB_NAME.MEMBERSHIP_OVERVIEW,
      show: true
    },
    {
      title: MEMBERSHIPS_REPORTS_TAB_NAME.MEMEBRSHIP_SALES,
      key: MEMBERSHIPS_REPORTS_TAB_NAME.MEMEBRSHIP_SALES,
      show: true
    },
  ]

  const [activeTab, setActiveTab] = useState<string>(
    MEMBERSHIP_REPORTS_TABS.filter((tab) => tab.show)[0]?.key
  )

  const getTabView = () => {
    switch (activeTab) {
      case MEMBERSHIPS_REPORTS_TAB_NAME.MEMBERSHIP_OVERVIEW:
        return (
          <MembershipOverviewReports />
        )
      case MEMBERSHIPS_REPORTS_TAB_NAME.MEMEBRSHIP_SALES:
        return (
          <MembershipSalesReports />
        )
    }
  }

  return (
    <Layout pageTitle={activeTab} pageLevel={3}>
      <Tabs
        tabs={MEMBERSHIP_REPORTS_TABS}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      <div className='w-full pb-20'>{getTabView()}</div>
    </Layout>
  )
}

const MembershipOverviewReports = () => {
  const { getSalonFieldValue } = useSalonCache()
  const salonId = getSalonFieldValue('id')
  const [selectedDates, setSelectedDates] = useState<[Date, Date] | null>(
    getDefaultQueryDates()
  )
  const [membership, setMembership] = useState<MembershipProps | null>(null)
  const {
    isVisible: isViewMembershipModalVisible,
    closeModal: closeViewMembershipModal,
    openModal: openViewMembershipModal
  } = useModal()
  const [before, setBefore] = useState<string | null>(null)
  const [after, setAfter] = useState<string | null>(null)

  const startDate = formatDateToOriginalDate(selectedDates[0], "start")
  const endDate = formatDateToOriginalDate(selectedDates[1], "end")

  const {
    data,
    loading: isLoading,
    refetch
  } = useMergedClientMemberships({
    startDate,
    endDate
  })

  // const memberships = useMemo(() => {
  //   if (data) {
  //     return data?.allClientMemberships
  //   }
  //   return []
  // }, [data])
  const memberships = useMemo(() => {
    if (data) {
      return data?.activeMemberships
    }
    return []
  }, [data])
  const purchases = useMemo(() => {
    if (data) {
      return data?.membershipPurchases
    }
    return []
  }, [data])
  const topPurchaseMembership = useMemo(() => {
    if (purchases?.length) {
      return purchases[0]
    }
  }, [purchases])
  const activeClientMembershipsCount = useMemo(() => {
    if (data) {
      return data?.activeClientMemberships?.length
    }
    return 0
  }, [data])
  const expiredClientMembershipsCount = useMemo(() => {
    if (data) {
      return data?.expiredClientMemberships?.length
    }
    return 0
  }, [data])
  const totalSoldValue = useMemo(() => {
    if (data) {
      return data?.activeClientMemberships?.reduce((acc, item) => acc + item?.membership?.fee, 0)
    }
    return 0
  }, [data])

  useEffect(() => {
    if (salonId) {
      refetch()
    }
  }, [refetch, salonId, selectedDates])

  const filename = `${createFileNameFromDates(selectedDates || getDefaultQueryDates())}_tips_report`;
  const formatMembershipsData = (data: MembershipProps[]) => {
    const tableData = data?.map((item) => {
      return {
        'Name': item?.name,
        'Amount': formatInToPrice(item?.fee),
        'Reward': item?.rewardPercentage + ' %',
        'Services': item && item?.services?.length ? item?.services?.map((service) => service?.name).join(', ') : '-',
        'Valid For': item?.validityPeriod + ' Month(s)',
        'Clients': item && item?.clients?.length ? item?.clients?.map((client) => client?.firstName).join(', ') : '-',
      }
    })
    return tableData
  }
  const _handleDownload = () => {
    if (memberships) {
      handleDownload(formatMembershipsData(memberships), filename)
    }
  }

  const downloadExcel = () => {
    if (memberships) {
      handleExcelDownload(formatMembershipsData(memberships), filename, 'Tips')
    }
  }

  const goToNext = () => {
    // okay
  }

  const goToPrev = () => {
    // cool
  }

  const MEMBERSHIPS_HEADINGS = [
    {
      label: 'Membership name',
      value: 'name',
      show: true
    },
    {
      label: 'Amount',
      value: 'amount',
      show: true
    },
    {
      label: 'Reward',
      value: 'reward',
      show: true
    },
    {
      label: 'Applicable services',
      value: 'services',
      show: true
    },
    {
      label: 'Valid For',
      value: 'validityPeriod',
      show: true
    },
    {
      label: 'Clients applied to',
      value: 'clients',
      show: true
    }
  ]

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [tipsReceivedHeadings, setTipsReceivedHeadings] = useState(MEMBERSHIPS_HEADINGS)

  const generateMembershipTableData = (data: MembershipProps[]) => {
    const tableData = data?.map((item) => {
      return {
        name: item?.name,
        amount: formatInToPrice(item?.fee),
        reward: item?.rewardPercentage + ' %',
        services: item && item?.services?.length ? item?.services?.map((service) => service?.name).join(', ') : '-',
        validityPeriod: item?.validityPeriod + ' Month(s)',
        clients: item && item?.clients?.length ? item?.clients?.map((client) => client?.firstName).join(', ') : '-',
      }
    })
    return tableData
  }

  const rows = generateMembershipTableData(memberships)

  const openMembershipModal = (id: number) => {
    if (!Array?.isArray(memberships)) return;
    const membership = memberships[id];
    if (!membership) return;
    setMembership(membership);
    openViewMembershipModal()
  }

  const closeModal = () => {
    setMembership(null)
    closeViewMembershipModal()
  }
  return (
    <>
      {isLoading ? <ContentLoader /> : null}
      <div className='w-full flex flex-col xl:flex-row justify-between lg:items-center p-2 md:p-3 lg:p-6 gap-2'>
        <div className='flex flex-col md:flex-row xl:flex-col gap-3'>
          <Heading size='h9' variant='h1' weight='bold'>
            Memberships Overview
          </Heading>
          <div className='flex space-x-1 items-center'>
            <Paragraph size='b4' color={COLORS.GREY[300]} className='flex space-x-1 items-center'>
              <SvgCalendar width="16px" height="16px" />
              <span>{`${getNumberMonthAndYearFromDate(selectedDates[0])} - ${getNumberMonthAndYearFromDate(selectedDates[1])}`}</span>
            </Paragraph>
            <span>|</span>
            <Paragraph size='b4' color={COLORS.GREY[300]} className='flex space-x-1 items-center'>
              <SvgLocationPin width="16px" height="16px" />
              <span>{getSalonFieldValue('branchName')}</span>
            </Paragraph>
          </div>
        </div>
        <div className='flex flex-col md:flex-row md:items-center gap-4 md:gap-2'>
          <Paragraph size="b4" className='hidden md:flex'>Showing:</Paragraph>
          <CalendarPicker {...{ selectedDates, setSelectedDates }} />
          <ExportDataToCsvOrXlsx downloadCsv={_handleDownload} downloadXlsx={downloadExcel} />
        </div>
      </div>
      <div className='w-full grid grid-col-1 xl:grid-cols-3 gap-2 p-3 md:p-6 border-t border-grey-100'>
        <div className='w-full flex flex-col justify-between space-y-3 xl:max-w-[374px] border border-grey-100 bg-grey-50/20 p-4 rounded-md'>
          <span className='w-[32px] h-[32px] flex justify-center items-center rounded-sm bg-grey-50'>
            <SvgMember width="32px" height="32px" />
          </span>

          <div className='w-full flex flex-col'>
            <Paragraph size='b4' color={COLORS.GREY[400]} weight='bold' className=''>
              Total memberships sold
            </Paragraph>
            <Paragraph size='b1' weight='semiBold' className=''>
              {activeClientMembershipsCount}
            </Paragraph>
          </div>
        </div>
        <div className='w-full flex flex-col justify-between space-y-3 xl:max-w-[374px] border border-grey-100 bg-grey-50/20 p-4 rounded-md'>
          <span className='w-[32px] h-[32px] flex justify-center items-center rounded-sm bg-grey-50'>
            <SvgMember width="32px" height="32px" />
          </span>

          <div className='w-full flex flex-col'>
            <Paragraph size='b4' color={COLORS.GREY[400]} weight='bold' className=''>
              Total active memberships
            </Paragraph>
            <Paragraph size='b1' weight='semiBold' className=''>
              {memberships?.length}
            </Paragraph>
          </div>
        </div>
        <div className='w-full flex flex-col justify-between space-y-3 xl:max-w-[374px] border border-grey-100 bg-grey-50/20 p-4 rounded-md'>
          <span className='w-[32px] h-[32px] flex justify-center items-center rounded-sm bg-grey-50'>
            <SvgMember width="32px" height="32px" />
          </span>

          <div className='w-full flex flex-col'>
            <Paragraph size='b4' color={COLORS.GREY[400]} weight='bold' className=''>
              Total expired memberships
            </Paragraph>
            <Paragraph size='b1' weight='semiBold' className=''>
              {expiredClientMembershipsCount}
            </Paragraph>
          </div>
        </div>
        <div className='w-full flex flex-col justify-between space-y-3 xl:max-w-[374px] border border-grey-100 bg-grey-50/20 p-4 rounded-md'>
          <span className='w-[32px] h-[32px] flex justify-center items-center rounded-sm bg-grey-50'>
            <SvgMember width="32px" height="32px" />
          </span>

          <div className='w-full flex flex-col'>
            <Paragraph size='b4' color={COLORS.GREY[400]} weight='bold' className=''>
              Total sold value (₦)
            </Paragraph>
            <Paragraph size='b1' weight='semiBold' className=''>
              {formatInToPrice(totalSoldValue)}
            </Paragraph>
          </div>
        </div>
        <div className='w-full flex flex-col justify-between space-y-3 xl:max-w-[374px] border border-grey-100 bg-grey-50/20 p-4 rounded-md'>
          <span className='w-[32px] h-[32px] flex justify-center items-center rounded-sm bg-grey-50'>
            <SvgMember width="32px" height="32px" />
          </span>

          <div className='w-full flex flex-col'>
            <Paragraph size='b4' color={COLORS.GREY[400]} weight='bold' className=''>
              Top selling memberships
            </Paragraph>
            {topPurchaseMembership ?
              <Paragraph size='b1' weight='semiBold' className=''>
                {topPurchaseMembership?.membership?.name} <span className="ml-2 text-b5">
                  {`(bought ${topPurchaseMembership?.purchaseCount || 0} times)`}
                </span>
              </Paragraph> : null}
          </div>
        </div>
      </div>
      <div className='w-full flex items-center justify-between border-t border-grey-100 p-3 md:p-6'>
        <Paragraph size='b4' weight='semiBold' className=''>
          Overview
        </Paragraph>
        {/* <Paginator pageInfo={null} {...{ goToNext, goToPrev }} /> */}
      </div>
      <FullTable
        rows={rows}
        headers={formatTableHeadersFilterArray(tipsReceivedHeadings)}
        onClick={openMembershipModal}
        hasBorder={true}
        hasFooterBg={false}
      />
      <ViewMembershipModal
        isVisible={isViewMembershipModalVisible}
        closeModal={closeModal}
        membership={membership}
      />
    </>
  )
}

const MembershipSalesReports = () => {
  const { getSalonFieldValue } = useSalonCache()
  const salonId = getSalonFieldValue('id')
  const [selectedDates, setSelectedDates] = useState<[Date, Date] | null>(
    getDefaultQueryDates()
  )
  const [membership, setMembership] = useState<MembershipProps | null>(null)
  const {
    isVisible: isViewMembershipModalVisible,
    closeModal: closeViewMembershipModal,
    openModal: openViewMembershipModal
  } = useModal()
  const [before, setBefore] = useState<string | null>(null)
  const [after, setAfter] = useState<string | null>(null)

  const startDate = formatDateToOriginalDate(selectedDates[0], "start")
  const endDate = formatDateToOriginalDate(selectedDates[1], "end")

  const {
    data,
    loading: isLoading,
    refetch
  } = useMergedClientMemberships({
    startDate,
    endDate
  })

  const memberships = useMemo(() => {
    if (data) {
      return data?.activeClientMemberships
    }
    return []
  }, [data])
  const purchases = useMemo(() => {
    if (data) {
      return data?.membershipPurchases
    }
    return []
  }, [data])
  const topPurchaseMembership = useMemo(() => {
    if (purchases?.length) {
      return purchases[0]
    }
  }, [purchases])
  const activeClientMembershipsCount = useMemo(() => {
    if (data) {
      return data?.activeClientMemberships?.length
    }
    return 0
  }, [data])
  const totalSoldValue = useMemo(() => {
    if (data) {
      return data?.activeClientMemberships?.reduce((acc, item) => acc + item?.membership?.fee, 0)
    }
    return 0
  }, [data])

  useEffect(() => {
    if (salonId) {
      refetch()
    }
  }, [refetch, salonId, selectedDates])

  const filename = `${createFileNameFromDates(selectedDates || getDefaultQueryDates())}_tips_report`;
  const formatMembershipsData = (data: ClientMembershipProps[]) => {
    const tableData = data?.map((item) => {
      return {
        'Name': item?.membership?.name,
        'Amount': formatInToPrice(item?.membership?.fee),
        'Reward': item?.membership?.rewardPercentage + ' %',
        'Services': item?.membership && item?.membership?.services?.length ? item?.membership?.services?.map((service) => service?.name).join(', ') : '-',
        'Valid For': item?.membership?.validityPeriod + ' Month(s)',
        'Clients': item?.membership && item?.membership?.clients?.length ? item?.membership?.clients?.map((client) => client?.firstName).join(', ') : '-',
      }
    })
    return tableData
  }
  const _handleDownload = () => {
    if (memberships) {
      handleDownload(formatMembershipsData(memberships), filename)
    }
  }

  const downloadExcel = () => {
    if (memberships) {
      handleExcelDownload(formatMembershipsData(memberships), filename, 'Tips')
    }
  }

  const goToNext = () => {
    // okay
  }

  const goToPrev = () => {
    // cool
  }

  const MEMBERSHIPS_SALES_HEADINGS = [
    {
      label: 'Membership name',
      value: 'name',
      show: true
    },
    {
      label: 'Date of purchase',
      value: 'date',
      show: true
    },
    {
      label: 'Client',
      value: 'client',
      show: true
    },
    {
      label: 'Services',
      value: 'services',
      show: true
    }, {
      label: 'Amount',
      value: 'amount',
      show: true
    }, {
      label: 'Amount used',
      value: 'used',
      show: true
    }, {
      label: 'Amount left',
      value: 'left',
      show: true
    },
  ]

  const generateMembershipTableData = (data: ClientMembershipProps[]) => {
    const tableData = data?.map((item) => {
      const used = item?.membership?.fee - item?.client?.membershipWalletBalance;
      const left = item?.membership?.fee - used;
      return {
        name: item?.membership?.name,
        date: getNumberMonthAndYearFromDate(item?.createdAt),
        client: item?.client?.firstName,
        services: item?.membership && item?.membership?.services?.length ? item?.membership?.services?.map((service) => service?.name).join(', ') : '-',
        amount: formatInToPrice(item?.membership?.fee),
        used: formatInToPrice(used),
        left: formatInToPrice(left),
      }
    })
    return tableData
  }

  const rows = generateMembershipTableData(memberships)

  const openMembershipModal = (id: number) => {
    console?.log({
      id, memberships
    })
    if (!Array?.isArray(memberships)) return;
    const membership = memberships[id];
    if (!membership) return;
    setMembership(membership?.membership);
    openViewMembershipModal()
  }


  const closeModal = () => {
    setMembership(null)
    closeViewMembershipModal()
  }
  return (
    <>
      {isLoading ? <ContentLoader /> : null}
      <div className='w-full flex flex-col xl:flex-row justify-between lg:items-center p-2 md:p-3 lg:p-6 gap-2'>
        <div className='flex flex-col md:flex-row xl:flex-col gap-3'>
          <Heading size='h9' variant='h1' weight='bold'>
            Membership Sales Overview
          </Heading>
          <div className='flex space-x-1 items-center'>
            <Paragraph size='b4' color={COLORS.GREY[300]} className='flex space-x-1 items-center'>
              <SvgCalendar width="16px" height="16px" />
              <span>{`${getNumberMonthAndYearFromDate(selectedDates[0])} - ${getNumberMonthAndYearFromDate(selectedDates[1])}`}</span>
            </Paragraph>
            <span>|</span>
            <Paragraph size='b4' color={COLORS.GREY[300]} className='flex space-x-1 items-center'>
              <SvgLocationPin width="16px" height="16px" />
              <span>{getSalonFieldValue('branchName')}</span>
            </Paragraph>
          </div>
        </div>
        <div className='flex flex-col md:flex-row md:items-center gap-4 md:gap-2'>
          <Paragraph size="b4" className='hidden md:flex'>Showing:</Paragraph>
          <CalendarPicker {...{ selectedDates, setSelectedDates }} />
          <ExportDataToCsvOrXlsx downloadCsv={_handleDownload} downloadXlsx={downloadExcel} />
        </div>
      </div>
      <div className='w-full grid grid-col-1 xl:grid-cols-3 gap-2 p-3 md:p-6 border-t border-grey-100'>
        <div className='w-full flex flex-col justify-between space-y-3 xl:max-w-[374px] border border-grey-100 bg-grey-50/20 p-4 rounded-md'>
          <span className='w-[32px] h-[32px] flex justify-center items-center rounded-sm bg-grey-50'>
            <SvgMember width="32px" height="32px" />
          </span>

          <div className='w-full flex flex-col'>
            <Paragraph size='b4' color={COLORS.GREY[400]} weight='bold' className=''>
              Total memberships sold
            </Paragraph>
            <Paragraph size='b1' weight='semiBold' className=''>
              {activeClientMembershipsCount}
            </Paragraph>
          </div>
        </div>
        <div className='w-full flex flex-col justify-between space-y-3 xl:max-w-[374px] border border-grey-100 bg-grey-50/20 p-4 rounded-md'>
          <span className='w-[32px] h-[32px] flex justify-center items-center rounded-sm bg-grey-50'>
            <SvgMember width="32px" height="32px" />
          </span>

          <div className='w-full flex flex-col'>
            <Paragraph size='b4' color={COLORS.GREY[400]} weight='bold' className=''>
              Total active memberships
            </Paragraph>
            <Paragraph size='b1' weight='semiBold' className=''>
              {memberships?.length}
            </Paragraph>
          </div>
        </div>
        <div className='w-full flex flex-col justify-between space-y-3 xl:max-w-[374px] border border-grey-100 bg-grey-50/20 p-4 rounded-md'>
          <span className='w-[32px] h-[32px] flex justify-center items-center rounded-sm bg-grey-50'>
            <SvgMember width="32px" height="32px" />
          </span>

          <div className='w-full flex flex-col'>
            <Paragraph size='b4' color={COLORS.GREY[400]} weight='bold' className=''>
              Total sold value (₦)
            </Paragraph>
            <Paragraph size='b1' weight='semiBold' className=''>
              {formatInToPrice(totalSoldValue)}
            </Paragraph>
          </div>
        </div>
        <div className='w-full flex flex-col justify-between space-y-3 xl:max-w-[374px] border border-grey-100 bg-grey-50/20 p-4 rounded-md'>
          <span className='w-[32px] h-[32px] flex justify-center items-center rounded-sm bg-grey-50'>
            <SvgMember width="32px" height="32px" />
          </span>

          <div className='w-full flex flex-col'>
            <Paragraph size='b4' color={COLORS.GREY[400]} weight='bold' className=''>
              Top selling memberships
            </Paragraph>
            {topPurchaseMembership ?
              <Paragraph size='b1' weight='semiBold' className=''>
                {topPurchaseMembership?.membership?.name} <span className="ml-2 text-b5">
                  {`(bought ${topPurchaseMembership?.purchaseCount || 0} times)`}
                </span>
              </Paragraph> : null}
          </div>
        </div>
        <div className='w-full flex flex-col justify-between space-y-3 xl:max-w-[374px] border border-grey-100 bg-grey-50/20 p-4 rounded-md'>
          <span className='w-[32px] h-[32px] flex justify-center items-center rounded-sm bg-grey-50'>
            <SvgUser width="24px" height="24px" />
          </span>

          <div className='w-full flex flex-col'>
            <Paragraph size='b4' color={COLORS.GREY[400]} weight='bold' className=''>
              MVP Client
            </Paragraph>
            <Paragraph size='b1' weight='semiBold' className=''>
              -
            </Paragraph>
          </div>
        </div>
      </div>
      <div className='w-full flex items-center justify-between border-t border-grey-100 p-3 md:p-6'>
        <Paragraph size='b4' weight='semiBold' className=''>
          Overview
        </Paragraph>
        {/* <Paginator pageInfo={null} {...{ goToNext, goToPrev }} /> */}
      </div>
      <FullTable
        rows={rows}
        headers={MEMBERSHIPS_SALES_HEADINGS}
        onClick={openMembershipModal}
        hasBorder={true}
        hasFooterBg={false}
      />
      <ViewMembershipModal
        isVisible={isViewMembershipModalVisible}
        closeModal={closeModal}
        membership={membership}
      />
    </>
  )
}
type AddMembershipProps = {
  membership?: MembershipProps | null;
  isVisible: boolean;
  closeModal: () => void;
}
const ViewMembershipModal = ({ isVisible, closeModal, membership }: AddMembershipProps) => {
  const getVoucherPillsForServices = (services: Service[]) => {
    if (Array.isArray(services) && services.length > 0) {
      return (<div className='flex flex-wrap gap-3'>
        {services.map(voucherService => {
          return <Pill variant='light' rounded='lg' className='py-2 px-2' key={voucherService?.id}>{voucherService?.name}</Pill>
        })}
      </div>)
    }

    return "-"
  }
  const nameMap = {
    "name": "Membership Name",
    "servicesIncluded": "Services applied to",
    "amount": "Amount",
    "reward": "Reward",
    "validityPeriod": "Valid for",
    "dateCreated": "Date created"
  };
  const membershipMap = {
    "name": membership?.name,
    "servicesIncluded": getVoucherPillsForServices(membership?.services),
    "amount": formatInToPrice(membership?.fee),
    "reward": `${membership?.rewardPercentage}%`,
    "validityPeriod": `${membership?.validityPeriod} month(s)`,
    "dateCreated": getNumberMonthAndYearFromDate(membership?.createdAt),
  };

  const purchasedRows = membership?.clients?.map((client: Client) => {
    return {
      date: getNumberMonthAndYearFromDate(client?.clientMembership?.purchaseDate),
      client: client?.firstName,
      location: client?.clientMembership?.salon?.branchName,
      lastUse: '-'
    }
  })
  return (
    <Modal
      show={isVisible}
      closeModal={closeModal}
      variant='right'
    >
      <div className='w-full relative my-[80px]'>
        <div className="fixed top-0 w-full bg-white flex border-b border-grey-100 cursor-pointer z-10">
          <div className='w-full hidden xl:flex space-x-2 px-6 py-5 items-start'>
            <Button
              variant='text'
              size='none'
              type='button'
              className='w-fit'
              fontSize='b4'
              onClick={closeModal}
            ><SvgArrowBack width="24px" height="24px" /> <span>Back</span></Button>
          </div>
          <div className='w-full flex xl:hidden space-x-[69px] p-4 items-center'>
            <Button
              variant='icon'
              size='square'
              type='button'
              rounded='md'
              className='w-fit'
              fontSize='b4'
              onClick={closeModal}
            ><SvgChevronLeft width="24px" height="24px" /></Button>
            <Paragraph size='b3' weight='bold'>Membership details</Paragraph>
          </div>
        </div>
        <div className='w-full space-y-12 px-6 py-4'>
          <div className='w-full flex items-center justify-between'>
            <div className="flex flex-col space-y-6">
              <Heading variant='h1' size='h9' weight='semiBold'>Membership details</Heading>
              <Paragraph size="b4">Find information about package here</Paragraph>
            </div>
          </div>
          <div className="w-full flex border border-grey-50 rounded-md p-4 flex-col space-y-3">
            {Object.entries(membershipMap).map(([key, value]) => (

              nameMap[key] && value !== null && (
                <div key={key} className="px-2 flex justify-between items-center space-x-4 bg-grey-10">
                  <div className="flex flex-col space-y-1 p-2">
                    <Paragraph size="b4" color="grey-300" className="text-left">
                      {nameMap[key]}
                    </Paragraph>
                  </div>
                  <div className="flex flex-col space-y-1">
                    <Paragraph size="b4" color={COLORS.GREY[900]} weight="semiBold" className="text-right">
                      {value}
                    </Paragraph>
                  </div>
                </div>
              )
            ))}
          </div>
        </div>
        <div className="pt-10">
          <FullTable
            headers={MEMBERSHIP_HISTORY}
            mobileHeaders={MEMBERSHIP_HISTORY_MOBILE}
            rows={purchasedRows}
          />
        </div>
      </div>
    </Modal>
  )
}

export default MembershipsReports
